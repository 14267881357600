import React from "react";
import { Droppable } from "react-beautiful-dnd";
import DraggableSection from "./DraggableSection";

const EditableCourse = (props) => {
  return (
    <div className="course bg-white">
      <Droppable droppableId={props.courseId} type="SECTION">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {props.courseData.sectionOrder.map((sectionRef, index) => {
              const section = props.courseData.sections[sectionRef];
              const taskLinks = section.courseTaskLinkList.map(
                (taskLinkRef) => props.courseData.courseTaskLinks[taskLinkRef]
              );
              return (
                <DraggableSection
                  key={section.sectionRef}
                  section={section}
                  taskLinks={taskLinks}
                  tasks={props.courseData.tasks}
                  index={index}
                  onTaskRemove={props.onTaskRemove}
                  onSectionRemove={props.onSectionRemove}
                  onSectionEdit={props.onSectionEdit}
                  projectDictData={props.projectDictData}
                />
              );
            })}
            {/* increase the space when needed */}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default EditableCourse;
