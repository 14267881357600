import React, { useState, useEffect } from "react";
import { CourseExecStatus } from "../../utils/constants";
import { Button, Row, ToggleButtonGroup } from "react-bootstrap";

import { loadUserEnrolmentList } from "../../actions";
import { ClassroomCourseCard } from "../course/CourseCard";

const MyCourses = () => {
  const [shouldReloadData, setShouldReloadData] = useState(true);
  const [userEnrolmentList, setUserEnrolmentList] = useState(null);
  const [mode, setMode] = useState(CourseExecStatus.ACTIVE);
  useEffect(() => {
    if (shouldReloadData) {
      loadUserEnrolmentList((data) => {
        setUserEnrolmentList(data);
        setShouldReloadData(false);
      });
    }
  }, [shouldReloadData]);

  const getCards = () => {
    return (
      userEnrolmentList &&
      userEnrolmentList.map((courseExecData, index) => {
        if (courseExecData.status === mode)
          return (
            <ClassroomCourseCard
              key={`card-${index}`}
              courseExecData={courseExecData}
              setShouldReloadData={setShouldReloadData}
            />
          );
        return null;
      })
    );
  };
  return (
    <>
      <div className={"pt-6 mb-3 d-flex justify-content-center"}>
        <h1 className="text-white">
          <strong>My Classrooms</strong>
        </h1>
      </div>
      <div className="mb-5 d-flex justify-content-center">
        <ToggleButtonGroup type="checkbox" className="mb-2">
          <Button
            className="rounded-pill border"
            variant={
              mode === CourseExecStatus.ACTIVE
                ? "warning border-warning text-white"
                : "primary"
            }
            value={CourseExecStatus.ACTIVE}
            onClick={(e) => setMode(e.currentTarget.value)}
          >
            Current
          </Button>
          <Button
            className="ml-2 rounded-pill border"
            variant={
              mode === CourseExecStatus.ARCHIVED
                ? "warning border-warning text-white"
                : "primary"
            }
            value={CourseExecStatus.ARCHIVED}
            onClick={(e) => setMode(e.currentTarget.value)}
          >
            Archived
          </Button>
        </ToggleButtonGroup>
      </div>
      {userEnrolmentList && mode && (
        <Row
          className={`${
            userEnrolmentList.filter(function(el) {
              return el.status === mode;
            }).length < 4
              ? "justify-content-center"
              : ""
          }`}
        >
          {getCards()}
        </Row>
      )}
    </>
  );
};

export default MyCourses;
