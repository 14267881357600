import React from "react";
import Header from "../../components/util/Header";
import { useSelector } from "react-redux";

const HeaderPageContainer = (props) => {
  const userDetailData = useSelector((state) => state.auth.userDetail);
  return (
    <div className="app application-offset">
      <div
        className="container-fluid container-application"
        style={{ maxWidth: "88.75rem" }}
      >
        <Header />
        {userDetailData && props.children}
      </div>
    </div>
  );
};

export default HeaderPageContainer;
