// Generate the format AntD upload component needs
export const getUploadDefaultFileList = (attachmentList) => {
  let result = [];
  for (const attachmentLink of attachmentList) {
    result.push({
      uid: attachmentLink.taskAttachmentLinkRef,
      name: attachmentLink.attachment.fileName,
      url: attachmentLink.attachment.url,
      status: "done",
    });
  }
  return result;
};
