import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { loadCourseDetail, duplicateCourse } from "../../actions";
import {
  handleGenericSaveFailed,
  handleGenericSaveSuccess,
} from "../../utils/CreateEditFormUtils";
import ProjectListDropDown from "../project/ProjectListDropdown";

const CourseDuplicateForm = ({ courseRef, onSaveSuccess, handleCloseForm }) => {
  const { addToast } = useToasts();
  const [courseDetailData, setCourseDetailData] = useState(null);
  const [selectedProjectRef, setSelectedProjectRef] = useState("no-project");

  useEffect(() => {
    loadCourseDetail(courseRef, (data) => setCourseDetailData(data));
  }, [courseRef]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      courseRef: courseRef,
      projectRef:
        selectedProjectRef !== "no-project"
          ? selectedProjectRef
          : courseDetailData.projectRef,
    };
    duplicateCourse(
      data,
      () =>
        handleGenericSaveSuccess(
          addToast,
          { object: "course", operation: "duplicated" },
          onSaveSuccess,
          handleCloseForm
        ),
      () => handleGenericSaveFailed(addToast, null, handleCloseForm)
    );
  };

  return (
    <>
      <p>
        Click to Duplicate "
        {courseDetailData === null ? "" : courseDetailData.title}" to the same
        project
      </p>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="form.ControlSelect1">
          <Form.Label>... Or Select another project here:</Form.Label>
          <ProjectListDropDown
            onProjectChange={(value) => setSelectedProjectRef(value)}
          />
        </Form.Group>
        <br /> <br />
        <Button size="md" variant="primary" type="submit" block>
          Duplicate
        </Button>
      </Form>
    </>
  );
};

export default CourseDuplicateForm;
