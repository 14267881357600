import React from "react";
import Tooltip from "./Tooltip";

const TRBScoreTableCellContent = ({ shouldAddDot, cellText }) => {
  const content = (
    <div className="font-weight-bolder p-3">
      {shouldAddDot ? (
        <div
          className="bg-warning rounded-circle"
          style={{
            width: "0.875rem",
            height: "0.875rem",
            position: "absolute",
            top: "50%",
            left: "0.5rem",
            transform: "translateY(-50%)",
            border: "solid white 2px",
          }}
        />
      ) : null}
      {cellText || <div className="opacity-0">-</div>}
    </div>
  );
  const tooltipText =
    cellText === "-" || shouldAddDot
      ? cellText === "-"
        ? "This question is not assessable"
        : "This response has been revised and resubmitted"
      : "";
  return tooltipText ? (
    <Tooltip title={tooltipText}>{content}</Tooltip>
  ) : (
    content
  );
};

export default TRBScoreTableCellContent;
