import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import {
  existsTaskResourceBlockWork,
  loadCourseCurriculum,
} from "../../../actions";
import { DragDropContext } from "react-beautiful-dnd";
import { Button, Card } from "react-bootstrap";
import EditableCourse from "./EditableCourse";
import { userUpdateCourseCurriculum } from "../../../actions";
import "../../../style/course-building.css";
import { ArrowLeftShort } from "react-bootstrap-icons";

const CourseEditor = ({
  courseRef,
  courseTitle,
  parentCourseCurriculumData,
  displayingCourseTaskLinkRef,
  handleSwitchTaskEdit = null,
  handleCurriculumUpdate = null,
}) => {
  const [courseCurriculumData, setCourseCurriculumData] = useState(
    parentCourseCurriculumData
  );
  const [currentEditTask, setCurrentEditTask] = useState(
    displayingCourseTaskLinkRef
  );
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const { addToast } = useToasts();
  const isFirstRun = React.useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // if the json data is completely the same as courseCurriculumData, then don't update
    loadCourseCurriculum(courseRef, (data) => {
      setCourseCurriculumData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentCourseCurriculumData]);

  useEffect(() => {
    if (updateSuccess) {
      addToast("Update successful.", {
        appearance: "success",
        autoDismiss: true,
      });
      setUpdateSuccess(false);
    }
  }, [updateSuccess, addToast]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let updatedState = null;

    if (result.type === "TASK" && source.droppableId !== "TASKLIBRARY") {
      // Moving within the existing course.
      const startSection = courseCurriculumData.sections[source.droppableId];
      const finishSection =
        courseCurriculumData.sections[destination.droppableId];

      if (startSection === finishSection) {
        // Moving in the same section
        const section = startSection;
        const newTaskIds = Array.from(section.courseTaskLinkList);
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);

        const newSection = {
          ...section,
          courseTaskLinkList: newTaskIds,
        };

        const newState = {
          ...courseCurriculumData,
          sections: {
            ...courseCurriculumData.sections,
            [newSection.sectionRef]: newSection,
          },
        };

        updatedState = newState;
      } else {
        // Moving to a different section
        const startLinkIds = Array.from(startSection.courseTaskLinkList);
        startLinkIds.splice(source.index, 1);
        const newStartSection = {
          ...startSection,
          courseTaskLinkList: startLinkIds,
        };

        const finishTaskIds = Array.from(finishSection.courseTaskLinkList);
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinishSection = {
          ...finishSection,
          courseTaskLinkList: finishTaskIds,
        };
        let newCourseTaskLinks = courseCurriculumData.courseTaskLinks;
        newCourseTaskLinks[draggableId].sectionRef =
          newFinishSection.sectionRef;
        const newState = {
          ...courseCurriculumData,
          courseTaskLinks: newCourseTaskLinks,
          sections: {
            ...courseCurriculumData.sections,
            [newStartSection.sectionRef]: newStartSection,
            [newFinishSection.sectionRef]: newFinishSection,
          },
        };

        updatedState = newState;
      }
    } else if (result.type === "SECTION") {
      // Move sections
      const newSectionIds = Array.from(courseCurriculumData.sectionOrder);
      newSectionIds.splice(source.index, 1);
      newSectionIds.splice(destination.index, 0, draggableId);

      const newState = {
        ...courseCurriculumData,
        sectionOrder: newSectionIds,
      };

      updatedState = newState;
    }
    if (updatedState) {
      setCourseCurriculumData(updatedState);
      onSaveClick(updatedState);
    }
  };

  const onSaveClick = async (courseCurriculumData) => {
    const newCurriculum = courseCurriculumData;
    await userUpdateCourseCurriculum(newCurriculum, (data) => {
      setCourseCurriculumData(data);
      if (handleCurriculumUpdate) handleCurriculumUpdate(data);
      setUpdateSuccess(true);
    });
  };

  const onTaskRemove = async (courseTaskLink) => {
    // Do not allow same task to be removed while editing
    if (!handleSwitchTaskEdit(courseTaskLink.courseTaskLinkRef, false)) {
      // if it is the same task then pop alert
      if (courseTaskLink.courseTaskLinkRef === currentEditTask) {
        alert(
          "Please save or cancel the current task changes before deleting this task."
        );
      }
      return;
    }
    const sectionId = courseTaskLink.sectionRef;
    const courseTaskLinkId = courseTaskLink.courseTaskLinkRef;
    const section = courseCurriculumData.sections[sectionId];
    const startLinkIds = Array.from(section.courseTaskLinkList);

    if (startLinkIds.length === 1) {
      if (
        !window.confirm(
          "You're about to delete the only task in this level\n\nAre you sure you want to delete it?"
        )
      )
        return;
    }

    const processSave = () => {
      startLinkIds.splice(
        startLinkIds.findIndex((item) => {
          return item === courseTaskLinkId;
        }),
        1
      );
      const newSection = {
        ...section,
        courseTaskLinkList: startLinkIds,
      };

      let newCourseTaskLinks = courseCurriculumData.courseTaskLinks;
      delete newCourseTaskLinks[courseTaskLinkId];
      const newState = {
        ...courseCurriculumData,
        courseTaskLinks: newCourseTaskLinks,
        sections: {
          ...courseCurriculumData.sections,
          [newSection.sectionRef]: newSection,
        },
      };
      setCourseCurriculumData(newState);
      onSaveClick(newState);
      // TODO: jumping to the first task may cause failure
      // const newFistTask = getFirstCourseTaskLinkRefFromCurriculum(newState);
      // handleSwitchTaskEdit(newFistTask);
    };
    existsTaskResourceBlockWork(
      {
        courseTaskLinkRef: courseTaskLinkId,
      },
      (data) => {
        const existsStudentWork = data.exists;
        if (existsStudentWork) {
          if (
            !window.confirm(
              "This task contains submitted student work. If you delete this task, you will permanently delete these submissions and any feedback or assessment left for them.\n\nAre you sure you want to delete this task?"
            )
          ) {
            return;
          }
        } else if (
          !window.confirm(
            "Once you delete a task, you can't get it back. All content and student work associated with that task will be deleted.\n\nAre you sure you want to delete this task?"
          )
        ) {
          return;
        }
        processSave();
      }
    );
  };

  const onTaskHide = (courseTaskLink) => {
    // Do not allow same task to be hidden while editing
    if (!handleSwitchTaskEdit(courseTaskLink.courseTaskLinkRef, false)) {
      // if it is the same task then pop alert
      if (courseTaskLink.courseTaskLinkRef === currentEditTask) {
        alert(
          "Please save or cancel the current task changes before hiding this task."
        );
      }
      return;
    }
    // update the state to set courseTaskLink.isHidden to true
    const courseTaskLinkId = courseTaskLink.courseTaskLinkRef;
    const processSave = () => {
      const newCourseTaskLinks = {
        ...courseCurriculumData.courseTaskLinks,
        [courseTaskLinkId]: {
          ...courseTaskLink,
          isHidden: !courseTaskLink.isHidden,
        },
      };
      const newState = {
        ...courseCurriculumData,
        courseTaskLinks: newCourseTaskLinks,
      };
      setCourseCurriculumData(newState);
      onSaveClick(newState);
    };
    if (!courseTaskLink.isHidden) {
      existsTaskResourceBlockWork(
        {
          courseTaskLinkRef: courseTaskLinkId,
        },
        (data) => {
          const existsStudentWork = data.exists;
          if (existsStudentWork) {
            if (
              !window.confirm(
                "This task contains submitted student work. If you hide this task, students will not be able to see their submitted work and any feedback or assessment left for it. \n\nAre you sure you want to hide it?"
              )
            ) {
              return;
            }
          }
          processSave();
        }
      );
    } else {
      processSave();
    }
  };

  const onTaskEdit = (courseTaskLink) => {
    if (handleSwitchTaskEdit(courseTaskLink.courseTaskLinkRef))
      setCurrentEditTask(courseTaskLink.courseTaskLinkRef);
    else
      alert(
        "Please save or cancel the current task changes before switching to another task."
      );
  };

  const onSectionRemove = (section) => {
    const sectionRef = section.sectionRef;
    const newSectionIds = Array.from(courseCurriculumData.sectionOrder);
    let newSections = courseCurriculumData.sections;
    let newCourseTaskLinks = courseCurriculumData.courseTaskLinks;
    const sectionCourseTaskLinkList =
      newSections[sectionRef].courseTaskLinkList;
    for (const linkRef of sectionCourseTaskLinkList) {
      delete newCourseTaskLinks[linkRef];
    }
    delete newSections[sectionRef];
    newSectionIds.splice(
      newSectionIds.findIndex((item) => {
        return item === sectionRef;
      }),
      1
    );

    const newState = {
      ...courseCurriculumData,
      courseTaskLinks: newCourseTaskLinks,
      sectionOrder: newSectionIds,
      sections: newSections,
    };

    setCourseCurriculumData(newState);
    onSaveClick(newState);
  };

  const onSectionEdit = (newTitle, sectionRef) => {
    let updatedSection = courseCurriculumData.sections[sectionRef];
    updatedSection.title = newTitle;
    const newState = {
      ...courseCurriculumData,
      sections: {
        ...courseCurriculumData.sections,
        [updatedSection.sectionRef]: updatedSection,
      },
    };

    setCourseCurriculumData(newState);
    onSaveClick(newState);
  };

  return (
    courseCurriculumData && (
      <Card>
        {/* let the user know curriculum editor will automatically saved, but the task edit will not */}
        <div className="mt-3 ml-2">
          <Button
            size="md"
            variant="outline-primary rounded-pill pl-3"
            onClick={() => {
              // Default classroom ref is the same as the course ref
              const courseExecRef = courseRef;
              // TODO: Use courseRef because it is the default courseRef. If multiple classrooms, back to the first un-deleted one
              window.location.href = `/classroom-detail/${courseExecRef}`;
            }}
          >
            <ArrowLeftShort size={20} className="mb-1" /> Course Management
          </Button>
        </div>
        <h4 className="text-dark px-3 pt-3 m-0">{courseTitle}</h4>
        <Card.Body className="p-0">
          <DragDropContext onDragEnd={onDragEnd}>
            {courseCurriculumData !== null && (
              <EditableCourse
                courseData={courseCurriculumData}
                courseId={courseCurriculumData.courseDetail.courseRef}
                currentEditCourseTaskLinkRef={currentEditTask}
                onTaskEdit={onTaskEdit}
                onTaskHide={onTaskHide}
                onTaskRemove={onTaskRemove}
                onSectionRemove={onSectionRemove}
                onSectionEdit={onSectionEdit}
              />
            )}
          </DragDropContext>
        </Card.Body>
      </Card>
    )
  );
};

export default CourseEditor;
