import React, { useState, useEffect, useRef } from "react";
import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import qs from "query-string";
import { useHistory } from "react-router-dom";
import {
  loadCourseCurriculum,
  loadCourseExecDetail,
  updateLastViewedCourseTaskLink,
} from "../../actions";
import SingleTaskView from "../task/SingleTaskView";
import CurriculumList from "./CurriculumList";
import "../../style/ck-content.css";
import Can from "../../utils/Can";
import { getFirstCourseTaskLinkRefFromCurriculum } from "../../utils/DataLogicUtils";
import { ArrowLeftShort, ListTask } from "react-bootstrap-icons";
import ModalButton from "../util/ModalButton";
import { CourseExecStatus, RoleType } from "../../utils/constants";
import UserClassroomProgressPanel from "../classroom/UserClassroomProgressPanel";
import {
  FixedClassroomArchiveAlert,
  FixedCoursePreviewAlert,
} from "../util/FixedTopAlert";

const CourseView = ({ courseRef, courseExecRef, isPreview = false }) => {
  let history = useHistory();
  const isAdmin = useSelector(
    (state) =>
      state.auth.userDetail &&
      state.auth.userDetail.userInfo.roleTypeName === RoleType.ADMIN
  );
  const queryParam = qs.parse(window.location.search);
  const [courseCurriculumData, setCourseCurriculumData] = useState(null);
  const [courseExecData, setCourseExecData] = useState(null);
  const [displayingCourseTaskLinkRef, setDisplayingCourseTaskLinkRef] =
    useState(null);
  const [displayDashboard, setDisplayDashboard] = useState(false);
  const updatedUnsavedTRBRefs = useRef([]);
  useEffect(() => {
    if (courseRef)
      loadCourseCurriculum(courseRef, (data) => {
        setCourseCurriculumData(data);
      });
    if (courseExecRef)
      loadCourseExecDetail(
        courseExecRef,
        (data) => {
          setCourseExecData(data);
          loadCourseCurriculum(data.course.courseRef, (data) => {
            setCourseCurriculumData(data);
          });
        },
        () => (window.location.href = "/access-denied")
      );
  }, []);

  useEffect(() => {
    if (!courseCurriculumData || (courseExecRef && !courseExecData)) return;
    if ("task" in queryParam) {
      const courseTaskLink = queryParam.task;
      if (courseTaskLink in courseCurriculumData.courseTaskLinks) {
        handleTaskClick(courseTaskLink);
        return;
      }
    }
    if (
      courseExecData?.userCourseExecLink?.lastViewedCourseTaskLinkRef &&
      courseExecData.userCourseExecLink.lastViewedCourseTaskLinkRef in
        courseCurriculumData.courseTaskLinks
    ) {
      const linkRef =
        courseExecData.userCourseExecLink.lastViewedCourseTaskLinkRef;
      handleTaskClick(linkRef);
    } else {
      handleTaskClick(
        getFirstCourseTaskLinkRefFromCurriculum(courseCurriculumData)
      );
    }
  }, [courseExecData, courseCurriculumData]);

  const updateURLSearch = (courseTaskLinkRef) => {
    const location = window.location;
    let search = null;
    if (search) search = qs.parse(location.search);
    const searchStr = qs.stringify({ ...search, task: courseTaskLinkRef });
    history.replace({
      pathname: location.pathname,
      search: searchStr,
    });
  };

  const handleTaskClick = (courseTaskLinkRef) => {
    if (courseExecRef && !isAdmin) {
      const data = {
        courseExecRef: courseExecRef,
        courseTaskLinkRef: courseTaskLinkRef,
      };
      updateLastViewedCourseTaskLink(
        data,
        () => {},
        () => {}
      );
    }
    updateURLSearch(courseTaskLinkRef);
    setDisplayingCourseTaskLinkRef(courseTaskLinkRef);
    setDisplayDashboard(false);
  };

  const confirmExitTask = () => {
    if (updatedUnsavedTRBRefs.current.length === 0) return true;
    if (
      window.confirm(
        "You have unsubmitted answers on this page.\n" +
          "If you leave or refresh this page, you might lose your work.\n\n" +
          "Are you sure you want to leave this page?"
      )
    ) {
      updatedUnsavedTRBRefs.current = [];
      return true;
    } else return false;
  };

  const CurriculumMenu = ({ handleItemSelected }) => (
    <>
      {courseExecRef && (
        <Can
          perform="course-exec-detail:management"
          yes={() => (
            <div className="mt-3 ml-2">
              <Button
                size="md"
                variant="outline-primary rounded-pill pl-3"
                onClick={() =>
                  (window.location.href = `/classroom-detail/${courseExecRef}`)
                }
              >
                <ArrowLeftShort size={20} className="mb-1" /> Course Management
              </Button>
            </div>
          )}
          no={() => null}
        />
      )}
      <h4 className="text-dark px-3 pt-3 m-0">
        {courseCurriculumData &&
          courseCurriculumData.courseDetail.project.title}
      </h4>

      {courseExecRef && (
        <Can
          perform="enrolled:student"
          yes={() => (
            <div className="">
              <Button
                className="ml-3 mt-3 mb-4"
                size="sm"
                onClick={() => {
                  if (!confirmExitTask()) return;
                  setDisplayingCourseTaskLinkRef(null);
                  setDisplayDashboard(true);
                }}
              >
                View Progress & Markbook
              </Button>
              <hr className="m-0" />
            </div>
          )}
          no={() => null}
        />
      )}
      <div className="py-3">
        <CurriculumList
          courseCurriculumData={courseCurriculumData}
          handleTaskClick={(linkRef) => {
            if (!isAdmin) {
              if (linkRef === displayingCourseTaskLinkRef) return;
              if (!confirmExitTask()) return;
            }
            handleTaskClick(linkRef);
            if (handleItemSelected) handleItemSelected();
          }}
          selectedCourseTaskLinkRef={displayingCourseTaskLinkRef}
        />
      </div>
    </>
  );

  const CurriculumListModalButton = () => (
    <div
      className="d-block d-lg-none mt-6 rounded-circle text-light"
      style={{
        backgroundColor: "rgba(255, 168, 52, 0.6)",
        position: "fixed",
        height: "4rem",
        width: "4rem",
        zIndex: "3",
      }}
    >
      <ListTask
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        size={32}
      />
    </div>
  );

  const ModalCurriculumMenu = ({ handleCloseForm }) => (
    <CurriculumMenu handleItemSelected={handleCloseForm} />
  );

  return (
    <Container fluid className="h-100 w-100">
      <ModalButton
        customisedClickableComponent={<CurriculumListModalButton />}
        variant="link"
        className="text-primary"
        modalTitle="Choose a Task"
      >
        <ModalCurriculumMenu />
      </ModalButton>
      <div
        className="h-100 bg-white mt-7 pb-10 d-none d-lg-block"
        style={{
          overflowY: "scroll",
          width: "24rem",
          position: "fixed",
          zIndex: 1,
        }}
      >
        <CurriculumMenu />
      </div>
      <div className="d-flex h-100 w-100">
        <div
          className="h-100 d-lg-block d-none"
          style={{ paddingLeft: "24rem" }}
        ></div>

        <div className="h-100 pt-6 pt-lg-7 course-task-view-container">
          {courseExecData &&
            courseExecData.status === CourseExecStatus.ARCHIVED && (
              <FixedClassroomArchiveAlert isInTaskDetail={true} />
            )}
          {isPreview && <FixedCoursePreviewAlert courseRef={courseRef} />}
          {courseExecData &&
            !displayingCourseTaskLinkRef &&
            displayDashboard && (
              <Can
                perform="enrolled:student"
                yes={() => (
                  <UserClassroomProgressPanel courseExecRef={courseExecRef} />
                )}
                no={() => null}
              />
            )}
          {displayingCourseTaskLinkRef && courseCurriculumData && (
            <SingleTaskView
              taskRef={
                courseCurriculumData.courseTaskLinks[
                  displayingCourseTaskLinkRef
                ].taskRef
              }
              courseExecRef={courseExecRef}
              courseExecData={courseExecData}
              courseTaskLinkRef={displayingCourseTaskLinkRef}
              updatedUnsavedTRBRefs={updatedUnsavedTRBRefs}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default CourseView;
