import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import {
  loadUserSchoolLinkList,
  createUserCourseLink,
  loadCourseDetail,
} from "../../actions";
import { RoleType } from "../../utils/constants";

const UserCourseLinkCreateEditForm = ({
  courseRef,
  mode,
  onSaveSuccess,
  handleCloseForm,
}) => {
  const [linkListData, setLinkListData] = useState(null);
  const [schoolRef, setSchoolRef] = useState(null);
  const { addToast } = useToasts();
  const userEmail = useSelector((state) => state.auth.userDetail.email);

  useEffect(() => {
    loadCourseDetail(courseRef, (data) => {
      // TODO: not working yet
      setSchoolRef(data.schoolRef);
    });
  }, [courseRef]);

  useEffect(() => {
    if (schoolRef) {
      loadUserSchoolLinkList(schoolRef, RoleType.TEACHER, (data) => {
        setLinkListData([
          {
            userSchoolLinkRef: "",
            user: { id: "", firstName: "", lastName: "", email: "" },
          },
          ...data,
        ]);
      });
    }
  }, [schoolRef]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userRef = event.target.userRef.value;
    if (!userRef) {
      addToast("Please select a user.", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    switch (mode) {
      case "create":
        const data = { courseRef, users: [userRef] };
        createUserCourseLink(data, () => {
          addToast("User successfully added.", {
            appearance: "success",
            autoDismiss: true,
          });
          onSaveSuccess();
          handleCloseForm();
        });
        break;
      default:
        console.warn(`No mode called: ${mode}`);
    }
  };

  return (
    <div>
      {linkListData && (
        <Form className="InputForm" onSubmit={handleSubmit}>
          <Form.Group controlId="form.ControlSelect1">
            <Form.Label>
              Choose from the list of teachers with Cura accounts at your school
            </Form.Label>
            <Form.Control as="select" name="userRef">
              {linkListData.map((userSchoolLink) => {
                return (
                  <option
                    key={userSchoolLink.user.id}
                    value={userSchoolLink.user.id}
                    disabled={userEmail === userSchoolLink.user.email}
                  >
                    {`${userSchoolLink.user.firstName} ${userSchoolLink.user.lastName} --- ${userSchoolLink.user.email}`}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <br /> <br />
          <Button size="md" variant="primary" type="submit" block>
            Save
          </Button>
        </Form>
      )}
    </div>
  );
};

export default UserCourseLinkCreateEditForm;
