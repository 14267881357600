import MiniSearch from "minisearch";

export const buildMiniSearchIndex = (courseList, projectList) => {
  let miniSearch = new MiniSearch({
    fields: ["textSearchMerged", "title"], // fields to index for full-text search
    storeFields: [
      "courseRef",
      "contentMetadata",
      "countryMetadata",
      "courseRef",
      "courseType",
      "createdAt",
      "id",
      "project",
      "projectRef",
      "textSearchMerged",
      "thumbnail",
      "studentSuccessMetadata",
    ], // fields to return with search results
  });
  const indexCourses = courseList.map((course) => {
    const project = projectList[course.projectRef];
    return {
      ...course,
      id: course.courseRef,
      // For array like search, check this https://github.com/lucaong/minisearch/issues/63
      textSearchMerged:
        project.title + " " + course?.contentMetadata?.keyword?.join(" ") ?? "",
      project: project,
      title: project.title,
    };
  });
  // Index all documents
  miniSearch.addAll(indexCourses);
  return miniSearch;
};
