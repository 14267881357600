import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { updateLastViewedCourseTaskLink } from "../../actions";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const TaskTitlePopup = ({
  sectionNumber,
  taskNumber,
  title,
  courseTaskLinkRef,
  courseExecRef,
  isHidden,
}) => {
  const handleTaskClicked = (courseTaskLinkRef) => {
    const data = {
      courseExecRef: courseExecRef,
      courseTaskLinkRef: courseTaskLinkRef,
    };
    updateLastViewedCourseTaskLink(
      data,
      () => {},
      () => {}
    );
    window.location.href = `/classroom/${courseExecRef}`;
    return;
  };
  const textColorClass = isHidden ? "text-secondary" : "text-primary";
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="bottom"
      overlay={
        <Popover>
          {!isHidden && (
            <Popover.Title className={textColorClass}>
              Level {sectionNumber}, Task {taskNumber}{" "}
              {isHidden && <FontAwesomeIcon icon={faEyeSlash} />}
            </Popover.Title>
          )}
          <Popover.Content
            as="h6"
            className="text-dark font-weight-bolder mt-2"
          >
            {title}
          </Popover.Content>
        </Popover>
      }
    >
      <div
        className={`${textColorClass} font-weight-bolder cursor-pointer d-flex justify-content-between align-items-center`}
        onClick={() => handleTaskClicked(courseTaskLinkRef)}
      >
        <div className="mx-auto">
          {isHidden ? (
            <span>
              Hidden
              <br />
              Task
            </span>
          ) : (
            <span>
              <span className="font-weight-normal">Level {sectionNumber}</span>
              <br />
              <u>Task {taskNumber}</u>
            </span>
          )}
        </div>
        <div style={{ position: "absolute", right: "1rem" }}>
          {isHidden && <FontAwesomeIcon icon={faEyeSlash} />}
        </div>
      </div>
    </OverlayTrigger>
  );
};

export default TaskTitlePopup;
