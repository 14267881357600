import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { useParams } from "react-router-dom";
import { getClassroomAnalyticsList } from "../../../actions";
import { ThreeDots } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import {
  CustomMenu,
  CustomToggle,
} from "../../../components/util/PlainStyleDropdown";
import { CourseExecStatus } from "../../../utils/constants";
import ModalButton from "../../../components/util/ModalButton";
import ClassroomUnarchiveForm from "../../../components/createEditForms/ClassroomUnarchiveForm";
import ClassroomArchiveForm from "../../../components/createEditForms/ClassroomArchiveForm";
import ClassroomDeleteForm from "../../../components/createEditForms/ClassroomDeleteForm";

const getStatusText = (record) => {
  if (record.status === CourseExecStatus.ARCHIVED) return "Archived";
  if (record.status === CourseExecStatus.INACTIVE) return "Deleted";
  const daysSinceCreated =
    (Date.now() - Date.parse(record.createAt)) / (24 * 60 * 60 * 1000);
  const daysSinceLastTeacherAccess =
    record.latestDateTeacher === null
      ? null
      : (Date.now() - Date.parse(record.latestDateTeacher)) /
        (24 * 60 * 60 * 1000);
  const daysSinceLastAccess =
    daysSinceLastTeacherAccess === null
      ? daysSinceCreated
      : Math.min(daysSinceCreated, daysSinceLastTeacherAccess);
  if (daysSinceLastAccess >= 42) return "Inactive";
  if (daysSinceLastAccess < 42 && daysSinceLastAccess > 14) return "At Risk";
  return "Active";
};

const preprocessData = (data) => {
  data.forEach((item) => {
    item.displayStatus = getStatusText(item);
  });
  return data;
};

const ClassroomListTable = () => {
  const { schoolRef } = useParams();
  const [schoolClassroomList, setSchoolClassroomList] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);
  useEffect(() => {
    if (shouldReloadData) {
      getClassroomAnalyticsList(
        { schoolRef, timezone_str: "Australia/Melbourne" },
        (data) => {
          setSchoolClassroomList(preprocessData(data));
        }
      );
      setShouldReloadData(false);
    }
  }, [shouldReloadData]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSortProps = (dataIndex, type = Number) => {
    return {
      sorter: {
        compare: (a, b) => {
          if (type === String)
            return (b[dataIndex] || "").localeCompare(a[dataIndex] || "");
          if (type === Number) return a[dataIndex] - b[dataIndex];
        },
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
    };
  };

  const getSchoolColumn = () => {
    if (!schoolRef)
      return [
        {
          title: <div className="text-dark">school</div>,
          dataIndex: "schoolName",
          key: "schoolName",
          className: "text-primary cursor-pointer bg-white",
          onCell: (record, rowIndex) => {
            return {
              onClick: (ev) => {
                window
                  .open(
                    "/admin/schools/school/" + record.schoolRef + "/dashboard",
                    "_blank"
                  )
                  .focus();
              },
            };
          },
          width: "12rem",
          fixed: "left",
          ...getColumnSortProps("schoolName", String),
          ...getColumnSearchProps("schoolName"),
        },
      ];
    return [];
  };

  const columns = [
    ...getSchoolColumn(),
    {
      title: "course",
      dataIndex: "project",
      key: "project",
      width: "12rem",
      className: "bg-white",
      fixed: "left",
      ...getColumnSortProps("project", String),
      ...getColumnSearchProps("project"),
    },
    {
      title: <div className="text-dark">classroom</div>,
      dataIndex: "classroomName",
      key: "classroomName",
      className: "text-primary cursor-pointer bg-white",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            window
              .open("/classroom-detail/" + record.courseExecRef, "_blank")
              .focus();
          },
        };
      },
      width: "8rem",
      fixed: "left",
      ...getColumnSortProps("classroomName", String),
      ...getColumnSearchProps("classroomName"),
    },
    {
      title: "status",
      dataIndex: "displayStatus",
      key: "displayStatus",
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "At Risk",
          value: "At Risk",
        },
        {
          text: "Archived",
          value: "Archived",
        },
        {
          text: "Deleted",
          value: "Deleted",
        },
        {
          text: "Inactive",
          value: "Inactive",
        },
      ],
      onFilter: (value, record) => record.displayStatus.indexOf(value) === 0,
      ...getColumnSortProps("displayStatus", String),
      width: "7rem",
    },
    {
      title: "",
      key: "action",
      className: "text-primary cursor-pointer",
      render: (text, record) =>
        record.status !== CourseExecStatus.INACTIVE && (
          <Dropdown className="cursor-pointer">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <ThreeDots />
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu} style={{ zIndex: 100 }}>
              {record.status === CourseExecStatus.ACTIVE && (
                <>
                  <Dropdown.ItemText>
                    <ModalButton
                      buttonText="Archive Classroom"
                      modalTitle="Archive Classroom"
                      variant="link"
                      className="text-dark p-0 text-sm"
                    >
                      <ClassroomArchiveForm
                        courseExecRef={record.courseExecRef}
                        onSaveSuccess={() => setShouldReloadData(true)}
                      />
                    </ModalButton>
                  </Dropdown.ItemText>
                  <Dropdown.Divider />
                </>
              )}
              {record.status === CourseExecStatus.ARCHIVED && (
                <>
                  <Dropdown.ItemText>
                    <ModalButton
                      buttonText="Unrchive Classroom"
                      modalTitle="Unrchive Classroom"
                      variant="link"
                      className="text-dark p-0 text-sm"
                    >
                      <ClassroomUnarchiveForm
                        courseExecRef={record.courseExecRef}
                        onSaveSuccess={() => setShouldReloadData(true)}
                      />
                    </ModalButton>
                  </Dropdown.ItemText>
                  <Dropdown.Divider />
                </>
              )}

              <Dropdown.ItemText>
                <ModalButton
                  buttonText="Delete Classroom"
                  modalTitle="Delete Classroom"
                  variant="link"
                  className="text-dark p-0 text-sm"
                >
                  <ClassroomDeleteForm
                    courseExecRef={record.courseExecRef}
                    onSaveSuccess={() => setShouldReloadData(true)}
                  />
                </ModalButton>
              </Dropdown.ItemText>
            </Dropdown.Menu>
          </Dropdown>
        ),
      width: "3rem",
    },
    {
      title: "teachers",
      dataIndex: "teachers",
      key: "teachers",
      width: "12rem",
      ellipsis: true,
      render: (text) => (text === "INACTIVE" ? "DELETED" : "test"),
      ...getColumnSearchProps("teachers"),
    },
    {
      title: "No. Students",
      dataIndex: "studentsCount",
      key: "studentsCount",
      width: "8rem",
      ...getColumnSortProps("studentsCount"),
    },
    {
      title: "Teacher Last Access",
      dataIndex: "latestDateTeacher",
      key: "latestDateTeacher",
      width: "8rem",
      ...getColumnSortProps("latestDateTeacher", String),
      ...getColumnSearchProps("latestDateTeacher"),
    },
    {
      title: "Student Last Access",
      dataIndex: "latestDateStudent",
      key: "latestDateStudent",
      width: "8rem",
      ...getColumnSortProps("latestDateStudent", String),
      ...getColumnSearchProps("latestDateStudent"),
    },
    {
      title: "createAt",
      dataIndex: "createAt",
      key: "createAt",
      width: "8rem",
      ...getColumnSortProps("createAt", String),
      ...getColumnSearchProps("createAt"),
    },
    {
      title: "scored",
      dataIndex: "scored",
      render: (text, record) => text + " / " + record.totalScorableSubmissions,
      key: "scored",
      width: "8rem",
      ...getColumnSortProps("scored"),
    },
    {
      title: "Feedback",
      dataIndex: "notScoredButHasFeedback",
      render: (text, record) =>
        text +
        record.unscorableButWithFeedback +
        " / " +
        (record.totalScorableSubmissions + record.totalUnscorableSubmissions),
      key: "notScoredButHasFeedback",
      width: "8rem",
      ...getColumnSortProps("notScoredButHasFeedback"),
    },
    {
      title: "Submissions",
      dataIndex: "totalScorableSubmissions",
      key: "totalScorableSubmissions",
      width: "8rem",
      render: (text, record) => text + record.totalUnscorableSubmissions,
      ...getColumnSortProps("totalScorableSubmissions"),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={schoolClassroomList}
      className="min-height-500-table"
      scroll={{ x: "calc(100% - 18rem)", y: 500 }}
      pagination={{ position: ["bottomRight"], pageSize: 25 }}
      bordered
    />
  );
};
export default ClassroomListTable;
