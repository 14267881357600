import React from "react";
import { CheckCircle } from "react-bootstrap-icons";

const PasswordResetDone = () => {
  return (
    <div className="text-center">
      <div className="AuthPanel">
        <h1 className="mb-4">
          <CheckCircle />
          {"  "}Your password has been reset!
        </h1>
        <a href="/">Back to Login</a>
      </div>
    </div>
  );
};

export default PasswordResetDone;
