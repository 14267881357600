import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { LikeFilled, DislikeFilled } from "@ant-design/icons";
import ModalButton from "../util/ModalButton";
import TaskFeedbackCreateForm from "../createEditForms/TaskFeedbackCreateFrom";
import { existsTaskFeedback } from "../../actions";
import Can from "../../utils/Can";
import { ChatRightTextFill } from "react-bootstrap-icons";

const TaskFeedbackBlock = ({
  courseExecRef,
  courseTaskLinkRef,
  taskDetailData,
}) => {
  const [taskFeedbackExists, setTaskFeedbackExists] = useState(true);
  const { addToast } = useToasts();
  useEffect(() => {
    existsTaskFeedback(
      {
        courseExecRef: courseExecRef,
        courseTaskLinkRef: courseTaskLinkRef,
      },
      (data) => {
        setTaskFeedbackExists(data.exists);
      },
      () => {}
    );
  }, []);

  const CreateEditForm = ({ rating, handleCloseForm }) => (
    <TaskFeedbackCreateForm
      courseExecRef={courseExecRef}
      courseTaskLinkRef={courseTaskLinkRef}
      taskDetailData={taskDetailData}
      defaultRating={rating}
      onSaveSuccess={() => {
        addToast("Thank you for your feedback", {
          appearance: "success",
          autoDismiss: true,
        });
        setTaskFeedbackExists(true);
      }}
      onSaveFailed={() =>
        addToast("An error has occured. Please try again later.", {
          appearance: "error",
          autoDismiss: true,
        })
      }
      handleCloseForm={handleCloseForm}
    />
  );

  const handleHide = () =>
    window.confirm("If you exit, your feedback will not be submitted");

  const RatingModalButton = ({ rating }) => (
    <ModalButton
      modalTitle="Rate this Task"
      modalSize="xl"
      backdrop="static"
      onHide={handleHide}
      customisedClickableComponent={
        <div
          className="border border-white rounded-circle p-2 ml-3 cursor-pointer d-flex justify-content-center"
          style={{ height: "3rem", width: "3rem", alignItems: "center" }}
        >
          {rating > 0 ? (
            <LikeFilled style={{ fontSize: "1.5rem" }} />
          ) : (
            <DislikeFilled style={{ fontSize: "1.5rem" }} />
          )}
        </div>
      }
      customisedModalBodyClassName="p-0"
    >
      <CreateEditForm rating={rating} />
    </ModalButton>
  );

  const AdminModalButton = () => (
    <ModalButton
      buttonText={
        <>
          <ChatRightTextFill className="mx-3" />
          Task Feedback
        </>
      }
      modalTitle="Task Feedback"
      className="border-light pl-2"
      modalSize="xl"
      backdrop="static"
      onHide={handleHide}
      customisedModalBodyClassName="p-0"
    >
      <CreateEditForm />
    </ModalButton>
  );

  return (
    courseExecRef &&
    courseTaskLinkRef && (
      <Can
        perform="task-feedback-create-edit:admin"
        yes={() => <AdminModalButton />}
        no={() =>
          !taskFeedbackExists && (
            <div className="my-auto d-flex">
              <div className="my-auto mr-2">Rate this task</div>
              <RatingModalButton rating={1} />
              <RatingModalButton rating={0} />
            </div>
          )
        }
      />
    )
  );
};

export default TaskFeedbackBlock;
