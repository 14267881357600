import React, { useState, useEffect, useRef } from "react";
import { Button, InputGroup, Form, FormControl } from "react-bootstrap";

const Editable = ({ childRef, text, onSave, maxLength, ...props }) => {
  const [isEditing, setEditing] = useState(false);
  const inputEl = useRef(null);
  useEffect(() => {
    if (inputEl && inputEl.current && isEditing === true) {
      inputEl.current.focus();
    }
  }, [isEditing, inputEl]);

  const onSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;

    onSave(form.elements.inputText.value);
    setEditing(false);
  };

  return (
    <section {...props}>
      {isEditing ? (
        <div>
          <Form onSubmit={onSubmit}>
            <InputGroup size="sm">
              <Form.Label srOnly>Title</Form.Label>
              <FormControl
                placeholder=""
                aria-label="section-form-control"
                aria-describedby="basic-addon2"
                defaultValue={text}
                name="inputText"
                maxLength={maxLength}
              />
              <InputGroup.Append>
                <Button size="xs" variant="success" type="submit">
                  ✓
                </Button>
                <Button
                  size="xs"
                  variant="danger"
                  onClick={() => setEditing(false)}
                >
                  X
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </div>
      ) : (
        <div onClick={() => setEditing(true)}>
          <div className="text-dark font-weight-bold text-truncate">{text}</div>
        </div>
      )}
    </section>
  );
};

export default Editable;
