import React from "react";
import { Media } from "react-bootstrap";
import { getNameInitials } from "../../utils/GenericUtils";

const AvatarWithText = ({ userDetail, showEmail = false }) => {
  const name = userDetail.firstName + " " + userDetail.lastName;
  return (
    <Media className="align-items-center">
      <div>
        <div className="avatar-parent-child">
          <span
            className={`avatar text-white rounded-circle ${
              userDetail.isActive ? "bg-primary-light" : "bg-secondary-light"
            }`}
          >
            {getNameInitials(name)}
          </span>
        </div>
      </div>
      <div className="media-body ml-3">
        <div className="name h6 mb-0">{name}</div>
        <div className="d-block font-weight-bold text-secondary">
          {showEmail && userDetail.isActive ? userDetail.email : null}
        </div>
      </div>
    </Media>
  );
};

export default AvatarWithText;
