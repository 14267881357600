import React from "react";
import { v4 as uuid } from "uuid";
import { Table } from "antd";
import { ProgressBar } from "react-bootstrap";
import { CheckCircleFill, CircleHalf, DashCircle } from "react-bootstrap-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import TaskTitlePopup from "../task/TaskTitlePopup";
import AntdTableDownloadExcelButton from "../util/AntdTableDownloadExcelButton";

const CourseProgressTable = ({
  courseExecData,
  courseExecUserData,
  studentTaskProgressData,
  tableHeaderData,
  mode = "progress", // progress/score
}) => {
  const userDataList = studentTaskProgressData.userDataList;
  const courseTaskLinkInfoDict = studentTaskProgressData.courseTaskLinkInfoDict;
  const size = "1.2rem";
  const greenColor = "var(--cura-green)";
  const orangeColor = "var(--warning-light)";
  const dashSymbol = "-";
  const progressStateCircleIconDict = {
    noSubmission: <DashCircle size={size} className="text-secondary" />,
    partialSubmission: (
      <CircleHalf size={size} style={{ color: orangeColor }} />
    ),
    completeSubmission: (
      <CheckCircleFill size={size} style={{ color: greenColor }} />
    ),
  };
  const getProgressIcon = (progressData, courseTaskLinkRef) => {
    if (
      !(courseTaskLinkRef in courseTaskLinkInfoDict) ||
      courseTaskLinkInfoDict[courseTaskLinkRef].trbCount === 0
    )
      return dashSymbol;
    if (!progressData || progressData.totalSubmittedResponses === 0)
      return progressStateCircleIconDict.noSubmission;
    if (
      progressData.totalSubmittedResponses ===
      progressData.totalSubmittedResponsesOutOf
    )
      return progressStateCircleIconDict.completeSubmission;
    return progressStateCircleIconDict.partialSubmission;
  };
  const getTaskColumns = (
    tableHeaderData,
    userDataList,
    isTextOnly = false
  ) => {
    let displayTaskNumber = 0;
    let currentSectionNumber = -1;
    return tableHeaderData.map((item, index) => {
      if (!item.isHidden) {
        displayTaskNumber++;
        if (currentSectionNumber === -1) {
          currentSectionNumber = item.sectionNumber;
        } else if (item.sectionNumber !== currentSectionNumber) {
          currentSectionNumber = item.sectionNumber;
          displayTaskNumber = 1;
        }
      }

      return {
        title: (
          <TaskTitlePopup
            sectionNumber={item.sectionNumber}
            taskNumber={displayTaskNumber}
            title={item.title}
            courseTaskLinkRef={item.courseTaskLinkRef}
            courseExecRef={courseExecData.courseExecRef}
            isHidden={item.isHidden}
          />
        ),
        __excelTitle__: `Level ${item.sectionNumber}, Task ${displayTaskNumber}`,
        key: `task${index}`,
        padding: 0,
        width: "10rem",
        align: "center",
        className: item.isHidden ? "bg-gray-100" : "",
        render(text, record) {
          const progressData =
            record.user.id in userDataList &&
            item.courseTaskLinkRef in
              userDataList[record.user.id].courseTaskLinks
              ? userDataList[record.user.id].courseTaskLinks[
                  item.courseTaskLinkRef
                ]
              : null;
          const scorePercentage =
            progressData && progressData.taskScoreOutOf !== 0
              ? `${Math.round(
                  (progressData.totalScore / progressData.taskScoreOutOf) * 100
                )}%`
              : dashSymbol;
          const scoreOutOfStr = `(${
            progressData
              ? `${progressData.totalScore}/${progressData.taskScoreOutOf}`
              : `0/0`
          })`;
          let scoreCell = dashSymbol;
          let textCell = dashSymbol;
          if (
            item.courseTaskLinkRef in courseTaskLinkInfoDict &&
            courseTaskLinkInfoDict[item.courseTaskLinkRef].scoringAvailable
          ) {
            scoreCell = (
              <>
                <span className="font-weight-bolder">{scorePercentage}</span>{" "}
                {scoreOutOfStr}
              </>
            );
            textCell = `${scorePercentage} ${scoreOutOfStr}`;
          }
          return {
            children: isTextOnly ? (
              <>{textCell}</>
            ) : (
              <>
                {mode === "progress"
                  ? getProgressIcon(progressData, item.courseTaskLinkRef)
                  : scoreCell}
              </>
            ),
          };
        },
      };
    });
  };

  const getColumns = (isTextOnly = false) => [
    {
      title: <div className="font-weight-bolder">Student</div>,
      __excelTitle__: "Student",
      padding: 0,
      width: "10rem",
      key: "name",
      fixed: "left",
      align: "center",
      render(text, record) {
        return {
          children: (
            <div className="font-weight-bold">
              {record.user.firstName + " " + record.user.lastName}
            </div>
          ),
        };
      },
    },
    ...getTaskColumns(tableHeaderData, userDataList, isTextOnly),
    {
      title: (
        <div className="font-weight-bolder">
          {mode === "progress" ? "Course Progress" : "Course Score"}
        </div>
      ),
      __excelTitle__: mode === "progress" ? "Course Progress" : "Course Score",
      width: "12rem",
      dataIndex: "score",
      key: "score",
      fixed: "right",
      align: "center",
      render(text, record) {
        const courseProgressData =
          record.user.id in userDataList ? userDataList[record.user.id] : null;
        const courseResponsePercentage =
          courseProgressData && courseProgressData.courseResponseOutOf !== 0
            ? Math.round(
                (courseProgressData.totalCourseResponse /
                  courseProgressData.courseResponseOutOf) *
                  100
              )
            : 0;
        const TotalProgressCell = () => (
          <>
            <div className="d-flex justify-content-between">
              <ProgressBar
                className="ml-auto my-auto"
                style={{
                  width: "6rem",
                }}
              >
                <ProgressBar
                  now={courseResponsePercentage}
                  style={{
                    backgroundColor: "var(--cura-green)",
                  }}
                />
              </ProgressBar>
              <div
                className="font-weight-bolder mr-auto ml-2"
                style={{ width: "2.6rem" }}
              >
                {courseResponsePercentage}%
              </div>
            </div>
          </>
        );

        const percentageStr = `${
          courseProgressData && courseProgressData.courseScoreOutOf !== 0
            ? Math.round(
                (courseProgressData.totalCourseScore /
                  courseProgressData.courseScoreOutOf) *
                  100
              ) + "%"
            : " " + dashSymbol
        }`;
        const totalOutOfStr = `(${
          courseProgressData && courseProgressData.courseScoreOutOf !== 0
            ? `${courseProgressData.totalCourseScore}/${courseProgressData.courseScoreOutOf}`
            : `0/0`
        })`;
        const TotalScoreCell = () => {
          return (
            <>
              <FontAwesomeIcon icon={faStar} className="text-warning mr-2" />
              <span className="font-weight-bolder">{percentageStr} </span>
              {totalOutOfStr}
            </>
          );
        };
        return {
          children:
            mode === "progress" ? (
              <TotalProgressCell />
            ) : isTextOnly ? (
              <>{`${percentageStr} ${totalOutOfStr}`}</>
            ) : (
              <TotalScoreCell isTextOnly={isTextOnly} />
            ),
        };
      },
    },
  ];
  const ProgressIconContainer = (props) => (
    <span className="ml-4 mr-2" style={{ fontSize: "1.4rem" }}>
      {props.children}
    </span>
  );
  return (
    <>
      {mode === "progress" ? (
        <div className="float-right font-weight-normal text-secondary">
          <ProgressIconContainer>
            {progressStateCircleIconDict.completeSubmission}
          </ProgressIconContainer>
          Completed
          <ProgressIconContainer>
            {progressStateCircleIconDict.partialSubmission}
          </ProgressIconContainer>
          In progress
          <ProgressIconContainer>
            {progressStateCircleIconDict.noSubmission}
          </ProgressIconContainer>
          Not started
        </div>
      ) : (
        <AntdTableDownloadExcelButton
          className="float-right px-2"
          variant="link"
          columns={getColumns(true)}
          data={courseExecUserData}
          filename="Course-Score"
        />
      )}
      <div className="mb-3" />

      <Table
        rowKey={() => uuid()}
        columns={getColumns()}
        dataSource={courseExecUserData}
        scroll={{ x: "calc(100% - 18rem)", y: 500 }}
        pagination={{ position: ["bottomRight"], pageSize: 25 }}
        bordered
      />
    </>
  );
};

export default CourseProgressTable;
