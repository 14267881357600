import React, { useState, useEffect } from "react";
import ModalButton from "../util/ModalButton";
import { useToasts } from "react-toast-notifications";
import ReactTable, { RightButtonsTableCell } from "../util/ReactTable";
import { loadUserList } from "../../actions";
import TablePanelHeader from "../util/TablePanelHeader";
import AvatarWithText from "../util/AvatarWithText";
import { PeopleFill } from "react-bootstrap-icons";
import { RoleType } from "../../utils/constants";
import SBUAdminCreateForm from "../createEditForms/SBUAdminCreateForm";
import UserDeleteForm from "../createEditForms/UserDeleteForm";
import SendInvitationEmailButton from "../util/SendInvitationEmailButton";

const SBUAdminList = () => {
  const [SBUAdminListData, setSBUAdminListData] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);
  const { addToast } = useToasts();

  useEffect(() => {
    if (shouldReloadData) {
      const requestData = {
        role: RoleType.ADMIN,
        role_level: 1,
      };
      loadUserList(
        requestData,
        (data) => {
          setSBUAdminListData(data);
        },
        () => {}
      );
      setShouldReloadData(false);
    }
  }, [shouldReloadData]);

  const tableRowButtons = (user) => {
    return (
      <RightButtonsTableCell>
        <SendInvitationEmailButton
          userRef={user.id}
          courseExecRef=""
          addToast={addToast}
        />
        <ModalButton
          variant="link"
          buttonText="Delete"
          modalTitle={`Delete SBU Admin`}
          className="text-danger"
        >
          <UserDeleteForm
            userDetail={user}
            onSaveSuccess={() => setShouldReloadData(true)}
          />
        </ModalButton>
      </RightButtonsTableCell>
    );
  };

  const columns = [
    {
      Header: "Name",
      accessor: (row) => `${row.firstName} ${row.lastName}`,
      Cell: (cellInfo) => <AvatarWithText userDetail={cellInfo.row.original} />,
    },
    {
      Header: "Email",
      accessor: "username",
      Cell: ({ value }) => value,
    },
    {
      Header: "",
      accessor: "lastName",
      Cell: (cellInfo) => tableRowButtons(cellInfo.row.original),
    },
  ];

  return (
    <>
      <TablePanelHeader title="SBU Admins">
        <ModalButton
          variant="warning"
          className="rounded-pill text-white"
          modalSize="lg"
          buttonText={
            <>
              +<PeopleFill size={20} className="pb-1 mr-2" />
              Create New SBU Admin
            </>
          }
          modalTitle="Create New SBU Admin"
        >
          <SBUAdminCreateForm onSaveSuccess={() => setShouldReloadData(true)} />
        </ModalButton>
      </TablePanelHeader>
      {SBUAdminListData && (
        <ReactTable
          title="SBU Admins"
          data={SBUAdminListData}
          columns={columns}
        />
      )}
    </>
  );
};

export default SBUAdminList;
