import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { Button } from "react-bootstrap";
import {
  handleGenericSaveFailed,
  handleGenericSaveSuccess,
} from "../../utils/CreateEditFormUtils";

import { loadTaskDetail, deleteTask } from "../../actions";

const TaskDeleteForm = ({ taskRef, onSaveSuccess, handleCloseForm }) => {
  const { addToast } = useToasts();
  const [taskDetailData, setTaskDetailData] = useState(null);

  useEffect(() => {
    loadTaskDetail(taskRef, (data) => setTaskDetailData(data));
  }, [taskRef]);

  const handleDelete = () => {
    const data = {
      taskRef: taskRef,
    };
    deleteTask(
      data,
      () =>
        handleGenericSaveSuccess(
          addToast,
          { object: "task", operation: "deleted" },
          onSaveSuccess,
          handleCloseForm
        ),
      (data) => handleGenericSaveFailed(addToast, data[0], handleCloseForm)
    );
  };

  return (
    taskDetailData && (
      <div>
        <p>
          Delete this version - "<b>{taskDetailData.versionName}</b>"
        </p>
        <p>
          Are you sure you want to delete this version of "
          <b>{taskDetailData.title}</b>"?
        </p>
        <br /> <br />
        <Button
          size="md"
          variant="danger"
          type="submit"
          onClick={handleDelete}
          block
        >
          Delete
        </Button>
      </div>
    )
  );
};

export default TaskDeleteForm;
