import React from "react";
import { Amplify, Storage } from "aws-amplify";
import awsExports from "../../aws-exports";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import AmplifyUpload from "../../utils/ckeditor5-amplify-upload/AmplifyUpload";

import "../../style/ck-content.css";

const { REGION, CONTENT_BUCKET_NAME } = window._env_;
Amplify.configure({
  ...awsExports,
  aws_user_files_s3_bucket_region: REGION,
  aws_user_files_s3_bucket: CONTENT_BUCKET_NAME,
});

const RichTextEditor = (props) => {
  const getCKEditorConfig = () => {
    const config = {
      toolbar: {
        items: [
          "heading",
          "|",
          "fontColor",
          "bold",
          "underline",
          "italic",
          "highlight",
          "strikethrough",
          "removeFormat",
          "|",
          "indent",
          "outdent",
          "alignment",
          "|",
          "link",
          "imageUpload",
          "insertTable",
          "|",
          "horizontalLine",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "subscript",
          "superscript",
          "specialCharacters",
          "|",
          "undo",
          "redo",
        ],
      },
      extraPlugins: [AmplifyUpload],
      AmplifyUpload: {
        storage: Storage,
        namePrefix: `editor-upload/${Date.now()}/`,
      },
      language: "en",
      image: {
        styles: ["block", "side", "alignLeft", "alignCenter", "alignRight"],
        toolbar: [
          "imageTextAlternative",
          "toggleImageCaption",
          "imageStyle:block",
          "imageStyle:side",
          "imageStyle:alignLeft",
          "imageStyle:alignCenter",
          "imageStyle:alignRight",
        ],
      },
      link: {
        decorators: {
          addTargetToExternalLinks: {
            mode: "automatic",
            callback: (url) => /^(https?:)?\/\//.test(url),
            attributes: {
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
        },
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      licenseKey: "",
    };
    return config;
  };

  return (
    <div>
      <CKEditor
        className="richTextEditor"
        editor={ClassicEditor}
        data={props.data ? props.data : ""}
        config={getCKEditorConfig()}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          console.log({ event, editor, data });
          props.onChange(data);
        }}
        onBlur={(event, editor) => {
          console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
      />
      <br />
    </div>
  );
};

export default RichTextEditor;
