import React from "react";
import ExploreApp from "./ExploreApp";
import PublicHeaderPageContainer from "../common/PublicHeaderPageContainer";

const PublicExplorePage = () => {
  return (
    <>
      <PublicHeaderPageContainer>
        <ExploreApp isPublic={true} />
      </PublicHeaderPageContainer>
    </>
  );
};

export default PublicExplorePage;
