import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button, Form } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { loadSchoolDetail, createSchool, updateSchool } from "../../actions";
import { COUNTRY_INFO, TEXT_FIELD_MAX_LENGTH } from "../../utils/constants";
import { isValidSalesforceId } from "../../utils/GenericUtils";
import {
  handleGenericSaveFailed,
  handleGenericSaveSuccess,
} from "../../utils/CreateEditFormUtils";

const SchoolCreateEditForm = ({
  schoolRef,
  mode,
  onSaveSuccess = () => {},
  handleCloseForm,
}) => {
  const { addToast } = useToasts();
  const [schoolDetailData, setSchoolDetailData] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleSaveSuccess = () => {
    handleGenericSaveSuccess(
      addToast,
      { object: "school", operation: "saved" },
      onSaveSuccess,
      handleCloseForm
    );
  };

  const handleSaveFailed = () => {
    handleGenericSaveFailed(addToast, null, handleCloseForm);
  };

  useEffect(() => {
    if (mode === "edit") {
      loadSchoolDetail(schoolRef, (data) => {
        setSchoolDetailData(data);
        setSelectedCountry(data.country);
      });
    } else {
      setSelectedCountry("");
    }
  }, [schoolRef, mode]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const data = {
      schoolName: form.elements.schoolName.value,
      country: form.elements.country.value,
      state: form.elements.state.value,
      salesforceId: form.elements.salesforceId.value,
      note: form.elements.note.value,
    };
    if (!isValidSalesforceId(data.salesforceId)) {
      alert(
        "Salesforce ID must be 18 characters long and only contain letters and numbers."
      );
      return;
    }
    switch (mode) {
      case "edit":
        data.schoolRef = schoolRef;
        updateSchool(
          data,
          () => handleSaveSuccess(),
          () => handleSaveFailed()
        );
        break;
      case "create":
        createSchool(
          data,
          () => handleSaveSuccess(),
          () => handleSaveFailed()
        );
        break;
      default:
        console.warn(`No mode called: ${mode}`);
    }
  };

  return (
    (mode === "create" || schoolDetailData) && (
      <div>
        <Form className="InputForm" onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>School Name:</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="e.g. The Big Dry"
              name="schoolName"
              defaultValue={
                mode === "edit" && schoolDetailData !== null
                  ? schoolDetailData.schoolName
                  : ""
              }
              maxLength={TEXT_FIELD_MAX_LENGTH.SCHOOL_TITLE}
            />
          </Form.Group>
          <Form.Group controlId="formCountry">
            <Form.Label>Country:</Form.Label>
            <Form.Control
              required
              as="select"
              name="country"
              defaultValue={
                mode === "edit" && schoolDetailData !== null
                  ? schoolDetailData.country
                  : ""
              }
              onChange={(event) => {
                setSelectedCountry(event.target.value);
              }}
            >
              <option value="">Choose...</option>
              {Object.values(COUNTRY_INFO).map((country, i) => (
                <option value={country.fullName} key={`country-${i}`}>
                  {country.fullName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formState">
            <Form.Label>State/Province/Region:</Form.Label>
            {selectedCountry ? (
              <Form.Control
                as="select"
                name="state"
                defaultValue={
                  mode === "edit" && schoolDetailData !== null
                    ? schoolDetailData.state
                    : ""
                }
              >
                <option value="">Choose...</option>
                {_.find(Object.values(COUNTRY_INFO), {
                  fullName: selectedCountry,
                }).states.map((value, i) => (
                  <option
                    value={value}
                    key={`state-${i}`}
                    selected={
                      schoolDetailData && schoolDetailData.state === value
                    }
                  >
                    {value}
                  </option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control as="select">
                <option value="">Please select a country above</option>
              </Form.Control>
            )}
          </Form.Group>
          <Form.Group controlId="formSalesforceId">
            <Form.Label>Salesforce ID:</Form.Label>
            <Form.Control
              required
              type="text"
              name="salesforceId"
              defaultValue={
                mode === "edit" && schoolDetailData !== null
                  ? schoolDetailData.salesforceId
                  : ""
              }
              maxLength={TEXT_FIELD_MAX_LENGTH.SCHOOL_TITLE}
            />
          </Form.Group>
          <Form.Group controlId="formNote">
            <Form.Label>Note:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="note"
              defaultValue={
                mode === "edit" && schoolDetailData !== null
                  ? schoolDetailData.note
                  : ""
              }
            />
          </Form.Group>
          <br /> <br />
          <Button size="md" variant="primary" type="submit" block>
            Save
          </Button>
        </Form>
      </div>
    )
  );
};

export default SchoolCreateEditForm;
