import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import DraggableTask from "./DraggableTask";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";

const DraggableSection = (props) => {
  return (
    <Draggable
      draggableId={props.section.sectionRef}
      index={props.index}
      type="SECTION"
    >
      {(provided) => (
        <div
          className="bg-gray-100 p-3 border-secondary border-bottom"
          {...provided.draggableProps}
          // Must have this for the draggable part to drag the whole.
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div
            className="d-flex justify-content-between mb-3"
            style={{ alignItems: "center" }}
          >
            <div className="font-weight-bolder text-dark">
              Level {props.index + 1}: {props.section.title}
            </div>
            {/* <FontAwesomeIcon icon={faPen} className="text-primary mr-2" />
              <FontAwesomeIcon icon={faTrashCan} className="text-danger mr-2" /> */}
            {props.index === 0 && (
              <div className="text-secondary text-sm text-right">
                <FontAwesomeIcon icon={faSave} /> Changes autosaved
              </div>
            )}
          </div>

          {/* <Button
              size="xs"
              variant="link"
              className="text-danger"
              onClick={() => {
                props.onSectionRemove(props.section);
              }}
            >
              Delete
            </Button>  */}
          <Row>
            <Col className="pr-0" sm={2}></Col>
            <Col className="p-0" sm={2}></Col>
          </Row>
          <Droppable droppableId={props.section.sectionRef} type="TASK">
            {(provided, snapshot) => (
              <div
                className="section-droppable border-0"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {props.taskLinks.map((courseTaskLink, index) => (
                  <DraggableTask
                    key={courseTaskLink.courseTaskLinkRef}
                    task={props.tasks[courseTaskLink.taskRef]}
                    courseTaskLink={courseTaskLink}
                    index={index}
                    currentEditCourseTaskLinkRef={
                      props.currentEditCourseTaskLinkRef
                    }
                    onTaskEdit={props.onTaskEdit}
                    onTaskHide={props.onTaskHide}
                    onTaskRemove={props.onTaskRemove}
                    projectDictData={props.projectDictData}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableSection;
