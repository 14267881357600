import React from "react";
import { Card, Form } from "react-bootstrap";

const TeacherAssessmentFeedbackAccordion = ({
  feedback,
  editable,
  disabled,
  onChange = () => {},
}) => {
  const feedbackStr = feedback && feedback.feedback;

  if (!editable && !feedbackStr) return null;

  return (
    <Card className="mt-4">
      <Card.Header>
        <div className="font-weight-bolder text-primary">Feedback</div>
      </Card.Header>
      <Card.Body>
        {editable ? (
          <Form.Control
            defaultValue={feedbackStr}
            onChange={(event) => onChange(event.target.value)}
            className="text-dark"
            type="text"
            name="feedback"
            placeholder="Add Feedback..."
            disabled={disabled}
          />
        ) : (
          feedbackStr ?? "No teacher feedback is given"
        )}
      </Card.Body>
    </Card>
  );
};

export default TeacherAssessmentFeedbackAccordion;
