import React, { useEffect, useState, useRef, useMemo } from "react";
import { Button, Table } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { COUNTRY_INFO } from "../../utils/constants";
import {
  deepCopy,
  getGenericReactSelectOption,
} from "../../utils/GenericUtils";

const EditableRow = ({
  rowData,
  index,
  handleDelete,
  handleUpdate,
  excludeCountries = [],
}) => {
  const isFirstRun = useRef(true);
  const initRow = { country: null, subject: [], levels: [] };
  const [updatedRowData, setUpdatedRowData] = useState(
    rowData ? rowData : initRow
  );
  const cellStyle = { width: "20rem" };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    handleUpdate(index, updatedRowData);
  }, [updatedRowData]);

  const getCountryOption = (country) =>
    country && country in COUNTRY_INFO
      ? getGenericReactSelectOption(country, COUNTRY_INFO[country].fullName)
      : null;

  return (
    <tr>
      <td style={cellStyle}>
        <Select
          value={getCountryOption(rowData.country)}
          options={
            excludeCountries &&
            Object.keys(COUNTRY_INFO).flatMap((key) => {
              return excludeCountries.includes(key)
                ? []
                : getCountryOption(key);
            })
          }
          onChange={(item) =>
            setUpdatedRowData({ ...updatedRowData, country: item.value })
          }
        />
      </td>
      <td style={cellStyle}>
        <Select
          isMulti
          value={
            rowData.subject &&
            rowData.subject.map((subject) =>
              getGenericReactSelectOption(subject)
            )
          }
          options={
            updatedRowData.country && updatedRowData.country in COUNTRY_INFO
              ? COUNTRY_INFO[updatedRowData.country].subjects.map((subject) =>
                  getGenericReactSelectOption(subject)
                )
              : null
          }
          onChange={(items) =>
            setUpdatedRowData({
              ...updatedRowData,
              subject: items.map((item) => item.value),
            })
          }
        />
      </td>
      <td style={cellStyle}>
        <Select
          isMulti
          value={
            rowData.levels &&
            rowData.levels.map((level) =>
              getGenericReactSelectOption(
                level,
                COUNTRY_INFO[updatedRowData.country].yearLevelPrefix +
                  " " +
                  level
              )
            )
          }
          options={
            updatedRowData.country && updatedRowData.country in COUNTRY_INFO
              ? COUNTRY_INFO[updatedRowData.country].levels.map((level) =>
                  getGenericReactSelectOption(
                    level,
                    COUNTRY_INFO[updatedRowData.country].yearLevelPrefix +
                      " " +
                      level
                  )
                )
              : null
          }
          onChange={(items) =>
            setUpdatedRowData({
              ...updatedRowData,
              levels: items.map((item) => item.value),
            })
          }
        />
      </td>
      <td>
        <Button
          size="sm"
          variant="danger rounded-circle btn-icon-only float-right"
          onClick={() => handleDelete(index)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
};

const metadataDictToList = (medatadataDict) => {
  if (!medatadataDict) return [];
  return Object.keys(medatadataDict).map((key) => {
    return {
      country: key,
      ...medatadataDict[key],
    };
  });
};

const metadataListToDict = (medatadataList) => {
  let result = {};
  for (const item of medatadataList) {
    const country = item.country;
    if (!item.country) continue;
    const newItem = deepCopy(item);
    delete newItem.country;
    result[country] = newItem;
  }
  return result;
};

const CourseCountryMetadataEditor = ({
  countryMetadata,
  handleMetadataUpdate,
}) => {
  const [updatedMetadataList, setUpdatedMetadataList] = useState(
    metadataDictToList(deepCopy(countryMetadata))
  );
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    handleMetadataUpdate(metadataListToDict(updatedMetadataList));
  }, [updatedMetadataList]);

  const handleAddClick = () => {
    const newState = [
      ...updatedMetadataList,
      { country: null, subject: [], levels: [] },
    ];
    setUpdatedMetadataList(newState);
  };
  const handleDeleteClick = (index) => {
    const newList = deepCopy(updatedMetadataList);
    newList.splice(index, 1);
    setUpdatedMetadataList(newList);
  };
  const handleContentChange = (index, data) => {
    const newState = deepCopy(updatedMetadataList);
    newState[index] = deepCopy(data);
    setUpdatedMetadataList(newState);
  };

  const memList = useMemo(
    () =>
      updatedMetadataList &&
      updatedMetadataList.map((item, index) => (
        <EditableRow
          key={`country-metadata-${index}`}
          rowData={item}
          index={index}
          handleUpdate={handleContentChange}
          handleDelete={handleDeleteClick}
          excludeCountries={updatedMetadataList.map((item) => item.country)}
        />
      )),
    [updatedMetadataList]
  );

  return (
    <Table bordered hover size="sm">
      <thead className="text-dark">
        <tr>
          <th>Country</th>
          <th>Subject</th>
          <th>Year Levels</th>
        </tr>
      </thead>
      <tbody>
        {memList}
        <tr>
          <td colSpan="4" className="text-dark">
            <Button
              size="sm"
              variant="warning rounded-circle btn-icon-only mr-2"
              onClick={() => handleAddClick()}
            >
              <FontAwesomeIcon icon={faPlus} className="text-white" />
            </Button>
            Add another country
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default CourseCountryMetadataEditor;
