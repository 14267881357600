import React, { useEffect, useState, useRef, useMemo } from "react";
import { Button, Card, Dropdown } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faPlus,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import EmptyTableCreator, { PreviewTable } from "./EmptyTableCreator";
import {
  GENERIC_EDITOR_MODES,
  TASK_RESOURCE_BLOCK_TYPE,
} from "../../../utils/constants";
import RichTextEditor from "../../util/RichTextEditor";
import RubricCreator, { PreviewRubric } from "./RubricCreator";

const AddTaskResourceBlockButton = ({ index, handleAddClick }) => {
  return (
    <Dropdown className="mb-3">
      <Dropdown.Toggle
        size="xs"
        variant="primary rounded-circle btn-icon-only"
        bsPrefix="p-0"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.keys(TASK_RESOURCE_BLOCK_TYPE).map((type, i) => (
          <Dropdown.Item
            key={`dropdown-item-${i}`}
            onClick={() =>
              handleAddClick(index, TASK_RESOURCE_BLOCK_TYPE[type])
            }
          >
            {TASK_RESOURCE_BLOCK_TYPE[type]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ResourceBlock = ({ mode, blockData, onUpdate }) => {
  const [updatedBody, setUpdatedBody] = useState(
    blockData.body ? blockData.body : ""
  );
  const [updatedData, setUpdatedData] = useState(blockData.data);
  const [updatedRubric, setUpdatedRubric] = useState(blockData.rubric);

  const [updatedSampleAnswer, setUpdatedSampleAnswer] = useState(
    blockData.sampleAnswer
  );

  const DisplayBlock = ({ blockData }) => (
    <>
      <div
        className="ck-content"
        dangerouslySetInnerHTML={{
          __html: blockData.body,
        }}
      />
      {blockData.resourceType === TASK_RESOURCE_BLOCK_TYPE.TABLE && (
        <div className="mb-3">
          <PreviewTable
            rows={blockData.data ? blockData.data.rows : []}
            columns={blockData.data ? blockData.data.columns : []}
          />
        </div>
      )}
      {blockData.resourceType !== TASK_RESOURCE_BLOCK_TYPE.CONTENT &&
        blockData.rubric && (
          <div className="mb-3">
            <div className="font-weight-bolder text-dark mb-3">Assessment</div>
            <PreviewRubric rubric={blockData.rubric} />
          </div>
        )}
      {blockData.resourceType !== TASK_RESOURCE_BLOCK_TYPE.CONTENT &&
        blockData.sampleAnswer && (
          <div className="mb-3">
            <div className="font-weight-bolder text-dark mb-3">
              Exemplar Answer
            </div>
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{
                __html: blockData.sampleAnswer,
              }}
            />
          </div>
        )}
    </>
  );

  // Return Display mode
  if (mode === GENERIC_EDITOR_MODES.DISPLAY)
    return <DisplayBlock blockData={blockData} />;

  const getCleanRubricData = (updatedRubrics) => {
    if (!updatedRubrics) return updatedRubric;
    return {
      ...updatedRubrics,
      criteria: updatedRubrics.criteria.trim(),
      items: updatedRubrics.items.map((item) => item.trim()),
    };
  };

  // Return Edit mode
  return (
    <>
      {blockData.resourceType !== TASK_RESOURCE_BLOCK_TYPE.RUBRIC && (
        <RichTextEditor
          data={blockData.body ? blockData.body : ""}
          onChange={(text) => {
            setUpdatedBody(text);
          }}
        />
      )}
      {blockData.resourceType === TASK_RESOURCE_BLOCK_TYPE.TABLE && (
        <div className="mb-3">
          <EmptyTableCreator
            blockData={blockData}
            onUpdate={(tableData) =>
              setUpdatedData({ ...updatedData, ...tableData })
            }
          />
        </div>
      )}
      {blockData.resourceType !== TASK_RESOURCE_BLOCK_TYPE.CONTENT && (
        <>
          <hr />
          <h5 className="text-primary mb-3">Rubrics</h5>
          <RubricCreator
            blockData={blockData}
            onUpdate={(rubricData) => setUpdatedRubric(rubricData)}
          />
        </>
      )}
      {![
        TASK_RESOURCE_BLOCK_TYPE.RUBRIC,
        TASK_RESOURCE_BLOCK_TYPE.CONTENT,
      ].includes(blockData.resourceType) && (
        <>
          <hr />
          <h5 className="text-primary mb-3">Exemplar answer</h5>
          <RichTextEditor
            data={blockData.sampleAnswer ? blockData.sampleAnswer : ""}
            onChange={(text) => {
              setUpdatedSampleAnswer(text);
            }}
          />
        </>
      )}

      <Button
        variant="danger"
        onClick={() => {
          if (
            !window.confirm(
              "You will lose edited data. Are you sure you want to cancel?"
            )
          )
            return;
          onUpdate(blockData);
        }}
      >
        Cancel
      </Button>
      <Button
        className="float-right"
        variant="success"
        onClick={() => {
          if (
            updatedRubric &&
            updatedRubric.totalScore === 0 &&
            !window.confirm(
              "This question is out of 0 points. Is that correct?"
            )
          )
            return;

          onUpdate({
            ...blockData,
            body: updatedBody,
            data: updatedData,
            rubric: getCleanRubricData(updatedRubric),
            sampleAnswer: updatedSampleAnswer,
          });
        }}
      >
        Finish Editing
      </Button>
    </>
  );
};

const TaskResourceBlockEditor = ({ blocks, onUpdate }) => {
  const isFirstRun = useRef(true);
  const [state, setState] = useState({
    blocksDict: blocks
      ? Object.assign(
          {},
          ...blocks.map((block) => ({ [block.taskResourceBlockRef]: block }))
        )
      : {},
    blockOrder: blocks ? blocks.map((block) => block.taskResourceBlockRef) : [],
  });

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    let newBlocksDict = state.blocksDict;
    for (let i = 0; i < state.blockOrder.length; i++)
      newBlocksDict[state.blockOrder[i]].seqNum = i;
    const sortedList = Object.values(newBlocksDict).sort((a, b) =>
      a.seqNum < b.seqNum ? -1 : 1
    );
    onUpdate(sortedList);
  }, [state]);

  const handleAddClick = (index, type) => {
    const newBlockId = uuid();
    const newBlock = {
      taskResourceBlockRef: newBlockId,
      title: "New Block",
      seqNum: index,
      data: null,
      body: null,
      rubric: null,
      sampleAnswer: null,
      resourceType: type,
    };
    const newBlocksDict = { ...state.blocksDict, [newBlockId]: newBlock };
    let newBlockOrder = Array.from(state.blockOrder);
    newBlockOrder.splice(index, 0, newBlockId);
    const newState = {
      ...state,
      blockOrder: newBlockOrder,
      blocksDict: newBlocksDict,
    };
    setState(newState);
  };
  const handleDeleteClick = (blockId) => {
    if (!window.confirm("Are you sure you want to delete this block?")) return;
    let newBlocksDict = state.blocksDict;
    delete newBlocksDict[blockId];
    let newBlockOrder = Array.from(state.blockOrder);
    newBlockOrder.splice(
      newBlockOrder.findIndex((item) => {
        return item === blockId;
      }),
      1
    );
    const newState = {
      ...state,
      blockOrder: newBlockOrder,
      blocksDict: newBlocksDict,
    };
    setState(newState);
  };
  const handleContentChange = (block) => {
    const newState = {
      ...state,
      blocksDict: { ...state.blocksDict, [block.taskResourceBlockRef]: block },
    };
    setState(newState);
  };
  const handleMoveBlock = (blockId, isMovingDown = false) => {
    let newBlockOrder = Array.from(state.blockOrder);
    const currentIndex = newBlockOrder.findIndex((item) => item === blockId);
    if (
      (isMovingDown && currentIndex === newBlockOrder.length - 1) ||
      (!isMovingDown && currentIndex === 0)
    )
      return;

    if (isMovingDown)
      [newBlockOrder[currentIndex + 1], newBlockOrder[currentIndex]] = [
        newBlockOrder[currentIndex],
        newBlockOrder[currentIndex + 1],
      ];
    else
      [newBlockOrder[currentIndex - 1], newBlockOrder[currentIndex]] = [
        newBlockOrder[currentIndex],
        newBlockOrder[currentIndex - 1],
      ];

    const newState = {
      ...state,
      blockOrder: newBlockOrder,
    };
    setState(newState);
  };

  const SingleBlockEditorCard = ({ block, index, blockId }) => {
    const [mode, setMode] = useState(GENERIC_EDITOR_MODES.DISPLAY);
    return (
      <Card className={mode === GENERIC_EDITOR_MODES.EDIT ? "shadow-lg" : ""}>
        <Card.Body>
          <Card.Title>
            {`${index + 1}. ${block.resourceType}`}
            <div className="float-right">
              <Button
                size="sm"
                variant="success rounded-circle btn-icon-only"
                disabled={mode === GENERIC_EDITOR_MODES.EDIT}
                onClick={() => setMode(GENERIC_EDITOR_MODES.EDIT)}
              >
                <FontAwesomeIcon icon={faPen} />
              </Button>
              <Button
                size="sm"
                variant="primary rounded-circle btn-icon-only"
                disabled={index === 0}
                onClick={() => handleMoveBlock(blockId)}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Button>
              <Button
                size="sm"
                variant="primary rounded-circle btn-icon-only"
                disabled={index === state.blockOrder.length - 1}
                onClick={() => handleMoveBlock(blockId, true)}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </Button>
              <Button
                size="sm"
                variant="danger rounded-circle btn-icon-only"
                onClick={() => handleDeleteClick(blockId)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          </Card.Title>
          <ResourceBlock
            blockData={block}
            onUpdate={(block) => {
              handleContentChange(block);
              setMode(GENERIC_EDITOR_MODES.DISPLAY);
            }}
            mode={mode}
          />
        </Card.Body>
      </Card>
    );
  };

  const memList = useMemo(
    () =>
      state.blockOrder.map((blockId, index) => {
        const block = state.blocksDict[blockId];
        return (
          <div key={`block-${index}`}>
            <SingleBlockEditorCard
              block={block}
              index={index}
              blockId={blockId}
            />

            <AddTaskResourceBlockButton
              index={index + 1}
              handleAddClick={handleAddClick}
            />
          </div>
        );
      }),
    [state.blockOrder, state.blocksDict]
  );

  return (
    <>
      <hr />
      <h2>Task Resource Block Editor</h2>
      <div>
        <AddTaskResourceBlockButton index={0} handleAddClick={handleAddClick} />
        {memList}
      </div>
    </>
  );
};

export default TaskResourceBlockEditor;
