import React from "react";
import { HouseDoor } from "react-bootstrap-icons";

const ErrorPage = ({ heading, message, imageUrl, bg = "primary" }) => {
  return (
    <div
      className={`min-vh-100 h-100vh py-5 d-flex align-items-center bg-gradient-${bg}`}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
    >
      <div className="container position-relative zindex-100">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 px-5 px-lg-0">
            <h6 className="display-1 mb-3 font-weight-600 text-white">
              {heading}
            </h6>
            <p className="lead text-lg text-white mb-5">{message}</p>
            <a
              href="/"
              className="btn btn-white btn-icon rounded-pill hover-translate-y-n3"
            >
              <HouseDoor className="pb-1 mr-0" size="22" />
              <span className="btn-inner--text">Return home</span>
            </a>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <figure className="w-100">
              <img
                alt="Color placeholder"
                src={imageUrl}
                className="svg-inject opacity-8 img-fluid"
                style={{ height: "500px" }}
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
