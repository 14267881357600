import React, { useEffect, useState } from "react";
import { getSchoolSummary } from "../../../actions";
import { Table } from "antd";

const SchoolSummaryTable = ({ data }) => {
  const columns = [
    {
      title: "Course",
      dataIndex: "courseNum",
      key: "courseNum",
    },
    {
      title: "Classroom",
      dataIndex: "courseExecNum",
      key: "courseExecNum",
    },
    {
      title: "Teacher",
      dataIndex: "teacherNum",
      key: "teacherNum",
    },
    {
      title: "Teacher Added Last 30 Days",
      dataIndex: "teacherNumLastMonth",
      key: "teacherNumLastMonth",
    },
    {
      title: "Student",
      dataIndex: "studentNum",
      key: "studentNum",
    },
    {
      title: "Student Added Last 30 Days",
      dataIndex: "studentNumLastMonth",
      key: "studentNumLastMonth",
    },
    {
      title: "Created",
      dataIndex: "createAt",
      key: "createAt",
    },
  ];
  return (
    <Table columns={columns} dataSource={data} pagination={false} bordered />
  );
};

const SchoolSummary = ({ schoolRef }) => {
  const [schoolSummaryData, setSchoolSummaryData] = useState(null);
  useEffect(() => {
    const data = { schoolRef };
    getSchoolSummary(data, (data) => {
      setSchoolSummaryData(data);
    });
  }, [schoolRef]);
  return (
    <>
      <h5 className="text-dark">This Year:</h5>
      <SchoolSummaryTable data={schoolSummaryData} />
    </>
  );
};

export default SchoolSummary;
