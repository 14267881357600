import React from "react";
import {
  COUNTRY_INFO,
  CourseExecStatus,
  CourseTypeDisplayText,
} from "../../utils/constants";
import { useSelector } from "react-redux";
import { Button, Card, Row, Col, Dropdown, Badge } from "react-bootstrap";
import {
  CustomMenu,
  CustomToggle,
} from "../../components/util/PlainStyleDropdown";
import ModalButton from "../../components/util/ModalButton";
import ClassroomCreateEditForm from "../../components/createEditForms/ClassroomCreateEditForm";
import ClassroomDeleteForm from "../../components/createEditForms/ClassroomDeleteForm";
import ClassroomArchiveForm from "../../components/createEditForms/ClassroomArchiveForm";
import Can from "../../utils/Can";
import { RoleType, AWS_REGION_COUNTRY_MAP } from "../../utils/constants";
import { ThreeDots } from "react-bootstrap-icons";
import { findRanges } from "../../utils/GenericUtils";
import FavouriteActionButton from "../explore/FavouriteActionButton";
import UserCourseDuplicateForm from "../createEditForms/UserCourseDuplicateForm";
import UserCourseArchiveForm from "../createEditForms/UserCourseArchiveForm";
import UserCourseDeleteForm from "../createEditForms/UserCourseDeleteForm";
import PPTLinkActionButton from "../explore/PPTLinkActionButton";

export const ClassroomCourseCard = ({
  courseExecData,
  setShouldReloadData,
}) => {
  const userRole = useSelector(
    (state) => state.auth.userDetail.userInfo.roleTypeName
  );
  const handleClick = () => {
    if (userRole === RoleType.STUDENT)
      window.location.href = `/classroom/${courseExecData.courseExecRef}`;
    else
      window.location.href = `/classroom-detail/${courseExecData.courseExecRef}`;
  };

  return (
    <Col lg={3} sm={6}>
      <Card className="border-0 card-hover-overlay hover-shadow-lg">
        <div className="image-wrapper-43">
          <Card.Img
            variant="top"
            src={courseExecData.course.thumbnail}
            style={{ cursor: "pointer" }}
            onClick={handleClick}
          />
        </div>
        <Card.Body>
          <Card.Title className="d-flex justify-content-between mb-3">
            <div
              className="overflow-hidden"
              onClick={handleClick}
              style={{
                cursor: "pointer",
                height: "3.125rem",
              }}
            >
              {courseExecData.course.project.title}
            </div>
            <Can
              perform="create-classroom:teacher"
              yes={() => (
                <Dropdown className="cursor-pointer">
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    <div className="px-3">
                      <ThreeDots size={24} />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu as={CustomMenu}>
                    {courseExecData.status === CourseExecStatus.ACTIVE && (
                      <>
                        <Dropdown.ItemText>
                          <ModalButton
                            buttonText="Add Another Classroom"
                            modalTitle="Add Another Classroom"
                            variant="link"
                            className="text-dark p-0 text-sm"
                          >
                            <ClassroomCreateEditForm
                              mode="create"
                              courseRef={courseExecData.courseRef}
                              onSaveSuccess={() => {
                                setShouldReloadData(true);
                              }}
                            />
                          </ModalButton>
                        </Dropdown.ItemText>
                        <Dropdown.Divider />
                        <Dropdown.ItemText>
                          <ModalButton
                            buttonText="Archive Classroom"
                            modalTitle="Archive Classroom"
                            variant="link"
                            className="text-dark p-0 text-sm"
                          >
                            <ClassroomArchiveForm
                              courseExecRef={courseExecData.courseExecRef}
                              onSaveSuccess={() => setShouldReloadData(true)}
                            />
                          </ModalButton>
                        </Dropdown.ItemText>
                        <Dropdown.Divider />
                      </>
                    )}
                    <Dropdown.ItemText>
                      <ModalButton
                        buttonText="Delete Classroom"
                        modalTitle="Delete Classroom"
                        variant="link"
                        className="text-dark p-0 text-sm"
                      >
                        <ClassroomDeleteForm
                          courseExecRef={courseExecData.courseExecRef}
                          onSaveSuccess={() => setShouldReloadData(true)}
                        />
                      </ModalButton>
                    </Dropdown.ItemText>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              no={() => null}
            />
          </Card.Title>
          <Row className="mb-3">
            <Col xs={6}>
              <span className="text-xs">Subject:</span>
              <br />
              <div
                className="text-dark text-truncate"
                title={courseExecData.subject}
              >
                {courseExecData.subject ? courseExecData.subject : "-"}
              </div>
            </Col>
            <Col xs={6}>
              <span className="text-xs">Classroom:</span>
              <br />
              <div
                className="text-dark text-truncate"
                title={courseExecData.classroomName}
              >
                {courseExecData.classroomName}
              </div>
            </Col>
          </Row>
          <Button size="sm" variant="primary" onClick={handleClick}>
            {courseExecData.status === CourseExecStatus.ARCHIVED
              ? "View"
              : "Resume Course"}
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
};

export const TeacherCourseCard = ({ courseData, setShouldReloadData }) => {
  const isTeacher = useSelector(
    (state) => state.auth.userDetail.userInfo.roleTypeName === RoleType.TEACHER
  );
  const handleClick = () => {
    // Default classroom ref is the same as the course ref
    const courseExecRef = courseData.courseRef;
    window.location.href = `/classroom-detail/${courseExecRef}`;
  };

  const CardDropdown = () => {
    return (
      <Dropdown className="cursor-pointer">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <div className="px-3">
            <ThreeDots size={24} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          {courseData.status === CourseExecStatus.ACTIVE && (
            <>
              <Dropdown.ItemText className="p-0">
                <ModalButton
                  buttonText="Duplicate Course"
                  modalTitle="Duplicate Course"
                  variant="link"
                  className="text-dark px-3 py-1 text-sm h-100 w-100 text-left"
                >
                  <UserCourseDuplicateForm
                    mode="create"
                    courseRef={courseData.courseRef}
                    onSaveSuccess={() => {
                      setShouldReloadData(true);
                    }}
                  />
                </ModalButton>
              </Dropdown.ItemText>
              <Dropdown.Divider />
              <Dropdown.ItemText className="p-0">
                <ModalButton
                  buttonText="Archive Course"
                  modalTitle="Archive Course"
                  variant="link"
                  className="text-dark px-3 py-1 text-sm h-100 w-100 text-left"
                >
                  <UserCourseArchiveForm
                    courseRef={courseData.courseRef}
                    onSaveSuccess={() => setShouldReloadData(true)}
                  />
                </ModalButton>
              </Dropdown.ItemText>
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.ItemText className="p-0">
            <ModalButton
              buttonText="Delete Course"
              modalTitle="Delete Course"
              variant="link"
              className="text-dark px-3 py-1 text-sm h-100 w-100 text-left"
            >
              <UserCourseDeleteForm
                courseRef={courseData.courseRef}
                onSaveSuccess={() => setShouldReloadData(true)}
              />
            </ModalButton>
          </Dropdown.ItemText>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Col lg={3} sm={6}>
      <Card className="border-0 card-hover-overlay hover-shadow-lg">
        <div className="image-wrapper-43">
          <Card.Img
            variant="top"
            src={courseData.thumbnail}
            style={{ cursor: "pointer" }}
            onClick={handleClick}
          />
        </div>
        {courseData.contentMetadata?.slidesUrl &&
          !courseData.contentMetadata?.slidesInTasksAvailable && (
            <div className="mr-2 mt-3 pr-1" style={{ position: "absolute" }}>
              <Badge variant="warning" className="text-white">
                Slides included
              </Badge>
            </div>
          )}
        {/* TODO: Remove this when no */}
        {courseData.contentMetadata?.slidesUrl &&
          !courseData.contentMetadata?.slidesInTasksAvailable && (
            <div
              className="mr-2 mt-2 pr-1 pt-1"
              style={{ position: "absolute", right: 0 }}
            >
              <div className="d-flex">
                <PPTLinkActionButton
                  url={courseData.contentMetadata?.slidesUrl}
                  slidesInTasksAvailable={
                    courseData.contentMetadata?.slidesInTasksAvailable
                  }
                />
              </div>
            </div>
          )}
        <Card.Body>
          <Card.Title className="d-flex justify-content-between mb-3">
            <div
              className="overflow-hidden"
              onClick={handleClick}
              style={{
                cursor: "pointer",
                height: "3.125rem",
              }}
            >
              {courseData.project.title}
            </div>
            {isTeacher && <CardDropdown />}
          </Card.Title>
          <Row className="mb-3">
            <Col xs={6}>
              <span className="text-xs">Subject:</span>
              <br />
              <div
                className="text-dark text-truncate"
                title={courseData.defaultCourseExec.subject}
              >
                {courseData.defaultCourseExec.subject
                  ? courseData.defaultCourseExec.subject
                  : "-"}
              </div>
            </Col>
            <Col xs={6}>
              <span className="text-xs">Course Name:</span>
              <br />
              <div
                className="text-dark text-truncate"
                title={courseData.defaultCourseExec.classroomName}
              >
                {courseData.defaultCourseExec.classroomName}
              </div>
            </Col>
          </Row>
          <Button size="sm" variant="primary" onClick={handleClick}>
            {courseData.status === CourseExecStatus.ARCHIVED
              ? "View"
              : "Resume Course"}
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
};

export const ExploreCourseCard = ({
  course,
  project,
  isPurchased,
  onClick = () => {},
}) => {
  const region = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];
  const metadata = course.countryMetadata[region];

  const CardBodyContent = () => {
    const CourseTag = (props) => (
      <div
        className="px-2 bg-warning rounded mr-1 mb-1 text-truncate"
        style={{ maxWidth: "calc(50% - 0.5rem)" }}
      >
        {props.children}
      </div>
    );
    return (
      <div className="overflow-hidden" style={{ height: "10.75rem" }}>
        <Card.Title
          className="d-flex overflow-hidden mb-2 mt-2"
          style={{
            maxHeight: "3.125rem",
          }}
        >
          {project.title}
        </Card.Title>
        <div
          className="d-flex text-white text-sm my-1"
          style={{ flexWrap: "wrap" }}
        >
          {metadata.subject &&
            metadata.subject.map((subject, index) => {
              return <CourseTag key={index}>{subject}</CourseTag>;
            })}
          {metadata.levels && metadata.levels.length > 0 && (
            <CourseTag>
              {`${COUNTRY_INFO[region].yearLevelPrefix} ${findRanges(
                metadata.levels
              )}`}
            </CourseTag>
          )}
          {course.courseType && (
            <CourseTag>{CourseTypeDisplayText[course.courseType]}</CourseTag>
          )}
        </div>
        <div className="mt-2">
          <div
            className="text-sm overflow-hidden"
            style={{
              maxHeight: "6rem",
            }}
          >
            {project.description}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card
      className="border-0 card-hover-overlay hover-shadow-lg cursor-pointer no-text-select"
      style={{
        boxShadow: "0 0.25rem 0.5rem rgb(18 38 63 / 10%)",
      }}
    >
      <div className="image-wrapper-43" onClick={onClick}>
        <Card.Img variant="top" src={course.thumbnail} />
      </div>
      <Can
        perform="favourite-course:teacher"
        yes={() => (
          <>
            {isPurchased && (
              <div className="mr-2 mt-3 pr-1" style={{ position: "absolute" }}>
                <Badge variant="warning" className="text-white">
                  Purchased
                </Badge>
              </div>
            )}
          </>
        )}
        no={() => null}
      />
      <Can
        perform="favourite-course:teacher"
        yes={() => (
          <div
            className="mr-2 mt-2 pr-1 pt-1"
            style={{ position: "absolute", right: 0 }}
          >
            <div className="d-flex">
              {course.contentMetadata?.slidesUrl &&
                !course.contentMetadata?.slidesInTasksAvailable && (
                  <PPTLinkActionButton
                    url={course.contentMetadata?.slidesUrl}
                    slidesInTasksAvailable={
                      course.contentMetadata?.slidesInTasksAvailable
                    }
                  />
                )}
              <div className="mr-2" />
              <FavouriteActionButton
                courseRef={course.courseRef}
                title={project.title}
              />
            </div>
          </div>
        )}
        no={() => null}
      />
      <Card.Body className="pt-3" onClick={onClick}>
        <CardBodyContent />
      </Card.Body>
    </Card>
  );
};
