import React from "react";
import { useParams } from "react-router-dom";
import CourseView from "../../components/course/CourseView";
import Header from "../../components/util/Header";

const CourseExecDetailPage = () => {
  const { courseExecRef } = useParams();
  return (
    <>
      <Header />
      <CourseView courseExecRef={courseExecRef} />
    </>
  );
};

export default CourseExecDetailPage;
