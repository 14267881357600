import React from "react";
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { KeyFill, PersonFill } from "react-bootstrap-icons";

const SignInForm = ({ handleSubmit, handleSwitchSSO }) => {
  return (
    <>
      <p className="text-muted my-3">Sign in to your account</p>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formGroupEmail">
          <Form.Label className="text-dark">Email address</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <PersonFill />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="name@example.com"
              aria-label="email"
              name="email"
              type="email"
            />
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formGroupPassword">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <Form.Label className="text-dark">Password</Form.Label>
            </div>
            <div className="mb-2">
              <a
                target="_blank"
                href="/password/reset"
                className="small text-muted text-underline--dashed border-primary"
                tabIndex="-1"
              >
                Lost password?
              </a>
            </div>
          </div>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <KeyFill />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="password"
              name="password"
              placeholder="Password"
            />
          </InputGroup>
        </Form.Group>
        <div className="mt-4">
          <Button
            aria-label="signin"
            size="md"
            variant="primary rounded-pill px-5"
            type="submit"
          >
            Sign In
          </Button>
        </div>
        <div
          className="mt-3 btn btn-link p-0 cursor-pointer"
          onClick={handleSwitchSSO}
        >
          <u>Sign in with your school account (SSO)</u>
        </div>
      </Form>
    </>
  );
};

export default SignInForm;
