import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { PreviewRubric } from "./RubricCreator";
import { GENERIC_EDITOR_MODES } from "../../../utils/constants";

const RubricAccordion = ({
  taskResourceBlock,
  responseData,
  mode,
  selectedScore,
  handleScoreChange,
  defaultExpand,
}) => {
  const blockRefs = [
    taskResourceBlock.taskResourceBlockRef,
    ...taskResourceBlock.rubricBlocks.map((e) => e.taskResourceBlockRef),
  ];
  const getSumScores = (scoreDict) => {
    let sum = 0;
    for (const blockRef of blockRefs) {
      if (blockRef in scoreDict) {
        sum += scoreDict[blockRef].score;
      }
    }
    return sum;
  };
  return (
    taskResourceBlock &&
    taskResourceBlock.rubric && (
      <Accordion className="pt-4" defaultActiveKey={defaultExpand ? "0" : null}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <div className="font-weight-bolder text-primary">Assessment</div>
            {mode !== GENERIC_EDITOR_MODES.DISPLAY && (
              <>
                <span className="text-sm text-secondary">Score: </span>
                <span className="text-warning font-weight-bolder">
                  {selectedScore
                    ? getSumScores(selectedScore)
                    : getSumScores(responseData)}
                  /{taskResourceBlock.questionTotalScore}
                </span>
              </>
            )}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="overflow-auto">
              <PreviewRubric
                initialScore={
                  responseData &&
                  responseData[taskResourceBlock.taskResourceBlockRef] &&
                  responseData[taskResourceBlock.taskResourceBlockRef].score
                }
                rubric={taskResourceBlock.rubric}
                mode={mode}
                handleScoreChange={(score) =>
                  handleScoreChange(
                    taskResourceBlock.taskResourceBlockRef,
                    score
                  )
                }
              />
              {taskResourceBlock.rubricBlocks &&
                taskResourceBlock.rubricBlocks.map((block, index) => (
                  <div key={`additional-rubric-${index}`} className="mt-4">
                    <PreviewRubric
                      initialScore={
                        responseData &&
                        responseData[block.taskResourceBlockRef] &&
                        responseData[block.taskResourceBlockRef].score
                      }
                      rubric={block.rubric}
                      mode={mode}
                      handleScoreChange={(score) =>
                        handleScoreChange(block.taskResourceBlockRef, score)
                      }
                    />
                  </div>
                ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    )
  );
};

export default RubricAccordion;
