// https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
import React from "react";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(({ children, style, className }, ref) => {
  return (
    <div ref={ref} style={style} className={className}>
      {children}
    </div>
  );
});

export { CustomMenu, CustomToggle };

//Example:
// render(
//   <Dropdown>
//     <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
//       Custom toggle
//     </Dropdown.Toggle>

//     <Dropdown.Menu as={CustomMenu}>
//       <Dropdown.Item eventKey="1">Red</Dropdown.Item>
//       <Dropdown.Item eventKey="2">Blue</Dropdown.Item>
//       <Dropdown.Item eventKey="3" active>
//         Orange
//       </Dropdown.Item>
//       <Dropdown.Item eventKey="1">Red-Orange</Dropdown.Item>
//     </Dropdown.Menu>
//   </Dropdown>
// );
