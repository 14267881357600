import React from "react";
import { Card } from "react-bootstrap";
import { FileEarmarkArrowDownFill } from "react-bootstrap-icons";

const FileCard = ({ url, fileName }) => {
  const FileLink = ({ url, fileName, textClassName, clickable = true }) => {
    const Content = () => (
      <span
        className={`d-inline-block text-truncate align-middle ${textClassName}`}
        style={{ maxWidth: "100%" }}
      >
        <FileEarmarkArrowDownFill size={32} className="mr-3" />
        {fileName}
      </span>
    );
    return clickable ? (
      <a href={url} download target="_blank" rel="noreferrer">
        <Content />
      </a>
    ) : (
      <Content />
    );
  };
  return (
    <a href={url} download target="_blank" rel="noreferrer">
      <Card className="bg-warning">
        <Card.Body>
          <FileLink
            textClassName="text-white font-weight-bold"
            fileName={fileName}
            url={url}
            clickable={false}
          />
        </Card.Body>
      </Card>
    </a>
  );
};

export default FileCard;
