import { inviteUserToCourseExec } from "../actions";

export const sendInvitationEmail = (
  userRef,
  courseExecRef,
  handleSuccess,
  handleFail
) => {
  const data = {
    courseExecRef: courseExecRef,
    users: [userRef],
  };
  inviteUserToCourseExec(
    data,
    () => {
      handleSuccess();
    },
    () => {
      handleFail();
    }
  );
};
