import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import qs from "query-string";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import {
  PeopleFill,
  PencilFill,
  BoxArrowInRight,
  X,
  Envelope,
  PersonCircle,
  Trash,
  CheckCircleFill,
} from "react-bootstrap-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import {
  loadCourseExecDetail,
  loadCourseCurriculum,
  listUserCourseExecLink,
  inviteUserToCourseExec,
  listUserCourseLink,
} from "../../actions";
import ClassroomCreateEditForm from "../../components/createEditForms/ClassroomCreateEditForm";
import UserCourseExecLinkDeleteForm from "../../components/createEditForms/UserCourseExecLinkDeleteForm";
import UserCreateForm from "../../components/createEditForms/UserCreateForm";
import CurriculumList from "../../components/course/CurriculumList";
import ModalButton from "../../components/util/ModalButton";
import ReactTable from "../../components/util/ReactTable";
import {
  CourseExecManagementMode,
  CourseExecStatus,
  RoleType,
} from "../../utils/constants";
import AvatarWithText from "../../components/util/AvatarWithText";
import TablePanelHeader from "../../components/util/TablePanelHeader";
import { exportUserCSV } from "../../utils/CSVUtils";
import { getFirstCourseTaskLinkRefFromCurriculum } from "../../utils/DataLogicUtils";
import SendInvitationEmailButton from "../../components/util/SendInvitationEmailButton";
import ClassroomProgressPanel from "../../components/classroom/ClassroomProgressPanel";
import { FixedClassroomArchiveAlert } from "../../components/util/FixedTopAlert";
import ClassroomTimelineTable from "../insight/classroom/ClassroomTimelineTable";
import Can from "../../utils/Can";
import UserCourseLinkCreateEditForm from "../../components/createEditForms/UserCourseLinkCreateEditForm";
import UserCourseLinkDeleteForm from "../../components/createEditForms/UserCourseLinkDeleteForm";
import UserEditCourseButton from "../../components/course/UserEditCourseButton";

const OverviewPanel = ({ courseExecData, setMode }) => {
  let history = useHistory();
  const [courseCurriculumData, setCourseCurriculumData] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);

  useEffect(() => {
    if (shouldReloadData) {
      loadCourseCurriculum(courseExecData.course.courseRef, (data) => {
        setCourseCurriculumData(data);
      });
      setShouldReloadData(false);
    }
  }, [courseExecData, shouldReloadData]);

  const ClassDetailItem = ({ title, titleButton, content }) => {
    return (
      <>
        <div className="d-flex justify-content">
          <div className="text-sm d-flex align-items-end mr-2 ">{title}</div>
          {titleButton}
        </div>
        <div className="text-dark">{content}</div>
      </>
    );
  };

  return (
    courseCurriculumData && (
      <Container fluid className="h-100">
        {courseCurriculumData && (
          <h4 className="text-light py-4">
            <a href="/teacher/my-courses" className="text-light">
              <u>My Courses</u>
            </a>{" "}
            / {courseCurriculumData.courseDetail.project.title}
          </h4>
        )}
        <Row>
          <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={7}>
            <div className="bg-white px-4 py-4 mb-3 rounded">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <h5>Course Curriculum</h5>
                </div>
                <div>
                  <UserEditCourseButton
                    courseRef={courseExecData.courseRef}
                    disabled={
                      courseExecData.status === CourseExecStatus.ARCHIVED
                    }
                  />
                  <Button
                    className="d-none d-md-inline-block ml-2"
                    variant="warning text-white rounded-pill b-1"
                    size="sm"
                    onClick={() =>
                      (window.location.href = `/classroom/${courseExecData.courseExecRef}`)
                    }
                  >
                    <FontAwesomeIcon icon={faBookOpen} /> View Course
                  </Button>
                  <Button
                    className="d-inline-block d-md-none"
                    variant="warning rounded-circle btn-icon-only text-white"
                    size="sm"
                    onClick={() =>
                      (window.location.href = `/classroom/${courseExecData.courseExecRef}`)
                    }
                  >
                    <FontAwesomeIcon icon={faBookOpen} />
                  </Button>
                </div>
              </div>
              <div className="mr-3">
                <CurriculumList
                  courseCurriculumData={courseCurriculumData}
                  selectedCourseTaskLinkRef={
                    courseExecData.userCourseExecLink &&
                    courseExecData.userCourseExecLink
                      .lastViewedCourseTaskLinkRef
                      ? courseExecData.userCourseExecLink
                          .lastViewedCourseTaskLinkRef
                      : getFirstCourseTaskLinkRefFromCurriculum(
                          courseCurriculumData
                        )
                  }
                  handleTaskClick={(courseTaskLinkRef) => {
                    const searchStr = qs.stringify({ task: courseTaskLinkRef });
                    history.push({
                      pathname: `/classroom/${courseExecData.courseExecRef}`,
                      search: searchStr,
                    });
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={5}>
            <div className="bg-white rounded mb-3 px-4 py-4">
              <span>
                <h5>
                  Course Details
                  <ModalButton
                    variant="link"
                    buttonText={<PencilFill size={12} />}
                    modalTitle="Edit Course"
                    className="p-0 pl-3 pb-1"
                  >
                    <ClassroomCreateEditForm
                      mode="edit"
                      courseExecRef={courseExecData.courseExecRef}
                      onSaveSuccess={() => {
                        window.location.reload();
                        setShouldReloadData(true);
                      }}
                    />
                  </ModalButton>
                </h5>
                <span></span>
              </span>

              <Row>
                <Col>
                  <ClassDetailItem
                    title="Classroom"
                    content={courseExecData.classroomName}
                  />
                </Col>
                <Col>
                  <ClassDetailItem
                    title="Subject"
                    content={
                      courseExecData.subject ? courseExecData.subject : "-"
                    }
                  />
                </Col>
              </Row>

              {courseExecData.status === CourseExecStatus.ACTIVE && (
                <>
                  <hr className="my-3" />
                  <h5>Manage enrolment</h5>
                  <Button
                    className="rounded-pill my-2"
                    onClick={() => setMode(CourseExecManagementMode.TEACHER)}
                  >
                    <PersonCircle size={20} className="pb-1" /> Add/Remove
                    Teachers
                  </Button>
                  <br />
                  <Button
                    className="rounded-pill mt-2"
                    onClick={() => setMode(CourseExecManagementMode.STUDENT)}
                  >
                    <PeopleFill size={20} className="pb-1" /> Add/Remove
                    Students
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    )
  );
};

const MainPanel = ({ courseExecData, setMode }) => {
  const HeaderTabs = ({ showTimeline = false }) => (
    <Tabs
      defaultActiveKey="home"
      className="font-weight-bold border-0 ml-2 mt-5"
      unmountOnExit={true}
    >
      <Tab eventKey="home" title="Course Curriculum & Class Management">
        <OverviewPanel courseExecData={courseExecData} setMode={setMode} />
      </Tab>
      <Tab eventKey="progress" title="Progress Dashboard">
        <ClassroomProgressPanel courseExecData={courseExecData} />
      </Tab>
      {showTimeline && (
        <Tab eventKey="timeline" title="Timeline">
          <TimelinePanel courseExecData={courseExecData} />
        </Tab>
      )}
    </Tabs>
  );

  return (
    <>
      {courseExecData.status === CourseExecStatus.ARCHIVED && (
        <FixedClassroomArchiveAlert />
      )}
      <div className="white-nav-tab">
        <Can
          perform="admin:dashboard"
          yes={() => <HeaderTabs showTimeline={true} />}
          no={() => <HeaderTabs showTimeline={false} />}
        />
      </div>
    </>
  );
};

const UserPanel = ({ role, courseExecData, setMode }) => {
  const [courseExecUserData, setCourseExecUserData] = useState(null);
  const [userCourseLinkData, setUserCourseLinkData] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);
  const [isSendingAll, setIsSendingAll] = useState(false);
  const { addToast } = useToasts();
  const userEmail = useSelector((state) => state.auth.userDetail.email);

  const handleSendAll = () => setIsSendingAll(true);

  useEffect(() => {
    if (shouldReloadData) {
      if (role === RoleType.STUDENT) {
        const data = {
          courseExecRef: courseExecData.courseExecRef,
          roleTypeName: role,
        };
        listUserCourseExecLink(data, (data) => {
          setCourseExecUserData(data);
        });
      }

      if (role === RoleType.TEACHER) {
        listUserCourseLink({ courseRef: courseExecData.courseRef }, (data) => {
          setUserCourseLinkData(data);
        });
      }
      setShouldReloadData(false);
    }
  }, [courseExecData, role, shouldReloadData]);

  const onSaveSuccess = () => setShouldReloadData(true);

  const removeFromClassTableRowButtons = (data) => {
    const ref = data.userCourseExecLinkRef;
    return (
      <ModalButton
        variant="link"
        className={`p-0 text-underline text-danger ${
          data.user.email === userEmail ? "invisible" : ""
        }`}
        buttonText={
          <>
            <Trash className="mr-2 pb-1" size={24} />
            Remove from class
          </>
        }
        modalTitle={`Remove ${roleStr} from class`}
      >
        <UserCourseExecLinkDeleteForm
          userCourseExecLinkRef={ref}
          onSaveSuccess={() => setShouldReloadData(true)}
        />
      </ModalButton>
    );
  };

  const removeFromCourseTableRowButtons = (data) => {
    const ref = data.userCourseLinkRef;
    return (
      <ModalButton
        variant="link"
        className={`p-0 text-underline text-danger ${
          data.user.email === userEmail ? "invisible" : ""
        }`}
        buttonText={
          <>
            <Trash className="mr-2 pb-1" size={24} />
            Remove from course
          </>
        }
        modalTitle={`Remove ${roleStr} from course`}
      >
        <UserCourseLinkDeleteForm
          userCourseLinkRef={ref}
          onSaveSuccess={() => setShouldReloadData(true)}
        />
      </ModalButton>
    );
  };

  const roleStr = role === RoleType.STUDENT ? "Student" : "Teacher";

  const columns = [
    {
      Header: "Name",
      accessor: (row) => `${row.user.firstName} ${row.user.lastName}`,
      Cell: (cellInfo) => (
        <AvatarWithText userDetail={cellInfo.row.original.user} />
      ),
    },
    {
      Header: "Email",
      accessor: "user.email",
      Cell: ({ value }) => value,
    },
    {
      Header: "Invitation Email",
      accessor: "emailSent",
      Cell: ({ value }) =>
        value ? (
          <>
            SENT{" "}
            <CheckCircleFill className="text-success mr-2 pb-1" size={24} />
          </>
        ) : (
          "NOT SENT"
        ),
    },
    {
      id: "sendEmailButton",
      Header: "",
      Cell: (cellInfo) => (
        <SendInvitationEmailButton
          userRef={cellInfo.row.original.user.id}
          courseExecRef={courseExecData.courseExecRef}
          showResend={cellInfo.row.original.emailSent}
          onSendSuccess={() => (cellInfo.row.original.emailSent = true)}
          addToast={addToast}
        />
      ),
    },
    {
      Header: "",
      accessor: "user.lastName",
      Cell: (cellInfo) => removeFromClassTableRowButtons(cellInfo.row.original),
    },
  ];

  const teacherColumns = [
    {
      Header: "Name",
      accessor: (row) => `${row.user.firstName} ${row.user.lastName}`,
      Cell: (cellInfo) => (
        <AvatarWithText userDetail={cellInfo.row.original.user} />
      ),
    },
    {
      Header: "Email",
      accessor: "user.email",
      Cell: ({ value }) => value,
    },
    {
      Header: "Invitation Email",
      accessor: "emailSent",
      Cell: ({ value }) =>
        value ? (
          <>
            SENT{" "}
            <CheckCircleFill className="text-success mr-2 pb-1" size={24} />
          </>
        ) : (
          "NOT SENT"
        ),
    },
    {
      id: "sendEmailButton",
      Header: "",
      Cell: (cellInfo) => (
        <SendInvitationEmailButton
          userRef={cellInfo.row.original.user.id}
          courseExecRef={courseExecData.courseExecRef}
          showResend={cellInfo.row.original.emailSent}
          onSendSuccess={() => (cellInfo.row.original.emailSent = true)}
          addToast={addToast}
        />
      ),
    },
    {
      Header: "",
      accessor: "user.lastName",
      Cell: (cellInfo) =>
        removeFromCourseTableRowButtons(cellInfo.row.original),
    },
  ];

  // Handle send all invitations
  useEffect(() => {
    if (courseExecData && courseExecUserData && isSendingAll) {
      let r = window.confirm(
        "You will send invitation emails to all users below. Continue?"
      );
      if (!r) {
        setIsSendingAll(false);
        return;
      }
      let users = [];
      for (const userCourseExecLink of courseExecUserData) {
        users.push(userCourseExecLink.user.id);
      }
      if (users.length === 0) {
        addToast("Please add users before sending invitations.", {
          appearance: "error",
          autoDismiss: true,
        });
        setIsSendingAll(false);
        return;
      }
      const data = {
        courseExecRef: courseExecData.courseExecRef,
        users: users,
      };
      inviteUserToCourseExec(
        data,
        () => {
          addToast("Invitation Sent!", {
            appearance: "success",
            autoDismiss: true,
          });
          setIsSendingAll(false);
          setShouldReloadData(true);
        },
        () => {
          addToast("Invitation Failed to send. Please try again later.", {
            appearance: "error",
            autoDismiss: true,
          });
          setIsSendingAll(false);
        }
      );
    }
  }, [isSendingAll, courseExecUserData, courseExecData]);

  const ManagementPanelTitle = ({ courseExecData }) => {
    return (
      <>
        <u
          className="cursor-pointer text-nowrap"
          onClick={() => setMode(CourseExecManagementMode.OVERVIEW)}
        >
          {courseExecData.course.project.title}
        </u>{" "}
        / {courseExecData.classroomName}
      </>
    );
  };

  const ResponsiveButtonTitle = ({ title }) => (
    <div className="d-none d-md-inline">{title}</div>
  );

  return (
    <>
      <TablePanelHeader
        title={<ManagementPanelTitle courseExecData={courseExecData} />}
      >
        <Button
          variant="link"
          className="text-light px-0"
          disabled={isSendingAll}
          onClick={!isSendingAll ? handleSendAll : null}
        >
          {isSendingAll ? (
            "Sending..."
          ) : (
            <>
              <Envelope className="mr-2 pb-1" size={24} />
              <ResponsiveButtonTitle
                title="
                Send Invitation Emails to All"
              />
            </>
          )}
        </Button>
        <Button
          variant="link"
          className="text-light px-0 mx-3"
          onClick={() => {
            let r = window.confirm(
              "You will export all users below. Continue?"
            );
            if (!r) {
              setIsSendingAll(false);
              return;
            }
            exportUserCSV(courseExecData.classroomName, courseExecUserData);
          }}
        >
          <BoxArrowInRight className="mr-2 pb-1" size={24} />
          <ResponsiveButtonTitle
            title="
                Export"
          />
        </Button>
        <ModalButton
          variant="warning"
          className="rounded-pill text-white"
          modalSize="lg"
          buttonText={
            <>
              +<PeopleFill size={20} className="pb-1 mr-2" />
              <ResponsiveButtonTitle title={`Add ${roleStr}s`} />
            </>
          }
          modalTitle={`Add ${roleStr}s`}
        >
          {role === RoleType.STUDENT ? (
            <UserCreateForm
              courseExecRef={courseExecData.courseExecRef}
              role={RoleType.STUDENT}
              onSaveSuccess={onSaveSuccess}
            />
          ) : (
            <UserCourseLinkCreateEditForm
              mode="create"
              courseRef={courseExecData.courseRef}
              onSaveSuccess={() => setShouldReloadData(true)}
            />
          )}
        </ModalButton>
        <Button
          className="text-light border-0 ml-3 px-0"
          variant="link"
          onClick={() => setMode(CourseExecManagementMode.OVERVIEW)}
        >
          <X size={32} />
        </Button>
      </TablePanelHeader>
      {courseExecUserData && role === RoleType.STUDENT && (
        <ReactTable
          title={`${roleStr}s`}
          data={courseExecUserData}
          columns={columns}
        />
      )}
      {userCourseLinkData && role === RoleType.TEACHER && (
        <ReactTable
          title={`${roleStr}s`}
          data={userCourseLinkData}
          columns={teacherColumns}
        />
      )}
    </>
  );
};

const TimelinePanel = ({ courseExecData }) => {
  return (
    <>
      {courseExecData.status === CourseExecStatus.ARCHIVED && (
        <FixedClassroomArchiveAlert />
      )}
      <ClassroomTimelineTable courseExecRef={courseExecData.courseExecRef} />
    </>
  );
};

const CourseExecManagementPanel = () => {
  const { courseExecRef } = useParams();
  const [mode, setMode] = useState(CourseExecManagementMode.OVERVIEW);
  const [courseExecData, setCourseExecData] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);

  useEffect(() => {
    if (shouldReloadData) {
      loadCourseExecDetail(
        courseExecRef,
        (data) => {
          setCourseExecData(data);
        },
        () => (window.location.href = "/access-denied")
      );
      setShouldReloadData(false);
    }
  }, [courseExecRef, shouldReloadData]);

  const switchMode = (mode) => {
    if (!courseExecData) return null;
    switch (mode) {
      case CourseExecManagementMode.OVERVIEW:
        return <MainPanel courseExecData={courseExecData} setMode={setMode} />;
      case CourseExecManagementMode.TIMELINE:
        return (
          <TimelinePanel courseExecData={courseExecData} setMode={setMode} />
        );
      case CourseExecManagementMode.TEACHER:
        return (
          <UserPanel
            role={RoleType.TEACHER}
            courseExecData={courseExecData}
            setMode={setMode}
          />
        );
      case CourseExecManagementMode.STUDENT:
        return (
          <UserPanel
            role={RoleType.STUDENT}
            courseExecData={courseExecData}
            setMode={setMode}
          />
        );
      default:
        break;
    }
  };
  return switchMode(mode);
};

export default CourseExecManagementPanel;
