import Adapter from "./Adapter";

export default function AmplifyUploadAdapterPlugin(editor) {
  const storage = editor.config.get("AmplifyUpload.storage");
  const namePrefix = editor.config.get("AmplifyUpload.namePrefix");

  if (!storage) {
    console.warn(
      "You have not passed in the AWS Storage Object as the editor config."
    );
    return;
  }

  editor.plugins.get("FileRepository").createUploadAdapter = loader =>
    new Adapter(loader, storage, namePrefix, editor.t);
}
