import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import {
  inviteUserToCourseExec,
  loadUserSchoolLinkList,
} from "../../../actions";
import UserCreateForm from "../../createEditForms/UserCreateForm";
import ModalButton from "../../util/ModalButton";
import ReactTable, { RightButtonsTableCell } from "../../util/ReactTable";
import { RoleType } from "../../../utils/constants";
import { exportUserCSV } from "../../../utils/CSVUtils";
import TablePanelHeader from "../../util/TablePanelHeader";
import AvatarWithText from "../../util/AvatarWithText";
import { CheckCircleFill, Envelope } from "react-bootstrap-icons";
import UserDeactivateForm from "../../createEditForms/UserDeactivateForm";
import SendInvitationEmailButton from "../../util/SendInvitationEmailButton";

const SchoolUserPanel = ({ role, schoolRef }) => {
  const [linkListData, setLinkListData] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);
  const [isDoneSaving, setIsDoneSaving] = useState(false);
  const [isSendingAll, setIsSendingAll] = useState(false);
  const { addToast } = useToasts();

  const handleSendAll = () => setIsSendingAll(true);

  useEffect(() => {
    if (shouldReloadData) {
      loadUserSchoolLinkList(schoolRef, role, (data) => {
        setLinkListData(data);
      });
      setShouldReloadData(false);
    }
  }, [schoolRef, role, shouldReloadData]);

  // Handle send all invitations
  useEffect(() => {
    if (linkListData && isSendingAll) {
      setIsSendingAll(true);
      let users = [];
      for (const link of linkListData) {
        users.push(link.user.id);
      }
      if (users.length === 0) {
        addToast("Please add users before sending invitations.", {
          appearance: "error",
          autoDismiss: true,
        });
        setIsSendingAll(false);
        return;
      }
      const data = {
        courseExecRef: "",
        users: users,
      };
      inviteUserToCourseExec(
        data,
        () => {
          addToast("Invitation Sent!", {
            appearance: "success",
            autoDismiss: true,
          });
          setIsSendingAll(false);
          setShouldReloadData(true);
        },
        () => {
          addToast("Invitation Failed to send. Please try again later.", {
            appearance: "error",
            autoDismiss: true,
          });
          setIsSendingAll(false);
        }
      );
    }
  }, [isSendingAll, linkListData]);

  const onSaveSuccess = () => {
    setShouldReloadData(true);
    setIsDoneSaving(true);
  };

  const tableRowButtons = (link) => {
    return (
      <RightButtonsTableCell>
        <ModalButton
          variant="link"
          buttonText="Delete"
          modalTitle={`Delete ${roleStr}`}
          className="text-danger"
        >
          <UserDeactivateForm
            userDetail={link.user}
            onSaveSuccess={() => setShouldReloadData(true)}
          />
        </ModalButton>
      </RightButtonsTableCell>
    );
  };

  const roleStr = role === RoleType.STUDENT ? "Student" : "Teacher";

  const columns = [
    {
      Header: roleStr,
      accessor: (row) => `${row.user.firstName} ${row.user.lastName}`,
      Cell: (cellInfo, { value }) => (
        <AvatarWithText userDetail={cellInfo.row.original.user} />
      ),
    },
    {
      Header: "Email",
      accessor: "user.username",
      Cell: ({ value }) => value,
    },
    {
      Header: "Invitation Email",
      accessor: "emailSent",
      Cell: ({ value }) =>
        value ? (
          <>
            SENT{" "}
            <CheckCircleFill className="text-success mr-2 pb-1" size={24} />
          </>
        ) : (
          "NOT SENT"
        ),
    },
    {
      id: "sendEmailButton",
      Header: "",
      Cell: (cellInfo) => (
        <SendInvitationEmailButton
          userRef={cellInfo.row.original.user.id}
          courseExecRef=""
          showResend={cellInfo.row.original.emailSent}
          onSendSuccess={() => (cellInfo.row.original.emailSent = true)}
          addToast={addToast}
        />
      ),
    },
    {
      Header: "",
      accessor: "lastName",
      Cell: (cellInfo) => tableRowButtons(cellInfo.row.original),
    },
  ];

  return (
    linkListData && (
      <>
        <TablePanelHeader>
          <Button
            variant="link"
            disabled={isSendingAll}
            onClick={!isSendingAll ? handleSendAll : null}
          >
            {isSendingAll ? (
              "Sending..."
            ) : (
              <>
                <Envelope className="mr-2 pb-1" size={24} />
                Send Invitation Emails to All
              </>
            )}
          </Button>
          <Button
            variant="link"
            onClick={() => exportUserCSV(`school-${role}`, linkListData)}
          >
            Export
          </Button>
          <ModalButton
            modalSize="lg"
            buttonText={`Add ${roleStr}`}
            modalTitle={`Add ${roleStr}`}
            getShouldClose={() => isDoneSaving}
          >
            <UserCreateForm
              schoolRef={schoolRef}
              role={role}
              onSaveSuccess={onSaveSuccess}
            />
          </ModalButton>
        </TablePanelHeader>

        <ReactTable
          title={`${roleStr}s`}
          data={linkListData}
          columns={columns}
        />
      </>
    )
  );
};

export default SchoolUserPanel;
