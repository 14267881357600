import React from "react";

const TablePanelHeader = (props) => {
  const Title = () => (
    <h4
      className={props.titleClassName ? props.titleClassName : "text-light m-0"}
      style={{}}
    >
      {props.title}
    </h4>
  );
  return (
    <>
      <div className="d-block d-lg-none">
        <Title />
      </div>
      <div className="d-flex justify-content-between pb-3 pt-4">
        <div className="d-none d-lg-flex align-items-center pr-3 w-100">
          <Title />
        </div>
        <div className="h-100 w-100">
          <div className="d-flex justify-content-end align-center">
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default TablePanelHeader;
