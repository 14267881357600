import React from "react";
import { useParams } from "react-router-dom";
import CourseList from "../../components/course/CourseList";

const CourseListPage = () => {
  const { projectRef } = useParams();
  return <CourseList projectRef={projectRef} />;
};

export default CourseListPage;
