import React, { useState, useEffect } from "react";
import ModalButton from "../util/ModalButton";
import { Button, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ReactTable from "../util/ReactTable";
import {
  loadCourseList,
  loadProjectDetail,
  updateCourseIsAssignable,
} from "../../actions";
import CourseCreateEditForm from "../createEditForms/CourseCreateEditForm";
import CourseDeleteForm from "../createEditForms/CourseDeleteForm";
import { Link } from "react-router-dom";
import TablePanelHeader from "../util/TablePanelHeader";
import CourseDuplicateForm from "../createEditForms/CourseDuplicateForm";
import { ChevronRight } from "react-bootstrap-icons";
import Can from "../../utils/Can";

const CourseList = ({ projectRef }) => {
  const [courseListData, setCourseListData] = useState(null);
  const [projectDetailData, setProjectDetailData] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);
  const [mode, setMode] = useState(true);

  useEffect(() => {
    if (shouldReloadData) {
      loadCourseList(projectRef, (data) => {
        setCourseListData(data);
      });
      loadProjectDetail(projectRef, (data) => {
        setProjectDetailData(data);
      });
      setShouldReloadData(false);
    }
  }, [projectRef, shouldReloadData]);

  const columns = [
    {
      Header: "Course Name",
      accessor: "title",
      Cell: (cellInfo) => (
        <div className="text-truncate">
          <Can
            perform="pbl-content:modify"
            yes={() => (
              <Link
                to={`course/${cellInfo.row.original.courseRef}`}
                replace={true}
              >
                {cellInfo.row.original.title}
              </Link>
            )}
            no={() => (
              <Link
                to={`/course-preview/${cellInfo.row.original.courseRef}`}
                target="_blank"
              >
                {cellInfo.row.original.title}
              </Link>
            )}
          />
        </div>
      ),
    },
    {
      Header: "Course Version",
      accessor: "description",
      Cell: ({ value }) => <div className="text-truncate">{value}</div>,
    },
    {
      Header: "",
      id: "previewButton",
      Cell: (cellInfo) => (
        <Button
          size="sm"
          variant="link p-0"
          onClick={() => {
            window
              .open(
                `/course-preview/${cellInfo.row.original.courseRef}`,
                "_blank"
              )
              .focus();
          }}
        >
          Preview
        </Button>
      ),
    },
    {
      Header: "",
      id: "archiveButton",
      Cell: (cellInfo) => (
        <Can
          perform="pbl-content:modify"
          yes={() => (
            <Button
              size="sm"
              variant="link p-0"
              onClick={() => {
                const modeStr = mode ? "archive" : "unarchive";
                if (
                  !window.confirm(
                    `Are you sure you want to ${modeStr} this course version?`
                  )
                )
                  return;
                const data = {
                  courseRef: cellInfo.row.original.courseRef,
                  isAssignable: !mode,
                };
                updateCourseIsAssignable(
                  data,
                  () => {
                    setShouldReloadData(true);
                  },
                  () => {}
                );
              }}
            >
              {mode ? "Archive" : "Unarchive"}
            </Button>
          )}
          no={() => null}
        />
      ),
    },
    {
      Header: "",
      id: "editButton",
      Cell: (cellInfo) => (
        <Can
          perform="pbl-content:modify"
          yes={() => (
            <ModalButton
              modalSize="xl"
              variant="link p-0"
              buttonText="Edit"
              modalTitle="Edit Course"
            >
              <CourseCreateEditForm
                mode="edit"
                courseRef={cellInfo.row.original.courseRef}
                onSaveSuccess={() => setShouldReloadData(true)}
              />
            </ModalButton>
          )}
          no={() => null}
        />
      ),
    },
    {
      Header: "",
      id: "duplicateButton",
      Cell: (cellInfo) => (
        <Can
          perform="pbl-content:modify"
          yes={() => (
            <ModalButton
              size="sm"
              variant="link p-0"
              buttonText="Duplicate"
              modalTitle="Duplicate Course"
            >
              <CourseDuplicateForm
                courseRef={cellInfo.row.original.courseRef}
                onSaveSuccess={() => setShouldReloadData(true)}
              />
            </ModalButton>
          )}
          no={() => null}
        />
      ),
    },
    {
      Header: "",
      id: "deleteButton",
      Cell: (cellInfo) => (
        <Can
          perform="pbl-content:modify"
          yes={() => (
            <ModalButton
              size="sm"
              variant="link p-0"
              className="text-danger"
              buttonText="Delete"
              modalTitle="Delete Course"
            >
              <CourseDeleteForm
                courseRef={cellInfo.row.original.courseRef}
                onSaveSuccess={() => setShouldReloadData(true)}
              />
            </ModalButton>
          )}
          no={() => null}
        />
      ),
    },
  ];

  const title = projectDetailData && (
    <>
      <Link className="text-light" to={`/admin/projects`}>
        <u>All Projects</u>
      </Link>
      <ChevronRight className="mx-2" size={20} />
      {projectDetailData.title}
    </>
  );

  const tableHeader =
    courseListData && projectDetailData && projectDetailData.title;

  return (
    <>
      <TablePanelHeader title={title}>
        <div className="mr-7">
          <ToggleButtonGroup
            type="radio"
            name="options"
            value={mode}
            onChange={(val) => setMode(val)}
          >
            <ToggleButton value={true} variant="outline-light">
              Active
            </ToggleButton>
            <ToggleButton value={false} variant="outline-light">
              Archived
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <Can
          perform="pbl-content:modify"
          yes={() => (
            <ModalButton
              modalSize="xl"
              buttonText="+ CREATE NEW COURSE"
              modalTitle="Create New Course"
              variant="light"
            >
              <CourseCreateEditForm
                mode="create"
                projectRef={projectRef}
                onSaveSuccess={() => setShouldReloadData(true)}
              />
            </ModalButton>
          )}
          no={() => null}
        />
      </TablePanelHeader>
      {courseListData ? (
        <ReactTable
          title={tableHeader}
          data={courseListData.filter((course) => course.isAssignable === mode)}
          columns={columns}
        />
      ) : null}
    </>
  );
};

export default CourseList;
