// https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
import { useSelector } from "react-redux";
import rules from "./rbac-rules";

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const getRole = (userInfo) => {
  if (userInfo.roleTypeName === "ADMIN" && userInfo.roleLevel === 1)
    return "SBU_ADMIN";
  return userInfo.roleTypeName;
};

const Can = (props) => {
  const userDetail = useSelector((state) => state.auth.userDetail);
  if (!userDetail) return null;

  return check(rules, getRole(userDetail.userInfo), props.perform, props.data)
    ? props.yes()
    : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
