import React, { useState, useEffect } from "react";
import { Card, Form, Button, Col, Row } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import {
  loadCurrentUserDetail,
  updateProfile,
  updatePassword,
} from "../../../actions";
import HeaderPageContainer from "../HeaderPageContainer";
import {
  getNameInitials,
  getNewPasswordValid,
} from "../../../utils/GenericUtils";

const ChangePasswordCard = () => {
  const [oldPasswordInValid, setOldPasswordInValid] = useState(false);
  const [newPasswordInValid, setNewPasswordInValid] = useState(false);
  const [passwordAgainInValid, setPasswordAgainInValid] = useState(false);
  const { addToast } = useToasts();

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    setOldPasswordInValid(false);
    setNewPasswordInValid(false);
    setPasswordAgainInValid(false);
    const oldPassword = event.target.oldPassword.value;
    const newPassword = event.target.newPassword.value;
    const newPasswordAgain = event.target.newPasswordAgain.value;
    if (newPassword !== newPasswordAgain) {
      setPasswordAgainInValid(true);
      return;
    }
    if (!getNewPasswordValid(newPassword)) {
      setNewPasswordInValid(true);
      return;
    }
    if (oldPassword === newPassword) {
      setOldPasswordInValid(true);
      setNewPasswordInValid(true);
      return;
    }
    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    updatePassword(
      data,
      () => {
        addToast("Update successful.", {
          appearance: "success",
          autoDismiss: true,
        });
        setTimeout(() => window.location.reload(), 1000);
      },
      () => {
        addToast("Old password must be correct.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    );
    return;
  };
  return (
    <Card>
      <Card.Body>
        <h5>Change Password</h5>
        <Form className="mt-3" onSubmit={handlePasswordSubmit}>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="formGridOldPassword">
                <Form.Label className="text-dark">Old Password</Form.Label>
                <Form.Control type="password" name="oldPassword" required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId="formGridNewPassword">
                <Form.Label className="text-dark">New Password</Form.Label>
                <Form.Control
                  isInvalid={newPasswordInValid}
                  type="password"
                  name="newPassword"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {oldPasswordInValid
                    ? "New password cannot be the same as the old password."
                    : "Passwords must be strong enough, with at least 6 characters, digits, " +
                      "lowercase/uppercase letters and special characters (e.g. $#%^*?:)."}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId="formGridPassword">
                <Form.Label className="text-dark text-nowrap">
                  Re-enter new password
                </Form.Label>
                <Form.Control
                  isInvalid={passwordAgainInValid}
                  type="password"
                  name="newPasswordAgain"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  New passwords must be the same
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <div className="float-right">
            <Button variant="primary rounded-pill" type="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

const ProfilePage = () => {
  const [userDetail, setUserDetail] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    loadCurrentUserDetail(
      (data) => setUserDetail(data),
      () => {}
    );
  }, []);

  const handleGeneralSubmit = (event) => {
    event.preventDefault();
    const data = {
      userRef: userDetail.id,
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
    };
    updateProfile(data, () => {
      addToast("Update successful.", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => window.location.reload(), 1000);
    });
    return;
  };

  return (
    userDetail && (
      <HeaderPageContainer>
        <div className="my-8 text-dark">
          <h3 className="text-light font-weight-bold">Profile</h3>
          <Card>
            <Card.Body>
              <h5>General</h5>
              <Form onSubmit={handleGeneralSubmit}>
                <Form.Row className="my-3">
                  <span className="avatar bg-primary-light text-white rounded-circle avatar-xl">
                    {getNameInitials(
                      userDetail.firstName + " " + userDetail.lastName
                    )}
                  </span>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridFirstname">
                    <Form.Label className="text-dark">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      defaultValue={userDetail.firstName}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridLastname">
                    <Form.Label className="text-dark">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      defaultValue={userDetail.lastName}
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label className="text-dark">Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      defaultValue={userDetail.email}
                      disabled
                    />
                  </Form.Group>
                </Form.Row>
                <div className="float-right">
                  <Button
                    variant="rounded-pill"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary rounded-pill" type="submit">
                    Save Changes
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <ChangePasswordCard />
        </div>
      </HeaderPageContainer>
    )
  );
};

export default ProfilePage;
