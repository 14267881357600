import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Alert, Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactGA4 from "react-ga4";
import { v4 as uuid } from "uuid";
import { QuestionCircle } from "react-bootstrap-icons";
import { useToasts } from "react-toast-notifications";
import { assessTaskResourceBlockWork } from "../../actions";
import {
  DATETIME_FORMAT,
  GA_ACTIONS,
  GENERIC_EDITOR_MODES,
  TASK_RESOURCE_BLOCK_TYPE,
  TrbStudentResponseStatus,
} from "../../utils/constants";
import AvatarWithText from "../util/AvatarWithText";
import {
  handleGenericSaveFailed,
  handleGenericSaveSuccess,
} from "../../utils/CreateEditFormUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ExemplarAnswerAccordion from "../task/resourceBlock/ExemplarAnswerAccordion";
import RubricAccordion from "../task/resourceBlock/RubricAccordion";
import { ContentSection } from "../task/ContentSection";
import TableWorkEditor from "../task/resourceBlock/TableWorkEditor";
import TeacherAssessmentFeedbackAccordion from "../task/resourceBlock/TeacherAssessmentFeedbackAccordion";
import QuestionBlockContainer from "../task/resourceBlock/QuestionBlockContainer";
import { deepCopy } from "../../utils/GenericUtils";
import { Select } from "antd";

const TaskAssessmentCreateEditForm = ({
  trbResponses,
  taskDetail,
  taskResourceBlock,
  questionBlocks,
  courseExecRef,
  courseTaskLinkRef,
  userRef,
  onSaveSuccess,
  handleCloseForm,
  shouldShowHideAlert,
  setShouldShowHideAlert,
  editable = true,
}) => {
  const { addToast } = useToasts();
  const [selectedUser, setSelectedUser] = useState(userRef);
  const [selectedQuestion, setSelectedQuestion] = useState(
    taskResourceBlock?.taskResourceBlockRef
  );
  const [dataToSubmit, setDataToSubmit] = useState({});

  useEffect(() => {
    if (userRef && taskResourceBlock) {
      const id = userRef + "_" + taskResourceBlock.taskResourceBlockRef;
      document.getElementById(id).scrollIntoView();
    }
  }, []);

  const FORM_MODE = {
    SINGLE_USER: "SINGLE_USER",
    MULTI_USER: "MULTI_USER",
  };
  const mode = userRef ? FORM_MODE.SINGLE_USER : FORM_MODE.MULTI_USER;

  const getDisplayData = ({ userRef, taskResourceBlock }) => {
    if (mode === FORM_MODE.SINGLE_USER) {
      const userWorkDict = trbResponses.find(
        (item) => item.user.id === userRef
      );
      return questionBlocks.map((item) => {
        return {
          trb: item,
          workDict: userWorkDict,
          user: userWorkDict.user,
        };
      });
    } else if (mode === FORM_MODE.MULTI_USER)
      // MULTI_USER
      return trbResponses.map((item) => {
        return {
          trb: taskResourceBlock,
          workDict: item,
          user: item.user,
        };
      });
    return null;
  };

  const [displayData, setDisplayData] = useState(
    getDisplayData({
      userRef: selectedUser,
      taskResourceBlock: questionBlocks.find(
        (item) => item.taskResourceBlockRef === selectedQuestion
      ),
    })
  );

  useEffect(() => {
    setDisplayData(
      getDisplayData({
        userRef: selectedUser,
        taskResourceBlock: questionBlocks.find(
          (item) => item.taskResourceBlockRef === selectedQuestion
        ),
      })
    );
    setDataToSubmit({});
  }, [selectedUser, selectedQuestion]);
  const handleSaveSuccess = () => {
    handleGenericSaveSuccess(
      addToast,
      { object: "student work", operation: "assessed" },
      onSaveSuccess,
      handleCloseForm,
      "Assessment submitted"
    );
  };

  const handleSaveFailed = () => {
    handleGenericSaveFailed(addToast, null, handleCloseForm);
  };

  const StopSubmissionAlert = () => (
    <Alert variant="danger" className="mb-4 font-weight-bold">
      Student has not submitted a response for this question
    </Alert>
  );

  const handleFormChanged = () => {
    setShouldShowHideAlert(true);
  };

  const updateDataToSubmit = (data, userRef, taskResourceBlock) => {
    setDataToSubmit((prevState) => {
      const taskResourceBlockRef = taskResourceBlock.taskResourceBlockRef;
      const dataDict = {
        [taskResourceBlockRef]: {
          taskResourceBlockRef: taskResourceBlockRef,
          taskResourceBlock: taskResourceBlock,
          ...data,
        },
      };
      if (!(userRef in prevState)) {
        return {
          ...prevState,
          [userRef]: dataDict,
        };
      } else {
        if (!(taskResourceBlockRef in prevState[userRef])) {
          return {
            ...prevState,
            [userRef]: {
              ...prevState[userRef],
              ...dataDict,
            },
          };
        } else {
          return {
            ...prevState,
            [userRef]: {
              ...prevState[userRef],
              [taskResourceBlockRef]: {
                ...prevState[userRef][taskResourceBlockRef],
                ...data,
              },
            },
          };
        }
      }
    });
  };

  const submitAll = (event) => {
    event.preventDefault();
    let trbResponsesResult = [];
    for (const userRef in dataToSubmit) {
      const userSubmission = deepCopy(dataToSubmit[userRef]);
      for (const taskResourceBlockRef in userSubmission) {
        const userTrbSubmission = userSubmission[taskResourceBlockRef];
        const taskResourceBlock = userTrbSubmission.taskResourceBlock;
        const scores = userTrbSubmission.selectedScore;
        const trbStudentResponsesDict = {};
        let scoreCount = 0;
        let blockRefs = [
          taskResourceBlock.taskResourceBlockRef,
          ...taskResourceBlock.rubricBlocks.map((e) => e.taskResourceBlockRef),
        ];
        for (const blockRef of blockRefs) {
          let newWork = { taskResourceBlockRef: blockRef };
          if (scores && scores[blockRef]) newWork = scores[blockRef];
          if (!newWork.trbStudentResponseRef)
            newWork.trbStudentResponseRef = uuid();
          if ("score" in newWork && newWork.score !== null) scoreCount++;
          if (
            userTrbSubmission.feedback &&
            newWork.taskResourceBlockRef ===
              taskResourceBlock.taskResourceBlockRef
          )
            newWork.feedback = { feedback: userTrbSubmission.feedback };
          if (
            "requestRevision" in userTrbSubmission &&
            userTrbSubmission.requestRevision !== null
          )
            newWork.requestRevision = userTrbSubmission.requestRevision;

          trbStudentResponsesDict[newWork.trbStudentResponseRef] = newWork;
        }
        if (
          scoreCount !== 0 &&
          scoreCount !== taskResourceBlock.rubricBlocks.length + 1
        ) {
          const user = trbResponses.find((item) => item.user.id === userRef)
            .user;
          // TODO: Change to also mention name and question No. , Best to redirect to that question
          alert(
            `Please assess all rubrics for ${user.firstName +
              " " +
              user.lastName}: Question ${
              taskResourceBlock.questionIndex
            } before submitting
            \r\n(${scoreCount} out of ${taskResourceBlock.rubricBlocks.length +
              1} rubrics assessed)`
          );
          return;
        }
        let item = {
          userRef: userRef,
          trbStudentResponsesDict: trbStudentResponsesDict,
        };
        trbResponsesResult.push(item);
        setShouldShowHideAlert(false);
      }
    }
    const data = {
      courseExecRef: courseExecRef,
      courseTaskLinkRef: courseTaskLinkRef,
      trbStudentResponses: trbResponsesResult,
    };
    assessTaskResourceBlockWork(
      data,
      () => {
        ReactGA4.event("assessment", {
          course_exec_ref: courseExecRef,
          course_task_link_ref: courseTaskLinkRef,
          trb_refs: null,
          explore_action: GA_ACTIONS.ASSESSMENT.TEACHER_ASSESS,
        });
        handleSaveSuccess();
      },
      () => handleSaveFailed()
    );
  };

  const handleUpdateDataToSubmit = useCallback(updateDataToSubmit, []);

  const SingleWorkAssessmentBox = ({ data, submitButtonRef, onChange }) => {
    const [selectedScore, setSelectedScore] = useState(data.workDict);
    const taskResourceBlock = data.trb;
    const user = data.user;
    const mainTrbWorkDetail =
      data.workDict[taskResourceBlock.taskResourceBlockRef];
    const trbWorkDict = data.workDict;

    const TopAlert = () => (
      <>
        {!editable && (
          <Alert variant="warning">
            This classroom has been archived. You cannot assess or provide
            feedback on student work
          </Alert>
        )}
        {!mainTrbWorkDetail && <StopSubmissionAlert />}
        {editable &&
          mainTrbWorkDetail &&
          mainTrbWorkDetail.status === TrbStudentResponseStatus.ACTIVE &&
          (mainTrbWorkDetail.lastAssessAt > mainTrbWorkDetail.lastSubmitAt ||
            (mainTrbWorkDetail.lastAssessAt &&
              !mainTrbWorkDetail.lastSubmitAt)) && (
            <Alert variant="success" className="mb-4 font-weight-bold">
              You have requested a revision. Please wait for the student to
              resubmit a response before assessing their work
            </Alert>
          )}
      </>
    );

    const handleChange = useCallback(onChange, [onChange]);

    return (
      trbWorkDict && (
        <div id={data.user.id + "_" + data.trb.taskResourceBlockRef}>
          <QuestionBlockContainer
            questionNumber={taskResourceBlock.questionIndex}
          >
            <TopAlert />
            <div className="d-block">
              <ContentSection content={taskResourceBlock.body} />
              <div className="mt-4" />
              {taskResourceBlock.resourceType ===
                TASK_RESOURCE_BLOCK_TYPE.QUESTION && (
                <Card className="mb-0">
                  <Card.Body
                    className="text-dark"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {/* Need a wrap line if there is no content for spacing */}
                    {mainTrbWorkDetail ? mainTrbWorkDetail.body : "\n"}
                  </Card.Body>
                </Card>
              )}
              {taskResourceBlock.resourceType ===
                TASK_RESOURCE_BLOCK_TYPE.TABLE &&
                taskResourceBlock.data && (
                  <div className="overflow-auto">
                    <TableWorkEditor
                      block={taskResourceBlock}
                      defaultData={
                        mainTrbWorkDetail && mainTrbWorkDetail.data
                          ? mainTrbWorkDetail.data.table
                          : null
                      }
                      editable={false}
                    />
                  </div>
                )}
            </div>
            <div className="d-block mt-4">
              <div className="d-sm-flex justify-content-between">
                <div className="d-flex mb-3 mb-sm-0">
                  {mainTrbWorkDetail &&
                    mainTrbWorkDetail.attachment &&
                    mainTrbWorkDetail.attachment.fileName && (
                      <div className="d-flex align-items-center h-100">
                        {mainTrbWorkDetail.attachment.url ? (
                          <a
                            href={mainTrbWorkDetail.attachment.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon icon={faPaperclip} />{" "}
                            {mainTrbWorkDetail.attachment.fileName}
                          </a>
                        ) : (
                          trbWorkDict[taskResourceBlock.taskResourceBlockRef]
                            .attachment.fileName
                        )}
                      </div>
                    )}
                </div>
                {mainTrbWorkDetail && mainTrbWorkDetail.lastSubmitAt && (
                  <div className="text-sm text-warning font-weight-bolder">
                    Last submitted on{" "}
                    {moment(mainTrbWorkDetail.lastSubmitAt).format(
                      DATETIME_FORMAT
                    )}
                  </div>
                )}
              </div>
            </div>
            <RubricAccordion
              taskResourceBlock={taskResourceBlock}
              responseData={trbWorkDict}
              mode={
                editable
                  ? GENERIC_EDITOR_MODES.EDIT
                  : GENERIC_EDITOR_MODES.READ_ONLY
              }
              selectedScore={selectedScore}
              handleScoreChange={(taskResourceBlockRef, score) => {
                const newSelectedScore = {
                  ...selectedScore,
                  [taskResourceBlockRef]: {
                    ...selectedScore[taskResourceBlockRef],
                    taskResourceBlockRef: taskResourceBlockRef,
                    score: score,
                  },
                };
                handleChange(
                  { selectedScore: newSelectedScore },
                  user.id,
                  taskResourceBlock
                );
                setSelectedScore(newSelectedScore);
                handleFormChanged();
              }}
              defaultExpand={true}
            />
            <ExemplarAnswerAccordion content={taskResourceBlock.sampleAnswer} />
            <TeacherAssessmentFeedbackAccordion
              feedback={mainTrbWorkDetail && mainTrbWorkDetail.feedback}
              editable={editable}
              onChange={(value) => {
                handleChange({ feedback: value }, user.id, taskResourceBlock);
                handleFormChanged();
              }}
            />
            {editable && (
              <>
                <div className="d-flex justify-content-center">
                  <Card className="mb-0 mt-4">
                    <Card.Body className="py-2 px-3">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input p-3 cursor-pointer"
                          id={
                            user.id +
                            "_" +
                            taskResourceBlock.taskResourceBlockRef +
                            "_re"
                          }
                          onChange={(event) => {
                            handleChange(
                              { requestRevision: event.target.checked },
                              user.id,
                              taskResourceBlock
                            );
                            handleFormChanged();
                          }}
                        />
                        <label
                          className="custom-control-label text-dark cursor-pointer mr-2"
                          htmlFor={
                            user.id +
                            "_" +
                            taskResourceBlock.taskResourceBlockRef +
                            "_re"
                          }
                        >
                          Ask student to revise and resubmit response
                        </label>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              This will allow students to redo and resubmit
                              their response. You can reassess their work after
                              they resubmit it
                            </Tooltip>
                          }
                        >
                          <QuestionCircle className="text-dark font-weight-bolder" />
                        </OverlayTrigger>
                      </div>
                    </Card.Body>
                  </Card>
                </div>

                <Button
                  variant="warning text-white font-weight-bolder mt-4"
                  className="w-100"
                  type="submit"
                  onClick={() => {
                    submitButtonRef.current.click();
                  }}
                >
                  Submit all and close
                </Button>
              </>
            )}
          </QuestionBlockContainer>
        </div>
      )
    );
  };

  const submitButtonRef = useRef();

  const ListItem = ({ item, index }) => {
    return (
      <>
        <hr
          key={"assessment-block-hr-" + index}
          className="border-secondary pt-0"
          style={{
            position: "absolute",
            boxSizing: "border-box",
            width: "100%",
            left: 0,
            right: 0,
          }}
        />
        <div className="mb-3 mt-5">
          <AvatarWithText userDetail={item.user} showEmail />
        </div>
        <SingleWorkAssessmentBox
          key={item.user.id + "_" + item.trb.taskResourceBlockRef}
          data={item}
          onChange={handleUpdateDataToSubmit}
          submitButtonRef={submitButtonRef}
        />
      </>
    );
  };

  const memoList = useMemo(
    () => (
      <>
        {displayData.map((item, index) => (
          <ListItem key={"list-item-" + index} item={item} index={index} />
        ))}
      </>
    ),
    [handleUpdateDataToSubmit, displayData]
  );

  const shouldProcessDropdownChange = () => {
    if (!shouldShowHideAlert) return true;
    const shouldHide = window.confirm(
      `If you switch student/question without saving your assessment, it will not be saved.\n
      Are you sure you want to switch?`
    );
    if (shouldHide) {
      setShouldShowHideAlert(false);
      return true;
    }
    return false;
  };

  const QuestionSelectorHeaderItem = ({ questionBlocks }) => {
    const questions = questionBlocks.map((item) => {
      return {
        label: `Question ${item.questionIndex}`,
        value: item.taskResourceBlockRef,
      };
    });
    return (
      <>
        <div className="text-secondary text-sm">Question</div>
        <Select
          defaultValue={selectedQuestion}
          value={selectedQuestion}
          style={{ width: "13rem" }}
          onChange={(value) => {
            if (shouldProcessDropdownChange()) setSelectedQuestion(value);
          }}
          options={questions}
        />
      </>
    );
  };

  const UserSelectorHeaderItem = ({ trbResponses }) => {
    const users = trbResponses.map((item) => {
      return {
        label: item.user.firstName + " " + item.user.lastName,
        value: item.user.id,
      };
    });
    return (
      <>
        <div className="text-secondary text-sm">Student</div>
        <Select
          defaultValue={selectedUser}
          value={selectedUser}
          style={{ width: "13rem" }}
          onChange={(value) => {
            if (shouldProcessDropdownChange()) setSelectedUser(value);
          }}
          options={users}
        />
      </>
    );
  };

  return (
    <>
      <div className="d-flex">
        <div>
          <div className="text-secondary text-sm mb-1">Task</div>
          <div className="h5 my-0 font-weight-bold">{taskDetail.title}</div>
        </div>
        <div className="ml-4">
          {userRef ? (
            <UserSelectorHeaderItem trbResponses={trbResponses} />
          ) : (
            <QuestionSelectorHeaderItem questionBlocks={questionBlocks} />
          )}
        </div>
        <Button
          id="submit-all-btn"
          variant="warning text-white"
          className="my-auto ml-auto"
          onClick={submitAll}
          ref={submitButtonRef}
        >
          Submit all
        </Button>
      </div>
      {memoList}
    </>
  );
};

export default TaskAssessmentCreateEditForm;
