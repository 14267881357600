import React from "react";
import SchoolCreateEditForm from "../../createEditForms/SchoolCreateEditForm";

const SchoolDashboard = ({ schoolRef }) => {
  return (
    <>
      <SchoolCreateEditForm mode="edit" schoolRef={schoolRef} />
    </>
  );
};

export default SchoolDashboard;
