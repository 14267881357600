import React from "react";
import { Button } from "react-bootstrap";
import { Excel } from "antd-table-saveas-excel";
import { BoxArrowInRight } from "react-bootstrap-icons";

const AntdTableDownloadExcelButton = ({
  columns,
  data,
  className,
  variant,
  filename,
  content,
}) => {
  const buttonContent = content ? (
    content
  ) : (
    <>
      <BoxArrowInRight className="mr-2 pb-1" size={24} />
      <u>Export</u>
    </>
  );
  columns.splice(1, 0, {
    title: "Email",
    key: "email",
    render(text, record) {
      return {
        children: <>{record.email || record.user.email}</>,
      };
    },
  });
  const excelColumns = columns.map((column) => {
    return { ...column, __style__: { width: 30, h: "center" } };
  });
  return (
    <Button
      className={className}
      variant={variant}
      onClick={() => {
        const excel = new Excel();
        excel
          .addSheet(filename)
          .setTHeadStyle({
            color: "000000",
            background: "cdd6e6",
            h: "center",
          })
          .addColumns(excelColumns)
          .addDataSource(data)
          .saveAs(`${filename}.xlsx`);
      }}
    >
      {buttonContent}
    </Button>
  );
};

export default AntdTableDownloadExcelButton;
