import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Accordion,
  AccordionContext,
  Button,
  Card,
  ListGroup,
  Modal,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadCourseCurriculum, loadTaskDetail } from "../../actions";
import {
  AWS_REGION_COUNTRY_MAP,
  COUNTRY_INFO,
  CourseTypeDisplayText,
  EXPLORE_PAGE_MODES,
  GA_ACTIONS,
  USER_PLAN_TYPES,
} from "../../utils/constants";
import { findRanges } from "../../utils/GenericUtils";
import ImageHeaderWithTitle from "../util/ImageHeaderWithTitle";
import ModalButton from "../util/ModalButton";
import { sendGAExploreEvent } from "../../utils/thirdParty/GoogleAnalyticsUtils";
import FavouriteActionButton from "./FavouriteActionButton";
import Can from "../../utils/Can";
import { NonSchoolUserCreateClassroomAlert } from "../classroom/NonSchoolUserCreateClassroomAlert";
import { PreviewUserEditCourseAlert } from "../classroom/PreviewUserEditCourseAlert";
import camelCaseRecursive from "../../utils/CamelCaseRecursive";
import PublicRedirectSignupModal from "../util/PublicRedirectSignupModal";
import { requestJsonObject } from "../../api/apis";
import UserCourseDuplicateForm from "../createEditForms/UserCourseDuplicateForm";
import "../../style/antd-image-preview.css";
import StudentSuccessSection from "./StudentSuccessSection";
import { FiletypePpt } from "react-bootstrap-icons";
import { Tooltip } from "antd";
import TeacherPurchaseCourseModal from "../util/TeacherPurchaseCourseModal";

const REGION_STR = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];
const YEAR_LEVEL_PREFIX = COUNTRY_INFO[REGION_STR].yearLevelPrefix;

const OverviewModal = ({
  show,
  setShow,
  isPublic = false,
  calledFrom = EXPLORE_PAGE_MODES.EXPLORE,
}) => {
  const userId = useSelector((state) => state.auth.userDetail?.id);
  const userSchool = useSelector((state) => state.auth.userDetail?.school);
  const course = show && show.course;
  const project = show && show.project;
  const metadata = course && course.countryMetadata[REGION_STR];
  const [courseCurriculumData, setCourseCurriculumData] = useState(null);
  const isPreviewUser = useSelector(
    (state) =>
      state.auth.userDetail?.userInfo.plan === USER_PLAN_TYPES.PREVIEW.value
  );
  const shouldPurchaseCourse = show && show.shouldPurchaseCourse;
  console.log(show);

  useEffect(() => {
    if (show && course.courseRef) {
      sendGAExploreEvent(
        userId,
        GA_ACTIONS.EXPLORE_SEARCH.CLICK_OVERVIEW,
        course.courseRef,
        calledFrom
      );
      if (isPublic) {
        const { CONTENT_BUCKET_CDN_DOMAIN } = window._env_;
        requestJsonObject(
          CONTENT_BUCKET_CDN_DOMAIN +
            "/public/content/course/" +
            course.courseRef +
            ".json",
          (data) => {
            setCourseCurriculumData(camelCaseRecursive(data));
          }
        );
        return;
      }
      loadCourseCurriculum(course.courseRef, (data) => {
        setCourseCurriculumData(data);
      });
    }
  }, [show]);

  const TaskAccordion = ({ task, index, sectionIndex, noBorder }) => {
    const currentEventKey = useContext(AccordionContext);
    const eventKey = `${sectionIndex}.${index}`;
    const isCurrentEventKey = currentEventKey === eventKey;
    const [taskDetail, setTaskDetail] = useState(null);
    const [rubrics, setRubrics] = useState(null);

    const handleLoadTaskDetailSuccess = (data) => {
      setTaskDetail(data);
      let rubrics = data.taskResourceBlockList.map((block) => {
        const { rubric } = block;
        if (!rubric) {
          return null;
        }
        return {
          codes: rubric.curriculumCodes.au.map((s) => s.trim()).join(" | "),
          criteria: rubric.criteria.trim(),
        };
      });
      rubrics = rubrics
        .filter((e) => e !== null)
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t.codes === value.codes && t.criteria === value.criteria
            )
        );
      setRubrics(rubrics);
    };

    useEffect(() => {
      if (currentEventKey === eventKey && !taskDetail) {
        if (isPublic) {
          const { CONTENT_BUCKET_CDN_DOMAIN } = window._env_;
          requestJsonObject(
            CONTENT_BUCKET_CDN_DOMAIN +
              "/public/content/task/" +
              task.taskRef +
              ".json",
            (data) => {
              handleLoadTaskDetailSuccess(camelCaseRecursive(data));
            }
          );
          return;
        }
        loadTaskDetail(task.taskRef, handleLoadTaskDetailSuccess);
      }
    }, [isCurrentEventKey]);

    return (
      <Card className="shadow-none rounded-0 border-top-0 border-left-0 border-right-0 border-bottom-1">
        <Accordion.Toggle
          as={Card.Header}
          eventKey={eventKey}
          className="px-0 border-0"
        >
          <div className="d-flex justify-content-between mr-lg-7 mr-6">
            <div className="text-dark font-weight-bolder text-truncate">
              {index + 1}. {task.title}
            </div>
            <div className="text-warning ml-3 text-nowrap">
              {task.suggestedTime} mins
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body className={"text-dark pt-0 px-3 rounded"}>
            <b className="d-lg-none">{task.title}</b>
            <div>{task.description}</div>
            {rubrics && rubrics.length > 0 && (
              <>
                <div className="text-primary text-sm mb-1 mt-3 font-weight-bolder">
                  CURRICULUM MAPPING
                </div>
                {rubrics.map((rubric, index) => (
                  <div
                    key={`${task.taskRef}-rubrics-${index}`}
                    className={`${index === 0 ? "" : " mt-3"}`}
                  >
                    <span className="font-weight-bolder">{rubric.codes}</span>
                    <br />
                    {rubric.criteria}
                  </div>
                ))}
              </>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  const MetadataButtons = () => {
    const yearLevelStr = `${YEAR_LEVEL_PREFIX} ${findRanges(metadata.levels)}`;
    const courseType = CourseTypeDisplayText[course.courseType];
    return (
      <>
        {[...metadata.subject, yearLevelStr, courseType].map((text, i) => (
          <span key={i} className="mr-2">
            {[
              { size: "sm", className: "d-lg-inline d-none" },
              { size: "xs", className: "d-lg-none" },
            ].map((item, j) => (
              <Button
                key={i + "-" + j}
                className={item.className}
                variant="outline-light rounded-pill text-white"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  cursor: "auto",
                }}
                size={item.size}
              >
                {text}
              </Button>
            ))}
          </span>
        ))}
      </>
    );
  };

  const ActionButtons = ({ courseRef }) => {
    return (
      <div className="text-lg-right mb-3">
        {shouldPurchaseCourse && (
          <ModalButton
            variant="warning text-white rounded-pill mb-2"
            buttonText={
              <>
                Purchase Course
              </>
            }
            animation={false}
            noHeader={true}
            centered={true}
            size="sm"
            handleButtonClick={() =>
              sendGAExploreEvent(
                userId,
                GA_ACTIONS.EXPLORE_SEARCH.CLICK_PURCHASE,
                courseRef,
                calledFrom
              )
            }
          >
            <TeacherPurchaseCourseModal courseRef={courseRef} />
          </ModalButton>
        )}
        {!shouldPurchaseCourse && (
          <>
            {isPublic ? (
              <ModalButton
                variant="primary rounded-pill mb-2"
                buttonText="View Full Course Content"
                animation={!isPublic}
                noHeader={isPublic}
                centered={isPublic}
                size="sm"
                handleButtonClick={() =>
                  sendGAExploreEvent(
                    userId,
                    GA_ACTIONS.EXPLORE_SEARCH.CLICK_PREVIEW,
                    courseRef,
                    calledFrom
                  )
                }
              >
                <PublicRedirectSignupModal
                  actionStr="view this unit's full content"
                  courseRef={courseRef}
                  calledFrom="View Full Course Content"
                />
              </ModalButton>
            ) : (
              <Button
                variant="primary rounded-pill mb-2"
                size="sm"
                onClick={() => {
                  sendGAExploreEvent(
                    userId,
                    GA_ACTIONS.EXPLORE_SEARCH.CLICK_PREVIEW,
                    courseRef,
                    calledFrom
                  );
                  window
                    .open(`/course-preview/${course.courseRef}`, "_blank")
                    .focus();
                }}
              >
                View Full Course Content
              </Button>
            )}
            <ModalButton
              variant="warning text-white rounded-pill mb-2"
              buttonText="Use Course"
              modalTitle="Use Course"
              animation={!isPublic}
              noHeader={isPublic}
              centered={isPublic}
              size="sm"
              handleButtonClick={() =>
                sendGAExploreEvent(
                  userId,
                  GA_ACTIONS.EXPLORE_SEARCH.CLICK_CREATE_CLASSROOM,
                  courseRef,
                  calledFrom
                )
              }
            >
              {isPublic ? (
                <PublicRedirectSignupModal
                  actionStr="Use this Course"
                  courseRef={courseRef}
                  calledFrom="Use Course"
                />
              ) : userSchool ? (
                <UserCourseDuplicateForm
                  mode="create"
                  courseRef={courseRef}
                  fromPage="EXPLORE"
                  onSaveSuccess={(data) => {
                    sendGAExploreEvent(
                      userId,
                      GA_ACTIONS.EXPLORE_SEARCH.CREATE_CLASSROOM_OVERVIEW,
                      courseRef,
                      calledFrom
                    );
                    const courseExecRef = data.courseRef;
                    setTimeout(() => {
                      window.open(
                        `/classroom-detail/${courseExecRef}`,
                        "_blank"
                      );
                    }, 1500);
                  }}
                />
              ) : (
                <NonSchoolUserCreateClassroomAlert />
              )}
            </ModalButton>
            <ModalButton
              variant="warning text-white rounded-pill mb-2"
              buttonText="Edit Course"
              modalTitle="Edit Course"
              animation={!isPublic}
              noHeader={isPublic}
              centered={isPublic}
              size="sm"
              handleButtonClick={() =>
                sendGAExploreEvent(
                  userId,
                  GA_ACTIONS.EXPLORE_SEARCH.CLICK_CREATE_CLASSROOM,
                  courseRef,
                  calledFrom
                )
              }
            >
              {isPublic ? (
                <PublicRedirectSignupModal
                  actionStr="Edit this Course"
                  courseRef={courseRef}
                  calledFrom="Edit Course"
                />
              ) : isPreviewUser ? (
                <PreviewUserEditCourseAlert />
              ) : (
                userSchool && (
                  <UserCourseDuplicateForm
                    mode="create"
                    courseRef={courseRef}
                    fromPage="EXPLORE"
                    onSaveSuccess={(data) => {
                      sendGAExploreEvent(
                        userId,
                        GA_ACTIONS.EXPLORE_SEARCH.CREATE_CLASSROOM_OVERVIEW,
                        courseRef,
                        calledFrom
                      );
                      const courseExecRef = data.courseRef;
                      setTimeout(() => {
                        window.open(
                          `/classroom-detail/${courseExecRef}`,
                          "_blank"
                        );
                      }, 1500);
                    }}
                  />
                )
              )}
            </ModalButton>
          </>
        )}
      </div>
    );
  };

  const taskAccordionList = useMemo(
    () =>
      courseCurriculumData &&
      courseCurriculumData.sectionOrder.map((sectionRef, sectionIndex) => {
        const section = courseCurriculumData.sections[sectionRef];
        return (
          <ListGroup.Item
            key={`section-${sectionIndex}`}
            className="px-0 pt-3 pb-0 border-0"
          >
            <div>
              <div className="text-sm">LEVEL {sectionIndex + 1}</div>
              <h5 className="text-primary">{section.title}</h5>
            </div>
            {section.courseTaskLinkList.map((item, index) => {
              const task =
                courseCurriculumData.tasks[
                  courseCurriculumData.courseTaskLinks[item].taskRef
                ];
              const noBorder = index === section.courseTaskLinkList.length - 1;
              return (
                <Accordion key={`task-accordion-${index}`}>
                  <TaskAccordion
                    task={task}
                    index={index}
                    noBorder={noBorder}
                    sectionIndex={sectionIndex}
                  />
                </Accordion>
              );
            })}
          </ListGroup.Item>
        );
      }),
    [courseCurriculumData]
  );

  const getTotalTime = (courseCurriculumData) => {
    let result = 0;
    courseCurriculumData.sectionOrder.forEach((sectionRef) => {
      const section = courseCurriculumData.sections[sectionRef];
      section.courseTaskLinkList.forEach((item) => {
        result +=
          courseCurriculumData.tasks[
            courseCurriculumData.courseTaskLinks[item].taskRef
          ].suggestedTime;
      });
      return;
    });
    return Math.ceil(result / 60.0);
  };

  return (
    show &&
    courseCurriculumData && (
      <Modal size="xl" show={show} onHide={() => setShow(null)}>
        <Modal.Header
          closeButton={false}
          className="p-0"
          style={{ position: "relative" }}
        >
          <ImageHeaderWithTitle
            title={project.title}
            thumbnail={course.thumbnail}
            customContainerStyle={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderTopLeftRadius: "0.375rem",
              borderTopRightRadius: "0.375rem",
            }}
          />
          {/* TODO: Optimise this by using styles/css */}
          {[
            { size: "sm", className: "d-lg-inline d-none" },
            { size: "xs", className: "d-lg-none" },
          ].map((item, index) => (
            <Button
              key={`back-button-${index}`}
              size={item.size}
              className={item.className}
              variant="outline-light rounded-circle btn-icon-only m-xl-3 m-1 text-white"
              style={{
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              }}
              onClick={() => setShow(null)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          ))}
          <Can
            perform="favourite-course:teacher"
            yes={() => (
              <div
                className="m-xl-3 m-1"
                style={{ position: "absolute", right: 0 }}
              >
                <div className="d-flex">
                  {(course.contentMetadata?.slidesUrl ||
                    course.contentMetadata?.slidesInTasksAvailable) && (
                    <Tooltip
                      title={
                        course.contentMetadata?.slidesInTasksAvailable
                          ? "Slides available in each task"
                          : "Click here to see the slides for this unit"
                      }
                      placement="bottom"
                    >
                      <Button
                        onClick={() => {
                          if (!course.contentMetadata?.slidesInTasksAvailable) {
                            window
                              .open(course.contentMetadata?.slidesUrl, "_blank")
                              .focus();
                          }
                        }}
                        variant="m-0 rounded-pill border border-warning text-light shadow hover-gray-400 hover-text-warning "
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.4)",
                        }}
                        size={"xs"}
                      >
                        <FiletypePpt
                          className="mr-2"
                          style={{ fontSize: "1.1rem" }}
                        />
                        Slides included
                      </Button>
                    </Tooltip>
                  )}
                  <div className="mr-2" />
                  <FavouriteActionButton
                    courseRef={course.courseRef}
                    title={project.title}
                    isResponsive={true}
                  />
                </div>
              </div>
            )}
            no={() => null}
          />
          <div
            className="d-none d-sm-block text-center text-dark w-100 mb-2 mb-lg-3"
            style={{ position: "absolute", bottom: 0 }}
          >
            <MetadataButtons />
          </div>
        </Modal.Header>
        <div className="d-sm-none text-center text-dark w-100 my-2">
          <MetadataButtons />
        </div>
        <hr className="d-sm-none text-dark p-0 m-0" />
        <Modal.Body>
          <ActionButtons courseRef={course.courseRef} />
          {course?.studentSuccessMetadata && (
            <Card style={{ zIndex: 4000 }}>
              <Card.Body>
                <h5 className="text-primary mb-4">Student Success</h5>
                <StudentSuccessSection
                  studentSuccessMetadata={course.studentSuccessMetadata}
                />
              </Card.Body>
            </Card>
          )}
          <Card>
            <Card.Body>
              <h5 className="text-primary mb-4">Unit Overview</h5>
              <div className="text-dark" style={{ whiteSpace: "pre-wrap" }}>
                {project.overview}
              </div>
            </Card.Body>
          </Card>
          <Card className="bg-white shadow-none">
            <Card.Body>
              <h5 className="text-primary mb-3">
                Unit Sequence & Curriculum Alignment
                <br className="d-inline d-lg-none" />
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={
                    <Popover>
                      <Popover.Content className="text-dark">
                        Time per lesson: 60 minutes
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <span className="float-lg-right text-warning">
                    {getTotalTime(courseCurriculumData)} lessons
                  </span>
                </OverlayTrigger>
              </h5>
              <ListGroup>{taskAccordionList}</ListGroup>
            </Card.Body>
          </Card>
          <ActionButtons courseRef={course.courseRef} />
        </Modal.Body>
      </Modal>
    )
  );
};

export default OverviewModal;
