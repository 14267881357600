import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import {
  updateCourseExec,
  createCourseExec,
  loadCourseExecDetail,
} from "../../actions";
import Can from "../../utils/Can";
import { TEXT_FIELD_MAX_LENGTH } from "../../utils/constants";

const ClassroomCreateEditForm = ({
  courseRef,
  schoolRef,
  courseExecRef,
  mode,
  onSaveSuccess,
  handleCloseForm,
}) => {
  const [courseExecDetailData, setCourseExecDetailData] = useState(null);
  const { addToast } = useToasts();
  useEffect(() => {
    if (mode === "edit") {
      loadCourseExecDetail(courseExecRef, (data) => {
        setCourseExecDetailData(data);
      });
    }
  }, [courseExecRef, mode]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      classroomName: event.target.classroomName.value,
      subject: event.target.subject.value,
      courseRef: courseRef,
      schoolRef: schoolRef,
    };
    const params = [
      data,
      (data) => {
        addToast("Classroom created", {
          appearance: "success",
          autoDismiss: true,
        });
        onSaveSuccess(data);
        if (handleCloseForm) handleCloseForm();
      },
      () => {
        addToast("Saving classroom failed. Please try again later.", {
          appearance: "error",
          autoDismiss: false,
        });
        if (handleCloseForm) handleCloseForm();
      },
    ];
    switch (mode) {
      case "edit":
        data.courseExecRef = courseExecRef;
        updateCourseExec(...params);
        break;
      case "create":
        createCourseExec(...params);
        break;
      default:
        console.warn(`No mode called: ${mode}`);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Label>Classroom Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. 7A"
          name="classroomName"
          defaultValue={
            mode === "edit" && courseExecDetailData !== null
              ? courseExecDetailData.classroomName
              : ""
          }
          required
          maxLength={TEXT_FIELD_MAX_LENGTH.CLASSROOM_TITLE}
        />
      </Form.Group>
      <Form.Group controlId="formSubject">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Geology"
          name="subject"
          defaultValue={
            mode === "edit" && courseExecDetailData !== null
              ? courseExecDetailData.subject
              : ""
          }
          required
          maxLength={TEXT_FIELD_MAX_LENGTH.CLASSROOM_SUBJECT_NAME}
        />
      </Form.Group>

      <Can
        perform="admin:dashboard"
        yes={() => (
          <p className="text-danger">
            Sorry, currently an admin cannot create classrooms. We are working
            on it.
          </p>
        )}
        no={() => (
          <Button
            className="mt-5"
            type="submit"
            size="md"
            variant="primary"
            block
          >
            Save
          </Button>
        )}
      />
    </Form>
  );
};

export default ClassroomCreateEditForm;
