import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import { LeftArrow, RightArrow } from "./HorizontalGalleryArrows";

function HorizontalGallery(props) {
  return (
    <ScrollMenu
      scrollContainerClassName="hidden-scroll-bar"
      itemClassName="col-xl-3 col-sm-5 col-11"
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      options={{
        ratio: 0.9,
        rootMargin: "5px",
        threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
      }}
    >
      {props.children}
    </ScrollMenu>
  );
}
export default HorizontalGallery;
