import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { loadCourseList } from "../../actions";
const { Option } = Select;

const CourseListDropDown = ({
  projectRef,
  onCourseChange,
  defaultValue = "Please select a course...",
  coursesData = null,
  showAll = false,
}) => {
  const [courseListData, setCourseListData] = useState(null);
  useEffect(() => {
    setCourseListData(null);
    const getCourseList = (data) => {
      let courseList = [...data];
      if (showAll)
        courseList.unshift({
          projectRef: "all-courses",
          title: "All Courses",
        });
      return courseList;
    };
    if (!coursesData) {
      loadCourseList(projectRef, (data) => {
        setCourseListData(getCourseList(data));
      });
    } else {
      setCourseListData(getCourseList(coursesData));
    }
  }, [projectRef]);

  return (
    courseListData && (
      <Select
        style={{ width: "100%" }}
        size="large"
        showSearch
        optionFilterProp="children"
        onChange={onCourseChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={defaultValue}
        className="border-secondary"
      >
        {courseListData.map((course) => {
          console.log(course);
          return (
            <Option key={course.courseRef} value={course.courseRef}>
              <b>{course.isAssignable ? "" : "[Archived] "}</b>
              {course.title} {course.description}
            </Option>
          );
        })}
      </Select>
    )
  );
};

export default CourseListDropDown;
