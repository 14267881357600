import React from "react";
import { ToastProvider } from "react-toast-notifications";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { hotjar } from "react-hotjar";
import { createBrowserHistory } from "history";
import SignIn from "../components/authentication/SignIn";
import PasswordResetCreate from "../components/authentication/PasswordResetCreate";
import PasswordReset from "../components/authentication/PasswordReset";
import PasswordResetDone from "../components/authentication/PasswordResetDone";
import InvitationPage from "../features/invitation/InvitationPage";
import AdminPage from "../features/admin/AdminPage";
import StudentPage from "../features/student/StudentPage";
import TeacherPage from "../features/teacher/TeacherPage";
import CoursePreviewPage from "../features/admin/CoursePreviewPage";
import ProfilePage from "../features/common/setting/ProfilePage";
import CourseExecDetailPage from "./common/CourseExecDetailPage";
import { Router, Route, Switch } from "react-router-dom";
import CourseExecManagementPage from "./common/CourseExecManagementPage";
import ToastContainer from "../utils/ToastContainer";
import Page404 from "../components/util/Page404";
import "../style/App.css";
import "../style/purpose.css";
import ClassroomAccessDeniedPage from "../components/util/ClassroomAccessDeniedPage";
import ExplorePage from "./explore/ExplorePage";
import PagePlanExpired from "../components/util/PagePlanExpired";
import SignUp from "../components/authentication/SignUp/SignUp";
import PublicExplorePage from "./explore/PublicExplorePage";
import { isAUPlatform } from "../utils/GenericUtils";
import CourseEditorPage from "./common/CourseEditorPage";
import MicrosoftLogin from "../utils/auth/microsoft/MicrosoftLogin";

ReactGA4.initialize("G-33GCKT69L6", {
  // gaOptions: {
  //   debug_mode: true,
  // },
  // gtagOptions: {
  //   debug_mode: true,
  // },
});
ReactGA.initialize("UA-153543110-2");
ReactGA.pageview(window.location.pathname);
const history = createBrowserHistory();

// Initialize google analytics page view tracking
// Add events:
// https://javascript.plainenglish.io/
// how-to-setup-and-add-google-analytics-to-your-react-app-fd361f47ac7b
// https://levelup.gitconnected.com/using-google-analytics-with-react-3d98d709399b
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

if (isAUPlatform()) {
  hotjar.initialize({ id: 3531298, sv: 6 });
}

class App extends React.Component {
  render() {
    return (
      <ToastProvider components={{ ToastContainer }}>
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={SignIn} />
            <Route path="/signup" exact component={SignUp} />
            <Route path="/password/reset" exact component={PasswordReset} />
            <Route
              path="/password/reset-link/:token"
              exact
              component={PasswordResetCreate}
            />
            <Route
              path="/password/reset-sent"
              exact
              component={PasswordResetDone}
            />
            <Route
              path="/accounts/microsoft/login/"
              exact
              component={MicrosoftLogin}
            />
            <Route
              path="/accounts/microsoft/login-single-tenant/"
              exact
              component={MicrosoftLogin}
            />
            <Route path="/explore/guest" exact component={PublicExplorePage} />
            <Route path="/invitation/:token" exact component={InvitationPage} />
            <Route
              path="/course-preview/:courseRef"
              exact
              component={CoursePreviewPage}
            />
            <Route
              path="/classroom/:courseExecRef"
              exact
              component={CourseExecDetailPage}
            />
            <Route
              path="/classroom-detail/:courseExecRef"
              exact
              component={CourseExecManagementPage}
            />
            <Route
              path="/course-edit/:courseRef"
              exact
              component={CourseEditorPage}
            />

            <Route path="/explore" exact component={ExplorePage} />
            <Route path="/profile" exact component={ProfilePage} />
            <Route path="/admin" component={AdminPage} />
            <Route path="/student" component={StudentPage} />
            <Route path="/teacher" component={TeacherPage} />
            <Route path="/404" component={Page404} />
            <Route path="/subscription-expired" component={PagePlanExpired} />
            <Route
              path="/access-denied"
              component={ClassroomAccessDeniedPage}
            />
            <Route path="*" exact={true} component={Page404} />
          </Switch>
        </Router>
      </ToastProvider>
    );
  }
}
export default App;
