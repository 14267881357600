import React from "react";
import { Button } from "react-bootstrap";
import { Envelope } from "react-bootstrap-icons";
import {
  handleGenericSaveSuccess,
  handleGenericSaveFailed,
} from "../../utils/CreateEditFormUtils";
import { sendInvitationEmail } from "../../utils/EmailUtils";

const SendInvitationEmailButton = ({
  userRef,
  courseExecRef = "",
  showResend = false,
  onSendSuccess = () => {},
  addToast,
}) => {
  return (
    <Button
      variant="link"
      className="text-underline text-primary p-0"
      onClick={() => {
        sendInvitationEmail(
          userRef,
          courseExecRef,
          () =>
            handleGenericSaveSuccess(
              addToast,
              {
                object: "email",
                operation: "sent",
              },
              onSendSuccess
            ),
          () => handleGenericSaveFailed(addToast, null)
        );
      }}
    >
      <Envelope className="mr-2 pb-1" size={24} />
      {showResend ? "Re-send Invitation Email" : "Send Invitation Email"}
    </Button>
  );
};

export default SendInvitationEmailButton;
