export const USER_SIGNOUT = "USER_SIGNOUT";
export const USER_DETAIL = "USER_DETAIL";

export const SESSION_EXPIRED = "SESSION_EXPIRED";

export const PROJECT_LIST = "PROJECT_LIST";
export const PROJECT_DETAIL = "PROJECT_DETAIL";
export const PROJECT_CREATE = "PROJECT_CREATE";
export const PROJECT_UPDATE = "PROJECT_UPDATE";
export const PROJECT_DELETE = "PROJECT_DELETE";

export const COURSE_LIST = "COURSE_LIST";
export const COURSE_DETAIL = "COURSE_DETAIL";
export const COURSE_CREATE = "COURSE_CREATE";
export const COURSE_UPDATE = "COURSE_UPDATE";
export const COURSE_DELETE = "COURSE_DELETE";
export const COURSE_CURRICULUM = "COURSE_CURRICULUM";
export const DISPLAY_TASK_LINK = "DISPLAY_TASK_LINK";

export const FAVOURITE_COURSES = "FAVOURITE_COURSES";

export const SECTION_LIST = "SECTION_LIST";
export const SECTION_DETAIL = "SECTION_DETAIL";
export const SECTION_CREATE = "SECTION_CREATE";
export const SECTION_UPDATE = "SECTION_UPDATE";
export const SECTION_DELETE = "SECTION_DELETE";

export const TASK_LIST = "TASK_LIST";
export const TASK_DETAIL = "TASK_DETAIL";
export const TASK_CREATE = "TASK_CREATE";
export const TASK_CREATE_SUCCESSFUL = "TASK_CREATE_SUCCESSFUL";
export const TASK_UPDATE = "TASK_UPDATE";
export const TASK_DELETE = "TASK_DELETE";

export const SCHOOL_LIST = "SCHOOL_LIST";
export const SCHOOL_DETAIL = "SCHOOL_DETIAL";
export const SCHOOL_CREATE = "SCHOOL_CREATE";
export const SCHOOL_UPDATE = "SCHOOL_UPDATE";
export const SCHOOL_DELETE = "SCHOOL_DELETE";
