import React from "react";
import { ILLUSTRATIONS, WEBSITE_LINKS } from "../../utils/constants";

const Footer = () => {
  const menuItems = [
    { title: "Learn", url: WEBSITE_LINKS.LEARN },
    { title: "Help Centre", url: WEBSITE_LINKS.HELP },
    { title: "Terms of Use", url: WEBSITE_LINKS.TERMS },
    {
      title: "Privacy Policy",
      url: WEBSITE_LINKS.PRIVACY,
    },
    { title: "Contact Us", url: WEBSITE_LINKS.CONTACT_US },
  ];
  const copyrightStr = "© 2024 Cura Education Inc. All rights reserved.";
  return (
    <div className="bg-primary text-center py-5 text-white">
      <div className="mb-5">
        <img
          style={{
            width: "15rem",
            filter: "brightness(1000%) saturate(1000%)",
          }}
          src={ILLUSTRATIONS.LOGO.MAIN_LOGO}
          alt="Cura"
          className="cursor-pointer"
          onClick={() => {
            window.location.href = WEBSITE_LINKS.ROOT;
          }}
        />
      </div>
      <div className="mt-4 pt-5 border-top border-white">
        <span className="d-none d-lg-inline mr-5">{copyrightStr}</span>
        {menuItems.map((item) => (
          <span className="mx-4 d-block d-sm-inline">
            <a
              href={item.url}
              alt={item.title}
              target="_blank"
              className="text-white"
              rel="noreferrer"
            >
              {item.title}
            </a>
          </span>
        ))}
      </div>
      <span className="d-block d-lg-none mt-4">{copyrightStr}</span>
    </div>
  );
};

export default Footer;
