import React, { useState, useEffect, useRef, useMemo } from "react";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Card, Button, Form } from "react-bootstrap";
import ReactGA4 from "react-ga4";
import { useToasts } from "react-toast-notifications";
import {
  listTaskResourceBlockWork,
  updateTaskResourceBlockWork,
} from "../../../actions";
import {
  DATETIME_FORMAT,
  GA_ACTIONS,
  GENERIC_EDITOR_MODES,
  MAX_STUDENT_WORK_FILE_SIZE,
  SYSTEM_MESSAGES,
  TASK_RESOURCE_BLOCK_TYPE,
} from "../../../utils/constants";
import {
  handleGenericSaveFailed,
  handleGenericSaveSuccess,
} from "../../../utils/CreateEditFormUtils";
import { isFileNameTooLong } from "../../../utils/GenericUtils";
import { uploadFileWithHandler } from "../../../utils/FileUpload";
import Can from "../../../utils/Can";
import { Trash } from "react-bootstrap-icons";
import { ContentSection } from "../ContentSection";
import ExemplarAnswerAccordion from "./ExemplarAnswerAccordion";
import RubricAccordion from "./RubricAccordion";
import { getAllDisplayTaskResourceBlocks } from "../../../utils/DataLogicUtils";
import TableWorkEditor from "./TableWorkEditor";
import TeacherAssessmentFeedbackAccordion from "./TeacherAssessmentFeedbackAccordion";
import QuestionBlockContainer from "./QuestionBlockContainer";

const TaskResourceBlockDisplayer = ({
  editable,
  courseExecRef,
  courseTaskLinkRef,
  taskDetailData,
  shouldLoadExistingWork,
  updatedUnsavedTRBRefs,
}) => {
  const [blockResponseData, setBlockResponseData] = useState(null);
  const allDisplayTRBs = getAllDisplayTaskResourceBlocks(
    taskDetailData.taskResourceBlockList
  );

  useEffect(() => {
    if (courseExecRef) {
      const data = {
        courseExecRef: courseExecRef,
        courseTaskLinkRef: courseTaskLinkRef,
      };
      // Only students need to display work and call this API. Others don't.
      if (shouldLoadExistingWork)
        listTaskResourceBlockWork(
          data,
          (data) => setBlockResponseData(data),
          () => {}
        );
    }
  }, []);

  const appendToEditingBlocks = (taskResourceBlockRef) => {
    if (!updatedUnsavedTRBRefs.current.includes(taskResourceBlockRef)) {
      updatedUnsavedTRBRefs.current = [
        ...updatedUnsavedTRBRefs.current,
        taskResourceBlockRef,
      ];
      window.onbeforeunload = () => true;
    }
  };

  const removeFromEditingBlocks = (taskResourceBlockRef) => {
    updatedUnsavedTRBRefs.current = updatedUnsavedTRBRefs.current.filter(
      (item) => item !== taskResourceBlockRef
    );
    if (updatedUnsavedTRBRefs.current.length === 0)
      window.onbeforeunload = null;
  };

  const SingleResourceBlock = ({
    index,
    block,
    responseData,
    courseExecRef,
    courseTaskLinkRef,
  }) => {
    const SingleResponseRequiredBlock = ({ index, block, responseData }) => {
      const isFirstRun = useRef(true);
      const filenputRef = useRef();
      const { addToast } = useToasts();
      const [selectedFile, setSelectedFile] = useState(null);
      const [updatedResponse, setUpdatedResponse] = useState(
        responseData && responseData[block.taskResourceBlockRef]
      );
      const [isSubmitting, setIsSubmitting] = useState(false);
      const [isChangesMade, setIsChangesMade] = useState(false);

      useEffect(() => {
        if (isFirstRun.current) {
          isFirstRun.current = false;
          return;
        }
        setIsChangesMade(true);
      }, [updatedResponse]);

      const handleBodyChange = (data) => {
        setUpdatedResponse({ ...updatedResponse, body: data });
        appendToEditingBlocks(block.taskResourceBlockRef);
      };

      const handleDataChange = (data) => {
        setUpdatedResponse({
          ...updatedResponse,
          data: data,
        });
        appendToEditingBlocks(block.taskResourceBlockRef);
      };

      const handleFileChange = (event) => {
        const workFile = event.target.files[0];
        if (!workFile) return;
        if (workFile.size / (1024 * 1024) > MAX_STUDENT_WORK_FILE_SIZE) {
          addToast(
            `Please select a file smaller than ${MAX_STUDENT_WORK_FILE_SIZE}MB.`,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          return;
        }
        if (isFileNameTooLong(workFile.name)) {
          alert(SYSTEM_MESSAGES.ERROR.EXEEDED_MAX_FILENAME_SIZE);
          return;
        }
        setSelectedFile(event.target.files[0]);
        setUpdatedResponse({
          ...updatedResponse,
          attachment: {
            attachmentRef: null,
            fileName: workFile.name,
            url: null,
          },
        });
        appendToEditingBlocks(block.taskResourceBlockRef);
      };

      const handleSubmit = async () => {
        if (
          !window.confirm(
            "\nOnce you submit your work, you cannot make further changes to it." +
              "\nAre you sure you want to submit this answer?"
          )
        )
          return;
        setIsSubmitting(true);
        let url = null;
        let attachmentData = null;
        if (selectedFile) {
          url = await uploadFileWithHandler(
            selectedFile,
            () => {},
            () => {
              handleGenericSaveFailed(
                addToast,
                "Upload Failed. Please try again later.",
                () => setIsSubmitting(false)
              );
            },
            "task-resource-block-response"
          );
          if (!url) return;
          attachmentData = {
            attachmentRef: uuid(),
            fileName: selectedFile.name,
            url: url,
          };
        } else {
          attachmentData =
            updatedResponse && updatedResponse.attachment
              ? updatedResponse.attachment
              : null;
        }
        const data = {
          courseExecRef: courseExecRef,
          courseTaskLinkRef: courseTaskLinkRef,
          taskResourceBlockRef: block.taskResourceBlockRef,
          body:
            updatedResponse && updatedResponse.body
              ? updatedResponse.body
              : null,
          data:
            updatedResponse && updatedResponse.data
              ? updatedResponse.data
              : null,
          attachment: attachmentData,
        };
        updateTaskResourceBlockWork(
          data,
          (data) => {
            handleGenericSaveSuccess(
              addToast,
              {},
              () => {
                setUpdatedResponse(data);
                setIsSubmitting(false);
                removeFromEditingBlocks(block.taskResourceBlockRef);
              },
              () => {},
              "Your answer has been submitted"
            );
            ReactGA4.event("assessment", {
              course_exec_ref: courseExecRef,
              course_task_link_ref: courseTaskLinkRef,
              task_resource_block_ref: block.taskResourceBlockRef,
              explore_action: GA_ACTIONS.ASSESSMENT.STUDENT_SUBMIT,
            });
          },
          () =>
            handleGenericSaveFailed(addToast, null, () =>
              setIsSubmitting(false)
            )
        );
      };

      const isSubmitted = () =>
        updatedResponse &&
        updatedResponse.status &&
        updatedResponse.status === "SUBMIT";

      return (
        <div className="mb-5" key={block.taskResourceBlockRef}>
          <QuestionBlockContainer questionNumber={block.questionIndex}>
            <div className="d-block">
              <ContentSection content={block.body} />
              <div className="mt-4" />
              {block.resourceType === TASK_RESOURCE_BLOCK_TYPE.QUESTION &&
                (isSubmitted() || !editable ? (
                  <Card className="mb-0">
                    <Card.Body
                      className="text-dark"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {/* Need a wrap line if there is no content for spacing */}
                      {updatedResponse ? updatedResponse.body : "\n"}
                    </Card.Body>
                  </Card>
                ) : (
                  <Form.Control
                    className="text-dark"
                    as="textarea"
                    placeholder="Type your answers here"
                    value={updatedResponse ? updatedResponse.body : ""}
                    onChange={(event) => handleBodyChange(event.target.value)}
                    disabled={isSubmitted() || !editable}
                  />
                ))}
              {block.resourceType === TASK_RESOURCE_BLOCK_TYPE.TABLE &&
                block.data && (
                  <div className="overflow-auto">
                    <TableWorkEditor
                      block={block}
                      defaultData={
                        updatedResponse && updatedResponse.data
                          ? updatedResponse.data.table
                          : null
                      }
                      handleChange={(tableData) =>
                        handleDataChange({ table: tableData })
                      }
                      editable={!isSubmitted() && editable}
                    />
                  </div>
                )}
            </div>
            {(editable ||
              (updatedResponse &&
                updatedResponse.attachment &&
                updatedResponse.attachment.fileName)) && (
              <div className="mt-4"></div>
            )}
            <div className="d-block">
              <div className="d-sm-flex justify-content-between">
                <div className="d-flex mb-0">
                  {editable && !isSubmitted() && (
                    <label className="btn btn-outline-primary btn-sm mb-0 mr-3">
                      Choose a file...
                      <input
                        ref={filenputRef}
                        type="file"
                        hidden
                        disabled={!editable}
                        onChange={handleFileChange}
                      />
                    </label>
                  )}
                  {updatedResponse &&
                    updatedResponse.attachment &&
                    updatedResponse.attachment.fileName && (
                      <div className="d-flex align-items-center h-100">
                        {updatedResponse.attachment.url ? (
                          <a
                            href={updatedResponse.attachment.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon icon={faPaperclip} />{" "}
                            {updatedResponse.attachment.fileName}
                          </a>
                        ) : (
                          updatedResponse.attachment.fileName
                        )}
                        {!isSubmitted() && (
                          <div
                            className="text-danger ml-3 text-lg cursor-pointer"
                            onClick={() => {
                              setSelectedFile(null);
                              setUpdatedResponse({
                                ...updatedResponse,
                                attachment: null,
                              });
                              filenputRef.current.value = null;
                            }}
                          >
                            <Trash size={24} />
                          </div>
                        )}
                      </div>
                    )}
                </div>
                {editable &&
                  (isSubmitted() ? (
                    updatedResponse.lastSubmitAt && (
                      <div className="text-sm text-warning font-weight-bolder">
                        Submitted on{" "}
                        {moment(updatedResponse.lastSubmitAt).format(
                          DATETIME_FORMAT
                        )}
                      </div>
                    )
                  ) : (
                    <Button
                      size="sm"
                      variant="warning text-white"
                      onClick={handleSubmit}
                      disabled={isSubmitting || !isChangesMade}
                    >
                      {!isSubmitting ? "Submit" : "Submitting..."}
                    </Button>
                  ))}
              </div>
            </div>
            <RubricAccordion
              taskResourceBlock={block}
              responseData={responseData}
              mode={
                shouldLoadExistingWork
                  ? GENERIC_EDITOR_MODES.READ_ONLY
                  : GENERIC_EDITOR_MODES.DISPLAY
              }
            />
            {/* Only student sees feedback, only teacher/admin sees exemplar answers */}
            <Can
              perform="visible:student"
              yes={() =>
                (responseData && responseData[block.taskResourceBlockRef] && (
                  <TeacherAssessmentFeedbackAccordion
                    feedback={responseData[block.taskResourceBlockRef].feedback}
                    editable={false}
                  />
                )) ??
                null
              }
              no={() => (
                <ExemplarAnswerAccordion content={block.sampleAnswer} />
              )}
            />
          </QuestionBlockContainer>
        </div>
      );
    };
    switch (block.resourceType) {
      case TASK_RESOURCE_BLOCK_TYPE.QUESTION:
      case TASK_RESOURCE_BLOCK_TYPE.TABLE:
        return (
          <SingleResponseRequiredBlock
            index={index}
            block={block}
            responseData={responseData}
          />
        );
      case TASK_RESOURCE_BLOCK_TYPE.CONTENT:
        return (
          <div className="mb-5">
            <ContentSection content={block.body} />
          </div>
        );
      default:
        return null;
    }
  };

  const getResponsesForBlock = (block, blockResponseData) => {
    if (!blockResponseData) return {};
    let result = {};
    const rubricBlockRefList = block.rubricBlocks
      ? block.rubricBlocks.map((a) => a.taskResourceBlockRef)
      : [];
    const blockRefList = [block.taskResourceBlockRef, ...rubricBlockRefList];
    for (const blockResponse of blockResponseData) {
      if (blockRefList.includes(blockResponse.taskResourceBlockRef))
        result[blockResponse.taskResourceBlockRef] = blockResponse;
    }
    return result;
  };

  const memoList = useMemo(
    () =>
      allDisplayTRBs.map((block, index) => (
        <SingleResourceBlock
          key={block.taskResourceBlockRef}
          index={index}
          block={block}
          responseData={getResponsesForBlock(block, blockResponseData)}
          courseExecRef={courseExecRef}
          courseTaskLinkRef={courseTaskLinkRef}
        />
      )),
    [blockResponseData]
  );
  return <>{memoList}</>;
};

export default TaskResourceBlockDisplayer;
