import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
import { uploadFileWithHandler } from "../../utils/FileUpload";
import { loadCourseDetail, createCourse, updateCourse } from "../../actions";
import {
  CourseType,
  CourseTypeDisplayText,
  TEXT_FIELD_MAX_LENGTH,
} from "../../utils/constants";
import {
  handleGenericSaveFailed,
  handleGenericSaveSuccess,
} from "../../utils/CreateEditFormUtils";
import CourseCountryMetadataEditor from "../course/CourseCountryMetadataEditor";
import { getGenericReactSelectOption } from "../../utils/GenericUtils";
import StudentSuccessMetadataEditor from "../course/StudentSuccessMetadataEditor";
import CourseContentMetadataEditor from "../course/CourseContentMetadataEditor";

const CourseCreateEditForm = ({
  courseRef,
  projectRef,
  mode,
  onSaveSuccess,
  handleCloseForm,
}) => {
  const { addToast } = useToasts();
  const [courseDetailData, setCourseDetailData] = useState(null);
  const [contentMetadata, setContentMetadata] = useState(null);
  const [countryMetadata, setCountryMetadata] = useState(null);
  const [studentSuccessMetadata, setStudentSuccessMetadata] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [thumbnailPreviewSrc, setThumbnailPreviewSrc] = useState(null);

  useEffect(() => {
    if (mode === "edit") {
      loadCourseDetail(courseRef, (data) => {
        setCourseDetailData(data);
        setThumbnailPreviewSrc(data.thumbnail);
        setContentMetadata(data.contentMetadata);
        setCountryMetadata(data.countryMetadata);
        setStudentSuccessMetadata(data.studentSuccessMetadata);
      });
    }
  }, [courseRef, mode]);

  const handleSaveSuccess = () => {
    handleGenericSaveSuccess(
      addToast,
      { object: "course", operation: "saved" },
      onSaveSuccess,
      handleCloseForm
    );
  };

  const handleSaveFailed = () => {
    handleGenericSaveFailed(addToast, null, handleCloseForm);
  };

  const checkStudentSuccessMetadata = (data) => {
    if (data && data.length > 0) {
      // Check if student success metadata has both empty file url and description
      return !data.some(
        (item) =>
          item.url === null ||
          item.url === "" ||
          item.description === null ||
          item.description === ""
      );
    }
    return true;
  };

  const checkContentMetadata = (data) => {
    return data?.keyword?.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!checkStudentSuccessMetadata(studentSuccessMetadata)) {
      alert("Please fill in the student success metadata description or image");
      return;
    }
    if (!checkContentMetadata(contentMetadata)) {
      alert(
        "You haven't added any keywords for this course. Please add some before saving"
      );
      return;
    }
    const form = event.target;
    const data = {
      title: form.elements.title.value,
      description: form.elements.description.value,
      courseType: form.elements.courseType.value,
      contentMetadata: {
        ...contentMetadata,
        slidesUrl: form.elements.slidesUrl.value,
        slidesInTasksAvailable: form.elements.slidesInTasksAvailable.checked,
      },
      countryMetadata: countryMetadata,
      studentSuccessMetadata: studentSuccessMetadata,
    };
    switch (mode) {
      case "edit":
        data.courseRef = courseRef;
        if (!thumbnailFile) {
          data.thumbnail = courseDetailData.thumbnail;
        } else {
          const url = await uploadFileWithHandler(
            thumbnailFile,
            (fileURI) => setThumbnailPreviewSrc(fileURI),
            () => handleSaveFailed(),
            "course-thumbnail",
            false
          );
          if (!url) return;
          data.thumbnail = url;
        }
        updateCourse(
          data,
          () => handleSaveSuccess(),
          () => handleSaveFailed()
        );
        break;
      case "create":
        data.projectRef = projectRef;
        const url = await uploadFileWithHandler(
          thumbnailFile,
          (fileURI) => setThumbnailPreviewSrc(fileURI),
          () => handleSaveFailed(),
          "course-thumbnail",
          false
        );
        if (!url) return;
        data.thumbnail = url;
        createCourse(
          data,
          () => handleSaveSuccess(),
          () => handleSaveFailed()
        );
        break;
      default:
        console.warn(`No mode called: ${mode}`);
    }
  };

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    setThumbnailFile(file);
    setThumbnailPreviewSrc(URL.createObjectURL(file));
  };

  return (
    !(mode === "edit" && courseDetailData === null) && (
      <Form className="text-dark" onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="formName">
              <Form.Label>Course Name:</Form.Label>
              <Form.Control
                className="text-dark"
                type="text"
                name="title"
                defaultValue={mode === "create" ? null : courseDetailData.title}
                required
                maxLength={TEXT_FIELD_MAX_LENGTH.COURSE_TITLE}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Version Name:</Form.Label>
              <Form.Control
                className="text-dark"
                type="text"
                name="description"
                defaultValue={
                  mode === "create" ? null : courseDetailData.description
                }
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Course Type</Form.Label>
              <Select
                defaultValue={
                  mode === "create"
                    ? null
                    : getGenericReactSelectOption(courseDetailData.courseType)
                }
                name="courseType"
                options={Object.values(CourseType).map((item) =>
                  getGenericReactSelectOption(item, CourseTypeDisplayText[item])
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.File
                id="thumbnailImage"
                label="Thumbnail"
                onChange={handleThumbnailChange}
                required={mode === "create"}
              />
              {thumbnailPreviewSrc ? (
                <Image width={300} src={thumbnailPreviewSrc} rounded />
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <CourseCountryMetadataEditor
          countryMetadata={
            mode === "create" ? null : courseDetailData.countryMetadata
          }
          handleMetadataUpdate={setCountryMetadata}
        />
        <StudentSuccessMetadataEditor
          studentSuccessMetadata={
            mode === "create" ? null : courseDetailData?.studentSuccessMetadata
          }
          handleMetadataUpdate={setStudentSuccessMetadata}
        />
        <CourseContentMetadataEditor
          contentMetadata={
            mode === "create" ? null : courseDetailData.contentMetadata
          }
          handleMetadataUpdate={setContentMetadata}
        />
        <Form.Group
          controlId="formSlidesUrl"
          className="mt-3 text-secondary text-xs"
        >
          <Form.Label>Slides URL: (Deprecated)</Form.Label>
          <Form.Control
            className="text-secondary"
            type="text"
            size="sm"
            name="slidesUrl"
            defaultValue={
              mode === "create"
                ? null
                : courseDetailData.contentMetadata?.slidesUrl
            }
            maxLength={TEXT_FIELD_MAX_LENGTH.URL}
          />
        </Form.Group>
        <Form.Group controlId="formSlidesInTasksCheckbox" className="mt-3">
          <Form.Check
            type="switch"
            name="slidesInTasksAvailable"
            label="Slides in Tasks Included"
            defaultChecked={
              mode === "create"
                ? null
                : courseDetailData.contentMetadata?.slidesInTasksAvailable
            }
          />
        </Form.Group>
        <br /> <br />
        <Button size="md" variant="primary" type="submit" block>
          Save
        </Button>
      </Form>
    )
  );
};

export default CourseCreateEditForm;
