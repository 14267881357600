import React, { useState, useEffect } from "react";
import { CourseExecStatus } from "../../utils/constants";
import { Button, Row, ToggleButtonGroup } from "react-bootstrap";

import { loadUserCourseList } from "../../actions";
import { useSelector } from "react-redux";
import { TeacherCourseCard } from "../course/CourseCard";

const MyTeacherEditableCourses = () => {
  const [shouldReloadData, setShouldReloadData] = useState(true);
  const [userCourseList, setUserCourseList] = useState(null);
  const [mode, setMode] = useState(CourseExecStatus.ACTIVE);
  const userDetailData = useSelector((state) => state.auth.userDetail);
  useEffect(() => {
    if (shouldReloadData) {
      const data = { userRef: userDetailData.id };
      loadUserCourseList(data, (data) => {
        setUserCourseList(data);
        setShouldReloadData(false);
      });
    }
  }, [shouldReloadData]);

  const getCards = () => {
    return (
      userCourseList &&
      userCourseList.map((courseData, index) => {
        if (courseData.status === mode)
          return (
            <TeacherCourseCard
              key={`card-${index}`}
              courseData={courseData}
              setShouldReloadData={setShouldReloadData}
            />
          );
        return null;
      })
    );
  };
  return (
    <>
      <div className={"pt-6 mb-3 d-flex justify-content-center"}>
        <h1 className="text-white">
          <strong>My Courses</strong>
        </h1>
      </div>
      <div className="mb-5 d-flex justify-content-center">
        <ToggleButtonGroup type="checkbox" className="mb-2">
          <Button
            className="rounded-pill border"
            variant={
              mode === CourseExecStatus.ACTIVE
                ? "warning border-warning text-white"
                : "primary"
            }
            value={CourseExecStatus.ACTIVE}
            onClick={(e) => setMode(e.currentTarget.value)}
          >
            Current
          </Button>
          <Button
            className="ml-2 rounded-pill border"
            variant={
              mode === CourseExecStatus.ARCHIVED
                ? "warning border-warning text-white"
                : "primary"
            }
            value={CourseExecStatus.ARCHIVED}
            onClick={(e) => setMode(e.currentTarget.value)}
          >
            Archived
          </Button>
        </ToggleButtonGroup>
      </div>
      {userCourseList && mode && (
        <Row
          className={`${
            userCourseList.filter(function(el) {
              return el.status === mode;
            }).length < 4
              ? "justify-content-center"
              : ""
          }`}
        >
          {getCards()}
        </Row>
      )}
    </>
  );
};

export default MyTeacherEditableCourses;
