import React from "react";
import SchoolCalendar from "../../../features/insight/school/SchoolCalendar";
import SchoolSummary from "../../../features/insight/school/SchoolSummary";
import SchoolTeacherActivityTable from "../../../features/insight/school/SchoolTeacherActivityTable";

const SchoolDashboard = ({ schoolRef }) => {
  return (
    <>
      <SchoolSummary schoolRef={schoolRef} />
      <div className="my-3" />
      <SchoolTeacherActivityTable schoolRef={schoolRef} />
      <SchoolCalendar schoolRef={schoolRef} />
    </>
  );
};

export default SchoolDashboard;
