import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { loadProjectDetail, createProject, updateProject } from "../../actions";
import { TEXT_FIELD_MAX_LENGTH } from "../../utils/constants";
import {
  handleGenericSaveFailed,
  handleGenericSaveSuccess,
} from "../../utils/CreateEditFormUtils";

const ProjectCreateEditForm = ({
  projectRef,
  mode,
  onSaveSuccess,
  handleCloseForm,
}) => {
  const { addToast } = useToasts();
  const [projectDetailData, setProjectDetailData] = useState(null);

  useEffect(() => {
    if (mode === "edit") {
      loadProjectDetail(projectRef, (data) => setProjectDetailData(data));
    }
  }, [projectRef, mode]);

  const handleSaveSuccess = () =>
    handleGenericSaveSuccess(
      addToast,
      { object: "project", operation: "saved" },
      onSaveSuccess,
      handleCloseForm
    );

  const handleSaveFailed = () =>
    handleGenericSaveFailed(addToast, null, handleCloseForm);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const data = {
      title: form.elements.title.value,
      description: form.elements.description.value,
      overview: form.elements.overview.value,
    };
    switch (mode) {
      case "edit":
        data.projectRef = projectRef;
        updateProject(
          data,
          () => handleSaveSuccess(),
          () => handleSaveFailed()
        );
        break;
      case "create":
        createProject(
          data,
          () => handleSaveSuccess(),
          () => handleSaveFailed()
        );
        break;
      default:
        console.warn(`No mode called: ${mode}`);
    }
  };

  return (
    <Form className="InputForm" onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Label>Project Name:</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="e.g. The Big Dry"
          name="title"
          defaultValue={
            mode === "edit" && projectDetailData !== null
              ? projectDetailData.title
              : ""
          }
          maxLength={TEXT_FIELD_MAX_LENGTH.PROJECT_TITLE}
        />
        <br />
        <Form.Label>Project Summary: (display on cards)</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Description of The Big Dry"
          name="description"
          defaultValue={
            mode === "edit" && projectDetailData !== null
              ? projectDetailData.description
              : ""
          }
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Project Overview: (display on overview modals)</Form.Label>
        <Form.Control
          as="textarea"
          name="overview"
          rows={6}
          defaultValue={
            mode === "edit" && projectDetailData !== null
              ? projectDetailData.overview
              : ""
          }
        />
      </Form.Group>
      <br /> <br />
      <Button size="md" variant="primary" type="submit" block>
        Save
      </Button>
    </Form>
  );
};

export default ProjectCreateEditForm;
