import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { loadCourseExecDetail, deleteCourseExec } from "../../actions";

const ClassroomDeleteForm = ({
  courseExecRef,
  onSaveSuccess,
  handleCloseForm,
}) => {
  const [linkDetailData, setLinkDetailData] = useState(null);
  const [linkDeleteSuccess, setLinkDeleteSuccess] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    loadCourseExecDetail(courseExecRef, (data) => setLinkDetailData(data));
  }, [courseExecRef]);

  const handleDelete = (event) => {
    deleteCourseExec(
      courseExecRef,
      () => {
        setLinkDeleteSuccess(true);
        addToast("Classroom deleted", {
          appearance: "success",
          autoDismiss: true,
        });
        if (handleCloseForm) handleCloseForm();
        onSaveSuccess();
      },
      () => setLinkDeleteSuccess(false)
    );
  };

  return (
    <div>
      <p>Deleting a classroom cannot be reversed.</p>
      <p>
        You won't be able to access the content or student data within a deleted
        classroom. Students won't be able to access it.
      </p>
      <p>
        Are you sure you want to delete:{" "}
        <b className="text-dark font-weight-bolder">
          "{linkDetailData === null ? "" : linkDetailData.classroomName}"
        </b>
        ?
      </p>
      <br />
      <Button
        size="md"
        variant="danger"
        type="submit"
        disabled={linkDeleteSuccess}
        onClick={handleDelete}
        block
      >
        Delete
      </Button>
      <Button
        size="sm"
        variant="link text-danger"
        type="submit"
        disabled={linkDeleteSuccess}
        onClick={handleCloseForm}
        block
      >
        Cancel
      </Button>
      {linkDeleteSuccess ? <Alert variant="success">Deleted.</Alert> : null}
    </div>
  );
};

export default ClassroomDeleteForm;
