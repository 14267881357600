import React, { useState, useEffect } from "react";
import {
  loadSearchableCourseList,
  loadFavouriteList,
  getAllSchoolPurchasedCourseLinks,
} from "../../actions";
import {
  AWS_REGION_COUNTRY_MAP,
  EXPLORE_PAGE_MODES,
  PURCHASE_MODEL_TYPE,
  RoleType,
} from "../../utils/constants";
import ExplorePanel from "../../components/explore/ExplorePanel";
import SearchPanel from "../../components/explore/SearchPanel";
import FavouritePanel from "../../components/explore/FavouritePanel";
import { useDispatch, useSelector } from "react-redux";
import { FAVOURITE_COURSES } from "../../actions/types";
import camelCaseRecursive from "../../utils/CamelCaseRecursive";
import { requestJsonObject } from "../../api/apis";

const REGION_STR = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];
const ExploreApp = ({ isPublic = false, initMode = null }) => {
  const [courseList, setCourseList] = useState(null);
  const [mode, setMode] = useState(initMode || EXPLORE_PAGE_MODES.EXPLORE);
  const [redirectFilter, setRedirectFilter] = useState(null);
  const dispatch = useDispatch();
  const isTeacher = useSelector(
    (state) =>
      state.auth.userDetail &&
      state.auth.userDetail.userInfo.roleTypeName === RoleType.TEACHER
  );
  const userDetailData = useSelector((state) => state.auth.userDetail);
  const [purchasedCourseList, setPurchasedCourseList] = useState(null);
  const isSchoolCoursePurchaseModel =
    userDetailData?.school?.purchaseModel ===
    PURCHASE_MODEL_TYPE.COURSE_PURCHASE;

  const handlePublicCoursesLoaded = (data) => {
    setCourseList({
      courses: data.courses.filter(
        (course) =>
          course.countryMetadata &&
          AWS_REGION_COUNTRY_MAP[window._env_["REGION"]] in
            course.countryMetadata
      ),
      projectDict: data.projectDict,
    });
  };

  const handleCoursesLoaded = (data) => {
    const courseList = {
      courses: data.courses.filter(
        (course) =>
          course.countryMetadata &&
          AWS_REGION_COUNTRY_MAP[window._env_["REGION"]] in
            course.countryMetadata
      ),
      projectDict: data.projectDict,
    };
    setCourseList(courseList);
    if (!isSchoolCoursePurchaseModel) {
      return;
    }
    getAllSchoolPurchasedCourseLinks(
      { schoolRef: userDetailData.school.schoolRef },
      (data) => {
        const courseFilterFunction = (item, filter) => {
          let result = true;
          if (filter?.levels?.length > 0) {
            result =
              result &&
              filter.levels.some((level) =>
                item.countryMetadata[REGION_STR].levels.includes(level)
              );
          }
          if (filter?.subject?.length > 0) {
            result =
              result &&
              filter.subject.some((subject) =>
                item.countryMetadata[REGION_STR].subject.includes(subject)
              );
          }
          return result;
        };
        const metadata = userDetailData.school.purchasedCourseMetadata;
        const purchasedIndividualCourseRefs = data.links.map(
          (link) => link.courseRef
        );
        const filteredCourseList = courseList.courses.filter(
          (course) =>
            metadata?.courseFilters?.some((filter) =>
              courseFilterFunction(course, filter)
            ) || purchasedIndividualCourseRefs.includes(course.courseRef)
        );
        setPurchasedCourseList(filteredCourseList);
      }
    );
  };

  useEffect(() => {
    if (isPublic) {
      const { CONTENT_BUCKET_CDN_DOMAIN } = window._env_;
      requestJsonObject(
        CONTENT_BUCKET_CDN_DOMAIN + "/public/content/all-courses.json",
        (data) => {
          handlePublicCoursesLoaded(camelCaseRecursive(data));
        }
      );
      return;
    }

    loadSearchableCourseList(null, handleCoursesLoaded);
  }, []);

  useEffect(() => {
    isTeacher &&
      loadFavouriteList((data) => {
        dispatch({ type: FAVOURITE_COURSES, payload: data });
      });
  }, [isTeacher]);

  useEffect(() => {
    // Reset filter if back to explore
    if (mode === EXPLORE_PAGE_MODES.EXPLORE) {
      setRedirectFilter(null);
    }
  }, [mode]);

  const switchMode = (mode) => {
    if (!courseList) {
      return null;
    }

    if (isSchoolCoursePurchaseModel) {
      if (purchasedCourseList === null || purchasedCourseList === undefined)
        return null;
    }
    const genericProps = {
      courseList,
      setMode,
      isPublic,
      isSchoolCoursePurchaseModel,
      purchasedCourseList,
    };
    switch (mode) {
      case EXPLORE_PAGE_MODES.EXPLORE:
        return (
          <ExplorePanel
            handleFilterUpdate={setRedirectFilter}
            {...genericProps}
          />
        );
      case EXPLORE_PAGE_MODES.SEARCH:
        return <SearchPanel defaultFilter={redirectFilter} {...genericProps} />;
      case EXPLORE_PAGE_MODES.FAVOURITE:
        return <FavouritePanel {...genericProps} />;
      default:
        break;
    }
  };
  return <div className="mt-lg-7 mt-4 pt-6">{switchMode(mode)}</div>;
};

export default ExploreApp;
