import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Button } from "react-bootstrap";
import { useCookieState } from "ahooks";
import { SSO_CONFIG } from "../../utils/constants";
const SSOSchoolSelectForm = () => {
  const setToken = useCookieState("escs")[1];
  const setTokenExp = useCookieState("exp")[1];
  const [showCantFinddSchoolAlert, setShowCantFindSchoolAlert] =
    useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [SSOConfigs, setSSOConfigs] = useState([]);
  const [optionLabels, setOptionLabels] = useState([]);
  const filteredOptions = optionLabels.filter(
    (o) => !selectedItems.includes(o)
  );
  useEffect(() => {
    setToken(null);
    setTokenExp(null);
    localStorage.clear();
    sessionStorage.clear();
    fetch(SSO_CONFIG.au)
      .then((response) => response.json())
      .then(async (data) => {
        setSSOConfigs(data);
        setOptionLabels(data.map((o) => o.name));
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CantFindSchoolWarning = () => {
    return (
      <div className="text-sm text-dark">
        <h5 className="my-3">Is your school missing? </h5>
        <p>
          If your school doesn't show up in the search results, it might not be
          connected with our system yet.
        </p>
        <p>
          To explore integrating our platform with your school's Single Sign-On
          (SSO) solution, contact us at{" "}
          <span className="text-primary">support@curaeducation.com</span>
        </p>
        <Button
          variant="primary rounded-pill"
          onClick={() => (window.location.href = "/")}
        >
          Login with email and password
        </Button>
      </div>
    );
  };
  return showCantFinddSchoolAlert ? (
    <CantFindSchoolWarning />
  ) : (
    <>
      <p className="text-muted my-3">Select your school account</p>
      <div className="text-sm text-dark d-flex justify-content-between">
        <div>
          Your school <span className="text-danger">*</span>
        </div>{" "}
        <u
          className="text-primary cursor-pointer"
          onClick={() => {
            setShowCantFindSchoolAlert(true);
          }}
        >
          Can't see your school?
        </u>
      </div>
      <Select
        showSearch
        style={{
          width: "100%",
        }}
        placeholder="Search to Select"
        optionFilterProp="label"
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={filteredOptions.map((item) => ({
          value: item,
          label: item,
        }))}
        onSelect={(value) => {
          setSelectedItems([value]);
          const selectedOption = SSOConfigs.find((o) => o.name === value);
          if (!selectedOption) return;
          if (selectedOption.ssoType === "MICROSOFT") {
            const metaData = selectedOption.integrationMetadata;
            window.location.href = `/accounts/microsoft/login/?client-id=${metaData.clientId}&tenant-id=${metaData.tenantId}`;
          }
        }}
      />
    </>
  );
};
export default SSOSchoolSelectForm;
