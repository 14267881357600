import React from "react";
import { Redirect, useParams } from "react-router-dom";
import CourseExecManagementPanel from "../admin/CourseExecManagementPanel";
import Can from "../../utils/Can";
import HeaderPageContainer from "./HeaderPageContainer";

const CourseExecManagementPage = () => {
  const { courseExecRef } = useParams();
  return (
    <HeaderPageContainer>
      <div className="pt-6 pt-lg-7"></div>
      <Can
        perform="course-exec-detail:management"
        yes={() => <CourseExecManagementPanel courseExecRef={courseExecRef} />}
        no={() => <Redirect to={`/classroom/${courseExecRef}`} />}
      />
    </HeaderPageContainer>
  );
};

export default CourseExecManagementPage;
