import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import { useToasts } from "react-toast-notifications";
import { loadTaskList, loadCourseCurriculum } from "../../../actions";
import { v4 as uuid } from "uuid";
import { DragDropContext } from "react-beautiful-dnd";
import { Button, Row, Col, Card } from "react-bootstrap";
import TaskLibrary from "./TaskLibrary";
import EditableCourse from "./EditableCourse";
import { updateCourseCurriculum, loadProjectList } from "../../../actions";
import "../../../style/course-building.css";

const CourseBuilder = ({ courseRef, courseTitle }) => {
  const [shouldReloadTaskListData, setShouldReloadTaskListData] = useState(
    true
  );
  const [taskListData, setTaskListData] = useState(null);
  const [taskListDict, setTaskListDict] = useState(null);
  const [courseCurriculumData, setCourseCurriculumData] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [projectDictData, setProjectDictData] = useState(null);
  const [isReadyForUpdate, setIsReadyForUpdate] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const { addToast } = useToasts();

  const taskListToDict = (taskListData) => {
    const dict = taskListData.reduce(function(map, obj) {
      map[obj.taskRef] = obj;
      return map;
    }, {});
    return dict;
  };

  useEffect(() => {
    loadProjectList((data) => {
      const projectDict = data.reduce(function(map, obj) {
        map[obj.projectRef] = obj;
        return map;
      }, {});
      setProjectDictData(projectDict);
    });
  }, []);

  useEffect(() => {
    if (shouldReloadTaskListData) {
      setShouldReloadTaskListData(false);
      loadTaskList((data) => {
        setTaskListData(data);
        setTaskListDict(taskListToDict(data));
      });
    }
  }, [shouldReloadTaskListData]);

  useEffect(() => {
    loadCourseCurriculum(courseRef, (data) => {
      setCourseCurriculumData(data);
      setIsReadyForUpdate(true);
    });
  }, [courseRef]);

  // TODO: This dependency array is not best practice. Fix and improve all other related issues.
  useEffect(() => {
    if (isReadyForUpdate) {
      window.onbeforeunload = () => true;
      setShowPrompt(true);
    }
  }, [courseCurriculumData]);

  useEffect(() => {
    if (updateSuccess) {
      addToast("Update successful.", {
        appearance: "success",
        autoDismiss: true,
      });
      setUpdateSuccess(false);
    }
  }, [updateSuccess, addToast]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (result.type === "TASK" && source.droppableId !== "TASKLIBRARY") {
      // Moving within the existing course.
      const startSection = courseCurriculumData.sections[source.droppableId];
      const finishSection =
        courseCurriculumData.sections[destination.droppableId];

      if (startSection === finishSection) {
        // Moving in the same section
        const section = startSection;
        const newTaskIds = Array.from(section.courseTaskLinkList);
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);

        const newSection = {
          ...section,
          courseTaskLinkList: newTaskIds,
        };

        const newState = {
          ...courseCurriculumData,
          sections: {
            ...courseCurriculumData.sections,
            [newSection.sectionRef]: newSection,
          },
        };

        setCourseCurriculumData(newState);
      } else {
        // Moving to a different section
        const startLinkIds = Array.from(startSection.courseTaskLinkList);
        startLinkIds.splice(source.index, 1);
        const newStartSection = {
          ...startSection,
          courseTaskLinkList: startLinkIds,
        };

        const finishTaskIds = Array.from(finishSection.courseTaskLinkList);
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinishSection = {
          ...finishSection,
          courseTaskLinkList: finishTaskIds,
        };
        let newCourseTaskLinks = courseCurriculumData.courseTaskLinks;
        newCourseTaskLinks[draggableId].sectionRef =
          newFinishSection.sectionRef;
        const newState = {
          ...courseCurriculumData,
          courseTaskLinks: newCourseTaskLinks,
          sections: {
            ...courseCurriculumData.sections,
            [newStartSection.sectionRef]: newStartSection,
            [newFinishSection.sectionRef]: newFinishSection,
          },
        };

        setCourseCurriculumData(newState);
      }
    } else if (result.type === "TASK" && source.droppableId === "TASKLIBRARY") {
      // Copy a task from the library
      const newCourseTaskLinks = courseCurriculumData.courseTaskLinks;
      const finishSection =
        courseCurriculumData.sections[destination.droppableId];
      const task = taskListDict[draggableId];
      const copiedCourseTaskLink = {
        taskRef: task.taskRef,
        courseTaskLinkRef: uuid(),
        sectionRef: finishSection.sectionRef,
      };
      const finishcourseTaskLinks = Array.from(
        finishSection.courseTaskLinkList
      );
      finishcourseTaskLinks.splice(
        destination.index,
        0,
        copiedCourseTaskLink.courseTaskLinkRef
      );
      newCourseTaskLinks[
        copiedCourseTaskLink.courseTaskLinkRef
      ] = copiedCourseTaskLink;

      const newFinishSection = {
        ...finishSection,
        courseTaskLinkList: finishcourseTaskLinks,
      };

      const newTaskList = courseCurriculumData.tasks;
      newTaskList[task.taskRef] = task;

      const newState = {
        ...courseCurriculumData,
        tasks: newTaskList,
        courseTaskLinks: newCourseTaskLinks,
        sections: {
          ...courseCurriculumData.sections,
          [newFinishSection.sectionRef]: newFinishSection,
        },
      };

      setCourseCurriculumData(newState);
    } else if (result.type === "SECTION") {
      // Move sections
      const newSectionIds = Array.from(courseCurriculumData.sectionOrder);
      newSectionIds.splice(source.index, 1);
      newSectionIds.splice(destination.index, 0, draggableId);

      const newState = {
        ...courseCurriculumData,
        sectionOrder: newSectionIds,
      };

      setCourseCurriculumData(newState);
    }
  };

  const onSaveClick = async () => {
    let r = window.confirm(
      "Saving this may change existing running courses in schools." +
        " Are you sure?"
    );
    if (!r) return;
    const newCurriculum = courseCurriculumData;
    await updateCourseCurriculum(newCurriculum, (data) => {
      setCourseCurriculumData(data);
      setUpdateSuccess(true);
      window.onbeforeunload = null;
      setShowPrompt(false);
    });
  };

  const onCancelClick = () => {
    let r = window.confirm("You will lose all unsaved progress. Are you sure?");
    if (!r) return;
    window.location.reload();
  };

  const onTaskRemove = (courseTaskLink) => {
    const sectionId = courseTaskLink.sectionRef;
    const courseTaskLinkId = courseTaskLink.courseTaskLinkRef;
    const section = courseCurriculumData.sections[sectionId];
    const startLinkIds = Array.from(section.courseTaskLinkList);
    startLinkIds.splice(
      startLinkIds.findIndex((item) => {
        return item === courseTaskLinkId;
      }),
      1
    );
    const newSection = {
      ...section,
      courseTaskLinkList: startLinkIds,
    };

    let newCourseTaskLinks = courseCurriculumData.courseTaskLinks;
    delete newCourseTaskLinks[courseTaskLinkId];
    const newState = {
      ...courseCurriculumData,
      courseTaskLinks: newCourseTaskLinks,
      sections: {
        ...courseCurriculumData.sections,
        [newSection.sectionRef]: newSection,
      },
    };

    setCourseCurriculumData(newState);
  };

  const onSectionCreate = () => {
    const newSectionId = uuid();
    const newSectionIds = Array.from(courseCurriculumData.sectionOrder);
    const newSection = {
      sectionRef: newSectionId,
      courseRef: courseCurriculumData.courseRef,
      title: "New Section",
      seqNumInCourse: newSectionIds.length,
      courseTaskLinkList: [],
    };
    newSectionIds.splice(newSectionIds.length, 0, newSectionId);

    const newState = {
      ...courseCurriculumData,
      sectionOrder: newSectionIds,
      sections: {
        ...courseCurriculumData.sections,
        [newSection.sectionRef]: newSection,
      },
    };

    setCourseCurriculumData(newState);
  };

  const onSectionRemove = (section) => {
    const sectionRef = section.sectionRef;
    const newSectionIds = Array.from(courseCurriculumData.sectionOrder);
    let newSections = courseCurriculumData.sections;
    let newCourseTaskLinks = courseCurriculumData.courseTaskLinks;
    const sectionCourseTaskLinkList =
      newSections[sectionRef].courseTaskLinkList;
    for (const linkRef of sectionCourseTaskLinkList) {
      delete newCourseTaskLinks[linkRef];
    }
    delete newSections[sectionRef];
    newSectionIds.splice(
      newSectionIds.findIndex((item) => {
        return item === sectionRef;
      }),
      1
    );

    const newState = {
      ...courseCurriculumData,
      courseTaskLinks: newCourseTaskLinks,
      sectionOrder: newSectionIds,
      sections: newSections,
    };

    setCourseCurriculumData(newState);
  };

  const onSectionEdit = (newTitle, sectionRef) => {
    let updatedSection = courseCurriculumData.sections[sectionRef];
    updatedSection.title = newTitle;
    const newState = {
      ...courseCurriculumData,
      sections: {
        ...courseCurriculumData.sections,
        [updatedSection.sectionRef]: updatedSection,
      },
    };

    setCourseCurriculumData(newState);
  };

  return (
    projectDictData &&
    courseCurriculumData && (
      <Card>
        <Prompt
          when={showPrompt}
          message="You will lose all unsaved progress. Are you sure you want to leave?"
        />
        <Card.Header>
          <h4>{courseTitle}</h4>
        </Card.Header>
        <Card.Body className="pt-0">
          <DragDropContext onDragEnd={onDragEnd}>
            <Row className="mb-6">
              <Col sm="4" className="px-0 pt-4">
                <Row className="px-3 mb-1">
                  <Col sm="auto" className="mb-2">
                    <Button
                      size="xs"
                      variant="success rounded-pill"
                      onClick={async () => {
                        await onSaveClick();
                        setTimeout(() => {
                          window.open(`/course-preview/${courseRef}`, "_blank");
                        }, 1500);
                      }}
                    >
                      Save&amp;Preview
                    </Button>
                  </Col>
                  <Col sm="auto" className="mb-2">
                    <Button
                      size="xs"
                      variant="secondary rounded-pill"
                      onClick={onSectionCreate}
                    >
                      +Section
                    </Button>
                  </Col>
                  <Col sm="auto" className="mb-2">
                    <Button
                      size="xs"
                      variant="link rounded-pill"
                      className="text-secondary"
                      onClick={onCancelClick}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="xs"
                      variant="primary rounded-pill"
                      onClick={onSaveClick}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
                {courseCurriculumData !== null && (
                  <EditableCourse
                    courseData={courseCurriculumData}
                    projectDictData={projectDictData}
                    courseId={courseCurriculumData.courseDetail.courseRef}
                    onTaskRemove={onTaskRemove}
                    onSectionRemove={onSectionRemove}
                    onSectionEdit={onSectionEdit}
                  />
                )}
              </Col>
              <Col sm="8" className="px-0 pt-4 border-secondary border-left">
                <TaskLibrary
                  reloadTaskListData={() => setShouldReloadTaskListData(true)}
                  taskListData={taskListData}
                  projectDictData={projectDictData}
                />
              </Col>
            </Row>
          </DragDropContext>
        </Card.Body>
      </Card>
    )
  );
};

export default CourseBuilder;
