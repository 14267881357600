import React, { useState, useEffect } from "react";
import { Card, Row, Col, Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  ILLUSTRATIONS,
  RoleType,
  WEBSITE_LINKS,
} from "../../../utils/constants";
import SignUpForm from "./SignUpForm";
import OTPSignUp from "./OTPSignUp";
import { signUp } from "../../../actions";

const SignUp = () => {
  const [isOTPMode, setIsOTPMode] = useState(false);
  const [credential, setCredential] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [formData, setFormData] = useState(null);
  const [validationRequested, setValidationRequested] = useState(false);
  const [isSignupFormSubmitting, setIsSignupFormSubmitting] = useState(false);
  const [signupFormErrorCode, setSignupFormErrorCode] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (userDetail) {
      switch (userDetail.userInfo.roleTypeName) {
        case RoleType.ADMIN:
          history.push("/admin/dashboard");
          break;
        case RoleType.TEACHER:
          history.push("/teacher");
          break;
        default:
          history.push("/student");
          break;
      }
    }
  }, [userDetail]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = event.target.elements;
    const data = {
      firstName: formData.firstName.value.trim(),
      lastName: formData.lastName.value.trim(),
      signupInfo: {
        schoolName: formData.schoolName.value.trim(),
        country: formData.country.value.trim(),
      },
      email: formData.email.value.toLowerCase().trim(),
      password: formData.password.value.trim(),
    };

    setFormData(data);
    setIsSignupFormSubmitting(true);
    setSignupFormErrorCode(null);
    signUp(
      data,
      (data) => {
        setCredential(data["token"]);
        setIsOTPMode(true);
        setIsSignupFormSubmitting(false);
      },
      (data) => {
        // USER_ALREADY_EXISTS = 408
        // NOT_SCHOOL_EMAIL = 409
        // VALIDATION_REQUIRED = 410
        let code = null;
        if (data.code) code = parseInt(data.code);
        if ([408, 409].includes(code)) {
          setSignupFormErrorCode(code);
        } else if (code === 410) {
          setValidationRequested(true);
        } else alert("Error. Please try again later");
        setIsSignupFormSubmitting(false);
      }
    );
  };
  return (
    <div className="container-fluid container-application">
      <div className="page-content">
        <div className="min-vh-100 py-5 d-flex align-items-center">
          <div className="w-100">
            <Row className="justify-content-center">
              <Col sm="8" md="12">
                <div className="d-flex justify-content-center">
                  <Card
                    className="shadow zindex-100 mb-0"
                    style={{ maxWidth: "50rem" }}
                  >
                    <Card.Body className="px-md-5 py-5">
                      <div>
                        {((!validationRequested && !isOTPMode) ||
                          validationRequested) && (
                          <div className="float-right ml-3">
                            Already have an account?{" "}
                            <a href="/">
                              <u>Sign in</u>
                            </a>
                          </div>
                        )}
                        <img
                          src={ILLUSTRATIONS.LOGO.MAIN_LOGO}
                          alt="logo"
                          height="24"
                          className="cursor-pointer"
                          onClick={() => {
                            window.location.href = WEBSITE_LINKS.ROOT;
                          }}
                        />
                        <p className="text-muted mt-3">
                          Get instant access to 100+ PBL units
                        </p>
                      </div>
                      {!validationRequested &&
                        (!isOTPMode ? (
                          <div className="mt-5">
                            <SignUpForm
                              handleSubmit={handleSubmit}
                              isSubmitting={isSignupFormSubmitting}
                              errorCode={signupFormErrorCode}
                            />
                          </div>
                        ) : (
                          <OTPSignUp
                            originalFormData={formData}
                            credential={credential}
                            handleUserDetailChange={(user) =>
                              setUserDetail(user)
                            }
                          />
                        ))}
                      {validationRequested && (
                        <>
                          <Alert variant="warning">
                            <p>
                              We couldn't validate your school email address
                              automatically. But don't worry - a Cura team
                              member will set you up with trial access shortly!
                            </p>
                            You can also contact us directly by emailing{" "}
                            <a href="mailto:hello@curaeducation.com">
                              <u>hello@curaeducation.com</u>
                            </a>
                          </Alert>
                          <p className="text-sm">
                            <a href={WEBSITE_LINKS.ROOT}>
                              <u>Return to Cura website</u>
                            </a>
                          </p>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
