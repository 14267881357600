import React, { useState, useEffect } from "react";
import { loadTaskList, loadProjectList } from "../../actions";
import ModalButton from "../util/ModalButton";
import ReactTable from "../util/ReactTable";
import TaskVersionManagementPanel from "./TaskVersionManagementPanel";
import { Redirect } from "react-router-dom";
import TablePanelHeader from "../util/TablePanelHeader";
import ProjectListDropDown from "../project/ProjectListDropdown";
import { Badge } from "react-bootstrap";

const TaskList = () => {
  const [projectDictData, setProjectDictData] = useState(null);
  const [displayedTaskListData, setDisplayedTaskListData] = useState(null);
  const [selectedProjectRef, setSelectedProjectRef] = useState("all-projects");
  const [shouldReloadData, setShouldReloadData] = useState(true);

  useEffect(() => {
    if (shouldReloadData) {
      loadTaskList((data) => {
        setDisplayedTaskListData(data);
      });
      loadProjectList((data) => {
        const projectDict = data.reduce(function(map, obj) {
          map[obj.projectRef] = obj;
          return map;
        }, {});
        setProjectDictData(projectDict);
      });
      setShouldReloadData(false);
    }
  }, [shouldReloadData]);

  const handleProjectFilterChagne = (value) => {
    const projectInput = value;
    setSelectedProjectRef(projectInput);
  };

  const customFilterFunction = (rows, id, filterValue) =>
    rows.filter(
      (row) =>
        filterValue === "all-projects" ||
        (row.original.projectRef == null && "no-project" === filterValue) ||
        row.original.projectRef === filterValue
    );

  const columns = [
    {
      id: "title",
      Header: "Task Name",
      accessor: "title",
      Cell: (cellInfo) => (
        <ModalButton
          className="float-left"
          variant="link"
          buttonText={cellInfo.row.original.title}
          modalTitle={cellInfo.row.original.title}
          modalSize="xl"
          onHide={() => {
            setShouldReloadData(true);
            return true;
          }}
        >
          <TaskVersionManagementPanel taskRef={cellInfo.row.original.taskRef} />
        </ModalButton>
      ),
    },
    {
      id: "versionName",
      Header: "Default Version",
      accessor: "versionName",
      Cell: ({ value }) => (
        <h5>
          <Badge className="badge-soft-warning">{value}</Badge>
        </h5>
      ),
    },
    {
      id: "projectRef",
      Header: "Project",
      accessor: "projectRef",
      Cell: ({ value }) => (value ? projectDictData[value].title : "N/A"),
      filter: customFilterFunction,
    },
  ];

  return (
    <>
      <TablePanelHeader>
        <div className="mr-3 my-auto">
          {projectDictData && (
            <ProjectListDropDown
              defaultValue="all-projects"
              onProjectChange={handleProjectFilterChagne}
              projectsData={Object.values(projectDictData)}
              showAll={true}
            />
          )}
        </div>

        <ModalButton
          buttonText="+ CREATE NEW TASK"
          modalTitle="Create New Task"
          variant="light"
          size="sm"
        >
          <Redirect to={`/admin/tasks/task/create/new-task`} />
        </ModalButton>
      </TablePanelHeader>
      {displayedTaskListData && projectDictData ? (
        <ReactTable
          title="Task Library"
          data={displayedTaskListData}
          columns={columns}
          filtersOutside={[{ ref: "projectRef", value: selectedProjectRef }]}
        />
      ) : null}
    </>
  );
};

export default TaskList;
