import React from "react";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

const Arrow = ({ children, disabled, onClick, className }) => {
  return (
    <div
      className={`d-flex ${className}`}
      style={{
        opacity: disabled ? "0" : "1",
      }}
    >
      <div
        className={
          "d-none d-xl-flex border border-primary border-1 shadow-lg " +
          "rounded-circle p-1 text-primary my-auto bg-white cursor-pointer"
        }
        variant="outline-primary"
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </div>
    </div>
  );
};

export const LeftArrow = () => {
  const {
    getPrevItem,
    isFirstItemVisible,
    scrollToItem,
    visibleItemsWithoutSeparators,
    initComplete,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  // NOTE: for scroll 1 item
  const clickHandler = () => {
    const prevItem = getPrevItem();
    scrollToItem(prevItem?.entry?.target, "smooth", "start");
  };

  return (
    <Arrow disabled={disabled} onClick={clickHandler} className="mr-3">
      <CaretLeftFill size={22} />
    </Arrow>
  );
};

export const RightArrow = () => {
  const {
    getNextItem,
    isLastItemVisible,
    scrollToItem,
    visibleItemsWithoutSeparators,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  // NOTE: for scroll 1 item
  const clickHandler = () => {
    const nextItem = getNextItem();
    scrollToItem(nextItem?.entry?.target, "smooth", "end");
  };

  return (
    <Arrow disabled={disabled} onClick={clickHandler} className="ml-3">
      <CaretRightFill size={22} />
    </Arrow>
  );
};
