import React, { useState, useEffect } from "react";
import ReactTable, { RightButtonsTableCell } from "../util/ReactTable";
import ModalButton from "../util/ModalButton";
import ProjectCreateEditForm from "../createEditForms/ProjectCreateEditForm";
import ProjectDeleteForm from "../createEditForms/ProjectDeleteForm";
import { loadProjectList } from "../../actions";
import { Link, useRouteMatch } from "react-router-dom";
import TablePanelHeader from "../util/TablePanelHeader";
import Can from "../../utils/Can";
import { Button } from "react-bootstrap";

const ProjectList = () => {
  const [projectListData, setProjectListData] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);
  let { url } = useRouteMatch();

  useEffect(() => {
    if (shouldReloadData) {
      loadProjectList((data) => {
        setProjectListData(data);
      });
      setShouldReloadData(false);
    }
  }, [shouldReloadData]);

  const tableRowButtons = (ref) => {
    return (
      <RightButtonsTableCell>
        <Can
          perform="pbl-content:modify"
          yes={() => (
            <>
              <ModalButton
                modalSize="lg"
                variant="link"
                buttonText="Edit"
                modalTitle="Edit Project"
              >
                <ProjectCreateEditForm
                  mode="edit"
                  projectRef={ref}
                  onSaveSuccess={() => setShouldReloadData(true)}
                />
              </ModalButton>
              <ModalButton
                variant="link"
                className="text-danger"
                buttonText="Delete"
                modalTitle="Delete Project"
              >
                <ProjectDeleteForm
                  projectRef={ref}
                  onSaveSuccess={() => setShouldReloadData(true)}
                />
              </ModalButton>
            </>
          )}
          no={() => <Button className="invisible">Placeholder</Button>}
        />
      </RightButtonsTableCell>
    );
  };

  const columns = [
    {
      Header: "Project Name",
      accessor: "title",
      Cell: (cellInfo) => (
        <Link
          to={`${url}/project/${cellInfo.row.original.projectRef}`}
          replace={true}
        >
          {cellInfo.row.original.title}
        </Link>
      ),
    },
    {
      Header: "",
      accessor: "projectRef",
      Cell: ({ value }) => tableRowButtons(value),
    },
  ];

  return (
    <>
      <TablePanelHeader title="Projects">
        <Can
          perform="pbl-content:modify"
          yes={() => (
            <ModalButton
              modalSize="lg"
              buttonText="+ CREATE NEW PROJECT"
              modalTitle="Create New Project"
              variant="light"
            >
              <ProjectCreateEditForm
                mode="create"
                onSaveSuccess={() => setShouldReloadData(true)}
              />
            </ModalButton>
          )}
          no={() => null}
        />
      </TablePanelHeader>

      {projectListData ? (
        <ReactTable title="Projects" data={projectListData} columns={columns} />
      ) : null}
    </>
  );
};

export default ProjectList;
