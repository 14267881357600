import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { loadUserCourseLinkDetail, deleteUserCourseLink } from "../../actions";

const UserCourseLinkDeleteForm = ({ userCourseLinkRef, onSaveSuccess }) => {
  const [linkDetailData, setLinkDetailData] = useState(null);
  const [linkDeleteSuccess, setLinkDeleteSuccess] = useState(null);

  useEffect(() => {
    loadUserCourseLinkDetail(userCourseLinkRef, (data) =>
      setLinkDetailData(data)
    );
  }, [userCourseLinkRef]);

  const handleDelete = (event) => {
    deleteUserCourseLink(
      { userCourseLinkRefs: [userCourseLinkRef] },
      () => {
        setLinkDeleteSuccess(true);
        onSaveSuccess();
      },
      () => setLinkDeleteSuccess(false)
    );
  };

  return (
    <div>
      <p>
        Are you sure you want to delete: "
        {linkDetailData === null ? "" : linkDetailData.user.email}"?
      </p>
      <br />
      <Button
        size="md"
        variant="primary"
        type="submit"
        disabled={linkDeleteSuccess}
        onClick={handleDelete}
        block
      >
        Delete
      </Button>
      {linkDeleteSuccess ? <Alert variant="success">Deleted.</Alert> : null}
    </div>
  );
};

export default UserCourseLinkDeleteForm;
