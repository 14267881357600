import { Col } from "react-bootstrap";

const ImageHeaderWithTitle = ({ title, thumbnail, customContainerStyle }) => {
  const containerStyle = {
    backgroundImage: `url(${thumbnail})`,
    backgroundSize: "100% 100%",
    position: "relative",
    width: "100%",
    paddingTop: "33.33%" /* 3:1 Aspect Ratio */,
    ...customContainerStyle,
  };
  const titleStyle = {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const textStyle = "text-white mb-0 text-center";
  return (
    <div style={containerStyle}>
      <Col sm="11" md="8" style={titleStyle} className="rounded-pill">
        <h2 className={`d-none d-lg-block px-4 py-3 ${textStyle}`}>{title}</h2>
        <h3 className={`d-lg-none p-1 ${textStyle}`}>{title}</h3>
      </Col>
    </div>
  );
};

export default ImageHeaderWithTitle;
