import React, { useEffect, useState } from "react";
import { Table, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useGlobalFilter,
} from "react-table";
import { Search } from "react-bootstrap-icons";

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="mw-30">
      <InputGroup>
        <FormControl
          className="border-secondary border-right-0"
          placeholder={`Search from ${count} records...`}
          aria-label="Search..."
          aria-describedby="basic-addon2"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
        <InputGroup.Append>
          <Button className="border-secondary px-3" variant="outline-secondary">
            <Search className="text-dark" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};

const RightButtonsTableCell = (props) => {
  return <div className="text-right">{props.children}</div>;
};

const ReactTable = ({ title, columns, data, filtersOutside }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setFilter,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters
  );

  useEffect(() => {
    if (filtersOutside)
      filtersOutside.forEach((fiilterObj) =>
        setFilter(fiilterObj.ref, fiilterObj.value)
      );
  }, [filtersOutside, setFilter]);

  useEffect(() => {
    if (!data) return;
    setGlobalFilter(state.globalFilter);
  }, [data, state.globalFilter, setGlobalFilter]);

  return (
    <Card>
      <Card.Header
        as="h5"
        className="text-dark actions-toolbar pb-0 border-0 d-flex justify-content-between"
      >
        <div className="d-flex align-items-center mr-3">{title}</div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Card.Header>
      <Card.Body className="px-0 overflow-auto">
        <Table className="align-items-center" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="management-table-td"
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export { RightButtonsTableCell };
export default ReactTable;
