import React, { useState, useEffect } from "react";
import { loadTaskDetail } from "../../actions";
import { Tabs, Tab, Card, Col, Row } from "react-bootstrap";
import Can from "../../utils/Can";
import FileCard from "./FileCard";
import TaskFeedbackBlock from "./TaskFeedbackBlock";
import TaskResourceBlockDisplayer from "./resourceBlock/TaskResourceBlocksDisplayer";
import TaskScoreTable from "./TaskScoreTable";
import { getQuestionBlocks } from "../../utils/DataLogicUtils";
import ImageHeaderWithTitle from "../util/ImageHeaderWithTitle";
import UserEditCourseButton from "../course/UserEditCourseButton";
import CanvaEmbeddedSlidesContainer from "../util/CanvaEmbeddedSlidesContainer";

const AttachmentList = ({ attachmentLinkList }) => {
  const getItems = () => {
    return attachmentLinkList.map((link, index) => {
      return (
        <Col
          xs={12}
          md={6}
          lg={attachmentLinkList.length > 2 ? 4 : 6}
          key={`attachment-${index}`}
        >
          <FileCard
            url={link.attachment.url}
            fileName={link.attachment.fileName}
          />
        </Col>
      );
    });
  };
  return <Row>{getItems()}</Row>;
};

const SingleTaskView = ({
  taskRef,
  courseExecRef,
  courseExecData,
  courseTaskLinkRef,
  updatedUnsavedTRBRefs,
}) => {
  const [taskDetailData, setTaskDetailData] = useState(null);

  useEffect(() => {
    if (taskRef !== "") {
      window.scrollTo(0, 0);
      loadTaskDetail(taskRef, (data) => setTaskDetailData(data));
    }
  }, [taskRef]);

  const TaskDescriptionInfo = ({ taskDetailData }) => {
    return (
      <>
        <Card className="mt-3">
          <Card.Header className="bg-primary text-white">
            <div
              className="d-block d-md-flex justify-content-between"
              style={{ minHeight: "3rem" }}
            >
              <div className="my-auto">
                <h5 className="text-white m-0">{taskDetailData.title}</h5>
                {taskDetailData.suggestedTime && (
                  <div className="text-xs text-white">
                    Estimated time: {taskDetailData.suggestedTime} minutes
                  </div>
                )}
              </div>
              <hr className="d-md-none my-3" />
              {courseExecRef && courseTaskLinkRef && (
                <TaskFeedbackBlock
                  courseExecRef={courseExecRef}
                  courseTaskLinkRef={courseTaskLinkRef}
                  taskDetailData={taskDetailData}
                />
              )}
            </div>
          </Card.Header>
          <Card.Body>
            <Card.Title className="text-primary">Task Requirements</Card.Title>
            <Card.Text>{taskDetailData.description}</Card.Text>
          </Card.Body>
          <hr className="my-0" />
          {taskDetailData.bodyAttachmentList.length > 0 && (
            <Card.Body>
              <Card.Title className="text-primary">Attachments</Card.Title>
              <AttachmentList
                attachmentLinkList={taskDetailData.bodyAttachmentList}
              />
            </Card.Body>
          )}
        </Card>
        <div className="bg-white p-4 rounded my-3">
          <div
            className="ck-content mb-5"
            dangerouslySetInnerHTML={{ __html: taskDetailData.body }}
          />
          <Can
            perform="task-work-upload:student"
            yes={() =>
              courseExecData && (
                <TaskResourceBlockDisplayer
                  shouldLoadExistingWork={true}
                  editable={courseExecData.status === "ACTIVE"}
                  courseExecRef={courseExecRef}
                  courseTaskLinkRef={courseTaskLinkRef}
                  taskDetailData={taskDetailData}
                  updatedUnsavedTRBRefs={updatedUnsavedTRBRefs}
                />
              )
            }
            no={() => (
              <TaskResourceBlockDisplayer
                shouldLoadExistingWork={false}
                editable={false}
                courseExecRef={courseExecRef}
                courseTaskLinkRef={courseTaskLinkRef}
                taskDetailData={taskDetailData}
              />
            )}
          />
        </div>
      </>
    );
  };

  const ResourceTabs = ({
    taskDetailData,
    courseExecRef,
    courseTaskLinkRef,
  }) => {
    const canvaEmbeddedSlidesUrl = taskDetailData?.canvaEmbeddedSlidesUrl;
    return (
      <Tabs
        variant="pills"
        defaultActiveKey="body"
        className="d-flex justify-content-center"
        unmountOnExit={true}
      >
        <Tab eventKey="body" title="Task Overview">
          <TaskDescriptionInfo taskDetailData={taskDetailData} />
        </Tab>
        {canvaEmbeddedSlidesUrl && (
          <Tab eventKey="slides" title="Slides">
            <CanvaEmbeddedSlidesContainer
              canvaEmbeddedSlidesUrl={canvaEmbeddedSlidesUrl}
            />
          </Tab>
        )}
        <Tab eventKey="teacherContent" title="Teacher Notes">
          {taskDetailData.teacherContentAttachmentList.length > 0 && (
            <Card className="mt-3">
              <Card.Body>
                <Card.Title>Attachments</Card.Title>
                <AttachmentList
                  attachmentLinkList={
                    taskDetailData.teacherContentAttachmentList
                  }
                />
              </Card.Body>
            </Card>
          )}

          <div
            className="ck-content bg-white p-4 rounded my-3"
            dangerouslySetInnerHTML={{
              __html: taskDetailData.teacherContent,
            }}
          />
        </Tab>
        {courseExecRef && (
          <Tab eventKey="scoreTable" title="Student Progress">
            <Card className="mw-100 my-3">
              <Card.Body>
                {taskDetailData.taskResourceBlockList &&
                getQuestionBlocks(taskDetailData.taskResourceBlockList).length >
                  0 ? (
                  <TaskScoreTable
                    taskDetailData={taskDetailData}
                    courseExecRef={courseExecRef}
                    courseExecStatus={courseExecData.status}
                    courseTaskLinkRef={courseTaskLinkRef}
                  />
                ) : (
                  "This task has no assessable questions"
                )}
              </Card.Body>
            </Card>
          </Tab>
        )}
      </Tabs>
    );
  };

  const FloatingTaskEditButton = () => {
    return (
      <Can
        perform="task-tabs:get"
        yes={() => {
          if (!courseExecData) return null;
          if (courseExecData.status !== "ACTIVE") return null;
          return (
            <div
              style={{
                position: "fixed",
                right: "2rem",
                bottom: "1rem",
                zIndex: "100",
              }}
            >
              <UserEditCourseButton
                courseRef={courseExecRef}
                courseTaskLinkRef={courseTaskLinkRef}
                forceIconOnly={true}
              />
            </div>
          );
        }}
      />
    );
  };

  return (
    <>
      {/* Note: 
        taskDetailData.taskRef === taskRef will avoid double rendering like this:
        1. taskRef change
        2. Unload below components
        3. Reload below components
        4. useEffect -> request taskdetail
        5. If not having this condition, 
          it will do 2 and 3 again, causing double loading
      */}
      {taskDetailData && taskDetailData.taskRef === taskRef && (
        <>
          <ImageHeaderWithTitle
            title={taskDetailData.title}
            thumbnail={taskDetailData.thumbnail}
          />
          <div className="my-4 mx-sm-4">
            <div className="float-right">
              <Can
                perform="task-tabs:get"
                yes={() => {
                  if (!courseExecData) return null;
                  if (courseExecData.status !== "ACTIVE") return null;
                  return (
                    <UserEditCourseButton
                      courseRef={courseExecData.courseRef}
                      courseTaskLinkRef={courseTaskLinkRef}
                    />
                  );
                }}
              />
            </div>
            <Can
              perform="task-tabs:get"
              yes={() => (
                <ResourceTabs
                  taskDetailData={taskDetailData}
                  courseExecRef={courseExecRef}
                  courseTaskLinkRef={courseTaskLinkRef}
                />
              )}
              no={() => <TaskDescriptionInfo taskDetailData={taskDetailData} />}
            />
            <FloatingTaskEditButton />
          </div>
        </>
      )}
    </>
  );
};

export default SingleTaskView;
