import React from "react";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const MultiFilesUpload = ({
  uploadKey,
  appendFileForSaving,
  removeFileLinkForSaving,
  defaultFileList,
}) => {
  const onRemove = (file) => {
    removeFileLinkForSaving(file.uid);
  };

  const beforeUpload = (file) => {
    appendFileForSaving(file);
    return false;
  };

  return (
    <Upload
      onRemove={onRemove}
      beforeUpload={beforeUpload}
      defaultFileList={defaultFileList}
    >
      <Button>
        <UploadOutlined /> Upload
      </Button>
    </Upload>
  );
};

export default MultiFilesUpload;
