import React, { useEffect, useState, useRef, useMemo } from "react";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deepCopy } from "../../utils/GenericUtils";
import TextArea from "antd/es/input/TextArea";
import { Upload } from "antd";
import { uploadFileWithHandler } from "../../utils/FileUpload";

const INIT_ROW = {
  title: null,
  description: null,
  url: null,
  note: null,
  type: "IMAGE",
};

const EditableRow = ({ rowData, index, handleDelete, handleUpdate }) => {
  const isFirstRun = useRef(true);
  const [updatedRowData, setUpdatedRowData] = useState(
    rowData ? rowData : deepCopy(INIT_ROW)
  );
  const [updatedImageUrl, setUpdatedImageUrl] = useState(rowData?.url);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setFileList(updatedImageUrl ? [{ url: updatedImageUrl }] : []);
  }, [updatedImageUrl]);

  const cellStyle = { width: "20rem" };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    handleUpdate(index, updatedRowData);
  }, [updatedRowData]);

  const getUploadedLink = async (file) => {
    let result = null;
    await uploadFileWithHandler(
      file,
      (url) => {
        result = url;
      },
      () => {
        // TODO: handle file upload error
      },
      "student-success",
      false
    );
    return result;
  };

  return (
    <tr>
      <td style={cellStyle}>
        <TextArea
          value={updatedRowData?.description}
          rows={2}
          onChange={(item) => {
            setUpdatedRowData({
              ...updatedRowData,
              description: item.target.value,
            });
          }}
        />
      </td>
      <td style={cellStyle}>
        <TextArea
          value={updatedRowData?.note}
          rows={2}
          onChange={(item) => {
            setUpdatedRowData({ ...updatedRowData, note: item.target.value });
          }}
        />
      </td>
      <td style={cellStyle}>
        <Upload
          accept="image/*"
          listType="picture-card"
          fileList={fileList}
          beforeUpload={async (file) => {
            // if image is not starting with image/ then it is not an image
            const isImage = file.type.startsWith("image/");
            if (!isImage) {
              alert("Please upload an image file");
              return;
            }
            const url = await getUploadedLink(file);
            setUpdatedRowData({ ...updatedRowData, url: url });
            setUpdatedImageUrl(url);
          }}
          onPreview={(file) => {
            let src = file.url;
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow?.document.write(image.outerHTML);
          }}
          onRemove={() => {
            setUpdatedRowData({ ...updatedRowData, url: null });
            setUpdatedImageUrl(null);
          }}
        >
          {!updatedImageUrl && "+ Upload"}
        </Upload>
      </td>
      <td>
        <Button
          size="sm"
          variant="danger rounded-circle btn-icon-only float-right"
          onClick={() => handleDelete(index)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
};

const StudentSuccessMetadataEditor = ({
  studentSuccessMetadata,
  handleMetadataUpdate,
}) => {
  const [updatedMetadataList, setUpdatedMetadataList] = useState(
    deepCopy(studentSuccessMetadata)
  );
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    handleMetadataUpdate(updatedMetadataList);
  }, [updatedMetadataList]);

  const handleAddClick = () => {
    const newState = [...(updatedMetadataList ?? []), deepCopy(INIT_ROW)];
    setUpdatedMetadataList(newState);
  };
  const handleDeleteClick = (index) => {
    const newList = deepCopy(updatedMetadataList);
    newList.splice(index, 1);
    setUpdatedMetadataList(newList);
  };
  const handleContentChange = (index, data) => {
    const newState = deepCopy(updatedMetadataList);
    newState[index] = deepCopy(data);
    setUpdatedMetadataList(newState);
  };

  const memList = useMemo(
    () =>
      updatedMetadataList?.map((item, index) => (
        <EditableRow
          key={`student-success-metadata-${index}`}
          rowData={item}
          index={index}
          handleUpdate={handleContentChange}
          handleDelete={handleDeleteClick}
        />
      )),
    [updatedMetadataList]
  );

  return (
    <Table bordered hover size="sm">
      <thead className="text-dark">
        <tr>
          <th>Student Success Description</th>
          <th>Note</th>
          <th>Image</th>
        </tr>
      </thead>
      <tbody>
        {memList}
        <tr>
          <td colSpan="4" className="text-dark">
            <Button
              size="sm"
              variant="warning rounded-circle btn-icon-only mr-2"
              onClick={() => handleAddClick()}
            >
              <FontAwesomeIcon icon={faPlus} className="text-white" />
            </Button>
            Add another image
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default StudentSuccessMetadataEditor;
