import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { loadProjectDetail, deleteProject } from "../../actions";

const ProjectDeleteForm = ({ projectRef, onSaveSuccess }) => {
  const [projectDetailData, setProjectDetailData] = useState(null);
  const [projectDeleteSuccess, setProjectDeleteSuccess] = useState(null);

  useEffect(() => {
    loadProjectDetail(projectRef, (data) => setProjectDetailData(data));
  }, [projectRef]);

  const handleDelete = (event) => {
    const data = {
      projectRef: projectRef,
    };
    deleteProject(
      data,
      () => {
        setProjectDeleteSuccess(true);
        onSaveSuccess();
      },
      () => setProjectDeleteSuccess(false)
    );
  };

  return (
    <div>
      <p>
        Are you sure you want to delete: "
        {projectDetailData === null ? "" : projectDetailData.title}"?
      </p>
      <br /> <br />
      <Button
        size="md"
        variant="primary"
        type="submit"
        disabled={projectDeleteSuccess}
        onClick={handleDelete}
        block
      >
        Delete
      </Button>
      {projectDeleteSuccess ? <Alert variant="success">Deleted.</Alert> : null}
    </div>
  );
};

export default ProjectDeleteForm;
