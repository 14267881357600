/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:d30088a9-2a26-43f6-bd06-70a65a292f86",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_yr0n0CvnQ",
    "aws_user_pools_web_client_id": "472c80so5afjbni0vigimvh26n",
    "oauth": {},
    "aws_user_files_s3_bucket": "cura-file-bucket151830-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
