import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Form,
  ToggleButtonGroup,
  Row,
  Col,
  InputGroup,
  Table,
} from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import { useDynamicList } from "ahooks";
import { CSVReader } from "react-papaparse";
import { loadCourseExecDetail, createUsers } from "../../actions";
import { MAX_USER_ENROLL_SIZE } from "../../utils/constants";
import { exportUserCSV } from "../../utils/CSVUtils";

const CreateMode = {
  MANUAL: "MANUAL",
  CSV: "CSV",
};

const errorDisplay = (errors, isCSV = false) => {
  return (
    <>
      <Alert variant="danger">
        Oops! Looks like something is wrong. Kindly review and submit again.
        <hr />
        {errors &&
          errors.map((error, index) => {
            if (typeof error === "string" || error instanceof String)
              return error;
            if (error.errorField === "file-extension")
              return "The file must be a .csv file. Please remove and re-upload.";
            else if (error.errorField === "max-length") {
              return (
                "The file is too big. " +
                `The .csv file must be less than ${MAX_USER_ENROLL_SIZE} lines.`
              );
            } else if (error.errorField === "blank") {
              return (
                `Error row ${
                  isCSV ? error.errorIndex + 1 : error.errorIndex
                }: Please check if the firstname, lastname` +
                " or email is blank."
              );
            } else
              return (
                <div key={`error-${index}`}>
                  Error row {isCSV ? error.errorIndex + 1 : error.errorIndex}:
                  {error.errorField === "length"
                    ? ` Incorrect number of fileds in this row.`
                    : ` Incorrect ${error.errorField} ---- ${
                        error[error.errorField]
                      }`}
                  <br />
                </div>
              );
          })}
      </Alert>
    </>
  );
};

const CSVUploader = ({ role, schoolRef, courseExecRef, onSaveSuccess }) => {
  const [fileData, setFileData] = useState(null);
  const [errors, setErrors] = useState(null);

  const handleOnDrop = (data, file) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
    if (!file.name.toLowerCase().endsWith(".csv")) {
      const error = {
        errorField: "file-extension",
      };
      setErrors([error]);
      return;
    }
    // Cannot be more than 100 rows
    if (data && data.length > MAX_USER_ENROLL_SIZE + 1) {
      const error = {
        errorField: "max-length",
      };
      setErrors([error]);
      return;
    }
    setFileData(data);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
    setFileData(null);
    setErrors(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (errors) return;
    setErrors(null);
    if (!fileData) return;
    let userDict = {};
    for (const [i, ele] of fileData.entries()) {
      if (i === 0) continue;
      const row = ele.data;
      // Ignore blank line
      if (row.length === 1 && row[0] === "") continue;
      let result = {};
      if (row.length < 3) {
        const error = {
          errorIndex: i,
          errorField: "length",
        };
        setErrors([error]);
        return;
      }
      if (row[0] === "" || row[1] === "" || row[2] === "") {
        const error = {
          errorIndex: i,
          errorField: "blank",
        };
        setErrors([error]);
        return;
      }
      result.firstName = row[0];
      result.lastName = row[1];
      result.email = row[2].toLowerCase();
      result.roleType = role;
      if (courseExecRef) result.courseExecRef = courseExecRef;
      userDict[result.email] = result;
    }

    const result = {
      schoolRef: schoolRef,
      userList: Object.values(userDict),
    };
    createUsers(
      result,
      (data) => {
        onSaveSuccess();
      },
      (data) => setErrors(data)
    );
  };

  const CSVExample = () => {
    const downloadableTemplateData = [
      {
        user: {
          firstName: "exampleFirstName1",
          lastName: "exampleLastName1",
          email: "exampleEmail1@domain.com",
        },
      },
      {
        user: {
          firstName: "exampleFirstName2",
          lastName: "exampleLastName2",
          email: "exampleEmail2@domain.com",
        },
      },
      {
        user: {
          firstName: "exampleFirstName3",
          lastName: "exampleLastName3",
          email: "exampleEmail3@domain.com",
        },
      },
    ];
    return (
      <>
        <>
          <Alert variant="warning">
            <b>Before uploading your CSV</b>
            <ul>
              <li>
                You must ensure that all rows contain a first name, a surname,
                and an email address. If any of these fields are missing for any
                student, the file will not upload successfully
              </li>
              <li>
                Your CSV must include a heading row in Row 1, as the data will
                only be pulled from Row 2 downwards.{" "}
                <u>
                  Do not put student details in Row 1 as they will not be
                  uploaded
                </u>
              </li>
            </ul>
          </Alert>

          <span className="font-weight-bold text-dark">Example</span>
          <u
            className="text-primary text-xs ml-3 cursor-pointer"
            onClick={() => {
              exportUserCSV("example", downloadableTemplateData);
            }}
          >
            Download Template
          </u>
        </>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>firstname</th>
              <th>lastname</th>
              <th>email</th>
            </tr>
          </thead>
          <tbody className="text-dark">
            <tr>
              <td>John</td>
              <td>Henley</td>
              <td>johnhenley92@emaildomain.com</td>
            </tr>
            <tr>
              <td>Mary</td>
              <td>Thornton</td>
              <td>marythornton@emaildomain.com</td>
            </tr>
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit} className="pt-3">
      <p className="text-dark mb-0">
        Upload a CSV file formatted by <b>firstname, lastname, email</b>
      </p>
      <p className="text-sm">
        Once the upload is successful, you can send invitation emails to notify
        the users.
      </p>
      <CSVReader
        onDrop={handleOnDrop}
        onError={handleOnError}
        addRemoveButton
        onRemoveFile={handleOnRemoveFile}
      >
        <span>Drop CSV file here or click to upload.</span>
      </CSVReader>
      <br />
      <CSVExample />
      <hr />
      {errors && (
        <>
          {errorDisplay(errors, true)}
          <hr />
        </>
      )}
      <Button type="submit" variant="primary" size="md" block disabled={errors}>
        Submit
      </Button>
    </Form>
  );
};

const ManualInputs = ({ role, schoolRef, courseExecRef, onSaveSuccess }) => {
  const [errors, setErrors] = useState(null);
  const { list, remove, getKey, push } = useDynamicList([
    { firstname: null, lastname: null, email: null },
    { firstname: null, lastname: null, email: null },
  ]);
  const CustomRow = (index, item) => (
    <InputGroup key={getKey(index)}>
      <Row className="pb-2">
        <Col xs={3}>
          <Form.Control
            inline="true"
            type="firstname"
            id={`firstname-${getKey(index)}`}
            placeholder="Enter First Name"
            defaultValue={item.firstname}
            required
          />
        </Col>
        <Col xs={3}>
          <Form.Control
            inline="true"
            type="lastname"
            id={`lastname-${getKey(index)}`}
            placeholder="Enter Last Name"
            defaultValue={item.lastname}
            required
          />
        </Col>
        <Col xs={5}>
          <Form.Control
            inline="true"
            type="email"
            id={`email-${getKey(index)}`}
            placeholder="Enter email"
            defaultValue={item.email}
            required
          />
        </Col>
        <Col xs={1}>
          {list.length > 1 && (
            <DashCircle
              className="ml-2 mt-2 cursor-pointer"
              size={30}
              onClick={() => {
                remove(index);
              }}
            />
          )}
        </Col>
      </Row>
    </InputGroup>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    let emails = [];
    let userList = [];
    list.forEach((ele, index) => {
      const key = getKey(index);
      const email = document
        .getElementById(`email-${key}`)
        .value.trim()
        .toLowerCase();

      if (emails.includes(email)) return;
      let result = {};
      result.firstName = document.getElementById(`firstname-${key}`).value;
      result.lastName = document.getElementById(`lastname-${key}`).value;
      result.email = email;
      result.roleType = role;
      if (courseExecRef) result.courseExecRef = courseExecRef;
      userList.push(result);
      emails.push(email);
      return;
    });

    const result = {
      schoolRef: schoolRef,
      userList: userList,
    };
    createUsers(
      result,
      (data) => {
        onSaveSuccess();
      },
      (data) => {
        setErrors(data);
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="mt-3">
        {list.map((ele, index) => CustomRow(index, ele))}
      </div>
      {list.length < 5 && (
        <span
          className="text-dark cursor-pointer"
          onClick={() => {
            push("");
          }}
        >
          <PlusCircle size={30} className="mr-2 my-3" />
          Add Another
        </span>
      )}
      {errors && (
        <>
          {errorDisplay(errors)}
          <hr />
        </>
      )}
      <Button type="submit" variant="primary" className="mt-3" size="md" block>
        Submit
      </Button>
    </Form>
  );
};

const UserCreateForm = ({
  schoolRef,
  courseExecRef,
  role,
  onSaveSuccess,
  handleCloseForm,
}) => {
  const [mode, setMode] = useState(CreateMode.MANUAL);
  const [courseExecDetailData, setCourseExecDetailData] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    courseExecRef &&
      loadCourseExecDetail(courseExecRef, (data) => {
        setCourseExecDetailData(data);
      });
  }, [courseExecRef]);

  const handleSaveSuccess = () => {
    addToast(`All ${role.toLowerCase()}s have been successfully added.`, {
      appearance: "success",
      autoDismiss: true,
    });
    onSaveSuccess();
    handleCloseForm();
  };

  return (
    <>
      {(courseExecDetailData || schoolRef) && (
        <>
          <ToggleButtonGroup type="checkbox" className="mb-2">
            <Button
              variant={mode === CreateMode.MANUAL ? "primary" : "light"}
              value={CreateMode.MANUAL}
              onClick={(e) => setMode(e.currentTarget.value)}
            >
              Manually
            </Button>
            <Button
              variant={mode === CreateMode.CSV ? "primary" : "light"}
              value={CreateMode.CSV}
              onClick={(e) => setMode(e.currentTarget.value)}
            >
              Import CSV
            </Button>
          </ToggleButtonGroup>
          {mode === CreateMode.CSV ? (
            <CSVUploader
              courseExecRef={courseExecRef}
              role={role}
              schoolRef={
                schoolRef ? schoolRef : courseExecDetailData.school.schoolRef
              }
              onSaveSuccess={handleSaveSuccess}
            />
          ) : (
            <ManualInputs
              courseExecRef={courseExecRef}
              role={role}
              schoolRef={
                schoolRef ? schoolRef : courseExecDetailData.school.schoolRef
              }
              onSaveSuccess={handleSaveSuccess}
            />
          )}
        </>
      )}
    </>
  );
};

export default UserCreateForm;
