import React from "react";
import { v4 as uuid } from "uuid";
import { Table } from "antd";
import { TrbStudentResponseStatus } from "../../utils/constants";
import {
  getCellColor,
  getCellText,
  TABLE_COLUMN_HEADER_CLASSNAME,
} from "../../utils/ScoreTableUtils";
import TRBScoreTableLegend from "./TRBScoreTableLegend";
import TRBScoreTableCellContent from "../util/TRBScoreTableCellContent";
import TaskTitlePopup from "../task/TaskTitlePopup";

const getQuestionColumnItem = (i, tableData) => {
  return {
    title: <div className={TABLE_COLUMN_HEADER_CLASSNAME}>{`Q${i + 1}`}</div>,
    key: i,
    align: "center",
    width: "9rem",
    render(text, record) {
      const isBlank = i >= record.taskResourceBlocks.length;
      const cellTaskResourceBlock = isBlank
        ? null
        : record.taskResourceBlocks[i];
      const taskResourceBlockRef = cellTaskResourceBlock
        ? cellTaskResourceBlock.taskResourceBlockRef
        : null;
      const trbWorkDict =
        record.courseTaskLinkRef in tableData.trbStudentResponses
          ? tableData.trbStudentResponses[record.courseTaskLinkRef]
          : {};
      const cellText =
        cellTaskResourceBlock &&
        getCellText(cellTaskResourceBlock, trbWorkDict);

      const mainRecord =
        taskResourceBlockRef in trbWorkDict
          ? trbWorkDict[taskResourceBlockRef]
          : null;
      const shouldAddDot =
        mainRecord &&
        mainRecord.status === TrbStudentResponseStatus.SUBMIT &&
        mainRecord.lastAssessAt &&
        mainRecord.lastSubmitAt > mainRecord.lastAssessAt;

      return {
        props: {
          style: {
            padding: 0,
            background: isBlank
              ? "white"
              : `var(--${getCellColor(mainRecord)})`,
          },
        },
        children: (
          <TRBScoreTableCellContent
            shouldAddDot={shouldAddDot}
            cellText={isBlank ? "-" : cellText}
          />
        ),
      };
    },
  };
};

const UserClassroomProgressTable = ({
  courseExecRef,
  tableData,
  maxQuestionNum,
}) => {
  // range(maxQuestionNum)
  let questions = Array(maxQuestionNum)
    .fill()
    .map((_, i) => getQuestionColumnItem(i, tableData));

  const columns = [
    {
      padding: 0,
      width: "7rem",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: { padding: 0 },
          },
          children: (
            <TaskTitlePopup
              sectionNumber={record.sectionNumber + 1}
              taskNumber={record.seqNumInSection + 1}
              title={record.taskTitle}
              courseTaskLinkRef={record.courseTaskLinkRef}
              courseExecRef={courseExecRef}
            />
          ),
        };
      },
    },
    ...questions,
    {
      title: <div className={TABLE_COLUMN_HEADER_CLASSNAME}>Score</div>,
      width: "8rem",
      dataIndex: "score",
      key: "score",
      fixed: "right",
      align: "center",
      render(text, record) {
        const summaryData = tableData.taskSummaryData[record.courseTaskLinkRef];
        return {
          props: {
            style: { padding: 0 },
          },
          children: summaryData ? (
            <>
              <b className="font-weight-bolder">
                {summaryData.totalAvailableScore !== 0
                  ? Math.round(
                      (summaryData.score / summaryData.totalAvailableScore) *
                        100
                    )
                  : 0}
                %
              </b>{" "}
              ({summaryData.score}/{summaryData.totalAvailableScore})
            </>
          ) : (
            <>- (0/0)</>
          ),
        };
      },
    },
  ];

  return (
    <>
      <div className="float-right mb-4">
        <TRBScoreTableLegend />
      </div>
      <Table
        rowKey={() => uuid()}
        columns={columns}
        dataSource={tableData.curriculumTrbInfo}
        scroll={{ x: "calc(100% - 18rem)", y: 500 }}
        pagination={{ position: ["bottomRight"], pageSize: 25 }}
        bordered
      />
    </>
  );
};
export default UserClassroomProgressTable;
