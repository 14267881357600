import React from "react";
import { Alert, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";

import { deleteUser } from "../../actions";
import AvatarWithText from "../util/AvatarWithText";

const UserDeleteForm = ({ userDetail, onSaveSuccess, handleCloseForm }) => {
  const { addToast } = useToasts();

  const handleSaveSuccess = () => {
    addToast(`The user has been successfully deleted`, {
      appearance: "success",
      autoDismiss: true,
    });
    onSaveSuccess();
    if (handleCloseForm) handleCloseForm();
  };

  const handleSaveFailed = () => {
    addToast(`Something went wrong. Please try again`, {
      appearance: "error",
      autoDismiss: true,
    });
    if (handleCloseForm) handleCloseForm();
  };

  const handleDelete = (event) => {
    const data = {
      userRef: userDetail.id,
    };
    deleteUser(
      data,
      () => handleSaveSuccess(),
      () => handleSaveFailed()
    );
  };

  return (
    <div>
      <Alert variant="warning">
        Are you sure you want to delete this user?
      </Alert>
      <div className="mb-3">
        <AvatarWithText userDetail={userDetail} showEmail={true} />
      </div>
      <br />
      <Button
        size="md"
        variant="primary"
        type="submit"
        onClick={handleDelete}
        block
      >
        Delete
      </Button>
    </div>
  );
};

export default UserDeleteForm;
