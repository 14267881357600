import React, { useEffect, useState } from "react";
import { useCookieState, useInterval } from "ahooks";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { signIn } from "../../actions";
import SignInForm from "./SignInForm";
import { SESSION_EXPIRED, USER_DETAIL } from "../../actions/types";
import OTPSignIn from "./OTPSignIn";
import { ILLUSTRATIONS, MAX_COOKIE_AGE } from "../../utils/constants";
import { setGAUserId } from "../../utils/thirdParty/GoogleAnalyticsUtils";
import SSOSchoolSelectForm from "./SSOSchoolSelectForm";

const SignInModal = (props) => {
  const userDetailData = useSelector((state) => state.auth.userDetail);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const isSessionExpired = useSelector((state) => state.auth.isSessionExpired);

  const { addToast } = useToasts();
  const [isOTPMode, setIsOTPMode] = useState(false);
  const [credential, setCredential] = useState(null);
  const setToken = useCookieState("escs")[1];
  const [tokenExp, setTokenExp] = useCookieState("exp");
  const [isSSOMode, setIsSSOMode] = useState(false);

  useInterval(() => {
    if (!show && new Date() > new Date(tokenExp)) {
      dispatch({ type: SESSION_EXPIRED, payload: true });
    }
  }, 3000);

  useEffect(() => {
    if (props.getShouldClose && props.getShouldClose()) setShow(false);
  }, [props]);

  useEffect(() => {
    if (isSessionExpired) setShow(true);
  }, [isSessionExpired]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const data = {
      username: form.elements.email.value.trim().toLowerCase(),
      password: form.elements.password.value,
    };
    signIn(
      data,
      (data) => {
        if ("action" in data) {
          // Redirect to mfa page
          setCredential(data["token"]);
          setIsOTPMode(true);
          return;
        }
        const exp = new Date(+new Date() + MAX_COOKIE_AGE);
        setToken(data.token, {
          expires: (() => exp)(),
        });
        setTokenExp(exp);
        setGAUserId(data.user.id);

        if (userDetailData.username !== data.user.username) {
          window.location.href = "/";
          return;
        }

        setShow(false);

        dispatch({ type: USER_DETAIL, payload: data.user });
        dispatch({ type: SESSION_EXPIRED, payload: false });
      },
      (data) => {
        addToast("Invalid email or password.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    );
  };

  const SignInModalHeader = () => (
    <div className="mb-4">
      <img src={ILLUSTRATIONS.LOGO.MAIN_LOGO} alt="logo" height="24" />
      <p className="text-danger mb-0 mt-4 text-sm">
        Your session has expired. Please log in again to keep using Cura
      </p>
    </div>
  );

  const handleSwitchSSO = () => {
    setIsSSOMode(true);
  };

  return (
    <Modal
      size="md"
      show={show}
      backdrop={true}
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Body className={props.customisedModalBodyClassName}>
        {show && (
          <>
            <SignInModalHeader />
            {isSSOMode ? null : !isOTPMode ? (
              <SignInForm
                handleSubmit={handleSubmit}
                handleSwitchSSO={handleSwitchSSO}
              />
            ) : (
              <OTPSignIn
                credential={credential}
                handleUserDetailChange={(user) => {
                  // TODO: If not calling setTokenExp again, the popup will show immediately.
                  // Fix to avoid double setting cookie.
                  const exp = new Date(+new Date() + MAX_COOKIE_AGE);
                  setTokenExp(exp);
                  setShow(false);
                  dispatch({ type: USER_DETAIL, payload: user });
                  dispatch({ type: SESSION_EXPIRED, payload: false });
                  setCredential(null);
                  setIsOTPMode(false);
                }}
              />
            )}
            {isSSOMode && <SSOSchoolSelectForm />}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SignInModal;
