import React, { useEffect, useState, useMemo } from "react";
import { Alert, Button, Card, Dropdown } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faPlus,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { PreviewTable } from "./EmptyTableCreator";
import {
  GENERIC_EDITOR_MODES,
  TASK_RESOURCE_BLOCK_TYPE,
} from "../../../utils/constants";
import RichTextEditor from "../../util/RichTextEditor";
import RubricCreator, { PreviewRubric } from "./RubricCreator";
import { Tooltip } from "antd";
import { existsTaskResourceBlockWork } from "../../../actions";
import TableWorkTemplateEditor from "./TableWorkTemplateEditor";

const AddTaskResourceBlockButton = ({
  index,
  handleAddClick,
  showAddRubric,
}) => {
  // deep copy TASK_RESOURCE_BLOCK_TYPE
  const types = JSON.parse(JSON.stringify(TASK_RESOURCE_BLOCK_TYPE));
  if (index === 0 || !showAddRubric) delete types.RUBRIC;
  return (
    <Dropdown className="mb-3 ml-4">
      <Tooltip title="Add a content block">
        <Dropdown.Toggle
          size="xs"
          variant="primary rounded-circle btn-icon-only"
          bsPrefix="p-0"
        >
          <FontAwesomeIcon icon={faPlus} />
        </Dropdown.Toggle>
      </Tooltip>
      <Dropdown.Menu>
        {Object.keys(types).map((type, i) => (
          <Dropdown.Item
            key={`dropdown-item-${i}`}
            onClick={() =>
              handleAddClick(index, TASK_RESOURCE_BLOCK_TYPE[type])
            }
          >
            {TASK_RESOURCE_BLOCK_TYPE[type]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ResourceBlock = ({
  mode,
  blockData,
  shouldShowRubricPositionWarning,
  onUpdate,
  onCancel,
}) => {
  const [updatedBody, setUpdatedBody] = useState(
    blockData.body ? blockData.body : ""
  );
  const [updatedData, setUpdatedData] = useState(blockData.data);
  const [updatedRubric, setUpdatedRubric] = useState(blockData.rubric);

  const [updatedSampleAnswer, setUpdatedSampleAnswer] = useState(
    blockData.sampleAnswer
  );

  const resetStates = () => {
    setUpdatedBody(blockData.body ? blockData.body : "");
    setUpdatedData(blockData.data);
    setUpdatedRubric(blockData.rubric);
    setUpdatedSampleAnswer(blockData.sampleAnswer);
  };

  const DisplayBlock = ({ blockData }) => {
    // Identify if this block has no data
    let isBlockEmpty = false;
    if (
      !blockData.body &&
      !blockData.data &&
      !blockData.rubric &&
      !blockData.sampleAnswer
    )
      isBlockEmpty = true;
    return (
      <>
        {shouldShowRubricPositionWarning && (
          <Alert variant="danger">
            A rubric should be placed after a question, table, or another rubric
            block. Please move this rubric block to the correct position or
            delete it
          </Alert>
        )}
        {isBlockEmpty && (
          <Alert variant="warning">
            This block is empty. Click the edit button to add content.
          </Alert>
        )}
        <div
          className="ck-content"
          dangerouslySetInnerHTML={{
            __html: blockData.body,
          }}
        />
        {blockData.resourceType === TASK_RESOURCE_BLOCK_TYPE.TABLE && (
          <div className="mb-3">
            <PreviewTable
              rows={blockData.data ? blockData.data.rows : []}
              columns={blockData.data ? blockData.data.columns : []}
            />
          </div>
        )}
        {blockData.resourceType !== TASK_RESOURCE_BLOCK_TYPE.CONTENT &&
          blockData.rubric && (
            <div className="mb-3">
              <div className="font-weight-bolder text-dark mb-3">
                Assessment
              </div>
              <PreviewRubric rubric={blockData.rubric} />
            </div>
          )}
        {blockData.resourceType !== TASK_RESOURCE_BLOCK_TYPE.CONTENT &&
          blockData.sampleAnswer && (
            <div className="mb-3">
              <div className="font-weight-bolder text-dark mb-3">
                Exemplar Answer
              </div>
              <div
                className="ck-content"
                dangerouslySetInnerHTML={{
                  __html: blockData.sampleAnswer,
                }}
              />
            </div>
          )}
      </>
    );
  };

  // Return Display mode
  if (mode === GENERIC_EDITOR_MODES.DISPLAY)
    return <DisplayBlock blockData={blockData} />;

  const getCleanRubricData = (updatedRubrics) => {
    if (!updatedRubrics) return updatedRubric;
    return {
      ...updatedRubrics,
      criteria: updatedRubrics.criteria.trim(),
      items: updatedRubrics.items.map((item) => item.trim()),
    };
  };

  // Return Edit mode
  return (
    <>
      {blockData.resourceType !== TASK_RESOURCE_BLOCK_TYPE.RUBRIC && (
        <RichTextEditor
          data={blockData.body ? blockData.body : ""}
          onChange={(text) => {
            setUpdatedBody(text);
          }}
        />
      )}
      {blockData.resourceType === TASK_RESOURCE_BLOCK_TYPE.TABLE && (
        <div className="mb-3">
          <TableWorkTemplateEditor
            blockData={blockData}
            onUpdate={(tableData) =>
              setUpdatedData({ ...updatedData, ...tableData })
            }
          />
        </div>
      )}
      {blockData.resourceType !== TASK_RESOURCE_BLOCK_TYPE.CONTENT && (
        <>
          <hr />
          <h5 className="text-primary mb-3">Rubrics</h5>
          <RubricCreator
            blockData={blockData}
            onUpdate={(rubricData) => setUpdatedRubric(rubricData)}
          />
          <div className="mb-4" />
        </>
      )}
      {![
        TASK_RESOURCE_BLOCK_TYPE.RUBRIC,
        TASK_RESOURCE_BLOCK_TYPE.CONTENT,
      ].includes(blockData.resourceType) && (
        <>
          <hr />
          <h5 className="text-primary mb-3">Exemplar answer</h5>
          <RichTextEditor
            data={blockData.sampleAnswer ? blockData.sampleAnswer : ""}
            onChange={(text) => {
              setUpdatedSampleAnswer(text);
            }}
          />
        </>
      )}

      <Button
        variant="danger"
        onClick={() => {
          // if no changes are made, just close the editor
          if (
            updatedBody === blockData.body &&
            updatedData === blockData.data &&
            updatedRubric === blockData.rubric &&
            updatedSampleAnswer === blockData.sampleAnswer
          ) {
            onCancel();
            return;
          }
          if (
            !window.confirm(
              "You will lose edited data. Are you sure you want to cancel?"
            )
          )
            return;
          // onUpdate(blockData);
          resetStates();
          onCancel();
        }}
      >
        Cancel
      </Button>
      <Button
        className="float-right"
        variant="success"
        onClick={() => {
          if (
            updatedRubric &&
            updatedRubric.totalScore === 0 &&
            !window.confirm(
              "This question is out of 0 points. Is that correct?"
            )
          )
            return;

          onUpdate({
            ...blockData,
            body: updatedBody,
            data: updatedData,
            rubric: getCleanRubricData(updatedRubric),
            sampleAnswer: updatedSampleAnswer,
          });
        }}
      >
        Save & Preview
      </Button>
    </>
  );
};

const UserTaskResourceBlockEditor = ({
  blocks,
  onUpdate,
  handleStartEdit = () => {},
  handleEndEdit = () => {},
}) => {
  const [state, setState] = useState({
    blocksDict: blocks
      ? Object.assign(
          {},
          ...blocks.map((block) => ({ [block.taskResourceBlockRef]: block }))
        )
      : {},
    blockOrder: blocks ? blocks.map((block) => block.taskResourceBlockRef) : [],
  });

  useEffect(() => {
    // deep copy blocks
    const clonedBlocks = JSON.parse(JSON.stringify(blocks));
    setState({
      blocksDict: clonedBlocks
        ? Object.assign(
            {},
            ...clonedBlocks.map((block) => ({
              [block.taskResourceBlockRef]: block,
            }))
          )
        : {},
      blockOrder: clonedBlocks
        ? clonedBlocks.map((block) => block.taskResourceBlockRef)
        : [],
    });
  }, [blocks]);

  // useEffect(() => {
  //   if (isFirstRun.current) {
  //     isFirstRun.current = false;
  //     return;
  //   }
  //   let newBlocksDict = state.blocksDict;
  //   for (let i = 0; i < state.blockOrder.length; i++)
  //     newBlocksDict[state.blockOrder[i]].seqNum = i;
  //   const sortedList = Object.values(newBlocksDict).sort((a, b) =>
  //     a.seqNum < b.seqNum ? -1 : 1
  //   );
  //   onUpdate(sortedList);
  // }, [state]);

  const updateBlocks = (newState, shouldShowPreview = true) => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }
    let newBlocksDict = newState.blocksDict;
    for (let i = 0; i < newState.blockOrder.length; i++)
      newBlocksDict[newState.blockOrder[i]].seqNum = i;
    const sortedList = Object.values(newBlocksDict).sort((a, b) =>
      a.seqNum < b.seqNum ? -1 : 1
    );
    onUpdate(sortedList, shouldShowPreview);
  };

  const handleAddClick = (index, type) => {
    const cardTitle = `${index + 1}. ${type}`;
    if (!handleStartEdit(cardTitle)) {
      return;
    }
    const newBlockId = uuid();
    const newBlock = {
      taskResourceBlockRef: newBlockId,
      title: "New Block",
      seqNum: index,
      data: null,
      body: null,
      rubric: null,
      sampleAnswer: null,
      resourceType: type,
    };
    const newBlocksDict = { ...state.blocksDict, [newBlockId]: newBlock };
    let newBlockOrder = Array.from(state.blockOrder);
    newBlockOrder.splice(index, 0, newBlockId);
    const newState = {
      ...state,
      blockOrder: newBlockOrder,
      blocksDict: newBlocksDict,
    };
    setState(newState);
    updateBlocks(newState, false);
  };

  const handleDeleteClick = (blockId) => {
    const cardTitle = `delete ${blockId}`;
    if (!handleStartEdit(cardTitle)) {
      return;
    }
    const deleteBlock = () => {
      let newBlocksDict = state.blocksDict;
      delete newBlocksDict[blockId];
      let newBlockOrder = Array.from(state.blockOrder);
      newBlockOrder.splice(
        newBlockOrder.findIndex((item) => {
          return item === blockId;
        }),
        1
      );
      const newState = {
        ...state,
        blockOrder: newBlockOrder,
        blocksDict: newBlocksDict,
      };
      setState(newState);
      updateBlocks(newState, false);
    };

    // if the block is a question or a table block, check if there are student submissions
    if (
      [
        TASK_RESOURCE_BLOCK_TYPE.QUESTION,
        TASK_RESOURCE_BLOCK_TYPE.TABLE,
      ].includes(state.blocksDict[blockId].resourceType)
    ) {
      existsTaskResourceBlockWork(
        {
          courseTaskLinkRef: null,
          taskResourceBlockRef: blockId,
        },
        (data) => {
          let existsStudentWork = data.exists;
          if (existsStudentWork) {
            if (
              !window.confirm(
                "Students have submitted work for this question. If you delete it, you will permanently delete these submissions and any feedback/assessment for them\n\nAre you sure you want to delete this question?"
              )
            ) {
              return;
            }
          } else if (
            !window.confirm("Are you sure you want to delete this block?")
          ) {
            return;
          }
          deleteBlock();
        }
      );
    } else {
      if (!window.confirm("Are you sure you want to delete this block?"))
        return;
      deleteBlock();
    }
  };

  const handleContentChange = (block) => {
    const updateContent = () => {
      const newState = {
        ...state,
        blocksDict: {
          ...state.blocksDict,
          [block.taskResourceBlockRef]: block,
        },
      };
      setState(newState);
      updateBlocks(newState);
    };

    const blockId = block.taskResourceBlockRef;

    // if the block is a question or a table block, check if there are student submissions
    if (
      [
        TASK_RESOURCE_BLOCK_TYPE.QUESTION,
        TASK_RESOURCE_BLOCK_TYPE.TABLE,
      ].includes(state.blocksDict[blockId].resourceType)
    ) {
      existsTaskResourceBlockWork(
        {
          courseTaskLinkRef: null,
          taskResourceBlockRef: blockId,
        },
        (data) => {
          let existsStudentWork = data.exists;
          if (existsStudentWork) {
            if (
              !window.confirm(
                "Students have submitted work for this question. If you edit this question, this submitted work may no longer satisfy the question criteria.\n\nAre you sure you want to edit this question?"
              )
            ) {
              return;
            } else {
              updateContent();
            }
          } else {
            updateContent();
          }
        }
      );
    } else {
      updateContent();
    }
  };
  const handleMoveBlock = (blockId, isMovingDown = false) => {
    const cardTitle = `move ${blockId}`;
    if (!handleStartEdit(cardTitle)) {
      return;
    }
    let newBlockOrder = Array.from(state.blockOrder);
    const currentIndex = newBlockOrder.findIndex((item) => item === blockId);
    if (
      (isMovingDown && currentIndex === newBlockOrder.length - 1) ||
      (!isMovingDown && currentIndex === 0)
    )
      return;

    if (isMovingDown)
      [newBlockOrder[currentIndex + 1], newBlockOrder[currentIndex]] = [
        newBlockOrder[currentIndex],
        newBlockOrder[currentIndex + 1],
      ];
    else
      [newBlockOrder[currentIndex - 1], newBlockOrder[currentIndex]] = [
        newBlockOrder[currentIndex],
        newBlockOrder[currentIndex - 1],
      ];

    const newState = {
      ...state,
      blockOrder: newBlockOrder,
    };
    setState(newState);
    updateBlocks(newState, false);
  };

  const SingleBlockEditorCard = ({ block, index, blockId }) => {
    const [mode, setMode] = useState(GENERIC_EDITOR_MODES.DISPLAY);
    const getShouldShowRubricPositionWarning = (block, state) => {
      if (block.resourceType !== TASK_RESOURCE_BLOCK_TYPE.RUBRIC) return false;
      const currentIndex = state.blockOrder.findIndex(
        (item) => item === blockId
      );
      if (currentIndex === 0) return true;
      const previousBlock =
        state.blocksDict[state.blockOrder[currentIndex - 1]];
      return ![
        TASK_RESOURCE_BLOCK_TYPE.RUBRIC,
        TASK_RESOURCE_BLOCK_TYPE.QUESTION,
        TASK_RESOURCE_BLOCK_TYPE.TABLE,
      ].includes(previousBlock.resourceType);
    };
    const cardTitle = `${index + 1}. ${block.resourceType}`;
    return (
      <Card
        className={
          mode === GENERIC_EDITOR_MODES.EDIT
            ? "shadow-lg border-success border-3"
            : ""
        }
      >
        <Card.Body>
          <div className="d-flex justify-content-between mb-3">
            <h5 className="mx-0 my-auto">{cardTitle}</h5>
            <div>
              <Tooltip title={"Edit this section"}>
                <Button
                  size="sm"
                  variant="warning text-white rounded-circle btn-icon-only"
                  disabled={mode === GENERIC_EDITOR_MODES.EDIT}
                  onClick={() => {
                    if (handleStartEdit(cardTitle) === false) {
                      return;
                    }
                    setMode(GENERIC_EDITOR_MODES.EDIT);
                  }}
                >
                  <FontAwesomeIcon icon={faPen} />
                </Button>
              </Tooltip>
              {!(index === 0) && (
                <Tooltip title="Move this block up one">
                  <Button
                    size="sm"
                    variant="primary rounded-circle btn-icon-only"
                    disabled={index === 0}
                    onClick={() => handleMoveBlock(blockId)}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </Button>
                </Tooltip>
              )}
              {!(index === state.blockOrder.length - 1) && (
                <Tooltip title="Move this block down one">
                  <Button
                    size="sm"
                    variant="primary rounded-circle btn-icon-only"
                    disabled={index === state.blockOrder.length - 1}
                    onClick={() => handleMoveBlock(blockId, true)}
                  >
                    <FontAwesomeIcon icon={faArrowDown} />
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Delete this block">
                <Button
                  size="sm"
                  variant="danger rounded-circle btn-icon-only"
                  onClick={() => handleDeleteClick(blockId)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <ResourceBlock
            shouldShowRubricPositionWarning={getShouldShowRubricPositionWarning(
              block,
              state
            )}
            blockData={block}
            onUpdate={(block) => {
              handleContentChange(block);
              handleEndEdit();
            }}
            onCancel={() => {
              setMode(GENERIC_EDITOR_MODES.DISPLAY);
              handleEndEdit();
            }}
            mode={mode}
          />
        </Card.Body>
      </Card>
    );
  };

  const memList = useMemo(
    () =>
      state.blockOrder.map((blockId, index) => {
        const block = state.blocksDict[blockId];
        return (
          <div key={`block-${index}`}>
            <SingleBlockEditorCard
              block={block}
              index={index}
              blockId={blockId}
            />

            <AddTaskResourceBlockButton
              index={index + 1}
              handleAddClick={handleAddClick}
              showAddRubric={[
                TASK_RESOURCE_BLOCK_TYPE.RUBRIC,
                TASK_RESOURCE_BLOCK_TYPE.QUESTION,
                TASK_RESOURCE_BLOCK_TYPE.TABLE,
              ].includes(block.resourceType)}
            />
          </div>
        );
      }),
    [state.blockOrder, state.blocksDict]
  );
  return (
    <>
      <div>
        <AddTaskResourceBlockButton index={0} handleAddClick={handleAddClick} />
        {memList}
      </div>
    </>
  );
};

export default UserTaskResourceBlockEditor;
