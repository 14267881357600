import React, { useEffect } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { loadCurrentUserDetail } from "../../actions";
import { useCookieState } from "ahooks";
import { useDispatch, useSelector } from "react-redux";
import { USER_DETAIL } from "../../actions/types";
import Can from "../../utils/Can";
import { getNameInitials } from "../../utils/GenericUtils";
import SignInModal from "../authentication/SignInModal";
import { ILLUSTRATIONS, RoleType, WEBSITE_LINKS } from "../../utils/constants";
import {
  sendGAGenericEvent,
  setGAUserId,
} from "../../utils/thirdParty/GoogleAnalyticsUtils";
import {
  hasValidPlanData,
  isSubscriptionPlanExpired,
} from "../../utils/DataLogicUtils";
import PlanExpireAlert from "./PlanExpireAlert";
import { QuestionCircle } from "react-bootstrap-icons";
import ProductFruitsUtil from "./ProductFruitsUtil";

const Header = () => {
  const userDetailData = useSelector((state) => state.auth.userDetail);
  const dispatch = useDispatch();

  const setToken = useCookieState("escs")[1];
  const setTokenExp = useCookieState("exp")[1];
  const onHandleLogout = () => {
    setToken(null);
    setTokenExp(null);
    localStorage.clear();
    sessionStorage.clear();
    window.onbeforeunload = null;
    window.location.href = "/";
  };

  const redirectToExpiredPage = (userDetailData) => {
    // If is teacher and subscription is expired
    if (userDetailData?.userInfo.roleTypeName === RoleType.TEACHER)
      if (
        !hasValidPlanData(userDetailData) ||
        isSubscriptionPlanExpired(
          userDetailData.userInfo.planStart,
          userDetailData.userInfo.planEnd
        )
      ) {
        window.location.href = "/subscription-expired";
        return true;
      }
    return false;
  };

  useEffect(() => {
    loadCurrentUserDetail(
      (data) => {
        setGAUserId(data.id);
        if (redirectToExpiredPage(data)) return;
        dispatch({ type: USER_DETAIL, payload: data });
      },
      () => onHandleLogout(),
      true,
      true
    );
  }, []);

  const getNavTitle = () => {
    return (
      <div className="media media-pill align-items-center d-inline-flex">
        <span className="avatar bg-primary-light text-white rounded-circle">
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {getNameInitials(
              userDetailData.firstName + " " + userDetailData.lastName
            )}
          </div>
        </span>
        <div className="ml-2">
          <span className="mb-0 text-sm font-weight-bold">
            {userDetailData.firstName
              ? userDetailData.firstName + " " + userDetailData.lastName
              : userDetailData.email}
          </span>
        </div>
      </div>
    );
  };

  const commonMenuItems = {
    explore: { title: "Explore", url: "/explore" },
    projects: { title: "Projects", url: "/admin/projects" },
    schools: { title: "Schools", url: "/admin/schools" },
    reports: { title: "Reports", url: "/admin/reports" },
    dashboard: { title: "Dashboard", url: "/admin/dashboard" },
    users: { title: "Users", url: "/admin/users" },
  };

  const menuItems = {
    student: [{ title: "My Classrooms", url: "/student/enrolled" }],
    teacher: [
      commonMenuItems.explore,
      { title: "My Courses", url: "/teacher/my-courses" },
    ],
    admin: [
      commonMenuItems.dashboard,
      commonMenuItems.explore,
      commonMenuItems.projects,
      { title: "Task Library", url: "/admin/tasks" },
      commonMenuItems.schools,
      commonMenuItems.users,
      commonMenuItems.reports,
      { title: "SBU Admins", url: "/admin/sbu-admins" },
    ],
    sbuAdmin: [
      commonMenuItems.dashboard,
      commonMenuItems.explore,
      commonMenuItems.projects,
      commonMenuItems.schools,
      commonMenuItems.users,
      commonMenuItems.reports,
    ],
  };

  const HeaderLinks = ({ links }) => {
    return (
      <Nav className="mr-lg-auto">
        {links.map((link, index) => {
          return (
            <Nav.Item key={index}>
              <Nav.Link href={link.url} target={link.target}>
                {link.title}
              </Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>
    );
  };
  return (
    userDetailData && (
      <div className="top-menu d-flex justify-content-center bg-primary">
        <Can
          perform="header:teacher"
          yes={() => <ProductFruitsUtil userDetailData={userDetailData} />}
          no={() => null}
        />
        {hasValidPlanData(userDetailData) && (
          <PlanExpireAlert userDetailData={userDetailData} />
        )}
        <SignInModal />
        <Navbar
          bg="primary"
          variant="dark"
          expand="lg"
          className="navbar-border w-100 pl-4"
        >
          <Navbar.Brand href="/">
            <img
              style={{
                marginBottom: "0.15rem",
                filter: "brightness(1000%) saturate(1000%)",
              }}
              src={ILLUSTRATIONS.LOGO.MAIN_LOGO}
              alt="cura"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Can
              perform="header:student"
              yes={() => <HeaderLinks links={menuItems.student} />}
              no={() => null}
            />
            <Can
              perform="header:teacher"
              yes={() => <HeaderLinks links={menuItems.teacher} />}
              no={() => null}
            />
            <Can
              perform="header:admin"
              yes={() => <HeaderLinks links={menuItems.admin} />}
              no={() => null}
            />
            <Can
              perform="header:sbuAdmin"
              yes={() => <HeaderLinks links={menuItems.sbuAdmin} />}
              no={() => null}
            />
            <NavDropdown.Divider />
            {/* Use mr if other nav links are needed */}
            <Nav className="ml-lg-auto">
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => <Tooltip {...props}>Help Centre</Tooltip>}
                placement="bottom"
              >
                <a
                  href={WEBSITE_LINKS.HELP}
                  target="_blank"
                  className="d-none d-lg-block p-0 text-white my-auto"
                  rel="noreferrer"
                  onClick={() => {
                    sendGAGenericEvent(userDetailData.id, "visit-help", null);
                  }}
                >
                  <QuestionCircle size={30} className="pb-1" />
                </a>
              </OverlayTrigger>
              <NavDropdown className="removecaret" title={getNavTitle()}>
                <NavDropdown.Item href="/profile">
                  Edit Profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={WEBSITE_LINKS.HELP}
                  target="_blank"
                  onClick={() => {
                    sendGAGenericEvent(userDetailData.id, "visit-help", null);
                  }}
                >
                  Help Centre
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={onHandleLogout}>
                  Log Out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  );
};

export default Header;
