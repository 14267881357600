import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/util/Header";
import CourseEditorView from "../../components/course/CourseEditorView";

const CourseEditorPage = () => {
  const { courseRef } = useParams();
  return (
    <>
      <Header />
      <CourseEditorView courseRef={courseRef} />
    </>
  );
};

export default CourseEditorPage;
