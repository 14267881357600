import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { listUserCourseExecLink } from "../../actions";

const ClassroomUserDropdown = ({ role, courseExecRef, handleSelect }) => {
  const { Option } = Select;
  const [courseExecUserData, setcourseExecUserData] = useState(null);

  useEffect(() => {
    const data = {
      courseExecRef: courseExecRef,
      roleTypeName: role,
    };
    listUserCourseExecLink(data, (data) => {
      setcourseExecUserData(data);
      if (data && data.length > 0) {
        handleSelect(data[0].user.id);
      }
    });
  }, []);

  return (
    courseExecUserData && (
      <Select
        showSearch
        placeholder="Select a user"
        optionFilterProp="children"
        onChange={handleSelect}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={
          courseExecUserData && courseExecUserData.length > 0
            ? courseExecUserData[0].user.id
            : null
        }
      >
        {courseExecUserData.map((userCourseExecLink) => {
          return (
            <Option
              key={userCourseExecLink.user.id}
              value={userCourseExecLink.user.id}
            >
              {`${userCourseExecLink.user.firstName} ${userCourseExecLink.user.lastName} (${userCourseExecLink.user.email})`}
            </Option>
          );
        })}
      </Select>
    )
  );
};

export default ClassroomUserDropdown;
