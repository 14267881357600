import React from "react";
import { useSelector } from "react-redux";
import { Alert, OverlayTrigger, Popover } from "react-bootstrap";
import { InfoCircleOutlined } from "@ant-design/icons";
import ModalButton from "./ModalButton";
import Can from "../../utils/Can";
import {
  EXPLORE_PAGE_MODES,
  GA_ACTIONS,
  USER_PLAN_TYPES,
} from "../../utils/constants";
import { sendGAExploreEvent } from "../../utils/thirdParty/GoogleAnalyticsUtils";
import { NonSchoolUserCreateClassroomAlert } from "../classroom/NonSchoolUserCreateClassroomAlert";
import { PreviewUserEditCourseAlert } from "../classroom/PreviewUserEditCourseAlert";
import UserCourseDuplicateForm from "../createEditForms/UserCourseDuplicateForm";

// TODO: Fix to make it generic
const FixedTopAlert = (props) => {
  const BasicAlert = ({ className }) => (
    <div
      className={className}
      style={{
        position: "fixed",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 10,
      }}
    >
      <div className="d-flex text-center w-100">
        {props.isInTaskDetail && (
          <div
            className="h-100 d-lg-block d-none"
            style={{ paddingLeft: "24rem" }}
          ></div>
        )}
        {props.children}
      </div>
    </div>
  );

  return (
    <>
      {props.isInTaskDetail ? (
        <>
          <BasicAlert className="d-none d-sm-block" />
          <BasicAlert className="d-sm-none bottom-0 rounded" />
        </>
      ) : (
        <BasicAlert />
      )}
    </>
  );
};

const GenericAlertComponent = (props) => (
  <Alert
    variant="warning text-dark font-weight-bolder px-4 py-1 py-sm-3 mb-0"
    style={{
      display: "flex",
      alignItems: "center",
      borderRadius: 0,
      borderBottomLeftRadius: 25,
      borderBottomRightRadius: 25,
      width: props.width,
    }}
  >
    {props.children}
  </Alert>
);

export const FixedCoursePreviewAlert = ({ courseRef }) => {
  const userId = useSelector((state) => state.auth.userDetail?.id);
  const userSchool = useSelector((state) => state.auth.userDetail?.school);
  const isPreviewUser = useSelector(
    (state) =>
      state.auth.userDetail?.userInfo.plan === USER_PLAN_TYPES.PREVIEW.value
  );
  return (
    <FixedTopAlert isInTaskDetail={true}>
      <GenericAlertComponent width="38rem">
        <div className="d-block w-100">
          <div className="d-block d-sm-inline">
            This is a preview. To use it with your students,
          </div>
          <ModalButton
            variant="warning text-white ml-2 font-weight-bolder"
            className="rounded-pill"
            buttonText="Use Course"
            modalTitle="Use Course"
            size="xs"
            handleButtonClick={() =>
              sendGAExploreEvent(
                userId,
                GA_ACTIONS.EXPLORE_SEARCH.CLICK_CREATE_CLASSROOM,
                courseRef,
                EXPLORE_PAGE_MODES.PREVIEW
              )
            }
          >
            {userSchool ? (
              <UserCourseDuplicateForm
                mode="create"
                courseRef={courseRef}
                fromPage="COURSE_PREVIEW"
                onSaveSuccess={(data) => {
                  sendGAExploreEvent(
                    userId,
                    GA_ACTIONS.EXPLORE_SEARCH.CREATE_CLASSROOM_PREVIEW,
                    courseRef,
                    EXPLORE_PAGE_MODES.PREVIEW
                  );
                  const courseExecRef = data.courseRef;
                  setTimeout(() => {
                    window.open(`/classroom-detail/${courseExecRef}`, "_blank");
                  }, 1500);
                }}
              />
            ) : (
              <NonSchoolUserCreateClassroomAlert />
            )}
          </ModalButton>
          or
          <ModalButton
            variant="warning text-white ml-2 font-weight-bolder"
            className="rounded-pill"
            buttonText="Edit Course"
            modalTitle="Edit Course"
            size="xs"
            handleButtonClick={() =>
              sendGAExploreEvent(
                userId,
                GA_ACTIONS.EXPLORE_SEARCH.CLICK_CREATE_CLASSROOM,
                courseRef,
                EXPLORE_PAGE_MODES.PREVIEW
              )
            }
          >
            {isPreviewUser ? (
              <PreviewUserEditCourseAlert />
            ) : (
              userSchool && (
                <UserCourseDuplicateForm
                  mode="create"
                  courseRef={courseRef}
                  fromPage="COURSE_PREVIEW"
                  onSaveSuccess={(data) => {
                    sendGAExploreEvent(
                      userId,
                      GA_ACTIONS.EXPLORE_SEARCH.CREATE_CLASSROOM_PREVIEW,
                      courseRef,
                      EXPLORE_PAGE_MODES.PREVIEW
                    );
                    const courseExecRef = data.courseRef;
                    setTimeout(() => {
                      window.open(
                        `/classroom-detail/${courseExecRef}`,
                        "_blank"
                      );
                    }, 1500);
                  }}
                />
              )
            )}
          </ModalButton>
        </div>
      </GenericAlertComponent>
    </FixedTopAlert>
  );
};

export const FixedClassroomArchiveAlert = ({ isInTaskDetail = false }) => {
  return (
    <FixedTopAlert isInTaskDetail={isInTaskDetail}>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="bottom"
        overlay={
          <Popover>
            <Popover.Content className="text-dark">
              <Can
                perform="enrolled:student"
                yes={() => (
                  <>
                    <p>
                      You can continue to view the content and student work
                      within an archived course.
                    </p>
                    But you will not be able to add any work to it.
                  </>
                )}
                no={() => (
                  <>
                    <p>
                      You can continue to view the content and student work
                      within an archived course.
                    </p>
                    But you will not be able to edit it or add students to it.
                    Students won't be able to add any work to it.
                  </>
                )}
              />
            </Popover.Content>
          </Popover>
        }
      >
        <Alert
          variant="warning text-dark px-4 py-1 py-sm-3"
          style={{
            display: "flex",
            alignItems: "center",
            borderRadius: 0,
            borderBottomLeftRadius: 25,
            borderBottomRightRadius: 25,
            width: "18rem",
          }}
        >
          This course has been archived{" "}
          <InfoCircleOutlined
            className="ml-2 text-warning"
            style={{ marginBottom: "0.16rem" }}
          />
        </Alert>
      </OverlayTrigger>
    </FixedTopAlert>
  );
};
