import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import {
  loadUserCourseExecLinkDetail,
  deleteUserCourseExecLink,
} from "../../actions";

const UserCourseExecLinkDeleteForm = ({
  userCourseExecLinkRef,
  onSaveSuccess,
}) => {
  const [linkDetailData, setLinkDetailData] = useState(null);
  const [linkDeleteSuccess, setLinkDeleteSuccess] = useState(null);

  useEffect(() => {
    loadUserCourseExecLinkDetail(userCourseExecLinkRef, (data) =>
      setLinkDetailData(data)
    );
  }, [userCourseExecLinkRef]);

  const handleDelete = (event) => {
    deleteUserCourseExecLink(
      [userCourseExecLinkRef],
      () => {
        setLinkDeleteSuccess(true);
        onSaveSuccess();
      },
      () => setLinkDeleteSuccess(false)
    );
  };

  return (
    <div>
      <p>
        Are you sure you want to unenrol: "
        {linkDetailData === null ? "" : linkDetailData.user.email}"?
      </p>
      <br /> <br />
      <Button
        size="md"
        variant="primary"
        type="submit"
        disabled={linkDeleteSuccess}
        onClick={handleDelete}
        block
      >
        Delete
      </Button>
      {linkDeleteSuccess ? <Alert variant="success">Deleted.</Alert> : null}
    </div>
  );
};

export default UserCourseExecLinkDeleteForm;
