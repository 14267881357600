import React, { useState, useEffect } from "react";
import { Form, Table } from "react-bootstrap";

const TableWorkEditor = ({ block, defaultData, handleChange, editable }) => {
  const [updatedTableData, setUpdatedTableData] = useState(defaultData);
  useEffect(() => {
    if (!defaultData) {
      setUpdatedTableData(
        Array.from({
          length: block.data.rows.length,
        }).map((x) =>
          Array.from({ length: block.data.columns.length - 1 }).map((x) => "")
        )
      );
    }
  }, []);

  const cellDisplayStyle = {
    fontSize: "1rem",
    whiteSpace: "pre-wrap",
    verticalAlign: "middle",
    text: "black",
  };

  const updateCellState = (val, row, col) => {
    updatedTableData[row][col] = val;
    setUpdatedTableData(updatedTableData);
    handleChange(updatedTableData);
  };

  return (
    updatedTableData && (
      <Table bordered className="text-dark bg-white">
        <thead>
          <tr className="bg-gray-200 text-center">
            {block.data.columns.map((val, index) => (
              <th
                style={cellDisplayStyle}
                key={`column-${index}`}
                className="border-white"
              >
                <b>{val}</b>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {block.data.rows.map((val, rowIndex) => (
            <tr key={`row-${rowIndex}`}>
              <td
                className="bg-gray-200 border-white text-center font-weight-bold"
                style={cellDisplayStyle}
              >
                <b>{val}</b>
              </td>
              {block.data.columns.map((val, columnIndex) => {
                if (columnIndex === 0) return null;
                if (!editable)
                  return (
                    <td
                      style={cellDisplayStyle}
                      key={`cell-${rowIndex}-${columnIndex - 1}`}
                    >
                      {updatedTableData[rowIndex][columnIndex - 1]}
                    </td>
                  );

                return (
                  <td
                    className="p-0"
                    style={cellDisplayStyle}
                    key={`cell-${rowIndex}-${columnIndex - 1}`}
                  >
                    <Form.Control
                      rows={5}
                      className="p-1 border-0 text-dark"
                      as="textarea"
                      placeholder="Type your answers here"
                      style={{ resize: "none" }}
                      defaultValue={updatedTableData[rowIndex][columnIndex - 1]}
                      onChange={(event) =>
                        updateCellState(
                          event.target.value,
                          rowIndex,
                          columnIndex - 1
                        )
                      }
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  );
};

export default TableWorkEditor;
