import React, { useEffect, useState } from "react";
import { Button, Divider, Modal, Popconfirm, Switch, Table } from "antd";
import Select from "react-select";
import { Alert } from "react-bootstrap";
import {
  bulkCreateSchoolPurchasedCourseLink,
  createSchoolPurchasedCourseLink,
  deleteSchoolPurchasedCourseLinks,
  getAllSchoolPurchasedCourseLinks,
  loadSearchableCourseList,
  updateSchool,
} from "../../../actions";
import {
  AWS_REGION_COUNTRY_MAP,
  COUNTRY_INFO,
  PURCHASE_MODEL_TYPE,
} from "../../../utils/constants";
import { useToasts } from "react-toast-notifications";
import { handleGenericSaveSuccess } from "../../../utils/CreateEditFormUtils";
import ProjectListDropDown from "../../project/ProjectListDropdown";
import CourseListDropDown from "../../course/CourseListDropdown";
import dayjs from "dayjs";
import { CopyOutlined } from "@ant-design/icons";

const REGION_STR = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];
const YEAR_LEVEL_PREFIX = COUNTRY_INFO[REGION_STR].yearLevelPrefix;

export const getGenericReactSelectOption = (value, label) => ({
  value: value,
  label: label ? label : value,
});

const AddFilterModalButton = ({ schoolDetailData }) => {
  const INIT_FILTER = {
    // Generate a unique key
    key: Math.random().toString(36).substring(7),
    levels: [],
    subject: [],
  };
  const [currentFilter, setCurrentFilter] = useState(INIT_FILTER);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (
      currentFilter.levels.length === 0 &&
      currentFilter.subject.length === 0
    ) {
      alert("Please select a subject and/or a year level");
      return;
    }
    setIsModalOpen(false);
    const data = {
      ...schoolDetailData,
      purchasedCourseMetadata: {
        courseFilters: [
          ...(schoolDetailData.purchasedCourseMetadata?.courseFilters || []),
          currentFilter,
        ],
      },
    };
    updateSchool(
      data,
      () => {
        // refresh the page
        window.location.reload();
      },
      () => alert("Failed to save")
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button className="mb-3" type="primary" onClick={showModal}>
        Add a course filter
      </Button>
      <Modal
        title="Choose a course filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          className="my-3"
          placeholder="All Subjects"
          options={COUNTRY_INFO[REGION_STR].subjects.map((subject) =>
            getGenericReactSelectOption(subject)
          )}
          onChange={(item) => {
            setCurrentFilter({
              ...currentFilter,
              subject: [item.value],
            });
          }}
        />
        <Select
          placeholder={`All ${YEAR_LEVEL_PREFIX} Levels`}
          isMulti
          options={COUNTRY_INFO[REGION_STR].levels.map((level) =>
            getGenericReactSelectOption(level, YEAR_LEVEL_PREFIX + " " + level)
          )}
          onChange={(items) => {
            setCurrentFilter({
              ...currentFilter,
              levels: items.map((item) => item.value),
            });
          }}
        />
      </Modal>
    </>
  );
};

const BulkAddByFilterModalButton = ({ schoolDetailData }) => {
  const [courseList, setCourseList] = useState(null);
  const INIT_FILTER = {
    levels: [],
    subject: [],
  };
  const [currentFilter, setCurrentFilter] = useState(INIT_FILTER);
  const [filteredCourseList, setFilteredCourseList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadSearchableCourseList(null, (data) => {
      const courseList = {
        courses: data.courses.filter(
          (course) =>
            course.countryMetadata &&
            AWS_REGION_COUNTRY_MAP[window._env_["REGION"]] in
              course.countryMetadata
        ),
        projectDict: data.projectDict,
      };
      setCourseList(courseList);
    });
  }, []);

  useEffect(() => {
    if (!courseList) return;
    const courseFilterFunction = (item, filter) => {
      let result = true;
      if (filter?.levels?.length > 0) {
        result =
          result &&
          filter.levels.some((level) =>
            item.countryMetadata[REGION_STR].levels.includes(level)
          );
      }
      if (filter?.subject?.length > 0) {
        result =
          result &&
          filter.subject.some((subject) =>
            item.countryMetadata[REGION_STR].subject.includes(subject)
          );
      }
      return result;
    };
    const filteredCourseList = courseList.courses.filter(
      (course) =>
        courseFilterFunction(course, currentFilter) &&
        !schoolDetailData.purchasedCourseMetadata?.courseFilters?.some(
          (filter) => courseFilterFunction(course, filter)
        )
    );
    setFilteredCourseList(filteredCourseList);
  }, [currentFilter, courseList, schoolDetailData]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (
      currentFilter.levels.length === 0 &&
      currentFilter.subject.length === 0
    ) {
      alert("Please select a subject and/or a year level");
      return;
    }
    setIsModalOpen(false);
    const data = {
      schoolRef: schoolDetailData.schoolRef,
      courseRefs: filteredCourseList.map((course) => course.courseRef),
    };
    bulkCreateSchoolPurchasedCourseLink(
      data,
      () => {
        // refresh the page
        window.location.reload();
      },
      () => alert("Failed to save")
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button className="mb-3" type="primary" onClick={showModal}>
        <CopyOutlined /> Bulk add courses
      </Button>
      <Modal
        title="Bulk add courses by filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          className="my-3"
          placeholder="All Subjects"
          options={COUNTRY_INFO[REGION_STR].subjects.map((subject) =>
            getGenericReactSelectOption(subject)
          )}
          allowClear
          onChange={(item) => {
            setCurrentFilter({
              ...currentFilter,
              subject: [item.value],
            });
          }}
        />
        <Select
          placeholder={`All ${YEAR_LEVEL_PREFIX} Levels`}
          isMulti
          options={COUNTRY_INFO[REGION_STR].levels.map((level) =>
            getGenericReactSelectOption(level, YEAR_LEVEL_PREFIX + " " + level)
          )}
          allowClear
          onChange={(items) => {
            setCurrentFilter({
              ...currentFilter,
              levels: items.map((item) => item.value),
            });
          }}
        />
        <Divider />
        <Table
          rowClassName={() => "editable-row"}
          bordered
          dataSource={filteredCourseList}
          columns={[
            {
              title: `Selected Courses (${filteredCourseList.length})`,
              dataIndex: "title",
              render: (_, record) => {
                // find the project in the projectDict using the projectRef
                const project = courseList.projectDict[record.projectRef];
                return `${project.title}`;
              },
            },
          ]}
        />
      </Modal>
    </>
  );
};

const CourseFilterTable = ({ schoolDetail }) => {
  const [dataSource, setDataSource] = useState(
    schoolDetail.purchasedCourseMetadata?.courseFilters || []
  );
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    const data = {
      ...schoolDetail,
      purchasedCourseMetadata: {
        courseFilters: newData,
      },
    };
    updateSchool(
      data,
      () => {
        // refresh the page
        window.location.reload();
      },
      () => alert("Failed to save")
    );
  };
  const defaultColumns = [
    {
      title: "Subject",
      dataIndex: "subject",
    },
    {
      title: "Year Levels",
      dataIndex: "level",
      render: (_, record) => {
        return record.levels.join(", ");
      },
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <a className="text-primary">Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div>
      <AddFilterModalButton schoolDetailData={schoolDetail} />
      <Table
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

const AddSchoolPurchasedCourseLinkModalButton = ({ schoolDetail }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProjectRef, setSelectedProjectRef] = useState(null);
  const [selectedCourseRef, setSelectedCourseRef] = useState(null);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    if (!selectedCourseRef) {
      alert("Please select a course");
      return;
    }
    const data = {
      schoolRef: schoolDetail.schoolRef,
      courseRef: selectedCourseRef,
    };
    createSchoolPurchasedCourseLink(
      data,
      () => {
        // refresh the page
        window.location.reload();
      },
      () => alert("Failed to save")
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button className="my-3 mr-3" type="primary" onClick={showModal}>
        Add an individual course
      </Button>

      <BulkAddByFilterModalButton schoolDetailData={schoolDetail} />
      <Modal
        title="Choose a course filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="mb-3" />
        <ProjectListDropDown
          onProjectChange={(data) => {
            setSelectedProjectRef(data);
          }}
        />
        <div className="mb-3" />
        <CourseListDropDown
          projectRef={selectedProjectRef}
          onCourseChange={(data) => {
            setSelectedCourseRef(data);
          }}
        />
      </Modal>
    </>
  );
};

const CourseTable = ({ schoolDetail, schoolPurchasedCourses }) => {
  const handleDelete = (key) => {
    deleteSchoolPurchasedCourseLinks(
      { schoolPurchasedCourseLinkRefs: [key] },
      () => {
        // refresh the page
        window.location.reload();
      },
      () => alert("Failed to save")
    );
  };
  const defaultColumns = [
    {
      title: "Course Name",
      dataIndex: "courseName",
      sorter: {
        compare: (a, b) => {
          return (b.course.title || "").localeCompare(a.course.title || "");
        },
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return record.course.title;
      },
    },
    {
      title: "Course Version",
      dataIndex: "courseVersion",
      render: (_, record) => {
        return record.course.description;
      },
    },
    {
      title: "Date Added",
      dataIndex: "createAt",
      render: (_, record) => {
        return dayjs(record.createAt).format("YYYY-MM-DD HH:mm:ss");
      },
      sorter: {
        compare: (a, b) => {
          return dayjs(a.createAt)
            .format("YYYY-MM-DD HH:mm:ss")
            .localeCompare(dayjs(b.createAt).format("YYYY-MM-DD HH:mm:ss"));
        },
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "",
      dataIndex: "action",
      render: (_, record) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record.schoolPurchasedCourseLinkRef)}
        >
          <a className="text-primary">Delete</a>
        </Popconfirm>
      ),
    },
  ];
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });
  return (
    <div>
      <AddSchoolPurchasedCourseLinkModalButton schoolDetail={schoolDetail} />
      <Table
        rowClassName={() => "editable-row"}
        bordered
        dataSource={schoolPurchasedCourses}
        columns={columns}
      />
    </div>
  );
};

const SchoolCoursePanel = ({ schoolRef }) => {
  const [schoolPurchasedCourses, setSchoolPurchasedCourses] = useState([]);
  const [schoolDetail, setSchoolDetail] = useState(null);
  const { addToast } = useToasts();
  useEffect(() => {
    if (!schoolRef) return;
    const data = {
      schoolRef,
    };
    getAllSchoolPurchasedCourseLinks(data, (data) => {
      setSchoolDetail(data.schoolDetail);
      // use data.links but add a key to each item where key is the schoolPurchasedCourseLinkRef
      data.links.forEach((item) => {
        item.key = item.schoolPurchasedCourseLinkRef;
      });
      setSchoolPurchasedCourses(data.links);
    });
  }, [schoolRef]);
  const onChange = (checked) => {
    handleUpdateSchoolPurchaseModel(
      checked
        ? PURCHASE_MODEL_TYPE.COURSE_PURCHASE
        : PURCHASE_MODEL_TYPE.DEFAULT
    );
  };

  const handleSaveSuccess = (data) => {
    setSchoolDetail(data);
    handleGenericSaveSuccess(
      addToast,
      { object: "school", operation: "saved" },
      () => {}
      // handleCloseForm
    );
  };

  const handleUpdateSchoolPurchaseModel = async (purchaseModel) => {
    const data = {
      ...schoolDetail,
      purchaseModel,
    };
    data.schoolRef = schoolRef;
    updateSchool(
      data,
      () => handleSaveSuccess(data)
      // () => handleSaveFailed()
    );
  };

  return schoolDetail ? (
    <div>
      <Alert
        variant={
          schoolDetail.purchaseModel === PURCHASE_MODEL_TYPE.COURSE_PURCHASE
            ? "success"
            : "secondary"
        }
      >
        <Alert.Heading>
          Individual Unit Purchases
          <span className="ml-3">
            <Switch
              color="danger"
              checkedChildren="Allowed"
              unCheckedChildren="Disabled"
              defaultChecked={
                schoolDetail.purchaseModel ===
                PURCHASE_MODEL_TYPE.COURSE_PURCHASE
              }
              onChange={onChange}
            />
          </span>
        </Alert.Heading>
        <p>
          This is where you can manage the courses that the school has purchased
        </p>
      </Alert>
      {schoolDetail.purchaseModel === PURCHASE_MODEL_TYPE.COURSE_PURCHASE && (
        <>
          <CourseFilterTable schoolDetail={schoolDetail} />
          <Divider />
          <CourseTable
            schoolDetail={schoolDetail}
            schoolPurchasedCourses={schoolPurchasedCourses}
          />
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default SchoolCoursePanel;
