import React from "react";
import { Alert } from "react-bootstrap";

export const PreviewUserEditCourseAlert = () => (
  <Alert variant="warning">
    <p>
      To edit this course, you'll need to{" "}
      <b>trigger your extended trial access.</b>
    </p>
    <p>
      You currently have 14-day preview access. You can extend this with a{" "}
      <b>free, 90-day trial</b>. This gives you more time to test Cura with your
      classrooms. It will also allow you to edit this (and other) units
    </p>
    <p>
      To request an extended trial, email us at{" "}
      <a href="mailto:support@curaeducation.com">
        <u>support@curaeducation.com</u>
      </a>
    </p>
  </Alert>
);
