import React from "react";
import { Navbar, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  GA_ACTIONS,
  ILLUSTRATIONS,
  WEBSITE_LINKS,
} from "../../utils/constants";
import {
  sendGAExploreEvent,
  sendGAGenericEvent,
} from "../../utils/thirdParty/GoogleAnalyticsUtils";
import { QuestionCircle } from "react-bootstrap-icons";

const PublicHeader = () => {
  return (
    <div className="top-menu d-flex justify-content-center bg-primary">
      <Navbar
        bg="primary"
        variant="dark"
        expand="lg"
        className="navbar-border w-100 pl-4"
      >
        <Navbar.Brand href={WEBSITE_LINKS.ROOT} className="mr-0">
          <img
            style={{
              marginBottom: "0.15rem",
              filter: "brightness(1000%) saturate(1000%)",
            }}
            src={ILLUSTRATIONS.LOGO.MAIN_LOGO}
            alt="cura"
          />
        </Navbar.Brand>
        <div className="ml-auto">
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => <Tooltip {...props}>Help Centre</Tooltip>}
            placement="bottom"
          >
            <a
              href={WEBSITE_LINKS.HELP}
              target="_blank"
              className="p-0 text-white my-auto d-none d-sm-inline mr-3"
              rel="noreferrer"
              onClick={() => {
                sendGAGenericEvent(null, "visit-help", null);
              }}
            >
              <QuestionCircle size={24} />
            </a>
          </OverlayTrigger>
          <Button
            className="mr-3 my-auto rounded-pill"
            variant="outline-light"
            size="sm"
            onClick={() => {
              sendGAExploreEvent(
                null,
                GA_ACTIONS.EXPLORE_SEARCH.CLICK_SIGNIN,
                null,
                "Header"
              );
              window.location.href = "/";
            }}
          >
            <span className="font-weight-bolder">Sign in</span>
          </Button>

          <Button
            className="my-auto text-light rounded-pill"
            variant="warning"
            size="sm"
            onClick={() => {
              sendGAExploreEvent(
                null,
                GA_ACTIONS.EXPLORE_SEARCH.CLICK_SIGNUP,
                null,
                "Header"
              );
              window.location.href = "/signup";
            }}
          >
            <span className="font-weight-bolder">
              Start <span className="d-none d-sm-inline">my </span>free trial
            </span>
          </Button>
        </div>
      </Navbar>
    </div>
  );
};

export default PublicHeader;
