import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LikeFilled, DislikeFilled } from "@ant-design/icons";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import {
  createTaskFeedback,
  createUpdateUserTaskFeedback,
  getTaskFeedback,
} from "../../actions";
import {
  RoleType,
  STUDENT_TASK_FEEDBACK_REASONS,
  TEACHER_TASK_FEEDBACK_REASONS,
} from "../../utils/constants";
import Can from "../../utils/Can";
import { getNameInitials } from "../../utils/GenericUtils";
import ClassroomUserDropdown from "../classroom/ClassroomUserDropdown";

const TaskFeedbackCreateForm = ({
  courseExecRef,
  courseTaskLinkRef,
  taskDetailData,
  defaultRating,
  onSaveSuccess,
  onSaveFailed,
  handleCloseForm,
}) => {
  const [rating, setRating] = useState(defaultRating);
  const [selectedUser, setSelectedUser] = useState(null);
  const [feedbackData, setFeedbackData] = useState(null);
  const userDetailData = useSelector((state) => state.auth.userDetail);

  useEffect(() => {
    if (!selectedUser) return;
    const data = {
      userRef: selectedUser,
      courseExecRef: courseExecRef,
      courseTaskLinkRef: courseTaskLinkRef,
    };
    getTaskFeedback(data, (data) => {
      setFeedbackData(data);
      if (data) setRating(data.rating);
      else setRating(null);
    });
  }, [selectedUser, courseExecRef, courseTaskLinkRef]);

  const getReasonsArray = (checkboxElements) => {
    let reasonArray = [];
    checkboxElements.forEach((reason) =>
      reason.checked ? reasonArray.push(reason.value) : null
    );
    return reasonArray;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const reasonArray = getReasonsArray(form.elements.reasons);
    let data = {
      courseExecRef: courseExecRef,
      courseTaskLinkRef: courseTaskLinkRef,
      rating: rating,
      comment: form.elements.comment ? form.elements.comment.value : "",
      body: {
        reasons: reasonArray,
      },
    };
    if (selectedUser) {
      // Edit api
      data.userRef = selectedUser;
      createUpdateUserTaskFeedback(
        data,
        () => {
          onSaveSuccess();
          handleCloseForm();
        },
        () => {
          if (onSaveFailed) onSaveFailed();
        }
      );
    } else
      createTaskFeedback(
        data,
        () => {
          onSaveSuccess();
        },
        () => {
          if (onSaveFailed) onSaveFailed();
        }
      );
  };

  const ThumbRatingButton = ({ active, onClick, rating = 1 }) => {
    const iconStyle = {
      fontSize: "3rem",
      display: "table-cell",
      verticalAlign: "middle",
      color: "#d6d6d6",
    };
    return (
      <div
        className="border shadow rounded-circle p-2 my-5 mx-3 text-center cursor-pointer"
        style={{ height: "9rem", width: "9rem", display: "table" }}
        onClick={onClick}
      >
        {rating > 0 ? (
          <LikeFilled
            className={active ? "text-success" : ""}
            style={iconStyle}
          />
        ) : (
          <DislikeFilled
            className={active ? "text-danger" : ""}
            style={iconStyle}
          />
        )}
      </div>
    );
  };

  const ColoredCheckbox = ({
    inputName,
    activeColor,
    label,
    index,
    isChecked = false,
  }) => {
    const [checked, setChecked] = useState(isChecked);
    return (
      <div
        className={`border shadow rounded my-2 mx-1 ${
          checked ? `bg-${activeColor} text-white` : ""
        }`}
      >
        <input
          type="checkbox"
          id={`customCheck${index}`}
          style={{ display: "none" }}
          value={label}
          name={inputName}
          onChange={(event) => {
            setChecked(event.target.checked);
          }}
          checked={checked}
        />
        <label
          className={`px-3 py-2 m-0 h-100 w-100 text-center font-weight-bold cursor-pointer ${
            checked ? "text-white" : "text-dark"
          }`}
          htmlFor={`customCheck${index}`}
        >
          {label}
        </label>
      </div>
    );
  };

  const Reasons = ({ reasons, isPositive, activeReasons = [] }) => {
    return (
      <Row className="mx-3">
        {reasons.map((reason, index) => (
          <Col sm={12} lg={4} className="p-0" key={`reason${index}`}>
            <ColoredCheckbox
              label={reason}
              inputName="reasons"
              index={index}
              activeColor={isPositive ? "success" : "danger"}
              isChecked={activeReasons.includes(reason)}
            />
          </Col>
        ))}
      </Row>
    );
  };

  const RatingForm = () => {
    return (
      <>
        <div className="d-flex justify-content-center">
          <ThumbRatingButton
            rating={1}
            active={rating > 0}
            onClick={() => setRating(1)}
          />
          <ThumbRatingButton
            rating={0}
            active={rating === 0}
            onClick={() => setRating(0)}
          />
        </div>
        {rating == null && (
          <div className="d-flex justify-content-center">
            <Alert variant="warning">You must select a rating first.</Alert>
          </div>
        )}
        {rating !== null && (
          <Form
            id="task-feedback-form"
            className="InputForm px-3 pb-3 pt-0"
            onSubmit={handleSubmit}
          >
            <Can
              perform="task-feedback-form:student"
              yes={() => (
                <Reasons
                  reasons={
                    rating > 0
                      ? STUDENT_TASK_FEEDBACK_REASONS.positive
                      : STUDENT_TASK_FEEDBACK_REASONS.negative
                  }
                  isPositive={rating > 0}
                />
              )}
              no={() => null}
            />
            <Can
              perform="task-feedback-form:teacher"
              yes={() => (
                <Reasons
                  reasons={
                    rating > 0
                      ? TEACHER_TASK_FEEDBACK_REASONS.positive
                      : TEACHER_TASK_FEEDBACK_REASONS.negative
                  }
                  isPositive={rating > 0}
                  activeReasons={feedbackData ? feedbackData.body.reasons : []}
                />
              )}
              no={() => null}
            />
            <Can
              perform="task-feedback-form:comment"
              yes={() => (
                <Form.Group controlId="formComment" className="mx-4 mt-4">
                  <h5 className="mb-4">Add Comments</h5>
                  <div className="d-flex justify-content-between">
                    <div
                      className="avatar bg-primary-light text-white rounded-circle mr-3"
                      style={{
                        height: "4rem",
                        width: "4rem",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {getNameInitials(
                          userDetailData.firstName +
                            " " +
                            userDetailData.lastName
                        )}
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="comment"
                        placeholder="Tell us what you liked, or what you think we should improve"
                        defaultValue={feedbackData ? feedbackData.comment : ""}
                      />
                    </div>
                  </div>
                </Form.Group>
              )}
              no={() => null}
            />
            <div className="my-3 d-flex justify-content-end">
              <Button
                size="md"
                variant="primary rounded-pill"
                className="px-5 mr-4"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </>
    );
  };

  return (
    <>
      <div className="bg-primary d-flex justify-content-between">
        <h4 className="text-white px-4 py-3 m-0">{taskDetailData.title}</h4>
        <Can
          perform="task-feedback-create-edit:admin"
          yes={() => (
            <div className="text-white my-auto mr-3">
              Feedback by{" "}
              <ClassroomUserDropdown
                courseExecRef={courseExecRef}
                role={RoleType.TEACHER}
                handleSelect={(value) => {
                  if (value !== selectedUser) setSelectedUser(value);
                }}
              />
            </div>
          )}
          no={() => null}
        />
      </div>
      <Can
        perform="task-feedback-create-edit:admin"
        yes={() =>
          selectedUser ? (
            <RatingForm />
          ) : (
            <div className="d-flex justify-content-center m-5">
              <Alert variant="warning">No user is selected.</Alert>
            </div>
          )
        }
        no={() => <RatingForm />}
      />
    </>
  );
};

export default TaskFeedbackCreateForm;
