import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import MyCourses from "../../components/classroom/MyCoursesView";
import Can from "../../utils/Can";
import HeaderPageContainer from "../common/HeaderPageContainer";

const StudentRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <div className="mt-7">
      <Switch>
        <Route
          exact
          path={`${path}`}
          children={<Redirect to={`${path}/enrolled`} />}
        />
        <Route exact path={`${path}/enrolled`} children={<MyCourses />} />
      </Switch>
    </div>
  );
};

const StudentPage = () => {
  return (
    <HeaderPageContainer>
      <Can
        perform="enrolled:student"
        yes={() => <StudentRoutes />}
        no={() => <Redirect to={"/404"} />}
      />
    </HeaderPageContainer>
  );
};

export default StudentPage;
