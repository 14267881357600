import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";

import App from "./features/App";
import reducers from "./reducers";
import Honeybadger from "@honeybadger-io/js";
import { HoneybadgerErrorBoundary } from "@honeybadger-io/react";

Honeybadger.configure({
  apiKey: "hbp_JnzhxfGmSwU9ZKFpde56w2dEcB63RD24bMhB",
  environment: "production",
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
  <HoneybadgerErrorBoundary honeybadger={Honeybadger}>
    <Provider store={store}>
      <App />
    </Provider>
  </HoneybadgerErrorBoundary>,
  document.querySelector("#root")
);
