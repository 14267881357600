const rules = {
  STUDENT: {
    static: [
      "enrolled:student",
      "header:student",
      "task-work-upload:student",
      "task-feedback-form:student",
      "visible:student",
    ],
  },
  TEACHER: {
    static: [
      "favourite-course:teacher",
      "explore:view",
      "enrolled:teacher",
      "header:teacher",
      "task-tabs:get",
      "course-exec-detail:management",
      "create-classroom:teacher",
      "unenrolled-courses:teacher",
      "task-feedback-form:teacher",
      "task-feedback-form:comment",
      "task:edit",
      // Examples:
      // "posts:list",
      // "posts:create",
      // "users:getSelf",
      // "home-page:visit",
      // "dashboard-page:visit",
    ],
    dynamic: {
      // Example:
      // "posts:edit": ({ userId, postOwnerId }) => {
      //   if (!userId || !postOwnerId) return false;
      //   return userId === postOwnerId;
      // },
    },
  },
  ADMIN: {
    static: [
      "explore:view",
      "pbl-content:modify",
      "school-delete:admin",
      "header:admin",
      "admin:dashboard",
      "task-tabs:get",
      "course-exec-detail:management",
      "course-exec-manage-switch-task:admin",
      "task-feedback-create-edit:admin",
      "task-feedback-form:teacher",
      "task-feedback-form:comment",
    ],
  },
  SBU_ADMIN: {
    static: [
      "explore:view",
      "header:sbuAdmin",
      "admin:dashboard",
      "task-tabs:get",
      "course-exec-detail:management",
      "course-exec-manage-switch-task:admin",
      "task-feedback-create-edit:admin",
      "task-feedback-form:teacher",
      "task-feedback-form:comment",
    ],
  },
};

export default rules;
