import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { EXPLORE_PAGE_MODES, ILLUSTRATIONS } from "../../utils/constants";
import BoldHeaderWithSideItems from "../util/BoldHeaderWithSideItems";
import OverviewModal from "./OverviewModal";
import BackToExploreButton from "./BackToExploreButton";
import CourseCardsFlat from "./CourseCardsFlat";

const NoSearchResultInfo = ({ setMode }) => (
  <div className="text-light text-center">
    <h5 className="text-light">Oops! No courses saved yet.</h5>
    <Button
      className="mt-3"
      variant="outline-light"
      onClick={() => setMode(EXPLORE_PAGE_MODES.EXPLORE)}
    >
      Continue Exploring
    </Button>
    {[
      { className: "d-lg-none w-100 mt-8", style: {} },
      { className: "d-none d-lg-block", style: { width: "60%" } },
    ].map((item, index) => (
      <img
        key={`responsive-${index}`}
        alt="0 result"
        src={ILLUSTRATIONS.ZERO_RESULT.FAVOURITE_COURSE}
        className={item.className + " mx-auto"}
        style={item.style}
      />
    ))}
  </div>
);

const FavouritePanel = ({
  courseList,
  setMode,
  isSchoolCoursePurchaseModel,
  purchasedCourseList,
}) => {
  const favouriteCourses = useSelector(
    (state) => state.explore.favouriteCourses
  );
  const [showCourseModal, setShowCourseModal] = useState(null);
  const courses =
    favouriteCourses &&
    courseList.courses.filter((course) =>
      favouriteCourses.includes(course.courseRef)
    );
  return (
    favouriteCourses && (
      <>
        <BoldHeaderWithSideItems title="My Favourites" left={true}>
          <BackToExploreButton setMode={setMode} />
        </BoldHeaderWithSideItems>
        <OverviewModal
          show={showCourseModal}
          setShow={setShowCourseModal}
          calledFrom={EXPLORE_PAGE_MODES.SEARCH}
          isSchoolCoursePurchaseModel={isSchoolCoursePurchaseModel}
          purchasedCourseList={
            isSchoolCoursePurchaseModel ? purchasedCourseList : null
          }
        />
        <div className="mt-5">
          <CourseCardsFlat
            courseList={{
              courses: courses,
              projectDict: courseList.projectDict,
            }}
            handleClick={setShowCourseModal}
            isSchoolCoursePurchaseModel={isSchoolCoursePurchaseModel}
            purchasedCourseList={
              isSchoolCoursePurchaseModel ? purchasedCourseList : null
            }
          />
          {courses.length === 0 && <NoSearchResultInfo setMode={setMode} />}
        </div>
      </>
    )
  );
};

export default FavouritePanel;
