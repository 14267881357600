import React, { useEffect, useState, useRef } from "react";
import { deepCopy } from "../../utils/GenericUtils";
import TextArea from "antd/es/input/TextArea";

const keywordMetadataDictToStr = (metadataDict) => {
  if (!metadataDict?.keyword) return "";
  const keywords = metadataDict.keyword; // example ["text1", "text2 phrase"]
  const keywordStr = keywords.join(";");
  return keywordStr;
};

const keywordStrToMetadataDict = (keywordStr) => {
  if (!keywordStr) return {};
  const trimmedKeywords = keywordStr
    .split(";")
    .map((keyword) => keyword.trim());
  return { keyword: trimmedKeywords };
};

const CourseContentMetadataEditor = ({
  contentMetadata,
  handleMetadataUpdate,
}) => {
  const [updatedKeywordsStr, setUpdatedKeywordsStr] = useState(
    keywordMetadataDictToStr(deepCopy(contentMetadata))
  );
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    handleMetadataUpdate(keywordStrToMetadataDict(updatedKeywordsStr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedKeywordsStr]);

  return (
    <>
      <div className="mb-2">Keywords:</div>
      <TextArea
        value={updatedKeywordsStr}
        onChange={(e) => {
          const updatedContent = e.target.value;
          setUpdatedKeywordsStr(updatedContent);
        }}
        placeholder="example: Biology;Cell;System;Respiratory"
        autoSize={{ minRows: 3 }}
      />
    </>
  );
};

export default CourseContentMetadataEditor;
