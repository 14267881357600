import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import {
  // loadCourseDetail,
  loadCourseExecDetail,
  archiveCourse,
} from "../../actions";

const UserCourseArchiveForm = ({
  courseRef,
  onSaveSuccess,
  handleCloseForm,
}) => {
  // const [courseDetailData, setCourseDetailData] = useState(null);
  const [courseExecDetailData, setCourseExecDetailData] = useState(null);
  const [courseArchiveSuccess, setCourseArchiveSuccess] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    // loadCourseDetail(courseRef, (data) => setCourseDetailData(data));
    loadCourseExecDetail(courseRef, (data) => {
      setCourseExecDetailData(data);
    });
  }, [courseRef]);

  const handleArchive = (event) => {
    event.preventDefault();
    archiveCourse(
      { courseRef: courseRef },
      () => {
        addToast("Course archived", {
          appearance: "success",
          autoDismiss: true,
        });
        if (handleCloseForm) handleCloseForm();
        setCourseArchiveSuccess(true);
        onSaveSuccess();
      },
      () => setCourseArchiveSuccess(false)
    );
  };

  return (
    // courseDetailData &&
    courseExecDetailData && (
      <div className="text-dark">
        <p>Archiving a course cannot be reversed.</p>
        <p>
          You can continue to view the content and student work within an
          archived course. But you will not be able to edit it or add students
          to it. Students won't be able to add any work to it.
        </p>
        <p>
          Are you sure you want to archive:{" "}
          <b className="font-weight-bolder">
            {/* "{courseDetailData === null ? "" : courseDetailData.project.name}" */}
            {courseExecDetailData.course.project.title}
          </b>
          ?
        </p>
        <br />
        <Button
          size="md"
          variant="warning text-white"
          type="submit"
          disabled={courseArchiveSuccess}
          onClick={handleArchive}
          block
        >
          Archive
        </Button>
        <Button
          size="sm"
          variant="link text-danger"
          type="submit"
          disabled={courseArchiveSuccess}
          onClick={handleCloseForm}
          block
        >
          Cancel
        </Button>
        {courseArchiveSuccess ? (
          <Alert variant="success">Archived.</Alert>
        ) : null}
      </div>
    )
  );
};

export default UserCourseArchiveForm;
