import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import ProjectList from "../../components/project/ProjectList";
import SchoolList from "../../components/school/SchoolList";
import TaskList from "../../components/task/TaskList";
import CourseListPage from "./CourseListPage";
import CourseDetailEditPage from "./CourseDetailEditPage";
import TaskCreateEditPage from "./TaskCreateEditPage";
import SchoolPage from "./SchoolPage";
import CourseExecManagementPanel from "./CourseExecManagementPanel";
import Can from "../../utils/Can";
import HeaderPageContainer from "../common/HeaderPageContainer";
import AdminDashboard from "../../components/dashboard/AdminDashboard";
import SBUAdminPage from "./SBUAdminPage";
import ReportPage from "./ReportPage";
import UserManagementPage from "./UserManagementPage";

const ContentPanels = () => {
  let { path } = useRouteMatch();
  return (
    <div className="pl-4 pr-4 pb-5 pt-5 content-panel">
      <Switch>
        <Route exact path={`${path}/schools`} children={<SchoolList />} />
        <Route
          path={`${path}/schools/school/:schoolRef`}
          children={<SchoolPage />}
        />
        <Route
          path={`${path}/classroom/:courseExecRef`}
          children={<CourseExecManagementPanel />}
        />
        <Route exact path={`${path}/tasks`} children={<TaskList />} />
        <Route
          exact
          path={`${path}/tasks/task/:mode/:taskRef`}
          children={<TaskCreateEditPage />}
        />
        <Route exact path={`${path}/projects`} children={<ProjectList />} />
        <Route
          exact
          path={`${path}/projects/project/:projectRef`}
          children={<CourseListPage />}
        />
        <Route
          exact
          path={`${path}/projects/project/course/:courseRef`}
          children={<CourseDetailEditPage />}
        />
        <Route exact path={`${path}/dashboard`} children={<AdminDashboard />} />
        <Route path={`${path}/reports`} children={<ReportPage />} />
        <Route path={`${path}/users`} children={<UserManagementPage />} />
        <Route path={`${path}/sbu-admins`} children={<SBUAdminPage />} />
        <Route exact path={`${path}/settings`} children={<div>Settings</div>} />
      </Switch>
    </div>
  );
};

const AdminPage = () => {
  return (
    <HeaderPageContainer>
      <Can
        perform="admin:dashboard"
        yes={() => <ContentPanels />}
        no={() => <Redirect to={"/404"} />}
      />
    </HeaderPageContainer>
  );
};

export default AdminPage;
