import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Card,
  ListGroup,
  Form,
  Table,
  Accordion,
  Alert,
} from "react-bootstrap";
import { Files, PencilFill, TrashFill } from "react-bootstrap-icons";
import {
  duplicateTask,
  loadActiveTaskCourseLinkList,
  loadTaskVersionList,
  markTaskVersion,
} from "../../actions";
import { DATETIME_FORMAT } from "../../utils/constants";
import SingleTaskView from "./SingleTaskView";
import ModalButton from "../util/ModalButton";
import TaskDeleteForm from "../createEditForms/TaskDeleteForm";

const CourseTaskLinksAccordion = ({ taskData }) => {
  const [courseTaskLinkListData, setCourseTaskLinkListData] = useState(null);
  useEffect(() => {
    if (taskData == null) {
      return;
    }
    const data = { taskRef: taskData.taskRef };
    loadActiveTaskCourseLinkList(data, (data) => {
      // Only show isAssignable ones for task editing purposes.
      // Otherwise the list may be too long.
      data.courseTaskLinks = data.courseTaskLinks.filter(
        (link) => data.courses[link.courseRef].isAssignable
      );
      setCourseTaskLinkListData(data);
    });
  }, [taskData]);

  const tdStyle = { whiteSpace: "normal" };

  return (
    taskData &&
    courseTaskLinkListData && (
      <>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Courses using version:{" "}
              <b className="text-warning">{taskData.versionName}</b>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {courseTaskLinkListData.courseTaskLinks.length ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Project</th>
                        <th>Course</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseTaskLinkListData.courseTaskLinks.map(
                        (link, index) => {
                          const course =
                            courseTaskLinkListData.courses[link.courseRef];
                          const project =
                            courseTaskLinkListData.projects[course.projectRef];
                          return (
                            <tr key={`row-${index}`}>
                              <td>{index + 1}</td>
                              <td style={tdStyle}>{project.title}</td>
                              <td style={tdStyle}>{course.title}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <Alert variant="success">
                    No course is linked to this task version
                  </Alert>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    )
  );
};

const TaskVersionManagementPanel = ({ taskRef }) => {
  const [taskVersionListData, setTaskVersionListData] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);
  const [displayTaskRef, setDisplayTaskRef] = useState(taskRef);

  useEffect(() => {
    if (shouldReloadData) {
      const data = {
        taskRef: taskRef,
      };
      loadTaskVersionList(data, (data) => {
        setTaskVersionListData(data);
        if (data.find((task) => task.taskRef === displayTaskRef)) return;
        if (data.length) setDisplayTaskRef(data[0].taskRef);
      });
      setShouldReloadData(false);
    }
  }, [shouldReloadData, taskRef, displayTaskRef]);
  return (
    taskVersionListData && (
      <div className="d-flex">
        {displayTaskRef &&
        taskVersionListData.find((task) => task.taskRef === displayTaskRef) ? (
          <div style={{ width: "calc(100% - 24rem)" }}>
            <div className="mb-3">
              <CourseTaskLinksAccordion
                taskData={taskVersionListData.find(
                  (task) => task.taskRef === displayTaskRef
                )}
              />
            </div>
            <SingleTaskView taskRef={displayTaskRef} />
          </div>
        ) : (
          <Alert className="w-100">Please select a task</Alert>
        )}
        <div style={{ width: "24rem" }}>
          <div className="bg-light h-100 w-100">
            <ListGroup variant="primary" className="text-dark">
              {taskVersionListData.map((task, index) => (
                <ListGroup.Item
                  key={`version-${index}`}
                  variant={`${
                    task.taskRef === displayTaskRef ? "warning" : ""
                  }`}
                  className="text-dark"
                  action
                  onClick={() => {
                    if (displayTaskRef !== task.taskRef)
                      setDisplayTaskRef(task.taskRef);
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Form.Check type="radio">
                      <Form.Check.Input
                        type="radio"
                        checked={task.isMarked}
                        onChange={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to set this version as default?"
                            )
                          ) {
                            const data = {
                              taskRef: task.taskRef,
                            };
                            markTaskVersion(data, () =>
                              setShouldReloadData(true)
                            );
                          }
                        }}
                      />
                      <Form.Check.Label>{task.versionName}</Form.Check.Label>
                      <div className="text-xs">
                        last edited on{" "}
                        {moment(task.updateAt).format(DATETIME_FORMAT)}
                        <br />
                        created on{" "}
                        {moment(task.createAt).format(DATETIME_FORMAT)}
                      </div>
                    </Form.Check>
                    <div className="float-right text-primary">
                      <Files
                        size={24}
                        className="mr-3"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to duplicate this version?"
                            )
                          ) {
                            const data = {
                              taskRef: task.taskRef,
                            };
                            duplicateTask(data, () =>
                              setShouldReloadData(true)
                            );
                          }
                        }}
                      />
                      <PencilFill
                        size={18}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to edit this version?"
                            )
                          )
                            window.open(
                              `/admin/tasks/task/edit/${task.taskRef}`,
                              "_blank"
                            );
                        }}
                      />
                      <br />
                      <div className="w-100 mt-2">
                        <ModalButton
                          customisedClickableComponent={
                            <TrashFill
                              size={24}
                              className="text-danger float-right"
                            />
                          }
                          modalTitle="Delete this Version"
                        >
                          <TaskDeleteForm
                            taskRef={task.taskRef}
                            onSaveSuccess={() => setShouldReloadData(true)}
                          />
                        </ModalButton>
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </div>
      </div>
    )
  );
};

export default TaskVersionManagementPanel;
