import { DefaultToastContainer } from "react-toast-notifications";

const ToastContainer = (props) => (
  <DefaultToastContainer
    className="toast-container react-toast-notifications__container"
    style={{ zIndex: 2000, top: "100px" }}
    {...props}
  />
);

export default ToastContainer;
