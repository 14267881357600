import React, { useState, useEffect } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card,
  InputGroup,
  FormControl,
  Button,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import ModalButton from "../../util/ModalButton";
import { ArrowCounterclockwise, Plus, Search } from "react-bootstrap-icons";
import ProjectListDropDown from "../../project/ProjectListDropdown";
import TaskCardBody from "../../task/TaskCardBody";
import TaskVersionManagementPanel from "../../task/TaskVersionManagementPanel";

const TaskLibraryHeader = ({
  originalTaskListData,
  handleFilter,
  handleReloadTaskListData,
  projectDictData,
}) => {
  const [selectedProjectRef, setSelectedProjectRef] = useState("no-project");

  const handleSearch = (event) => {
    event.preventDefault();
    const lowerCaseQuery = event.target.query.value.toLowerCase();
    const projectInput = selectedProjectRef.toLowerCase();
    const projectRefQuery = projectInput === "no-project" ? null : projectInput;
    const tokenList = lowerCaseQuery.split(" ");
    let copied = JSON.parse(JSON.stringify(originalTaskListData));
    let filteredTaskListData = copied.filter((task) => {
      if (!["all-projects", task.projectRef].includes(projectRefQuery))
        return false;
      const lowerTitle = task.title.toLowerCase();
      return tokenList.every((item) => {
        return lowerTitle.includes(item);
      });
    });
    handleFilter(filteredTaskListData);
    return;
  };

  return (
    <div className="d-flex justify-content-between pb-3">
      <Row>
        <Col sm="auto" className="pr-0">
          <div className="d-flex align-items-center h-100">
            <h5 className="m-0">Task Library</h5>
          </div>
        </Col>
        <Col sm="auto" className="d-flex justify-content-between pb-1 pr-0">
          <ProjectListDropDown
            onProjectChange={(value) => setSelectedProjectRef(value)}
            showAll={true}
            projectsData={Object.values(projectDictData)}
          />

          <Form onSubmit={handleSearch}>
            <InputGroup size="sm">
              <FormControl
                className="border-secondary border-right-0 border-left-0"
                aria-label="Search..."
                name="query"
              />
              <InputGroup.Append>
                <Button
                  size="sm"
                  type="submit"
                  className="border-secondary px-3"
                  variant="outline-secondary"
                >
                  <Search className="text-dark" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </Col>
        <Col sm="auto" className="pr-0">
          <Button
            size="sm"
            className="px-0"
            variant="link"
            onClick={() => {
              handleFilter(originalTaskListData);
            }}
          >
            Show All
          </Button>
        </Col>
        <Col sm="auto">
          <Button
            size="xs"
            className="rounded-pill btn-icon-only"
            onClick={handleReloadTaskListData}
          >
            <ArrowCounterclockwise size={26} />
          </Button>
          <Button
            size="xs"
            className="rounded-pill btn-icon-only"
            onClick={() => {
              window.open("/admin/tasks/task/create/new-task", "_blank");
            }}
          >
            <Plus size={32} />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const TaskLibrary = ({ taskListData, projectDictData, reloadTaskListData }) => {
  const [displayedTaskListData, setDisplayedTaskListData] = useState(null);
  useEffect(() => {
    setDisplayedTaskListData(taskListData);
  }, [taskListData]);

  // This method is needed for rendering clones of draggables
  const getRenderItem = (items) => (provided, snapshot, rubric) => {
    const item = items[rubric.source.index];
    return (
      <Card
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={provided.draggableProps.style}
        className={`border-secondary ${
          snapshot.isDragging ? "dragging" : null
        }`}
      >
        <TaskCardBody task={item} projectDictData={projectDictData} />
      </Card>
    );
  };

  const renderList = (reRenderTaskList, provided, snapshot) => {
    return reRenderTaskList.map((item, index) => {
      const shouldRenderClone = item.taskRef === snapshot.draggingFromThisWith;
      return (
        <Col sm={6} key={item.taskRef}>
          {shouldRenderClone ? (
            <Card className={`border-secondary`}>
              <TaskCardBody task={item} projectDictData={projectDictData} />
            </Card>
          ) : (
            <Draggable draggableId={item.taskRef} index={index} type="TASK">
              {(provided, snapshot) => (
                <ModalButton
                  modalTitle={item.title}
                  modalSize="xl"
                  customisedClickableComponent={
                    <Card
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`border-secondary ${
                        snapshot.isDragging ? "dragging" : null
                      }`}
                    >
                      <TaskCardBody
                        task={item}
                        projectDictData={projectDictData}
                      />
                    </Card>
                  }
                >
                  <TaskVersionManagementPanel taskRef={item.taskRef} />
                </ModalButton>
              )}
            </Draggable>
          )}
        </Col>
      );
    });
  };

  return (
    <div className="px-4">
      <TaskLibraryHeader
        originalTaskListData={taskListData}
        projectDictData={projectDictData}
        handleFilter={(data) => {
          setDisplayedTaskListData(data);
        }}
        handleReloadTaskListData={reloadTaskListData}
      />
      {displayedTaskListData && (
        <Droppable
          renderClone={getRenderItem(displayedTaskListData)}
          droppableId="TASKLIBRARY"
          type="TASK"
          isDropDisabled={true}
        >
          {(provided, snapshot) => (
            <Row ref={provided.innerRef}>
              {renderList(displayedTaskListData, provided, snapshot)}
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
      )}
    </div>
  );
};

export default TaskLibrary;
