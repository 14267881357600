import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getSchoolCourseExecCalendar } from "../../../actions";

const SchoolCalendar = ({ schoolRef }) => {
  const [calendarData, setCalendarData] = useState(null);
  useEffect(() => {
    const data = {
      schoolRef,
      timezoneStr: "Australia/Melbourne",
      startTimestamp: 1,
      endTimestamp: new Date()
        .toISOString()
        .replace("T", " ")
        .replace("Z", "+00:00"),
    };
    getSchoolCourseExecCalendar(data, (data) => {
      setCalendarData(data);
    });
  }, [schoolRef]);
  const columns = [
    {
      title: "date",
      dataIndex: "date",
      key: "date",
      width: "6rem",
    },
    {
      title: "teachers",
      dataIndex: "teachers",
      key: "teachers",
      width: "6rem",
      render: (text, record) => text.length,
    },
    {
      title: "students",
      dataIndex: "students",
      key: "students",
      width: "6rem",
      render: (text, record) => text.length,
    },
    {
      title: "classrooms",
      dataIndex: "courseExecRefs",
      key: "courseExecRefs",
      width: "6rem",
      render: (text, record) => (text ? text.length : 0),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={calendarData}
      pagination={false}
      scroll={{ x: "calc(100% - 18rem)" }}
      bordered
    />
  );
};

export default SchoolCalendar;
