import React from "react";
import {
  getClassroomReport,
  getSchoolSummary,
  getTaskFeedbackReport,
  getTeacherReport,
  getUserNumberReport,
} from "../../actions";
import TablePanelHeader from "../util/TablePanelHeader";
import { Card, Table } from "react-bootstrap";
import { exportGenericCSV } from "../../utils/CSVUtils";
import { AWS_REGION_COUNTRY_MAP } from "../../utils/constants";

const ReportList = () => {
  const DownloadButton = ({ filenamePrefix, handleDownloadClick }) => (
    <u
      className="text-primary text-xs ml-3 cursor-pointer"
      onClick={() => {
        handleDownloadClick(
          null,
          (data) =>
            exportGenericCSV(
              `${
                AWS_REGION_COUNTRY_MAP[window._env_["REGION"]]
              }-${filenamePrefix}-${new Date().toISOString().slice(0, 10)}.csv`,
              data
            ),
          () => {}
        );
      }}
    >
      Download
    </u>
  );

  const reportList = [
    {
      name: "Task Feedback",
      description: "All task feedback left by teachers and students",
      fileNamePrefix: "task-feedback",
      callback: getTaskFeedbackReport,
    },
    {
      name: "Classrooms",
      description: "All classrooms that teachers have created",
      fileNamePrefix: "classroom",
      callback: getClassroomReport,
    },
    {
      name: "Teachers",
      description: "All teachers enrolled on the platform",
      fileNamePrefix: "teacher",
      callback: getTeacherReport,
    },
    {
      name: "Total Users",
      description: "The number of all user types on the platform",
      fileNamePrefix: "user-number",
      callback: getUserNumberReport,
    },
    {
      name: "Total Active Students in Schools",
      description:
        "The number of active students (enrolled in a classroom) " +
        `for each school in ${new Date().getFullYear()}`,
      fileNamePrefix: "school-student-number",
      callback: getSchoolSummary,
    },
  ];

  return (
    <>
      <TablePanelHeader title="Reports"></TablePanelHeader>
      <Card>
        <Card.Body>
          <Table striped bordered hover className="text-dark">
            <thead>
              <tr>
                <th>#</th>
                <th>Report</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reportList.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>
                    <DownloadButton
                      filenamePrefix={item.fileNamePrefix}
                      handleDownloadClick={item.callback}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReportList;
