import React, { useState, useRef } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PreviewTable = ({ columns, rows }) => {
  const cellDisplayStyle = {
    fontSize: "1rem",
    whiteSpace: "pre-wrap",
    verticalAlign: "middle",
    textAlign: "center",
    fontWeight: "bold",
    borderColor: "white",
  };

  return (
    <Table striped bordered hover className="text-dark">
      <thead>
        <tr>
          {columns.map((val, index) => (
            <th
              style={cellDisplayStyle}
              className="bg-gray-200"
              key={`column-${index}`}
            >
              {val}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((val, index) => (
          <tr key={`row-${index}`}>
            <td style={cellDisplayStyle} className="bg-gray-200">
              {val}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const EmptyTableCreator = ({ blockData, onUpdate }) => {
  const [columns, setColumns] = useState(
    blockData.data && "columns" in blockData.data ? blockData.data.columns : []
  );
  const [rows, setRows] = useState(
    blockData.data && "rows" in blockData.data ? blockData.data.rows : []
  );
  const [newColumnName, setNewColumnName] = useState("");
  const [newRowName, setNewRowName] = useState("");
  const refColInput = useRef();
  const refRowInput = useRef();

  return (
    <>
      <div className="d-flex">
        <h5 className="mr-3" style={{ width: "6rem" }}>
          Column
        </h5>
        <Form.Control
          as="textarea"
          style={{ width: "18rem", height: "3rem" }}
          value={newColumnName}
          onChange={(event) => setNewColumnName(event.target.value)}
          ref={refColInput}
        />
        <div className="ml-3">
          <Button
            variant="primary rounded-circle btn-icon-only"
            onClick={() => {
              const newColumns = [...columns, newColumnName];
              setColumns(newColumns);
              setNewColumnName("");
              refColInput.current.focus();
              onUpdate({ columns: newColumns, rows: rows });
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Button
            variant="danger rounded-circle btn-icon-only"
            onClick={() => {
              setColumns(columns.splice(0, columns.length - 1));
              setNewColumnName("");
            }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </div>
      </div>
      <div className="d-flex my-3">
        <h5 className="mr-3" style={{ width: "6rem" }}>
          Row
        </h5>
        <Form.Control
          as="textarea"
          style={{ width: "18rem", height: "3rem" }}
          value={newRowName}
          onChange={(event) => setNewRowName(event.target.value)}
          ref={refRowInput}
        />
        <div className="ml-3">
          <Button
            variant="primary rounded-circle btn-icon-only"
            onClick={() => {
              const newRows = [...rows, newRowName];
              setRows(newRows);
              setNewRowName("");
              refRowInput.current.focus();
              onUpdate({ columns: columns, rows: newRows });
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Button
            variant="danger rounded-circle btn-icon-only"
            onClick={() => {
              setRows(rows.splice(0, rows.length - 1));
              setNewRowName("");
            }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </div>
      </div>
      <div>
        Preview:
        <PreviewTable columns={columns} rows={rows} />
      </div>
    </>
  );
};

export default EmptyTableCreator;
