import React, { useState, useEffect } from "react";
import {
  Badge,
  Col,
  Form,
  Row,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import {
  GENERIC_EDITOR_MODES,
  RUBRIC_LEVELS_TITLE,
} from "../../../utils/constants";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export const PreviewRubric = ({
  rubric,
  initialScore,
  handleScoreChange,
  mode,
}) => {
  const [score, setScore] = useState(initialScore);

  const cellDisplayStyle = rubric && {
    whiteSpace: "pre-wrap",
    verticalAlign: "middle",
    textAlign: "center",
    fontSize: "1rem",
    width: `${100 / rubric.items.length}%`,
    minWidth: "10rem",
  };
  const getScoreFromIndex = (totalScore, index, numLevels) =>
    (totalScore / (numLevels - 1)) * (numLevels - index - 1);

  const getCellAdditionalClass = (score, displayScore, defaultClass = "") => {
    const colorClass =
      [GENERIC_EDITOR_MODES.EDIT, GENERIC_EDITOR_MODES.READ_ONLY].includes(
        mode
      ) && score === displayScore
        ? "bg-warning text-white"
        : defaultClass;
    const hoverClass =
      mode === GENERIC_EDITOR_MODES.EDIT &&
      score !== displayScore &&
      "hover-gray-400";
    return `${colorClass} ${
      mode === GENERIC_EDITOR_MODES.EDIT && "cursor-pointer"
    } ${hoverClass}`;
  };
  return (
    rubric && (
      <Table bordered className="text-dark text-center bg-white">
        <thead>
          <tr>
            <th
              colSpan="99"
              className="bg-gray-400 pt-3"
              style={cellDisplayStyle}
            >
              {rubric.curriculumCodes &&
                rubric.curriculumCodes.au.map((code, index) => (
                  <Badge
                    key={`badge-${index}`}
                    className="mr-2"
                    variant="secondary"
                  >
                    {code}
                  </Badge>
                ))}
              <div className="mt-2"></div>
              {rubric.criteria}
            </th>
          </tr>
          <tr>
            {rubric.items.map((_, index) => {
              const displayScore = getScoreFromIndex(
                rubric.totalScore,
                index,
                rubric.items.length
              );
              return (
                <th
                  key={`column-${index}`}
                  style={cellDisplayStyle}
                  className={getCellAdditionalClass(
                    score,
                    displayScore,
                    "bg-gray-200 border-white"
                  )}
                  onClick={() => {
                    if (mode !== GENERIC_EDITOR_MODES.EDIT) return;
                    const score = getScoreFromIndex(
                      rubric.totalScore,
                      index,
                      rubric.items.length
                    );
                    setScore(score);
                    if (handleScoreChange) handleScoreChange(score);
                  }}
                >
                  {displayScore}
                  <br />
                  {RUBRIC_LEVELS_TITLE[rubric.items.length][index]}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {rubric.items.map((val, index) => {
              const displayScore = getScoreFromIndex(
                rubric.totalScore,
                index,
                rubric.items.length
              );
              return (
                <td
                  key={`row-1-${index}`}
                  style={cellDisplayStyle}
                  className={getCellAdditionalClass(score, displayScore)}
                  onClick={() => {
                    if (mode !== GENERIC_EDITOR_MODES.EDIT) return;
                    setScore(displayScore);
                    if (handleScoreChange) handleScoreChange(displayScore);
                  }}
                >
                  {val}
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>
    )
  );
};

const RubricCreator = ({ blockData, onUpdate }) => {
  const MIN_LEVELS = 3;
  const MAX_LEVELS = 5;
  const [shouldIncludeRubrics, setShouldIncludeRubrics] = useState(
    Boolean(blockData.rubric)
  );
  const [updatedRubrics, setUpdatedRubrics] = useState(blockData.rubric);
  useEffect(() => onUpdate(updatedRubrics), [updatedRubrics, onUpdate]);

  const getCodesFromStr = (str) => {
    const codes = str.split(/\s*\/\s*/).map((s) => s.trim());
    return codes;
  };

  const getNewItemList = (numLevels) => {
    let items = Array(numLevels).fill("");
    items[items.length - 1] = "No evidence";
    return items;
  };

  return (
    <>
      <div>
        <Form.Check
          className="mb-3"
          type="switch"
          label="Include Rubrics?"
          id="custom-switch"
          checked={shouldIncludeRubrics}
          onChange={(event) => {
            if (event.target.checked)
              setUpdatedRubrics({
                totalScore: 0,
                items: getNewItemList(MIN_LEVELS),
                criteria: "",
                curriculumCodes: { au: [] },
              });
            else setUpdatedRubrics(null);
            setShouldIncludeRubrics(event.target.checked);
          }}
        />
        {shouldIncludeRubrics && (
          <>
            <Form.Control
              className="mb-3"
              placeholder="Enter curriculum codes"
              defaultValue={
                updatedRubrics.curriculumCodes &&
                updatedRubrics.curriculumCodes.au.join(" / ")
              }
              onChange={(event) => {
                const codes = getCodesFromStr(event.target.value);
                setUpdatedRubrics({
                  ...updatedRubrics,
                  curriculumCodes: { au: codes },
                });
              }}
            />
            <Form.Control
              as="textarea"
              placeholder="Enter curriculum criteria"
              value={updatedRubrics.criteria}
              onChange={(event) =>
                setUpdatedRubrics({
                  ...updatedRubrics,
                  criteria: event.target.value,
                })
              }
            />
            <Row className="my-3">
              <Col
                size="auto"
                className="d-flex"
                style={{ alignItems: "center" }}
              >
                Select number of levels
                <ToggleButtonGroup
                  className="ml-3"
                  type="radio"
                  name="options"
                  value={
                    updatedRubrics && updatedRubrics.items
                      ? updatedRubrics.items.length
                      : MIN_LEVELS
                  }
                  onChange={(val) =>
                    setUpdatedRubrics({
                      ...updatedRubrics,
                      items: getNewItemList(val),
                    })
                  }
                >
                  {Array(MAX_LEVELS - MIN_LEVELS + 1)
                    .fill()
                    .map((_, idx) => {
                      const num = MIN_LEVELS + idx;
                      return (
                        <ToggleButton
                          variant="outline-primary"
                          key={`toggle-${num}`}
                          value={num}
                        >
                          {num}
                        </ToggleButton>
                      );
                    })}
                </ToggleButtonGroup>
                <span className="ml-3 text-warning cursor-pointer text-lg">
                  <Tooltip title="Changing the number of levels for this rubric will delete all existing text from the rubric level boxes below">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Tooltip>
                </span>
              </Col>
              <Col>
                <div className="d-flex float-right">
                  <div className="my-auto">The question is out of </div>
                  <Form.Control
                    className="mx-3 text-center"
                    style={{
                      width: "6rem",
                    }}
                    placeholder="0"
                    type="number"
                    value={updatedRubrics && updatedRubrics.totalScore}
                    onChange={(event) =>
                      setUpdatedRubrics({
                        ...updatedRubrics,
                        totalScore: event.target.value,
                      })
                    }
                  />
                  <div className="my-auto"> points</div>
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              {updatedRubrics &&
                updatedRubrics.items.map((val, index) => (
                  <Col key={`rubric-level-${index}`}>
                    <Form.Control
                      as="textarea"
                      value={val}
                      rows={3}
                      onChange={(event) => {
                        let newItems = JSON.parse(
                          JSON.stringify(updatedRubrics.items)
                        );
                        newItems[index] = event.target.value;
                        setUpdatedRubrics({
                          ...updatedRubrics,
                          items: newItems,
                        });
                      }}
                    />
                  </Col>
                ))}
            </Row>
          </>
        )}
        {updatedRubrics && <PreviewRubric rubric={updatedRubrics} />}
      </div>
    </>
  );
};

export default RubricCreator;
