import React, { useState, useEffect } from "react";
import { Card, Row, Col, ProgressBar } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { listTRBWorkCourseExecSingleUser } from "../../actions";
import { handleGenericSaveFailed } from "../../utils/CreateEditFormUtils";
import { getQuestionBlocks } from "../../utils/DataLogicUtils";
import UserClassroomProgressTable from "./UserClassroomProgressTable";

const UserClassroomProgressPanel = ({ courseExecRef }) => {
  const [maxQuestionNum, setMaxQuestionNum] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [courseSummary, setCourseSummary] = useState(null);
  const { addToast } = useToasts();
  useEffect(() => {
    const data = {
      courseExecRef: courseExecRef,
    };
    listTRBWorkCourseExecSingleUser(
      data,
      (data) => preprocessData(data),
      () => handleGenericSaveFailed(addToast, null, () => null)
    );
  }, []);

  const preprocessData = (data) => {
    let maxQuestionNum = 0;
    let taskSummaryData = {};
    const courseSummary = {
      courseResponseOutOf: 0,
      totalCourseResponse: 0,
      totalCourseScore: 0,
      courseScoreOutOf: 0,
    };
    // Don't show hidden tasks to students
    const displayCurriculumTrbInfo = data.curriculumTrbInfo.filter(
      (courseTaskLink) => !courseTaskLink.isHidden
    );

    let currentDisplaySeqNumInSection = 0;
    let currentSectionNum = 0;
    for (const courseTaskLink of displayCurriculumTrbInfo) {
      // Format task number
      if (courseTaskLink.sectionNumber !== currentSectionNum) {
        currentSectionNum = courseTaskLink.sectionNumber;
        currentDisplaySeqNumInSection = 0;
      }
      courseTaskLink.seqNumInSection = currentDisplaySeqNumInSection;

      const questionBlocks = getQuestionBlocks(
        courseTaskLink.taskResourceBlocks
      );
      courseTaskLink.taskResourceBlocks = questionBlocks;
      if (questionBlocks.length > maxQuestionNum)
        maxQuestionNum = questionBlocks.length;

      courseSummary.courseResponseOutOf += questionBlocks.length;

      // Calculate total score and available scores
      let totalScore = 0;
      let totalAvailableScore = 0;
      const questionTotalScoreDict = {};
      for (const questionBlock of questionBlocks)
        questionTotalScoreDict[questionBlock.taskResourceBlockRef] =
          questionBlock.questionTotalScore;
      if (
        questionBlocks.length !== 0 &&
        courseTaskLink.courseTaskLinkRef in data.trbStudentResponses
      ) {
        Object.values(
          data.trbStudentResponses[courseTaskLink.courseTaskLinkRef]
        ).forEach((item) => {
          if (item.score !== null) {
            totalScore += item.score;
            totalAvailableScore += questionTotalScoreDict[
              item.taskResourceBlockRef
            ]
              ? questionTotalScoreDict[item.taskResourceBlockRef]
              : 0;
          }
          if (
            item.lastSubmitAt != null &&
            item.taskResourceBlockRef in questionTotalScoreDict // Avoid rubric blocks
          )
            courseSummary.totalCourseResponse += 1;
        });
        taskSummaryData[courseTaskLink.courseTaskLinkRef] = {
          score: totalScore,
          totalAvailableScore: totalAvailableScore,
        };
      }
      courseSummary.totalCourseScore += totalScore;
      courseSummary.courseScoreOutOf += totalAvailableScore;
      currentDisplaySeqNumInSection += 1;
    }

    setMaxQuestionNum(maxQuestionNum);
    setTableData({
      ...data,
      curriculumTrbInfo: displayCurriculumTrbInfo,
      taskSummaryData: taskSummaryData,
    });
    setCourseSummary(courseSummary);
  };

  const getDisplayPercentage = (num, outOfNum) => {
    return outOfNum !== 0 ? Math.round((num / outOfNum) * 100) : 0;
  };

  const SummaryCard = ({ title, color, percentage, text }) => {
    return (
      <Card>
        <Card.Body>
          <h5 style={{ color: `var(--${color})` }}>{title}</h5>
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: "-1.6rem" }}
          >
            <ProgressBar
              className="ml-0 mb-0"
              style={{
                width: "100%",
                marginTop: "2rem",
              }}
            >
              <ProgressBar
                now={percentage}
                style={{
                  backgroundColor: `var(--${color})`,
                }}
              />
            </ProgressBar>
            <div
              className="text-right text-lg text-nowrap text-dark font-weight-bolder mr-0 ml-4 "
              style={{ marginTop: "-0.9rem" }}
            >
              {percentage}%
              <div className="text-sm font-weight-normal mt-1">{text}</div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className="p-4">
      {courseSummary && (
        <Row xs={1} lg={2}>
          <Col>
            <SummaryCard
              title="Course Progress"
              color="cura-green"
              percentage={getDisplayPercentage(
                courseSummary.totalCourseResponse,
                courseSummary.courseResponseOutOf
              )}
              text={
                <>
                  {courseSummary.totalCourseResponse}/
                  {courseSummary.courseResponseOutOf} questions completed
                </>
              }
            />
          </Col>
          <Col>
            <SummaryCard
              title="Course Score"
              color="warning"
              percentage={getDisplayPercentage(
                courseSummary.totalCourseScore,
                courseSummary.courseScoreOutOf
              )}
              text={`${courseSummary.totalCourseScore}/${courseSummary.courseScoreOutOf}`}
            />
          </Col>
        </Row>
      )}
      <hr className="border-secondary mx-1 mt-2 mb-4" />
      {tableData && (
        <UserClassroomProgressTable
          tableData={tableData}
          courseExecRef={courseExecRef}
          maxQuestionNum={maxQuestionNum}
        />
      )}
    </div>
  );
};

export default UserClassroomProgressPanel;
