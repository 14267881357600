import React, { useEffect, useState } from "react";
import { getSchoolSummary } from "../../../actions";
import _ from "lodash";
import { Table } from "antd";

const SiteSummaryTable = ({ data }) => {
  const columns = [
    {
      title: "School",
      dataIndex: "schoolNum",
      key: "schoolNum",
    },
    {
      title: "Classroom",
      dataIndex: "courseExecNum",
      key: "courseExecNum",
    },
    {
      title: "Teacher",
      dataIndex: "teacherNum",
      key: "teacherNum",
    },
    {
      title: "Teacher Added Last 30 Days",
      dataIndex: "teacherNumLastMonth",
      key: "teacherNumLastMonth",
    },
    {
      title: "Student",
      dataIndex: "studentNum",
      key: "studentNum",
    },
    {
      title: "Student Added Last 30 Days",
      dataIndex: "studentNumLastMonth",
      key: "studentNumLastMonth",
    },
  ];
  return (
    <Table columns={columns} dataSource={data} pagination={false} bordered />
  );
};

const preprocessData = (data) => {
  return [
    {
      schoolNum: data.length,
      courseExecNum: _.sumBy(data, "courseExecNum"),
      teacherNum: _.sumBy(data, "teacherNum"),
      teacherNumLastMonth: _.sumBy(data, "teacherNumLastMonth"),
      studentNum: _.sumBy(data, "studentNum"),
      studentNumLastMonth: _.sumBy(data, "studentNumLastMonth"),
    },
  ];
};

const SiteSummary = () => {
  const [schoolSummaryData, setSchoolSummaryData] = useState(null);
  useEffect(() => {
    const data = {};
    getSchoolSummary(data, (data) => {
      setSchoolSummaryData(preprocessData(data));
    });
  }, []);
  return (
    <>
      <h4 className="text-white">This Year:</h4>
      <SiteSummaryTable data={schoolSummaryData} />
    </>
  );
};

export default SiteSummary;
