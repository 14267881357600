import React, { useState } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { passwordResetSendEmail } from "../../actions";

const PasswordReset = () => {
  const [isPasswordResetEmailSent, setIsPasswordResetEmailSent] = useState(
    null
  );
  const [isProceedClicked, setIsProceedClicked] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsProceedClicked(true);
    const form = event.target;
    const data = {
      username: form.elements.email.value.trim().toLowerCase(),
    };
    passwordResetSendEmail(
      data,
      () => {
        setIsPasswordResetEmailSent(true);
        setIsProceedClicked(false);
      },
      () => {
        setIsPasswordResetEmailSent(false);
        setIsProceedClicked(false);
      }
    );
  };

  return (
    <div className="text-center">
      <div className="AuthPanel">
        {isPasswordResetEmailSent === false ? (
          <Alert variant="danger">
            A user with this email address does not exist. Please re-enter your
            email
          </Alert>
        ) : null}
        {isPasswordResetEmailSent ? (
          <Alert variant="success">
            You will receive an email with instructions on how to reset your
            password in a few minutes.
          </Alert>
        ) : null}
        <h1>Forgot your password?</h1>
        <br />
        Enter your email address and we'll send you a link to reset your
        password.
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <br />
            <Form.Label>Your Email</Form.Label>
            <Form.Control type="email" name="email" />
          </Form.Group>
          <div>
            Already have an account? <a href="/">Sign In</a>
          </div>
          <br />
          <Button
            aria-label="reset-password"
            size="md"
            variant="primary"
            type="submit"
            disabled={isPasswordResetEmailSent}
            block
          >
            {isProceedClicked ? (
              <Spinner animation="grow" size="sm" />
            ) : isPasswordResetEmailSent ? (
              "Email sent!"
            ) : (
              "Reset Password"
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};
export default PasswordReset;
