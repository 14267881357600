import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { loadFavouriteList, updateFavourite } from "../../actions";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { FAVOURITE_COURSES } from "../../actions/types";

const FavouriteActionButton = ({ courseRef, title, isResponsive = false }) => {
  const favouriteCourses = useSelector(
    (state) => state.explore.favouriteCourses
  );
  const dispatch = useDispatch();
  const [favourite, setFavourite] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    favouriteCourses && setFavourite(favouriteCourses.includes(courseRef));
  }, [favouriteCourses, courseRef]);

  const favouriteToast = (
    <>
      <Button
        variant="light"
        className="bg-white text-warning rounded-circle btn-icon-only"
        size="xs"
      >
        <FontAwesomeIcon icon={faHeart} />
      </Button>

      <span>
        <b>{title}</b> has been successfully added to{" "}
        <b className="text-primary">Explore &gt; My Favourites</b>
      </span>
    </>
  );
  const handleClick = () => {
    const data = {
      courseRef: courseRef,
      favourite: !favourite,
    };
    updateFavourite(data, () => {
      !favourite &&
        addToast(favouriteToast, {
          appearance: "success",
          autoDismiss: true,
        });
      loadFavouriteList((data) => {
        dispatch({ type: FAVOURITE_COURSES, payload: data });
      });
    });
  };
  const buttonStyles = [
    { size: "sm", className: "d-lg-inline d-none" },
    { size: "xs", className: "d-lg-none" },
  ];

  const FavouriteButton = ({ size = "sm", additionalClassName = "" }) => {
    return favourite ? (
      <Button
        variant="light"
        className={
          "m-0 text-warning rounded-circle btn-icon-only " + additionalClassName
        }
        size={size}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faHeart} />
      </Button>
    ) : (
      <Button
        variant="light"
        className={
          "m-0 rounded-circle btn-icon-only text-light shadow hover-gray-400 hover-text-warning " +
          additionalClassName
        }
        size={size}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faHeartRegular} />
      </Button>
    );
  };

  return (
    favourite !== null && (
      <>
        {!isResponsive ? (
          <FavouriteButton />
        ) : (
          buttonStyles.map((item, index) => (
            <FavouriteButton
              size={item.size}
              key={index}
              additionalClassName={item.className}
            />
          ))
        )}
      </>
    )
  );
};

export default FavouriteActionButton;
