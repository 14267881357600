import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import { getAllTeacherExplore } from "../../../actions";
import { deepCopy, findRanges } from "../../../utils/GenericUtils";
import { AWS_REGION_COUNTRY_MAP, GA_ACTIONS } from "../../../utils/constants";
import ModalButton from "../../../components/util/ModalButton";

const preprocessData = (data, schoolRef = null, email = "") => {
  if (!data) return null;
  const region = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];
  const detailData = deepCopy(data);
  const displayData = [];
  let courses = _.values(detailData.courseDetails);

  _.values(detailData.userDetails).forEach(
    (userDetail) =>
      (userDetail.fullName = userDetail.firstName + " " + userDetail.lastName)
  );

  // Aggregate
  courses.forEach((course) => {
    // Favourite
    const favourite = _.filter(
      _.values(detailData.userDetails),
      (user) =>
        (email === "" || user.username === email) &&
        (schoolRef === null || user.schoolRef === schoolRef) &&
        user.favouriteCourses.includes(course.courseRef)
    );
    const courseActivities = _.filter(
      detailData.exploreActivities,
      (activity) => {
        if (activity.courseRef !== course.courseRef) return false;
        if (
          email !== "" &&
          detailData.userDetails[activity.userId].username !== email
        )
          return false;
        if (
          schoolRef !== null &&
          detailData.userDetails[activity.userId].schoolRef !== schoolRef
        )
          return false;
        return true;
      }
    );
    if (courseActivities.length + favourite.length === 0) return;

    let uniqueVisitors = new Set();
    courseActivities.forEach((element) => {
      uniqueVisitors.add(element.userId);
    });
    const overview = _.filter(courseActivities, {
      exploreAction: GA_ACTIONS.EXPLORE_SEARCH.CLICK_OVERVIEW,
    });
    const preview = _.filter(courseActivities, {
      exploreAction: GA_ACTIONS.EXPLORE_SEARCH.CLICK_PREVIEW,
    });
    const classroomCreation = _.filter(courseActivities, (activity) =>
      [
        GA_ACTIONS.EXPLORE_SEARCH.CREATE_CLASSROOM_PREVIEW,
        GA_ACTIONS.EXPLORE_SEARCH.CREATE_CLASSROOM_OVERVIEW,
      ].includes(activity.exploreAction)
    );
    course.favourite = favourite;
    course.favouriteNum = favourite.length;
    course.uniqueVisitors = Array.from(uniqueVisitors);
    course.uniqueVisitorsNum = course.uniqueVisitors.length;
    course.overview = overview;
    course.overviewNum = overview.length;
    course.preview = preview;
    course.previewNum = preview.length;
    course.classroomCreation = classroomCreation;
    course.classroomCreationNum = classroomCreation.length;
    course.subject = course.countryMetadata
      ? course.countryMetadata[region].subject.join(", ")
      : "";
    course.yearLevels = course.countryMetadata
      ? findRanges(course.countryMetadata[region].levels)
      : "";
    displayData.push(course);
  });
  return displayData;
};

const SiteTeacherExploreTable = ({ schoolRef, email }) => {
  const [originalData, setOriginalData] = useState(null);
  const [displayData, setDisplayData] = useState(null);
  useEffect(() => {
    const data = {
      timezoneStr: "Australia/Melbourne",
    };
    getAllTeacherExplore(data, (data) => {
      setOriginalData(data);
      const preprocessedData = preprocessData(data);
      setDisplayData(preprocessedData);
    });
  }, []);

  useEffect(() => {
    setDisplayData(preprocessData(originalData, schoolRef, email));
  }, [schoolRef, email]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSortProps = (dataIndex, type = Number) => {
    return {
      sorter: {
        compare: (a, b) => {
          if (type === String)
            return (b[dataIndex] || "").localeCompare(a[dataIndex] || "");
          if (type === Number) return a[dataIndex] - b[dataIndex];
        },
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
    };
  };

  const columns = [
    {
      title: "Course",
      dataIndex: "projectTitle",
      key: "projectTitle",
      width: "8rem",
      fixed: "left",
      className: "bg-white",
      ...getColumnSortProps("projectTitle", String),
      ...getColumnSearchProps("projectTitle"),
    },
    {
      title: "Length",
      dataIndex: "courseType",
      key: "courseType",
      render: (text) => text.toLowerCase(),
      width: "6rem",
      fixed: "left",
      ...getColumnSortProps("courseType", String),
      ...getColumnSearchProps("courseType"),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: "6rem",
      fixed: "left",
      className: "bg-white",
      ...getColumnSortProps("subject", String),
      ...getColumnSearchProps("subject"),
    },
    {
      title: "Year",
      dataIndex: "yearLevels",
      key: "yearLevels",
      width: "6rem",
      fixed: "left",
      className: "bg-white",
      ...getColumnSortProps("yearLevels", String),
      ...getColumnSearchProps("yearLevels"),
    },
    {
      title: "Unique Visitors",
      dataIndex: "uniqueVisitorsNum",
      key: "uniqueVisitorsNum",
      width: "8rem",
      ...getColumnSortProps("uniqueVisitorsNum", Number),
    },
    {
      title: "Overview Clicks",
      dataIndex: "overviewNum",
      key: "overviewNum",
      width: "6rem",
      ...getColumnSortProps("overviewNum", Number),
    },
    {
      title: "Preview",
      dataIndex: "previewNum",
      key: "previewNum",
      width: "8rem",
      ...getColumnSortProps("previewNum", Number),
    },
    {
      title: "Favourite",
      dataIndex: "favouriteNum",
      key: "favouriteNum",
      width: "8rem",
      ...getColumnSortProps("favouriteNum", Number),
    },
    {
      title: "Classroom Created",
      dataIndex: "classroomCreationNum",
      key: "classroomCreationNum",
      width: "7rem",
      ...getColumnSortProps("classroomCreationNum", Number),
    },
    {
      title: "",
      dataIndex: "courseRef",
      key: "courseRef",
      render: (text, record) => {
        return (
          <ModalButton
            buttonText="View"
            modalTitle={record.projectTitle}
            variant="link"
            className="p-0 text-sm"
            modalSize="xl"
          >
            <>
              <CourseExploreUsers
                courseExploreData={record}
                originalData={originalData}
              />
            </>
          </ModalButton>
        );
      },
      width: "3rem",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={displayData}
      scroll={{ x: "calc(100% - 18rem)", y: 500 }}
      bordered
    />
  );
};

const CourseExploreUsers = ({ courseExploreData, originalData }) => {
  const userColumns = [
    {
      title: "School",
      dataIndex: "schoolName",
      key: "schoolName",
      width: "8rem",
    },
    {
      title: "Teacher",
      dataIndex: "fullName",
      key: "fullName",
      width: "16rem",
    },
    {
      title: "Email",
      dataIndex: "username",
      key: "username",
      width: "20rem",
    },
  ];
  const activityColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "8rem",
    },
    {
      title: "School",
      dataIndex: "schoolName",
      key: "schoolName",
      render: (text, record) =>
        originalData.userDetails[record.userId].schoolName,
      width: "8rem",
    },
    {
      title: "Teacher",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) =>
        originalData.userDetails[record.userId].firstName +
        " " +
        originalData.userDetails[record.userId].lastName,
      width: "16rem",
    },
    {
      title: "Email",
      dataIndex: "username",
      key: "username",
      render: (text, record) =>
        originalData.userDetails[record.userId].username,
      width: "20rem",
    },
  ];
  return (
    <>
      <h4>Overview</h4>
      <Table
        columns={activityColumns}
        dataSource={courseExploreData.overview}
        scroll={{ x: "calc(100% - 18rem)", y: 500 }}
        bordered
      />
      <h4>Preview</h4>
      <Table
        columns={activityColumns}
        dataSource={courseExploreData.preview}
        scroll={{ x: "calc(100% - 18rem)", y: 500 }}
        bordered
      />
      <h4>Create Classroom</h4>
      <Table
        columns={activityColumns}
        dataSource={courseExploreData.classroomCreation}
        scroll={{ x: "calc(100% - 18rem)", y: 500 }}
        bordered
      />
      <h4>Favourite</h4>
      <Table
        columns={userColumns}
        dataSource={courseExploreData.favourite}
        scroll={{ x: "calc(100% - 18rem)", y: 500 }}
        bordered
      />
    </>
  );
};

export default SiteTeacherExploreTable;
