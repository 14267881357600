// Modified Copy of the original TaskCreateEditForm
import React, { useState, useEffect, useRef } from "react";
import { Button, Tab, Tabs, Card, Modal } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { GENERIC_EDITOR_MODES, TaskContentType } from "../../utils/constants";
import RichTextEditor from "../util/RichTextEditor";
import { loadTaskDetail, userUpdateTask } from "../../actions";
import {
  handleGenericSaveFailed,
  handleGenericSaveSuccess,
} from "../../utils/CreateEditFormUtils";
import ImageHeaderWithTitle from "../util/ImageHeaderWithTitle";
import UserTaskResourceBlockEditor from "./resourceBlock/UserTaskResourceBlockEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Alert, Input, InputNumber, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import SingleTaskView from "./SingleTaskView";
import CanvaEmbeddedSlidesContainer from "../util/CanvaEmbeddedSlidesContainer";

const TaskDescriptionInfo = ({
  taskDetailData,
  handleStartEdit,
  handleEndEdit,
  submitData,
}) => {
  const [mode, setMode] = useState(GENERIC_EDITOR_MODES.DISPLAY);
  const [updatedTitle, setUpdatedTitle] = useState(null);
  const [updatedDescription, setUpdatedDescription] = useState(null);
  const [updatedSuggestedTime, setUpdatedSuggestedTime] = useState(null);
  return (
    <>
      <Card
        className={
          mode === GENERIC_EDITOR_MODES.EDIT ? "border-success border-3" : ""
        }
      >
        <Card.Header
          className="bg-primary text-white d-flex justify-content-between"
          style={{ alignItems: "center" }}
        >
          <div
            className="d-block d-md-flex justify-content-between"
            style={{ minHeight: "3rem" }}
          >
            <div className="my-auto">
              {mode === GENERIC_EDITOR_MODES.EDIT ? (
                <Input
                  size="large"
                  className="mb-3"
                  maxLength="80"
                  style={{ minWidth: "20rem" }}
                  defaultValue={taskDetailData.title}
                  onChange={(e) => setUpdatedTitle(e.target.value)}
                />
              ) : (
                <h5 className="text-white m-0">{taskDetailData.title}</h5>
              )}
              {taskDetailData.suggestedTime && (
                <div className="text-xs text-white">
                  Estimated time:{" "}
                  {mode === GENERIC_EDITOR_MODES.EDIT ? (
                    <InputNumber
                      min={0}
                      max={999}
                      defaultValue={taskDetailData.suggestedTime}
                      onChange={(value) => {
                        setUpdatedSuggestedTime(value);
                      }}
                    />
                  ) : (
                    taskDetailData.suggestedTime
                  )}{" "}
                  minutes
                </div>
              )}
            </div>
          </div>

          <Tooltip title={"Edit this section"}>
            <Button
              size="sm"
              variant="warning text-white rounded-circle btn-icon-only"
              disabled={mode === GENERIC_EDITOR_MODES.EDIT}
              onClick={() => {
                if (handleStartEdit("Task Description") === false) {
                  return;
                }
                setMode(GENERIC_EDITOR_MODES.EDIT);
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Tooltip>
        </Card.Header>
        <Card.Body>
          <Card.Title className="text-primary">Task Requirements</Card.Title>
          {mode === GENERIC_EDITOR_MODES.EDIT ? (
            <TextArea
              rows={4}
              defaultValue={taskDetailData.description}
              maxLength={500}
              onChange={(e) => setUpdatedDescription(e.target.value)}
            />
          ) : (
            <Card.Text>{taskDetailData.description}</Card.Text>
          )}
          {mode === GENERIC_EDITOR_MODES.EDIT && (
            <div className="mt-3">
              <Button
                variant="danger"
                onClick={() => {
                  // if no changes are made, just close the editor
                  if (
                    !updatedTitle &&
                    !updatedDescription &&
                    !updatedSuggestedTime
                  ) {
                    setMode(GENERIC_EDITOR_MODES.DISPLAY);
                    handleEndEdit();
                    return;
                  }

                  if (
                    !window.confirm(
                      "You will lose edited data. Are you sure you want to cancel?"
                    )
                  )
                    return;
                  setUpdatedTitle(null);
                  setUpdatedDescription(null);
                  setUpdatedSuggestedTime(null);
                  setMode(GENERIC_EDITOR_MODES.DISPLAY);
                  handleEndEdit();
                }}
              >
                Cancel
              </Button>
              <Button
                className="float-right"
                variant="success"
                onClick={async () => {
                  if (
                    !updatedTitle &&
                    !updatedDescription &&
                    !updatedSuggestedTime
                  ) {
                    await submitData(null, null, true);
                    setMode(GENERIC_EDITOR_MODES.DISPLAY);
                    return;
                  }
                  const form = {};
                  if (updatedTitle) form.title = updatedTitle;
                  if (updatedDescription) form.description = updatedDescription;
                  if (updatedSuggestedTime)
                    form.suggestedTime = updatedSuggestedTime;
                  await submitData(form, null, true);
                  setMode(GENERIC_EDITOR_MODES.DISPLAY);
                }}
              >
                Save & Preview
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

const TaskBodyEditorCard = ({
  task,
  taskContentType,
  onChange,
  handleStartEdit,
  handleEndEdit,
  submitData,
}) => {
  const [mode, setMode] = useState(GENERIC_EDITOR_MODES.DISPLAY);
  const [updatedContent, setUpdatedContent] = useState(null);
  const content =
    taskContentType === TaskContentType.BODY ? task.body : task.teacherContent;
  return (
    <Card
      className={
        mode === GENERIC_EDITOR_MODES.EDIT
          ? "shadow-lg border-success border-3"
          : ""
      }
    >
      <Card.Body>
        <Card.Title className="w-100">
          &nbsp;
          <div className="float-right">
            <Tooltip title={"Edit this section"}>
              <Button
                size="sm"
                variant="warning text-white rounded-circle btn-icon-only"
                disabled={mode === GENERIC_EDITOR_MODES.EDIT}
                onClick={() => {
                  if (
                    handleStartEdit(
                      taskContentType === TaskContentType.BODY
                        ? "Task Overview"
                        : "Teacher Notes"
                    ) === false
                  ) {
                    return;
                  }
                  setMode(GENERIC_EDITOR_MODES.EDIT);
                }}
              >
                <FontAwesomeIcon icon={faPen} />
              </Button>
            </Tooltip>
          </div>
        </Card.Title>
        {mode === GENERIC_EDITOR_MODES.EDIT ? (
          <>
            <RichTextEditor data={content} onChange={setUpdatedContent} />
            <Button
              variant="danger"
              onClick={() => {
                // if no changes are made, just close the editor
                if (!updatedContent) {
                  setMode(GENERIC_EDITOR_MODES.DISPLAY);
                  handleEndEdit();
                  return;
                }
                if (
                  !window.confirm(
                    "You will lose edited data. Are you sure you want to cancel?"
                  )
                )
                  return;
                setUpdatedContent(null);
                setMode(GENERIC_EDITOR_MODES.DISPLAY);
                handleEndEdit();
              }}
            >
              Cancel
            </Button>
            <Button
              className="float-right"
              variant="success"
              onClick={async () => {
                if (!updatedContent) {
                  // No changes have been made
                  await submitData(null, null, true);
                  setMode(GENERIC_EDITOR_MODES.DISPLAY);
                  return;
                }
                const form = {};
                if (taskContentType === TaskContentType.BODY)
                  form.body = updatedContent;
                else form.teacherContent = updatedContent;
                await submitData(form, null, true);
                setMode(GENERIC_EDITOR_MODES.DISPLAY);
              }}
            >
              Save & Preview
            </Button>
          </>
        ) : (
          <div
            className="ck-content"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        )}
      </Card.Body>
    </Card>
  );
};

const TaskCreateEditForm = ({
  taskRef,
  mode,
  onSaveSuccess,
  onStartEdit,
  onEndEdit,
}) => {
  const { addToast } = useToasts();
  const [shouldReloadTask, setShouldReloadTask] = useState(false);
  const [taskDetailData, setTaskDetailData] = useState(null);
  const [updatedTaskDetail, setUpdatedTaskDetail] = useState({
    body: null,
    teacherContent: null,
    taskResourceBlockList: null,
  });
  const [isProceedClicked, setIsProceedClicked] = useState(false); // TODO: add spinner if is slow for saving
  const [showPreview, setShowPreview] = useState(false);
  const [editInProgress, setEditInProgress] = useState(false);
  const editInProgressRef = useRef(false);
  const editInProgressTitle = useRef(null);

  useEffect(() => {
    if (editInProgress) window.onbeforeunload = () => true;
    else window.onbeforeunload = null;
  }, [editInProgress]);

  useEffect(() => {
    if (mode === "edit") {
      loadTaskDetail(taskRef, (data) => {
        updateTaskDetailStates(data);
      });
    }
  }, [taskRef, mode]);

  useEffect(() => {
    if (shouldReloadTask) {
      loadTaskDetail(taskRef, (data) => {
        updateTaskDetailStates(data);
        setShouldReloadTask(false);
      });
    }
  }, [shouldReloadTask]);

  const handleStartEdit = (name) => {
    if (!editInProgressRef.current) {
      editInProgressRef.current = true;
      editInProgressTitle.current = name;
      setEditInProgress(true);
      onStartEdit();
      return true;
    } else {
      alert(
        `You have unsaved changes in the ${editInProgressTitle.current} block. Save or discard your changes there before editing another block`
      );
      return false;
    }
  };
  const handleEndEdit = () => {
    editInProgressRef.current = false;
    setEditInProgress(false);
    onEndEdit();
  };

  const updateTaskDetailStates = (data) => {
    setTaskDetailData(data);
    setUpdatedTaskDetail({
      ...updatedTaskDetail,
      bodyFiles: data.bodyAttachmentList,
      teacherContentFiles: data.teacherContentAttachmentList,
    });
  };

  const handleSubmitSuccess = (data) => {
    // Data might be inaccurate, so we reload the task
    setShouldReloadTask(true);
    setIsProceedClicked(false);
    handleGenericSaveSuccess(
      addToast,
      { object: "task", operation: "saved" },
      onSaveSuccess
    );
  };

  const handleSubmitFail = () => {
    setIsProceedClicked(false);
    handleGenericSaveFailed(addToast);
  };

  const submitData = async (
    form,
    updatedTaskDetail,
    shouldShowPreview = false
  ) => {
    if (!form && !updatedTaskDetail) {
      // No changes have been made
      handleEndEdit();
      if (shouldShowPreview) setShowPreview(true);
      return;
    }
    setIsProceedClicked(true);
    const data = {};
    if (form?.body) data.body = form.body;
    if (form?.teacherContent) data.teacherContent = form.teacherContent;
    if (form?.title) data.title = form.title;
    if (form?.description) data.description = form.description;
    if (form?.suggestedTime) data.suggestedTime = form.suggestedTime;
    // if form has canvaEmbeddedSlidesUrl and it is either a legit string or "", update it
    if (form?.canvaEmbeddedSlidesUrl !== undefined)
      data.canvaEmbeddedSlidesUrl = form.canvaEmbeddedSlidesUrl;

    if (updatedTaskDetail) {
      data.taskResourceBlockList = updatedTaskDetail.taskResourceBlockList
        ? updatedTaskDetail.taskResourceBlockList
        : taskDetailData
        ? taskDetailData.taskResourceBlockList
        : null;
    }

    switch (mode) {
      case "edit":
        data.taskRef = taskRef;
        userUpdateTask(
          data,
          (data) => {
            // TODO: Fix this rerendering
            handleSubmitSuccess(data);
            handleEndEdit();
            if (shouldShowPreview) setShowPreview(true);
          },
          handleSubmitFail
        );
        break;
      default:
        console.warn(`No mode called: ${mode}`);
    }
  };

  return (
    ((mode === "edit" && taskDetailData) || mode === "create") && (
      <div className="text-dark">
        <Modal
          size="lg"
          show={showPreview}
          onHide={() => {
            setShowPreview(false);
            return true;
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Task Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SingleTaskView taskRef={taskRef} />
          </Modal.Body>
        </Modal>
        <ImageHeaderWithTitle
          title={taskDetailData.title}
          thumbnail={taskDetailData.thumbnail}
        />
        <div className="my-4">
          <TaskDescriptionInfo
            taskDetailData={taskDetailData}
            handleStartEdit={handleStartEdit}
            handleEndEdit={handleEndEdit}
            submitData={submitData}
          />
        </div>
        <Tabs
          variant="pills"
          className="d-flex justify-content-center"
          defaultActiveKey="body"
          id="uncontrolled-tab-example"
        >
          <Tab eventKey="body" title="Task Overview">
            <div className="mt-4">
              <TaskBodyEditorCard
                task={taskDetailData}
                taskContentType={TaskContentType.BODY}
                handleStartEdit={handleStartEdit}
                handleEndEdit={handleEndEdit}
                submitData={submitData}
              />
            </div>
            <div className="mt-3">
              <UserTaskResourceBlockEditor
                blocks={taskDetailData?.taskResourceBlockList}
                handleStartEdit={handleStartEdit}
                handleEndEdit={handleEndEdit}
                onUpdate={async (blocks, shouldShowPreview = true) => {
                  const tempData = {
                    ...updatedTaskDetail,
                    taskResourceBlockList: blocks,
                  };
                  await setUpdatedTaskDetail(tempData);
                  await submitData(null, tempData, shouldShowPreview);
                }}
              />
            </div>
          </Tab>
          {taskDetailData?.canvaEmbeddedSlidesUrl && (
            <Tab eventKey="slides" title="Slides">
              <Alert
                showIcon
                className="mt-3 ml-3"
                message="You cannot edit the slides"
                style={{ width: "fit-content" }}
                type="warning"
                closable
              />
              <Button
                variant="danger"
                size="sm"
                className="mt-3 ml-3"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete the slides? This action cannot be undone."
                    )
                  ) {
                    submitData({ canvaEmbeddedSlidesUrl: "" }, null, false);
                    // jump back to the body tab
                    document
                      .getElementById("uncontrolled-tab-example-tab-body")
                      .click();
                  }
                }}
              >
                Delete Slides
              </Button>
              <CanvaEmbeddedSlidesContainer
                canvaEmbeddedSlidesUrl={taskDetailData?.canvaEmbeddedSlidesUrl}
              />
            </Tab>
          )}
          <Tab eventKey="teaching" title="Teacher Notes">
            <div className="mt-4">
              <TaskBodyEditorCard
                task={taskDetailData}
                taskContentType={TaskContentType.TEACHER_CONTENT}
                handleStartEdit={handleStartEdit}
                handleEndEdit={handleEndEdit}
                submitData={submitData}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    )
  );
};

export default TaskCreateEditForm;
