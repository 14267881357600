import React from "react";
import "../../style/tooltip.css";

// Use this tooltip when a modal is required to popup
const Tooltip = ({ children, title }) => {
  return (
    <div className="custom-tooltip d-flex justify-content-center">
      {children}
      {title ? <span className="custom-tooltiptext">{title}</span> : null}
    </div>
  );
};

export default Tooltip;
