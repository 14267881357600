import React from "react";
import PublicHeader from "../../components/util/PublicHeader";
import Footer from "../../components/util/Footer";

const PublicHeaderPageContainer = (props) => {
  return (
    <>
      <div className="app application-offset">
        <div
          className="container-fluid container-application"
          style={{ maxWidth: "88.75rem" }}
        >
          <PublicHeader />
          {props.children}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PublicHeaderPageContainer;
