import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Form,
  Input,
  Popconfirm,
  Table,
  Typography,
  Button,
  DatePicker,
  Select,
  Space,
} from "antd";
import { Tab, Tabs } from "react-bootstrap";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import ModalButton from "../util/ModalButton";
import { useToasts } from "react-toast-notifications";
import { loadUserList, updatePlanAndSchool } from "../../actions";
import { RoleType, USER_PLAN_TYPES } from "../../utils/constants";
import { deepCopy } from "../../utils/GenericUtils";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import UserBulkEditForm from "../createEditForms/UserBulkEditForm";
import UserDeactivateForm from "../createEditForms/UserDeactivateForm";
import { isSubscriptionPlanExpired } from "../../utils/DataLogicUtils";
import SchoolListDropDown from "../school/SchoolListDropdown";
import AntdTableDownloadExcelButton from "../util/AntdTableDownloadExcelButton";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const displayDateFormat = "DD/MM/YYYY";
const dateFormat = "YYYY-MM-DD";

const getPlanNodeItems = (dataIndex, record) => {
  if (!record || !dataIndex) return null;
  let node = null;
  const CustomisedFormItem = ({ children }) => {
    return (
      <Form.Item
        name={`edited_${dataIndex}`}
        className="p-0 m-0"
        key={record.id}
      >
        {children}
      </Form.Item>
    );
  };
  if (dataIndex === "planDisplay")
    node = (
      <CustomisedFormItem>
        <Select
          key={record.id}
          defaultValue={record ? record.plan : null}
          style={{ width: "6rem" }}
          options={_.values(USER_PLAN_TYPES).map((item) => {
            return { label: item.displayText, value: item.value };
          })}
        />
      </CustomisedFormItem>
    );
  else if (dataIndex === "schoolSelect") {
    node = (
      <SchoolListDropDown
        key={record.id}
        onSchoolChange={(data) => {
          console.log(data);
        }}
        itemKey={record.id}
        name={`edited_${dataIndex}`}
        size=""
        withFormItem={true}
      />
    );
  } else
    node = (
      <CustomisedFormItem>
        <DatePicker
          key={record.id}
          className="px-0 mx-0"
          defaultValue={record[dataIndex] ? dayjs(record[dataIndex]) : null}
          format={displayDateFormat}
          style={{ width: "6rem" }}
        />
      </CustomisedFormItem>
    );
  return node;
};

const getIsPlanExpired = (item) => {
  return isSubscriptionPlanExpired(item.planStart, item.planEnd);
};

const getIsPlanError = (item) => {
  return (
    !(item.planStart && item.planEnd && item.plan) ||
    (item.planStart &&
      item.planEnd &&
      dayjs(item.planEnd).isBefore(item.planStart))
  );
};

const preprocessData = (data) => {
  let processedData = deepCopy(data);
  processedData.forEach((item) => {
    item.key = item.id;
    item.fullName = `${item.firstName} ${item.lastName}\n${item.username}`;
    item.schoolName = item.schoolInfo?.schoolName;
    item.schoolCountry = item.schoolInfo?.country;
    item.schoolState = item.schoolInfo?.state;
    item.schoolRef = item.schoolInfo?.schoolRef;
    item.providedCountry = item.userInfo.signupInfo?.country;
    item.providedSchoolName = item.userInfo.signupInfo?.schoolName;
    item.plan = item.userInfo.plan;
    item.planDisplay = item.plan
      ? USER_PLAN_TYPES[item.plan].displayText
      : null;
    item.planStart = item.userInfo.planStart
      ? item.userInfo.planStart.split("T")[0]
      : null;
    item.planEnd = item.userInfo.planEnd
      ? item.userInfo.planEnd.split("T")[0]
      : null;
  });
  return processedData;
};

const UserManagementList = () => {
  const [userListData, setUserListData] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);
  const [verifiedMode, setVerifiedMode] = useState(true);

  useEffect(() => {
    if (shouldReloadData) {
      const requestData = {
        role: RoleType.TEACHER,
        shouldLoadSchoolInfo: true,
      };
      loadUserList(
        requestData,
        (data) => {
          setUserListData(preprocessData(data));
        },
        () => {}
      );
      setShouldReloadData(false);
    }
  }, [shouldReloadData]);
  return (
    <>
      <div className="white-nav-tab mt-5 mb-3">
        <Tabs
          defaultActiveKey="verified"
          className="font-weight-bold border-0 ml-2"
          unmountOnExit={true}
          onSelect={(k) => setVerifiedMode(k === "verified")}
        >
          <Tab eventKey="verified" title="Verified Users"></Tab>
          <Tab eventKey="unverified" title="Unverified Users"></Tab>
        </Tabs>
      </div>
      <div style={{ position: "relative", minWidth: "84rem" }}>
        {userListData && (
          <DataForm
            verifiedMode={verifiedMode}
            originData={userListData}
            setShouldReloadData={setShouldReloadData}
          />
        )}
      </div>
    </>
  );
};

export default UserManagementList;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => (
  <td {...restProps}>
    {editing ? getPlanNodeItems(dataIndex, record) : children}
  </td>
);
const DataForm = ({ originData, setShouldReloadData, verifiedMode }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { addToast } = useToasts();
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  useEffect(() => {
    setData(originData);
  }, [originData]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [verifiedMode]);

  const edit = (record) => {
    form.resetFields();
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const confirmCreateNewPlanRecord = (originalRowData, editedRow) => {
    if (
      !originalRowData ||
      !(
        originalRowData.userInfo.plan ===
          USER_PLAN_TYPES.BASIC_SUBSCRIPTION.value &&
        (!editedRow.plan ||
          editedRow.plan === USER_PLAN_TYPES.BASIC_SUBSCRIPTION.value)
      ) ||
      (!editedRow.planStart && !editedRow.planEnd)
    )
      return false;
    return window.confirm(
      `You are updating the dates of a subscription. Do you want to edit the existing subscription or create a new one?\n
Press 'OK' if you are creating a new subscription (and want to create a new Salesforce booking).\n
Press 'Cancel' if you are editing the dates of an existing subscription (and do not want to create a new booking).`
    );
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      let editedRow = {};
      if (row.edited_schoolSelect) {
        editedRow.schoolRef = row.edited_schoolSelect;
      }
      if (row.edited_planDisplay) {
        editedRow.plan = row.edited_planDisplay;
        editedRow.planDisplay = USER_PLAN_TYPES[editedRow.plan].displayText;
      }
      if (row.edited_planStart)
        editedRow.planStart = row.edited_planStart.format("YYYY-MM-DD");
      if (row.edited_planEnd)
        editedRow.planEnd = row.edited_planEnd.format("YYYY-MM-DD");
      // find original data using key from data
      const originalRowData = data.find((item) => item.key === key);
      const isNewPlanRecord = confirmCreateNewPlanRecord(
        originalRowData,
        editedRow
      );

      // TODO: Validate

      updatePlanAndSchool(
        { ...editedRow, userRefs: [key], isNewPlanRecord },
        () => {
          const newData = [...data];
          const index = newData.findIndex((item) => key === item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...editedRow,
            });
            setData(newData);
            setEditingKey("");
          } else {
            newData.push(row);
            setData(newData);
            setEditingKey("");
          }
          setShouldReloadData(true);
          addToast("Successfully updated!", {
            appearance: "success",
            autoDismiss: true,
          });
        },
        () => {
          addToast("Update failed. Please try again later.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  // Search and filter Related
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSortProps = (dataIndex, type = Number) => {
    return {
      sorter: {
        compare: (a, b) => {
          if (type === String)
            return (b[dataIndex] || "").localeCompare(a[dataIndex] || "");
          if (type === Number) return a[dataIndex] - b[dataIndex];
        },
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
    };
  };

  const getColumnStatusText = (item) => {
    const notStarted = item.planStart && dayjs().isBefore(item.planStart);
    const isPlanExpired = getIsPlanExpired(item);
    if (getIsPlanError(item)) {
      return "Error";
    }
    return notStarted
      ? "Not Started"
      : isPlanExpired === null
      ? ""
      : isPlanExpired
      ? "Expired"
      : "Active";
  };

  const formatColumnDate = (text) => {
    return text ? dayjs(text, dateFormat).format(displayDateFormat) : null;
  };

  const excelColumns = [
    {
      title: "Name",
      __excelTitle__: "Name",
      render: (text, record) => <>{`${record.firstName} ${record.lastName}`}</>,
    },
    {
      title: "user_ref",
      __excelTitle__: "user_ref",
      render: (text, record) => <>{record.id}</>,
    },
    // {
    //   title: "cura_subscription_id",
    //   __excelTitle__: "cura_subscription_id",
    //   render: (text, record) => <>{record.userInfo.planRecordRef}</>,
    // },
    ...(verifiedMode
      ? [
          {
            title: "Role",
            __excelTitle__: "Role",
            dataIndex: "role",
            render: (text, record) => (
              <>{record.userInfo.roleTypeName.toLowerCase()}</>
            ),
          },
          {
            title: "School",
            __excelTitle__: "School",
            dataIndex: "schoolName",
            key: "schoolName",
          },
          {
            title: "Country",
            __excelTitle__: "Country",
            dataIndex: "schoolCountry",
            key: "schoolCountry",
          },
          {
            title: "State",
            __excelTitle__: "State",
            dataIndex: "schoolState",
            key: "schoolState",
          },
        ]
      : []),
    ...(!verifiedMode
      ? [
          {
            title: "Provided School",
            __excelTitle__: "Provided School",
            dataIndex: "providedSchoolName",
            key: "providedSchoolName",
          },
          {
            title: "Provided Country",
            __excelTitle__: "Provided Country",
            dataIndex: "providedCountry",
            key: "providedCountry",
          },
          {
            title: "School",
            __excelTitle__: "School",
            render: (text, record) => "No School",
          },
        ]
      : []),
    {
      title: "Subscription Type",
      __excelTitle__: "Subscription Type",
      dataIndex: "planDisplay",
    },
    {
      title: "Status",
      __excelTitle__: "Status",
      dataIndex: "isPlanExpired",
      render: (text, item) => getColumnStatusText(item),
    },
    {
      title: "Start",
      __excelTitle__: "Start",
      dataIndex: "planStart",
      key: "planStart",
      render: (text, record) => formatColumnDate(text),
    },
    {
      title: "End",
      __excelTitle__: "End",
      dataIndex: "planEnd",
      key: "planEnd",
      render: (text, record) => formatColumnDate(text),
    },
    {
      title: "Date Joined",
      __excelTitle__: "Date Joined",
      dataIndex: "dateJoined",
      render: (text, record) => formatColumnDate(text),
    },
    {
      title: "Last Login",
      __excelTitle__: "Last Login",
      dataIndex: "lastLogin",
      render: (text, record) => formatColumnDate(text),
    },
  ];

  const columns = [
    {
      title: "Name & Email",
      dataIndex: "fullName",
      className: "text-pre-wrap",
      ellipsis: true,
      width: "14rem",
      ...getColumnSortProps("fullName", String),
      ...getColumnSearchProps("fullName"),
    },
    {
      title: "Role",
      dataIndex: "role",
      hidden: !verifiedMode,
      width: "7rem",
      render: (text, record) => (
        <div className="text-capitalize">
          {record.userInfo.roleTypeName.toLowerCase()}
        </div>
      ),
    },
    {
      title: <div className="text-dark">School</div>,
      dataIndex: "schoolName",
      key: "schoolName",
      hidden: !verifiedMode,
      className: "text-primary cursor-pointer",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            window
              .open(
                "/admin/schools/school/" + record.schoolRef + "/dashboard",
                "_blank"
              )
              .focus();
          },
        };
      },
      width: "14rem",
      ...getColumnSortProps("schoolName", String),
      ...getColumnSearchProps("schoolName"),
    },
    {
      title: "Provided School",
      dataIndex: "providedSchoolName",
      key: "providedSchoolName",
      hidden: verifiedMode,
      className: "text-dark",
      render: (text) => <span className="text-sm">{text}</span>,
      ...getColumnSearchProps("providedSchoolName"),
    },
    {
      title: "Provided Country",
      dataIndex: "providedCountry",
      key: "providedCountry",
      hidden: verifiedMode,
      className: "text-dark",
      render: (text) => <span className="text-sm">{text}</span>,
      ...getColumnSearchProps("providedCountry"),
    },
    {
      title: <div className="text-dark">School</div>,
      dataIndex: "schoolSelect",
      key: "schoolSelect",
      hidden: verifiedMode,
      editable: true,
      width: "18rem",
      className: "text-danger cursor-pointer",
      render: (text, record) => "No School",
    },
    {
      title: "Subscription Type",
      dataIndex: "planDisplay",
      width: "8rem",
      editable: true,
      filters: _.values(USER_PLAN_TYPES).map((item) => {
        return { text: item.displayText, value: item.value };
      }),
      onFilter: (value, record) =>
        record.plan !== null && record.plan === value,
    },
    {
      title: "Status",
      dataIndex: "isPlanExpired",
      width: "8rem",
      render: (text, item) => {
        const notStarted = item.planStart && dayjs().isBefore(item.planStart);
        const isPlanExpired = getIsPlanExpired(item);
        if (getIsPlanError(item))
          return <div className="text-danger">Error</div>;
        return (
          <div
            className={
              notStarted ? "" : !isPlanExpired ? "text-success" : "text-danger"
            }
          >
            {getColumnStatusText(item)}
            <br />
            {item.planStart &&
            item.planEnd &&
            dayjs().isSameOrBefore(item.planEnd) &&
            dayjs().isSameOrAfter(item.planStart)
              ? `${-dayjs().diff(item.planEnd, "day") + 1} day(s) left`
              : ""}
            {item.planStart &&
            item.planEnd &&
            dayjs().isSame(item.planEnd, "day")
              ? "Expires today"
              : ""}
          </div>
        );
      },
      filters: [
        {
          text: "Not Started",
          value: "Not Started",
        },
        {
          text: "Active",
          value: false,
        },
        {
          text: "Expired",
          value: true,
        },
        {
          text: "Error",
          value: "Error",
        },
      ],
      onFilter: (value, record) => {
        if (value === "Not Started")
          return record.planStart && dayjs().isBefore(record.planStart);
        if (value === "Error") return getIsPlanError(record);
        const isPlanExpired = getIsPlanExpired(record);
        return isPlanExpired !== null && isPlanExpired === value;
      },
    },
    {
      title: "Start",
      dataIndex: "planStart",
      key: "planStart",
      width: "7rem",
      editable: true,
      render: (text, record) =>
        text ? dayjs(text, dateFormat).format(displayDateFormat) : null,
      ...getColumnSortProps("planStart", String),
    },
    {
      title: "End",
      dataIndex: "planEnd",
      key: "planEnd",
      width: "7rem",
      editable: true,
      render: (text, record) =>
        text ? dayjs(text, dateFormat).format(displayDateFormat) : null,
      ...getColumnSortProps("planEnd", String),
    },
    {
      title: "",
      dataIndex: "operation",
      width: "5rem",
      align: "center",
      hidden: selectedRowKeys.length > 0,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span style={{ width: "10rem" }}>
            <Popconfirm
              title="Sure to save?"
              style={{
                marginRight: 8,
              }}
              onConfirm={() => save(record.key)}
            >
              <a className="text-primary">Save</a>
            </Popconfirm>
            <br />
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              className="mr-3"
              style={{ verticalAlign: "middle" }}
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Typography.Link>
              <ModalButton
                variant="link"
                size="sm"
                buttonText="Delete"
                modalTitle={`Delete User`}
                className="text-danger p-0 border-0"
                s
              >
                <UserDeactivateForm
                  userDetail={record}
                  onSaveSuccess={() => setShouldReloadData(true)}
                />
              </ModalButton>
            </Typography.Link>
          </span>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "planDisplay" ? "text" : "date",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // Selection

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);

    // !!! IMPORTANT Note: By commenting this, we can bulk edit users with a SUBSCRIPTION plan.
    // None of these will create a new record for existing subscription

    // if (isEditingSubscription(newSelectedRowKeys)) {
    //   window.alert(
    //     "You cannot bulk editing a user with a SUBSCRIPTION plan. Please edit them individually."
    //   );
    //   return;
    // }

    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  // const isEditingSubscription = (newKeys) => {
  //   // Check if in any of the selected rows, there is a plan = "BASIC_SUBSCRIPTION" if so, show alert
  //   let hasBasicSubscription = false;
  //   newKeys.forEach((item) => {
  //     const record = data.find((record) => record.key === item);
  //     if (record.plan === "BASIC_SUBSCRIPTION") hasBasicSubscription = true;
  //   });
  //   return hasBasicSubscription;
  // };

  return (
    <Form form={form} component={false}>
      {hasSelected && verifiedMode && (
        <div className="text-light mb-3 float-left">
          <ModalButton
            size="sm"
            variant="outline-light"
            className="mr-3"
            modalSize="sm"
            buttonText="Edit"
            modalTitle="Edit User"
          >
            <UserBulkEditForm
              userRefs={selectedRowKeys}
              onSaveSuccess={() => {
                setShouldReloadData(true);
              }}
            />
          </ModalButton>
          <span>
            Selected {selectedRowKeys.length} user
            {selectedRowKeys.length > 1 && "s"}
          </span>
        </div>
      )}
      <div style={{ position: "absolute", right: 0, top: "-4rem" }}>
        <AntdTableDownloadExcelButton
          className="px-2 text-white"
          variant="link"
          columns={excelColumns.filter((item) => !item.hidden)}
          data={data.filter((item) => {
            return verifiedMode ? item.schoolRef : !item.schoolRef;
          })}
          filename="users"
        />
      </div>

      <Table
        rowSelection={rowSelection}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={deepCopy(data).filter((item) => {
          return verifiedMode ? item.schoolRef : !item.schoolRef;
        })}
        columns={mergedColumns.filter((item) => !item.hidden)}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
          position: ["bottomRight"],
          pageSize: 100,
        }}
      />
    </Form>
  );
};
