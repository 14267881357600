import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import {
  RoleType,
  USER_PLAN_TYPES,
  WEBSITE_LINKS,
} from "../../utils/constants";
import dayjs from "dayjs";
import { useRouteMatch } from "react-router-dom";
import { useLocalStorageState } from "ahooks";

const PlanExpireAlert = ({ userDetailData }) => {
  let { url } = useRouteMatch();
  const [show, setShow] = useState(true);
  const [shownOnDates, setShownOnDates] = useLocalStorageState(
    "user-plan-expire-alert-shown-on",
    ""
  );

  const endingInDays =
    -dayjs().diff(userDetailData.userInfo.planEnd, "day") + 1;

  const shouldShowAlert = () => {
    if (shownOnDates === dayjs().format("YYYY-MM-DD")) return false;
    const DAYS_SHOW_PLAN_ALERT = {
      [USER_PLAN_TYPES.PREVIEW.value]: [7, 3, 1, 0],
      [USER_PLAN_TYPES.TRIAL.value]: [30, 14, 7, 1, 0],
      [USER_PLAN_TYPES.BASIC_SUBSCRIPTION.value]: [30, 14, 7, 1, 0],
    };
    const shouldBeLessThanDays =
      DAYS_SHOW_PLAN_ALERT[userDetailData.userInfo.plan][0];
    if (endingInDays <= shouldBeLessThanDays && endingInDays >= 0) return true;
    return false;
  };

  if (
    show &&
    url.includes("/explore") &&
    userDetailData?.userInfo.roleTypeName === RoleType.TEACHER &&
    shouldShowAlert()
  ) {
    return (
      <Alert
        variant="warning"
        className="mt-3"
        onClose={() => {
          setShownOnDates(dayjs().format("YYYY-MM-DD"));
          setShow(false);
        }}
        dismissible
        style={{ position: "absolute", zIndex: 200 }}
      >
        <span class="badge badge-warning badge-circle text-white mr-2">!</span>
        <span>
          Your{" "}
          {USER_PLAN_TYPES[
            userDetailData.userInfo.plan
          ].displayText.toLowerCase()}{" "}
          is ending{" "}
          {dayjs().isSame(userDetailData.userInfo.planEnd, "day")
            ? "today"
            : `in ${endingInDays} day${endingInDays > 1 ? "s" : ""}`}
        </span>
        <Button
          variant="warning"
          className="text-white ml-2"
          size="xs"
          onClick={() =>
            window.open(WEBSITE_LINKS.CONTACT_US, "_blank").focus()
          }
        >
          Contact Us
        </Button>
      </Alert>
    );
  }
  return <></>;
};

export default PlanExpireAlert;
