import React from "react";
import HeaderPageContainer from "../common/HeaderPageContainer";
import ExploreApp from "./ExploreApp";

const ExplorePage = () => {
  return (
    <HeaderPageContainer>
      <ExploreApp />
    </HeaderPageContainer>
  );
};

export default ExplorePage;
