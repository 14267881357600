import React from "react";
import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { createSBUAdmin } from "../../actions";

const SBUAdminCreateForm = ({ onSaveSuccess, handleCloseForm }) => {
  const { addToast } = useToasts();

  const handleSaveSuccess = () => {
    addToast(`The user has been successfully added`, {
      appearance: "success",
      autoDismiss: true,
    });
    onSaveSuccess();
    if (handleCloseForm) handleCloseForm();
  };

  const handleSaveFailed = () => {
    addToast(`An SBU admin with that account already exists`, {
      appearance: "error",
      autoDismiss: true,
    });
    if (handleCloseForm) handleCloseForm();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const data = {
      firstName: form.elements.firstName.value.trim(),
      lastName: form.elements.lastName.value.trim(),
      email: form.elements.email.value.trim().toLowerCase(),
    };
    createSBUAdmin(
      data,
      () => handleSaveSuccess(),
      () => handleSaveFailed()
    );
  };

  return (
    <>
      <Form className="InputForm" onSubmit={handleSubmit}>
        <InputGroup>
          <Row className="pb-2">
            <Col xs={3}>
              <Form.Label>First name</Form.Label>
              <Form.Control
                inline="true"
                type="firstname"
                name="firstName"
                required
              />
            </Col>
            <Col xs={3}>
              <Form.Label>Last name</Form.Label>
              <Form.Control
                inline="true"
                type="lastname"
                name="lastName"
                required
              />
            </Col>
            <Col xs={5}>
              <Form.Label>Email</Form.Label>
              <Form.Control inline="true" type="email" name="email" required />
            </Col>
          </Row>
        </InputGroup>
        <br /> <br />
        <Button size="md" variant="primary" type="submit" block>
          Save
        </Button>
      </Form>
    </>
  );
};

export default SBUAdminCreateForm;
