import { FAVOURITE_COURSES } from "../actions/types";

const INTIAL_STATE = {
  favouriteCourses: null,
};

const exploreReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case FAVOURITE_COURSES:
      return {
        ...state,
        favouriteCourses: action.payload,
      };
    default:
      return state;
  }
};

export default exploreReducer;
