import React, { useState, useEffect } from "react";
import ClassroomProgressTable from "./ClassroomProgressTable";
import { Button, Card, ToggleButtonGroup } from "react-bootstrap";
import { RoleType } from "../../utils/constants";
import {
  getProgressCourseExec,
  listUserCourseExecLink,
  loadCourseCurriculum,
} from "../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp, faStar } from "@fortawesome/free-solid-svg-icons";

const ProgressPanel = ({ courseExecData }) => {
  const [courseExecUserData, setcourseExecUserData] = useState(null);
  const [studentTaskProgressData, setStudentTaskProgressData] = useState(null);
  const [courseCurriculumData, setCourseCurriculumData] = useState(null);
  const [mode, setMode] = useState("progress"); // progress/score

  useEffect(() => {
    loadCourseCurriculum(courseExecData.course.courseRef, (data) => {
      setCourseCurriculumData(data);
    });

    getProgressCourseExec(courseExecData.courseExecRef, (data) => {
      setStudentTaskProgressData(data);
    });

    const data = {
      courseExecRef: courseExecData.courseExecRef,
      roleTypeName: RoleType.STUDENT,
    };
    listUserCourseExecLink(data, (data) => {
      setcourseExecUserData(data);
    });
  }, [courseExecData]);

  const getTableHeaderData = (courseCurriculumData) => {
    let result = [];
    for (let i = 0; i < courseCurriculumData.sectionOrder.length; i++) {
      const section =
        courseCurriculumData.sections[courseCurriculumData.sectionOrder[i]];
      const linkDict = courseCurriculumData.courseTaskLinks;
      const taskDict = courseCurriculumData.tasks;
      for (let j = 0; j < section.courseTaskLinkList.length; j++) {
        const link = linkDict[section.courseTaskLinkList[j]];
        const taskRef = link.taskRef;
        const isHidden = link.isHidden;
        const task = taskDict[taskRef];
        result.push({
          sectionNumber: i + 1,
          taskNumber: j + 1,
          title: task.title,
          courseTaskLinkRef: section.courseTaskLinkList[j],
          isHidden,
        });
      }
    }
    return result;
  };

  return (
    courseExecUserData &&
    studentTaskProgressData &&
    courseCurriculumData && (
      <Card className="mt-4">
        <Card.Body>
          <h4 className="text-dark mb-4">Progress Dashboard</h4>
          <ToggleButtonGroup type="checkbox" className="mb-2">
            <Button
              className="slider-button rounded-pill border px-6"
              variant={
                mode === "progress"
                  ? "primary bordered border-primary"
                  : "outline-primary border-primary"
              }
              style={{ zIndex: mode === "progress" ? 2 : 0 }}
              value="progress"
              onClick={(e) => setMode(e.currentTarget.value)}
            >
              <FontAwesomeIcon icon={faArrowTrendUp} className="mr-1" /> Course
              Progress
            </Button>
            <Button
              className="slider-button ml-2 rounded-pill border px-6"
              variant={
                mode === "score"
                  ? "warning text-white bordered border-warning"
                  : "outline-warning border-warning"
              }
              style={{ left: "-3rem", zIndex: mode === "score" ? 2 : 0 }}
              value="score"
              onClick={(e) => setMode(e.currentTarget.value)}
            >
              <FontAwesomeIcon icon={faStar} className="mr-1" /> Scores
            </Button>
          </ToggleButtonGroup>
          <ClassroomProgressTable
            courseExecData={courseExecData}
            courseExecUserData={courseExecUserData}
            studentTaskProgressData={studentTaskProgressData}
            tableHeaderData={getTableHeaderData(courseCurriculumData)}
            mode={mode}
          />
        </Card.Body>
      </Card>
    )
  );
};

export default ProgressPanel;
