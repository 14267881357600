import dayjs from "dayjs";
import { TASK_RESOURCE_BLOCK_TYPE } from "./constants";

export const getFirstCourseTaskLinkRefFromCurriculum = (
  courseCurriculumData
) => {
  if (!courseCurriculumData) return null;
  for (const sectionRef of courseCurriculumData.sectionOrder) {
    const section = courseCurriculumData.sections[sectionRef];
    if (section.courseTaskLinkList.length > 0) {
      return section.courseTaskLinkList[0];
    }
  }
  return null;
};

export const getAllDisplayTaskResourceBlocks = (originalBlockList) => {
  let result = [];
  let blockList = JSON.parse(JSON.stringify(originalBlockList));
  let questionIndex = 1;
  for (let index = 0; index < blockList.length; index++) {
    if (blockList[index].resourceType === TASK_RESOURCE_BLOCK_TYPE.RUBRIC)
      continue;
    if (blockList[index].resourceType === TASK_RESOURCE_BLOCK_TYPE.CONTENT) {
      result.push(blockList[index]);
      continue;
    }
    let questionBlock = blockList[index];
    questionBlock.questionTotalScore = questionBlock.rubric
      ? parseFloat(questionBlock.rubric.totalScore)
      : 0;
    questionBlock.questionIndex = questionIndex;
    questionIndex++;
    questionBlock.rubricBlocks = [];
    for (let i = index + 1; i < blockList.length; i++) {
      if (blockList[i].resourceType !== TASK_RESOURCE_BLOCK_TYPE.RUBRIC) break;
      questionBlock.rubricBlocks.push(blockList[i]);
      questionBlock.questionTotalScore += blockList[i].rubric
        ? parseFloat(blockList[i].rubric.totalScore)
        : 0;
    }
    result.push(questionBlock);
  }
  return result;
};

export const getQuestionBlocks = (blockList) => {
  return getAllDisplayTaskResourceBlocks(blockList).filter((block) =>
    [
      TASK_RESOURCE_BLOCK_TYPE.QUESTION,
      TASK_RESOURCE_BLOCK_TYPE.TABLE,
    ].includes(block.resourceType)
  );
};

export const isSubscriptionPlanExpired = (planStart, planEnd) => {
  if (planEnd && dayjs().isAfter(planEnd, "day")) return true;
  if (planStart && dayjs().isBefore(planStart, "day")) return true;
  if (
    planEnd &&
    planStart &&
    dayjs().isSameOrBefore(planEnd, "day") &&
    dayjs().isSameOrAfter(planStart, "day")
  ) {
    return false;
  }
  return null;
};

export const hasValidPlanData = (userDetail) => {
  const userInfo = userDetail?.userInfo;
  if (!userInfo) return null;
  return userInfo.plan && userInfo.planStart && userInfo.planEnd;
};
