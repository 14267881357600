import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { Button, Input, Space, Table } from "antd";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import CalendarHeatmap from "react-calendar-heatmap";
import {
  getAllTeacherExplore,
  getSchoolTeacherActivity,
} from "../../../actions";
import ModalButton from "../../../components/util/ModalButton";
import { AWS_REGION_COUNTRY_MAP, GA_ACTIONS } from "../../../utils/constants";
import { deepCopy, findRanges } from "../../../utils/GenericUtils";
import "../../../style/calendar-heatmap.css";

const YearsCalendarHeatmap = ({ dates }) => {
  const [yearDatesDict, setYearDatesDict] = useState(null);
  const [displayYears, setDisplayYears] = useState(null);

  const groupDatesByYear = (dates) => {
    const groupedDates = {};
    for (const date of dates) {
      const year = date.split("-")[0];
      if (!groupedDates.hasOwnProperty(year)) {
        groupedDates[year] = [];
      }
      groupedDates[year].push(date);
    }
    return groupedDates;
  };

  useEffect(() => {
    const yearDatesDict = groupDatesByYear(dates);
    const years = Object.keys(yearDatesDict);
    years.sort().reverse();
    setYearDatesDict(yearDatesDict);
    setDisplayYears(years);
  }, []);

  return (
    yearDatesDict && (
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={displayYears.length ? displayYears[0] : null}
      >
        <Row>
          <Col sm={1}>
            <Nav variant="pills" className="flex-column">
              {displayYears.map((year) => (
                <Nav.Item>
                  <Nav.Link eventKey={year} className="text-center">
                    {year}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={11}>
            <Tab.Content>
              {displayYears.map((year) => (
                <Tab.Pane eventKey={year}>
                  <CalendarHeatmap
                    startDate={new Date(parseInt(year) - 1 + "-12-31")}
                    endDate={new Date(parseInt(year) + 1 + "-01-01")}
                    values={yearDatesDict[year].map((date) => {
                      return {
                        date: new Date(date),
                        dateStr: date,
                        count: 1,
                      };
                    })}
                    classForValue={(value) => {
                      if (!value) {
                        return "color-empty";
                      }
                      return `color-github-2`;
                    }}
                    tooltipDataAttrs={(value) => {
                      return {
                        "data-tip": value.dateStr,
                      };
                    }}
                    showWeekdayLabels={true}
                  />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    )
  );
};

const getStatusText = (record) => {
  const lastLogin = record.lastLogin;
  if (lastLogin === null) return "N/A";
  const daysSinceLastLogin =
    (Date.now() - Date.parse(lastLogin)) / (24 * 60 * 60 * 1000);
  if (daysSinceLastLogin >= 70) return "Inactive";
  if (daysSinceLastLogin < 70 && daysSinceLastLogin > 35) return "At Risk";
  return "Active";
};

const preprocessData = (data) => {
  data.forEach((item) => {
    item.displayStatus = getStatusText(item);
    item.fullName = item.firstName + " " + item.lastName;
  });
  return data;
};

const SchoolTeacherActivityTable = ({ schoolRef }) => {
  const [teacherActivityData, setTeacherActivityData] = useState(null);
  const [originalAllTeacherExploreData, setOriginalAllTeacherExploreData] =
    useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSortProps = (dataIndex, type = Number) => {
    return {
      sorter: {
        compare: (a, b) => {
          if (type === String)
            return (b[dataIndex] || "").localeCompare(a[dataIndex] || "");
          if (type === Number) return a[dataIndex] - b[dataIndex];
        },
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
    };
  };
  useEffect(() => {
    const data = {
      schoolRef,
      timezoneStr: "Australia/Melbourne",
      startTimestamp: 1,
    };
    getSchoolTeacherActivity(data, (data) => {
      setTeacherActivityData(preprocessData(data));
      const dataReq = {
        schoolRef,
        timezoneStr: "Australia/Melbourne",
      };
      getAllTeacherExplore(dataReq, (data) => {
        setOriginalAllTeacherExploreData(data);
      });
    });
  }, [schoolRef]);
  const columns = [
    {
      title: "Teacher",
      dataIndex: "fullName",
      key: "fullName",
      width: "16rem",
      fixed: "left",
      className: "bg-white",
      ...getColumnSortProps("fullName", String),
      ...getColumnSearchProps("fullName"),
    },
    {
      title: "Status",
      dataIndex: "displayStatus",
      key: "displayStatus",
      width: "6rem",
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "At Risk",
          value: "At Risk",
        },
        {
          text: "N/A",
          value: "N/A",
        },
        {
          text: "Inactive",
          value: "Inactive",
        },
      ],
      onFilter: (value, record) => record.displayStatus.indexOf(value) === 0,
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      key: "lastLogin",
      ellipsis: true,
      width: "9rem",
      ...getColumnSortProps("lastLogin", String),
      ...getColumnSearchProps("lastLogin"),
    },
    {
      title: "Total Logins",
      dataIndex: "activeDatesCount",
      key: "activeDatesCount",
      ellipsis: true,
      width: "9rem",
      ...getColumnSortProps("activeDatesCount", Number),
    },
    {
      title: "Date Joined",
      dataIndex: "dateJoined",
      key: "dateJoined",
      ellipsis: true,
      width: "10rem",
      ...getColumnSortProps("dateJoined", String),
      ...getColumnSearchProps("dateJoined"),
    },
    {
      title: "Email",
      dataIndex: "username",
      key: "username",
      width: "20rem",
      ...getColumnSortProps("username", String),
      ...getColumnSearchProps("username"),
    },
    {
      title: "Classrooms",
      dataIndex: "classroomCount",
      key: "classroomCount",
      width: "8rem",
      ...getColumnSortProps("classroomCount", Number),
    },
    {
      title: "Classroom Created",
      dataIndex: "createClassroomCount",
      key: "createClassroomCount",
      width: "7rem",
      ...getColumnSortProps("createClassroomCount", Number),
    },
    {
      title: "Course Overview",
      dataIndex: "clickOverviewCourseCount",
      key: "clickOverviewCourseCount",
      width: "6rem",
      ...getColumnSortProps("clickOverviewCourseCount", Number),
    },
    {
      title: "Course Preview",
      dataIndex: "clickPreviewCourseCount",
      key: "clickPreviewCourseCount",
      width: "6rem",
      ...getColumnSortProps("clickPreviewCourseCount", Number),
    },
    {
      title: "Favourite",
      dataIndex: "favouriteCount",
      key: "favouriteCount",
      width: "6rem",
      ...getColumnSortProps("favouriteCount", Number),
    },
    {
      title: "Details",
      dataIndex: "courseRef",
      key: "courseRef",
      render: (text, record) => {
        return (
          <ModalButton
            buttonText="View"
            modalTitle={record.fullName}
            variant="link"
            className="p-0 text-sm"
            modalSize="xl"
          >
            <>
              {originalAllTeacherExploreData && (
                <UserActivityDetailTablesView
                  originalData={originalAllTeacherExploreData}
                  row={record}
                />
              )}
            </>
          </ModalButton>
        );
      },
      fixed: "right",
      width: "5rem",
    },
  ];

  const UserActivityDetailTablesView = ({ originalData, row }) => {
    const preprocessData = (
      data,
      schoolRef = null,
      email = "",
      userRef = ""
    ) => {
      if (!data || !(userRef in data.userDetails))
        return {
          favourite: null,
          overview: null,
          preview: null,
          classroomCreation: null,
        };
      const detailData = deepCopy(data);
      // Favourite
      let favouriteActivities = detailData.userDetails[userRef]
        ? detailData.userDetails[userRef].favouriteActivities
        : [];
      favouriteActivities.map(
        (x) => (x.date = dayjs(x.datetime).format("YYYY-MM-DD"))
      );
      favouriteActivities = _.sortBy(favouriteActivities, [
        (o) => o.datetime,
      ]).reverse();
      const courseActivities = _.filter(
        detailData.exploreActivities,
        (activity) => activity.userId === userRef
      );
      if (courseActivities.length + favouriteActivities.length === 0) return;

      const overview = _.filter(courseActivities, {
        exploreAction: GA_ACTIONS.EXPLORE_SEARCH.CLICK_OVERVIEW,
      });
      const preview = _.filter(courseActivities, {
        exploreAction: GA_ACTIONS.EXPLORE_SEARCH.CLICK_PREVIEW,
      });
      const classroomCreation = _.filter(courseActivities, (activity) =>
        [
          GA_ACTIONS.EXPLORE_SEARCH.CREATE_CLASSROOM_PREVIEW,
          GA_ACTIONS.EXPLORE_SEARCH.CREATE_CLASSROOM_OVERVIEW,
        ].includes(activity.exploreAction)
      );
      let activities = {};
      activities.favourite = favouriteActivities;
      activities.overview = overview;
      activities.preview = preview;
      activities.classroomCreation = classroomCreation;

      return activities;
    };

    const region = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];
    const activityColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: "8rem",
      },
      {
        title: "Course",
        dataIndex: "projectTitle",
        key: "projectTitle",
        render: (text, record) => {
          return originalData.courseDetails[record.courseRef]?.projectTitle;
        },
        width: "8rem",
      },
      {
        title: "Length",
        dataIndex: "courseType",
        key: "courseType",
        render: (text, record) => {
          return originalData.courseDetails[record.courseRef]?.courseType;
        },
        width: "8rem",
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "firstName",
        render: (text, record) =>
          originalData.courseDetails[record.courseRef]?.countryMetadata[
            region
          ].subject.join(", "),
        width: "16rem",
      },
      {
        title: "Year",
        dataIndex: "yearLevels",
        key: "yearLevels",
        render: (text, record) => {
          const course = originalData.courseDetails[record.courseRef];
          if (!course) return null;
          return findRanges(
            originalData.courseDetails[record.courseRef].countryMetadata[region]
              .levels
          );
        },
        width: "20rem",
      },
    ];
    const displayData = preprocessData(originalData, null, null, row.userRef);
    return (
      displayData && (
        <>
          <h4>Logged in to Cura</h4>
          <div className="pl-3 pt-4 border border-secondary rounded">
            {row.activeDatesArr ? (
              <YearsCalendarHeatmap dates={row.activeDatesArr} />
            ) : (
              "No data"
            )}
          </div>

          <br />
          <h4 className="mt-6">Explore page activity: Course overviews</h4>
          <Table
            columns={activityColumns}
            dataSource={displayData.overview}
            scroll={{ x: "calc(100% - 18rem)", y: 500 }}
            bordered
          />
          <br />
          <h4>Explore page activity: Course previews</h4>
          <Table
            columns={activityColumns}
            dataSource={displayData.preview}
            scroll={{ x: "calc(100% - 18rem)", y: 500 }}
            bordered
          />
          <br />
          <h4>Explore page activity: Courses favourited</h4>
          <Table
            columns={activityColumns}
            dataSource={displayData.favourite}
            scroll={{ x: "calc(100% - 18rem)", y: 500 }}
            bordered
          />
          <br />
          <h4>Explore page activity: Classrooms created</h4>
          <Table
            columns={activityColumns}
            dataSource={displayData.classroomCreation}
            scroll={{ x: "calc(100% - 18rem)", y: 500 }}
            bordered
          />
        </>
      )
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={teacherActivityData}
      pagination={false}
      scroll={{ x: "calc(100% - 18rem)", y: 500 }}
      bordered
    />
  );
};

export default SchoolTeacherActivityTable;
