import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { sendGAExploreEvent } from "../../utils/thirdParty/GoogleAnalyticsUtils";
import { GA_ACTIONS } from "../../utils/constants";

const PublicRedirectSignupModal = ({
  actionStr,
  handleCloseForm,
  calledFrom,
  courseRef,
}) => {
  return (
    <div className="text-center mt-3">
      <h4>
        To {actionStr.toLowerCase()}, <br />
        you'll need to sign up for a trial.
      </h4>
      <div className="text-dark mt-4">
        If you sign up for a trial, you'll have{" "}
        <span className="font-weight-bolder">two weeks</span> to explore Cura's
        units in detail. Then, you'll have a{" "}
        <span className="font-weight-bolder">whole extra term</span> to trial
        them if you wish.{" "}
        {actionStr === "Edit this Course" && (
          <span>
            It's in this extended trial that you'll be able to{" "}
            <span className="font-weight-bolder">edit</span> Cura's courses.
          </span>
        )}
      </div>
      <Button
        aria-label="signup"
        size="md"
        variant="warning text-white rounded-pill mt-4 mr-0"
        onClick={() => {
          sendGAExploreEvent(
            null,
            GA_ACTIONS.EXPLORE_SEARCH.CLICK_SIGNUP,
            courseRef,
            calledFrom
          );
          window.location.href = "/signup";
        }}
      >
        Start my free trial
      </Button>
      <div>
        <Button
          aria-label="close"
          size="md"
          variant="link mt-3 text-underline"
          onClick={() => {
            if (handleCloseForm) {
              handleCloseForm();
            }
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back to Explore
        </Button>
      </div>
      <img src="/img/build_rocket.jpg" alt="sign up" className="w-100" />
    </div>
  );
};

export default PublicRedirectSignupModal;
