import { Amplify, Storage } from "aws-amplify";
import awsExports from "../aws-exports";
import { getSafeFileName, isAUPlatform } from "./GenericUtils";

const {
  REGION,
  REGION_BUCKET_NAME,
  CONTENT_BUCKET_NAME,
  CONTENT_BUCKET_CDN_DOMAIN,
} = window._env_;

const uploadFile = async (
  file,
  filePath = "",
  params = {},
  isNotContent = true
) => {
  if (!isNotContent && !isAUPlatform()) {
    alert(
      "You cannot modify content outside of the main region. Please change to the main region"
    );
    return;
  }
  const bucketName = isNotContent ? REGION_BUCKET_NAME : CONTENT_BUCKET_NAME;
  Amplify.configure({
    ...awsExports,
    aws_user_files_s3_bucket_region: REGION,
    aws_user_files_s3_bucket: bucketName,
  });
  const fileName = `${filePath}/${Date.now()}/${getSafeFileName(file.name)}`;

  let result = null;
  try {
    result = await Storage.put(fileName, file, params);
    console.log("successfully saved file!");
  } catch (err) {
    console.warn("error uploading file!", err);
    return null;
  }
  // Use Content CDN
  if (!isNotContent) return CONTENT_BUCKET_CDN_DOMAIN + "/public/" + result.key;

  const newURL = await Storage.get(result.key);
  const i = newURL.indexOf("?");
  const uri = newURL.slice(0, i);
  return uri;
};

export const uploadFileWithHandler = async (
  file,
  onRequestSuccess,
  onRequestFail,
  filePath = "",
  isNotContent = true
) => {
  const fileURI = await uploadFile(
    file,
    filePath ? filePath : "",
    {},
    isNotContent
  );
  if (!fileURI) {
    onRequestFail();
    return null;
  }
  onRequestSuccess(fileURI);
  return fileURI;
};

export default uploadFile;
