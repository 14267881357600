import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { loadProjectList } from "../../actions";
const { Option } = Select;

const ProjectListDropDown = ({
  onProjectChange,
  defaultValue = "no-project",
  projectsData = null,
  showAll = false,
}) => {
  const [projectListData, setProjectListData] = useState(null);
  useEffect(() => {
    const getProjectList = (data) => {
      let projectList = [{ projectRef: "no-project", title: "N/A" }, ...data];
      if (showAll)
        projectList.unshift({
          projectRef: "all-projects",
          title: "All Projects",
        });
      return projectList;
    };
    if (!projectsData) {
      loadProjectList((data) => {
        setProjectListData(getProjectList(data));
      });
    } else {
      setProjectListData(getProjectList(projectsData));
    }
  }, []);

  return (
    projectListData && (
      <Select
        size="large"
        showSearch
        optionFilterProp="children"
        onChange={onProjectChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={defaultValue}
        className="border-secondary"
        style={{ width: "16rem" }}
      >
        {projectListData.map((project) => {
          return (
            <Option key={project.projectRef} value={project.projectRef}>
              {project.title}
            </Option>
          );
        })}
      </Select>
    )
  );
};

export default ProjectListDropDown;
