import { TrbStudentResponseStatus } from "./constants";

const GRAY_COLOUR_VAR = "gray-500";

export const TABLE_COLUMN_HEADER_CLASSNAME = "text-nowrap font-weight-bolder";

export const getCellColor = (work) => {
  if (
    !work ||
    (work &&
      work.lastSubmitAt === null &&
      work.status !== TrbStudentResponseStatus.ACTIVE)
  )
    return GRAY_COLOUR_VAR;

  if (work.status === TrbStudentResponseStatus.ACTIVE) {
    if (
      work.lastAssessAt > work.lastSubmitAt ||
      (work.lastAssessAt && !work.lastSubmitAt)
    )
      return "warning-light";
    return GRAY_COLOUR_VAR;
  }

  if (work.status === TrbStudentResponseStatus.SUBMIT) {
    return "cura-light-green";
  }
};

export const STATUS_COLOR = [
  { color: "cura-green", text: "Submitted" },
  { color: "warning-light", text: "Revision requested" },
  { color: GRAY_COLOUR_VAR, text: "Not submitted" },
];

export const getCellText = (item, record) => {
  const work = record[item.taskResourceBlockRef];
  let cellTextType = "";
  if (!item.rubric) cellTextType = "-";
  else if (
    !work ||
    (!work.lastSubmitAt &&
      !work.lastAssessAt &&
      work.score == null &&
      item.rubric)
  )
    cellTextType = "";
  else if (work.score !== null)
    cellTextType = `${getCellScore(item, record)}/${item.questionTotalScore}`;
  else cellTextType = "Not assessed";
  return cellTextType;
};

export const getCellScore = (item, record) => {
  let score =
    item.taskResourceBlockRef in record
      ? record[item.taskResourceBlockRef].score
      : 0;
  for (let rubricBlock of item.rubricBlocks)
    score +=
      rubricBlock.taskResourceBlockRef in record
        ? record[rubricBlock.taskResourceBlockRef].score
        : 0;
  return score;
};
