import React, { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";
import { Link, useParams } from "react-router-dom";
import SingleTaskView from "../../components/task/SingleTaskView";
import TaskCreateEditForm from "../../components/createEditForms/TaskCreateEditForm";
import TaskDeleteForm from "../../components/createEditForms/TaskDeleteForm";
import ModalButton from "../../components/util/ModalButton";
import { isAUPlatform } from "../../utils/GenericUtils";
import { Redirect } from "react-router-dom";

const TaskCreateEditPage = () => {
  const { mode, taskRef } = useParams();
  const [showPreview, setShowPreview] = useState(false);
  return !isAUPlatform() ? (
    //Avoid non-au users edit tasks
    <Redirect to={"/404"} />
  ) : (
    <Card className="mb-4">
      <Card.Body>
        <Link to="/admin/tasks">
          <ArrowLeft className="mr-2" />
          Back to All Tasks
        </Link>
        <div className="d-flex justify-content-between">
          <h4 className="mt-3 font-weight-bold">
            {mode === "create" ? "Create New" : "Edit"} Task
          </h4>
          {mode === "create" ? null : (
            <ModalButton
              variant="danger"
              buttonText="Delete this version"
              modalTitle="Delete this version"
            >
              <TaskDeleteForm
                taskRef={taskRef}
                onSaveSuccess={() => (window.location = "/admin/tasks")}
              />
            </ModalButton>
          )}
        </div>
        <Modal
          size="lg"
          show={showPreview}
          onHide={() => {
            setShowPreview(false);
            return true;
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Task Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SingleTaskView taskRef={taskRef} />
          </Modal.Body>
        </Modal>

        <TaskCreateEditForm
          mode={mode}
          taskRef={taskRef}
          onSaveSuccess={() => {
            if (mode === "edit") setShowPreview(true);
          }}
        />
      </Card.Body>
    </Card>
  );
};

export default TaskCreateEditPage;
