import { SESSION_EXPIRED, USER_DETAIL, USER_SIGNOUT } from "../actions/types";

const INTIAL_STATE = {
  isUserSignOut: null,
  userDetail: null,
};

const authReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case USER_SIGNOUT:
      return {
        ...state,
        isUserSignOut: action.payload,
      };
    case USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
      };
    case SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
