import React, { useEffect, useState } from "react";
import { Table, Button, Form } from "react-bootstrap";
import { PreviewTable } from "./EmptyTableCreator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "antd";

const DEFAULT_COLUMNS = ["", "", ""];
const DEFAULT_ROWS = ["", ""];

const TableWorkTemplateEditor = ({ blockData, onUpdate }) => {
  const [columnHeaders, setColumnHeaders] = useState(
    blockData?.data?.columns ?? []
  );
  const [rowHeaders, setRowHeaders] = useState(blockData?.data?.rows ?? []);

  useEffect(() => {
    if (!blockData?.data) {
      setColumnHeaders(DEFAULT_COLUMNS);
      setRowHeaders(DEFAULT_ROWS);
      onUpdate({
        columns: DEFAULT_COLUMNS,
        rows: DEFAULT_ROWS,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddRow = () => {
    const newRowHeaders = [...rowHeaders, ``];
    setRowHeaders(newRowHeaders);
    onUpdate({ columns: columnHeaders, rows: newRowHeaders });
  };

  const handleAddColumn = () => {
    const newColumnHeaders = [...columnHeaders, ``];
    setColumnHeaders(newColumnHeaders);
    onUpdate({ columns: newColumnHeaders, rows: rowHeaders });
  };

  const handleRemoveRow = (index) => {
    const newRowHeaders = rowHeaders.filter((_, i) => i !== index);
    setRowHeaders(newRowHeaders);
    onUpdate({ columns: columnHeaders, rows: newRowHeaders });
  };

  const handleRemoveColumn = (index) => {
    const newColumnHeaders = columnHeaders.filter((_, i) => i !== index);
    setColumnHeaders(newColumnHeaders);
    onUpdate({ columns: newColumnHeaders, rows: rowHeaders });
  };

  const handleColumnHeaderChange = (index, value) => {
    const newColumnHeaders = [...columnHeaders];
    newColumnHeaders[index] = value;
    setColumnHeaders(newColumnHeaders);
    onUpdate({ columns: newColumnHeaders, rows: rowHeaders });
  };

  const handleRowHeaderChange = (index, value) => {
    const newRowHeaders = [...rowHeaders];
    newRowHeaders[index] = value;
    setRowHeaders(newRowHeaders);
    onUpdate({ columns: columnHeaders, rows: newRowHeaders });
  };

  return (
    <div>
      <Table bordered hover className="mt-3">
        <thead>
          <tr>
            <th style={{ border: "none" }}></th>
            {columnHeaders.map((_, colIndex) => (
              <th
                key={colIndex}
                style={{ border: "none", height: 0 }}
                className="py-0 text-center"
              >
                {colIndex > 0 && (
                  <Tooltip title="Delete column">
                    <Button
                      size="sm"
                      className="my-3"
                      variant="danger rounded-circle btn-icon-only"
                      onClick={() => handleRemoveColumn(colIndex)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Tooltip>
                )}
              </th>
            ))}
            <th style={{ border: "none" }}></th>
          </tr>
          <tr>
            <th style={{ border: "none" }}></th>
            {columnHeaders.map((header, colIndex) => (
              <th key={colIndex} className="bg-secondary">
                <Form.Control
                  className="text-dark"
                  type="text"
                  value={header}
                  placeholder="New column"
                  onChange={(e) =>
                    handleColumnHeaderChange(colIndex, e.target.value)
                  }
                />
              </th>
            ))}
            <th
              key={"AddColumn"}
              style={{ border: "none", height: "5rem", width: 0 }}
              className="p-0 align-middle"
            >
              <Tooltip title="Add column">
                <Button
                  size="sm"
                  className="mx-3"
                  variant="primary rounded-circle btn-icon-only"
                  onClick={handleAddColumn}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          {rowHeaders.map((header, rowIndex) => (
            <tr key={rowIndex}>
              <td
                style={{ border: "none", height: "5rem", width: 0 }}
                className="p-0 align-middle text-center"
              >
                <Tooltip title="Delete row">
                  <Button
                    size="sm"
                    className="mx-3"
                    variant="danger rounded-circle btn-icon-only"
                    onClick={() => handleRemoveRow(rowIndex)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Tooltip>
              </td>
              <td className="bg-secondary">
                <Form.Control
                  className="text-dark"
                  type="text"
                  value={header}
                  placeholder="New row"
                  onChange={(e) =>
                    handleRowHeaderChange(rowIndex, e.target.value)
                  }
                />
              </td>
              {columnHeaders.map(
                (_, colIndex) => colIndex > 0 && <td key={colIndex}></td>
              )}
            </tr>
          ))}
          <tr>
            <td style={{ border: "none" }}></td>
            <td
              style={{ border: "none", height: 0 }}
              className="p-0 text-center"
            >
              <Tooltip title="Add row">
                <Button
                  size="sm"
                  className="my-3"
                  variant="primary rounded-circle btn-icon-only"
                  onClick={handleAddRow}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Tooltip>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="mt-2">
        Preview:
        <PreviewTable columns={columnHeaders} rows={rowHeaders} />
      </div>
    </div>
  );
};

export default TableWorkTemplateEditor;
