import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ModalButton = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.getShouldClose && props.getShouldClose()) setShow(false);
  }, [props]);

  const handleCloseForm = () => {
    setShow(false);
  };

  const onClick = () => {
    props.handleButtonClick && props.handleButtonClick();
    setShow(true);
  };

  return (
    <>
      {props.customisedClickableComponent ? (
        <div onClick={onClick}>{props.customisedClickableComponent}</div>
      ) : (
        <Button
          className={props.className ? props.className : "rounded-pill"}
          variant={props.variant ? props.variant : "primary"}
          size={props.size ? props.size : "md"}
          onClick={onClick}
        >
          {props.buttonText}
        </Button>
      )}

      <Modal
        size={props.modalSize ? props.modalSize : "md"}
        show={show}
        backdrop={props.backdrop ? props.backdrop : true}
        onHide={() => {
          if (props.preventClose) return;
          let shouldHide = true;
          if (props.onHide) shouldHide = props.onHide();
          if (shouldHide) setShow(false);
        }}
        aria-labelledby="example-custom-modal-styling-title"
        centered={props.centered}
        animation={props.animation}
      >
        {!props.noHeader && (
          <Modal.Header closeButton={props.preventClose ? false : true}>
            <Modal.Title id="example-custom-modal-styling-title">
              {props.modalTitle}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className={props.customisedModalBodyClassName}>
          {show &&
            React.cloneElement(props.children, {
              handleCloseForm: handleCloseForm,
            })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalButton;
