import React, { useState, useEffect, useRef, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { Table } from "antd";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import {
  CourseExecStatus,
  TrbStudentResponseStatus,
} from "../../utils/constants";
import { listTRBWorkAllUsers } from "../../actions";
import { handleGenericSaveFailed } from "../../utils/CreateEditFormUtils";
import ModalButton from "../util/ModalButton";
import { useToasts } from "react-toast-notifications";
import TaskAssessmentCreateEditForm from "../createEditForms/TaskAssessmentCreateEditForm";
import { useAsyncDebounce } from "react-table";
import { getQuestionBlocks } from "../../utils/DataLogicUtils";
import {
  getCellColor,
  getCellText,
  TABLE_COLUMN_HEADER_CLASSNAME,
} from "../../utils/ScoreTableUtils";
import TRBScoreTableLegend from "../classroom/TRBScoreTableLegend";
import TRBScoreTableCellContent from "../util/TRBScoreTableCellContent";
import AntdTableDownloadExcelButton from "../util/AntdTableDownloadExcelButton";

const CellModalButton = ({
  item,
  record,
  shouldAddDot,
  cellText,
  taskDetail,
  questionBlocks,
  i,
  courseExecRef,
  courseExecStatus,
  courseTaskLinkRef,
  trbResponses,
  onSaveSuccess,
}) => {
  const [shouldShowHideAlert, setShouldShowHideAlert] = useState(false);
  return (
    <ModalButton
      buttonText={
        <TRBScoreTableCellContent
          shouldAddDot={shouldAddDot}
          cellText={cellText}
        />
      }
      modalSize="xl"
      modalTitle={
        record?.user.id
          ? "View all responses from a student"
          : "View all responses for a question"
      }
      variant="link"
      className="text-dark p-0 text-sm w-100 h-100 hover-white rounded-0"
      onHide={() => {
        if (!shouldShowHideAlert) return true;
        const shouldHide = window.confirm(
          "If you exit, your assessment will not be submitted"
        );
        if (shouldHide) setShouldShowHideAlert(false);
        return shouldHide;
      }}
    >
      <TaskAssessmentCreateEditForm
        taskDetail={taskDetail}
        trbResponses={trbResponses}
        questionNumber={i + 1}
        taskResourceBlock={item}
        questionBlocks={questionBlocks}
        editable={courseExecStatus === CourseExecStatus.ACTIVE}
        courseExecRef={courseExecRef}
        courseTaskLinkRef={courseTaskLinkRef}
        userRef={record?.user.id}
        onSaveSuccess={onSaveSuccess}
        shouldShowHideAlert={shouldShowHideAlert}
        setShouldShowHideAlert={setShouldShowHideAlert}
      />
    </ModalButton>
  );
};

const getQuestionColumnItem = (
  item,
  i,
  courseExecRef,
  courseExecStatus,
  courseTaskLinkRef,
  taskDetail,
  questionBlocks,
  onSaveSuccess,
  trbResponses,
  isTextOnly = false
) => {
  const headerText = <div className="text-primary">{`Q${i + 1}`}</div>;
  return {
    title: (
      <CellModalButton
        item={item}
        cellText={headerText}
        taskDetail={taskDetail}
        questionBlocks={questionBlocks}
        i={i}
        courseExecRef={courseExecRef}
        courseExecStatus={courseExecStatus}
        courseTaskLinkRef={courseTaskLinkRef}
        onSaveSuccess={onSaveSuccess}
        trbResponses={trbResponses}
      />
    ),
    __excelTitle__: headerText,
    key: i,
    align: "center",
    width: "9rem",
    className: "p-0 text-primary",
    render(text, record) {
      const mainRecord = record[item.taskResourceBlockRef];
      const cellText = getCellText(item, record);
      const shouldAddDot =
        mainRecord &&
        mainRecord.status === TrbStudentResponseStatus.SUBMIT &&
        mainRecord.lastAssessAt &&
        mainRecord.lastSubmitAt > mainRecord.lastAssessAt;
      return {
        props: {
          style: {
            padding: 0,
            background: `var(--${getCellColor(mainRecord)})`,
          },
        },
        children: isTextOnly ? (
          cellText
        ) : (
          <CellModalButton
            item={item}
            record={record}
            shouldAddDot={shouldAddDot}
            cellText={cellText}
            taskDetail={taskDetail}
            questionBlocks={questionBlocks}
            i={i}
            courseExecRef={courseExecRef}
            courseExecStatus={courseExecStatus}
            courseTaskLinkRef={courseTaskLinkRef}
            onSaveSuccess={onSaveSuccess}
            trbResponses={trbResponses}
          />
        ),
      };
    },
  };
};

const TaskScoreTable = ({
  taskDetailData,
  courseExecRef,
  courseExecStatus,
  courseTaskLinkRef,
}) => {
  const [trbResponses, setTrbResponses] = useState(null);
  const [filteredTrbResponses, setFilteredTrbResponses] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);
  const containerRef = useRef();
  const nameFilterRef = useRef();
  const { addToast } = useToasts();
  const questionBlocks = taskDetailData.taskResourceBlockList
    ? getQuestionBlocks(taskDetailData.taskResourceBlockList)
    : [];

  useEffect(() => {
    if (trbResponses) {
      setFilteredTrbResponses(
        nameFilterRef.current.value
          ? getFilteredTrbResponses(trbResponses, nameFilterRef.current.value)
          : trbResponses
      );
    }
  }, [trbResponses]);

  const getFilteredTrbResponses = (trbResponses, filterStr) => {
    if (!trbResponses) return null;
    let result = trbResponses.filter((item) =>
      (item.user.firstName + " " + item.user.lastName)
        .toLowerCase()
        .includes(filterStr.toLowerCase())
    );
    return result;
  };

  useEffect(() => {
    if (shouldReloadData) {
      const data = {
        courseExecRef: courseExecRef,
        courseTaskLinkRef: courseTaskLinkRef,
      };
      listTRBWorkAllUsers(
        data,
        (data) => {
          const tableData = [];
          const questionTotalScoreDict = {};
          for (const questionBlock of questionBlocks)
            questionTotalScoreDict[questionBlock.taskResourceBlockRef] =
              questionBlock.questionTotalScore;
          for (let i = 0; i < data.length; i++) {
            let totalScore = 0;
            let totalAvailableScore = 0;
            data[i].trbStudentResponses &&
              Object.values(data[i].trbStudentResponses).forEach((item) => {
                if (item.score !== null) {
                  totalScore += item.score;
                  totalAvailableScore += questionTotalScoreDict[
                    item.taskResourceBlockRef
                  ]
                    ? questionTotalScoreDict[item.taskResourceBlockRef]
                    : 0;
                }
              });
            tableData.push({
              key: i,
              user: data[i].user,
              name: (
                <div className="text-truncate font-weight-bolder text-primary">
                  {`${data[i].user.firstName} ${data[i].user.lastName}`}
                </div>
              ),
              ...data[i].trbStudentResponses,
              score: totalScore,
              totalAvailableScore: totalAvailableScore,
            });
          }
          setTrbResponses(tableData);
        },
        () => handleGenericSaveFailed(addToast, null, () => null)
      );
      setShouldReloadData(false);
    }
  }, [shouldReloadData]);

  const onNameFilterChange = useAsyncDebounce((value) => {
    setFilteredTrbResponses(
      value ? getFilteredTrbResponses(trbResponses, value) : trbResponses
    );
  }, 500);

  const getQuestionColumns = (isTextOnly) => {
    let questions = [];
    if (questionBlocks !== []) {
      questions = questionBlocks.map((item, i) =>
        getQuestionColumnItem(
          item,
          i,
          courseExecRef,
          courseExecStatus,
          courseTaskLinkRef,
          taskDetailData,
          questionBlocks,
          () => setShouldReloadData(true),
          trbResponses,
          isTextOnly
        )
      );
    }
    return questions;
  };

  const getColumns = (isTextOnly = false) => [
    {
      title: <div className={TABLE_COLUMN_HEADER_CLASSNAME}>Student</div>,
      __excelTitle__: "Student",
      padding: 0,
      width: "10rem",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      align: "center",
      render(text, record) {
        return {
          props: {
            style: { padding: 0 },
          },
          children: (
            <CellModalButton
              // item={item}
              record={record}
              // shouldAddDot={shouldAddDot}
              cellText={text}
              taskDetail={taskDetailData}
              questionBlocks={questionBlocks}
              // i={i}
              courseExecRef={courseExecRef}
              courseExecStatus={courseExecStatus}
              courseTaskLinkRef={courseTaskLinkRef}
              onSaveSuccess={() => setShouldReloadData(true)}
              trbResponses={trbResponses}
            />
          ),
        };
      },
    },
    ...getQuestionColumns(isTextOnly),
    {
      title: <div className={TABLE_COLUMN_HEADER_CLASSNAME}>Score</div>,
      __excelTitle__: "Score",
      width: "8rem",
      dataIndex: "score",
      key: "score",
      fixed: "right",
      align: "center",
      render(text, record) {
        const percentageStr = `${
          record.totalAvailableScore !== 0
            ? Math.round((text / record.totalAvailableScore) * 100)
            : 0
        }%`;
        const scoreStr = `(${text}/${record.totalAvailableScore})`;
        return {
          props: {
            style: { padding: 0 },
          },
          children: isTextOnly ? (
            `${percentageStr} ${scoreStr}`
          ) : (
            <>
              <b className="font-weight-bolder">{percentageStr}</b> {scoreStr}
            </>
          ),
        };
      },
    },
  ];

  const tableStyle = {
    th: {
      padding: 0,
    },
  };
  const memoTable = useMemo(
    () => (
      <Table
        rowKey={() => uuid()}
        style={tableStyle}
        columns={getColumns()}
        dataSource={filteredTrbResponses}
        scroll={{ x: "calc(100% - 18rem)", y: 500 }}
        pagination={{ position: ["bottomRight"], pageSize: 25 }}
        bordered
      />
    ),
    [filteredTrbResponses]
  );

  return (
    <>
      <div className="d-flex justify-content-between mw-100" ref={containerRef}>
        <div className="mw-30">
          <InputGroup>
            <FormControl
              className="border-secondary border-right-0"
              placeholder={`Student Name`}
              aria-label="Search..."
              aria-describedby="basic-addon2"
              onChange={(e) => onNameFilterChange(e.target.value)}
              ref={nameFilterRef}
            />
            <InputGroup.Append>
              <Button
                className="border-secondary px-3"
                variant="outline-secondary"
              >
                <Search className="text-dark" />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
        <div className="my-auto text-sm text-lg-md ml-4">
          <TRBScoreTableLegend />
        </div>
      </div>
      <AntdTableDownloadExcelButton
        className="float-right p-0 pb-2 m-0"
        variant="link"
        columns={getColumns(true)}
        data={filteredTrbResponses}
        filename="Task-Score"
      />
      {memoTable}
    </>
  );
};
export default TaskScoreTable;
