import React from "react";
import { Row, Col } from "react-bootstrap";
import { STATUS_COLOR } from "../../utils/ScoreTableUtils";

const TRBScoreTableLegend = () => {
  return (
    <Row>
      {STATUS_COLOR.map((item) => (
        <Col xs="auto" className="d-flex" key={item.text}>
          <div
            className={`bg-${item.color} pr-3 pb-3 my-auto mr-2`}
            style={{ height: 0, backgroundColor: `var(--${item.color})` }}
          />
          <div className="my-auto">{item.text}</div>
        </Col>
      ))}
    </Row>
  );
};

export default TRBScoreTableLegend;
