import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Card } from "react-bootstrap";
import TaskCardBody from "./TaskCardBody";

const DraggableTask = (props) => {
  return (
    <Draggable
      draggableId={props.courseTaskLink.courseTaskLinkRef}
      index={props.index}
      type="TASK"
    >
      {(provided) => (
        <Card
          {...provided.draggableProps}
          // Must have this for the draggable part to drag the whole.
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={` ${
            props.courseTaskLink.isHidden ? "bg-light" : "bg-white"
          }${
            props.currentEditCourseTaskLinkRef ===
            props.courseTaskLink.courseTaskLinkRef
              ? " border-success border-3"
              : " border border-3 border-secondary"
          }`}
          onClick={() => {
            props.onTaskEdit(props.courseTaskLink);
          }}
        >
          <TaskCardBody
            task={props.task}
            courseTaskLink={props.courseTaskLink}
            currentEditCourseTaskLinkRef={props.currentEditCourseTaskLinkRef}
            handleEdit={() => {
              props.onTaskEdit(props.courseTaskLink);
            }}
            handleHide={() => {
              props.onTaskHide(props.courseTaskLink);
            }}
            handleDelete={() => {
              props.onTaskRemove(props.courseTaskLink);
            }}
          />
        </Card>
      )}
    </Draggable>
  );
};

export default DraggableTask;
