import React, { useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { Button, Form } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { updatePlanAndSchool } from "../../actions";
import { DatePicker, Select } from "antd";
import { USER_PLAN_TYPES } from "../../utils/constants";

const displayDateFormat = "DD/MM/YYYY";
const dateFormat = "YYYY-MM-DD";

const UserBulkEditForm = ({ userRefs, onSaveSuccess, handleCloseForm }) => {
  const [plan, setPlan] = useState(null);
  const { addToast } = useToasts();
  console.log(userRefs);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const planStartStr = form.elements.planStart.value;
    const planEndStr = form.elements.planEnd.value;
    const data = {
      plan: plan,
      planStart: planStartStr
        ? dayjs(planStartStr, displayDateFormat).format(dateFormat)
        : null,
      planEnd: planEndStr
        ? dayjs(planEndStr, displayDateFormat).format(dateFormat)
        : null,
      userRefs: userRefs,
    };
    updatePlanAndSchool(
      data,
      () => {
        addToast("Successfully updated!", {
          appearance: "success",
          autoDismiss: true,
        });
        if (onSaveSuccess) onSaveSuccess();
        if (handleCloseForm) handleCloseForm();
      },
      () => {
        addToast("Update failed. Please try again later.", {
          appearance: "error",
          autoDismiss: true,
        });
        if (onSaveSuccess) onSaveSuccess();
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit} className="text-dark">
      {userRefs.length} users selected.
      <br />
      <br />
      <Form.Group controlId="formPlan">
        <Form.Label className="mr-3">Plan:</Form.Label>
        <Select
          name="plan"
          style={{ width: 120 }}
          onChange={(value) => setPlan(value)}
          options={_.values(USER_PLAN_TYPES).map((item) => {
            return { label: item.displayText, value: item.value };
          })}
        />
      </Form.Group>
      <Form.Group controlId="formPlanStart">
        <Form.Label className="mr-3">Plan Start:</Form.Label>
        <DatePicker name="planStart" format="DD/MM/YYYY" />
      </Form.Group>
      <Form.Group controlId="formPlanEnd">
        <Form.Label className="mr-3">Plan End:</Form.Label>
        <DatePicker name="planEnd" format="DD/MM/YYYY" />
      </Form.Group>
      <br /> <br />
      <Button size="md" variant="primary" type="submit" block>
        Save
      </Button>
    </Form>
  );
};

export default UserBulkEditForm;
