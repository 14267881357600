import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { passwordResetCreate } from "../../actions";
import { getNewPasswordValid } from "../../utils/GenericUtils";

const PasswordResetCreate = (props) => {
  const [isPasswordResetSuccessful, setIsPasswordResetSuccessful] = useState(
    null
  );
  const [showPasswordNotMatch, setShowPasswordNotMatch] = useState(false);
  const [newPasswordInValid, setNewPasswordInValid] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setShowPasswordNotMatch(false);
    const form = event.target;
    const newPassword = form.elements.password.value;
    if (newPassword === form.elements.passwordRepeat.value) {
      if (!getNewPasswordValid(newPassword)) {
        setNewPasswordInValid(true);
        return;
      }
      const data = {
        password: newPassword,
        resetLink: props.match.params.token,
      };
      passwordResetCreate(
        data,
        () => {
          setIsPasswordResetSuccessful(true);
        },
        () => {
          setIsPasswordResetSuccessful(false);
        }
      );
    } else {
      setShowPasswordNotMatch(true);
    }
  };

  const history = useHistory();
  useEffect(() => {
    if (isPasswordResetSuccessful) {
      history.push("/password/reset-sent");
    }
  });

  return (
    <div className="text-center">
      <div className="AuthPanel">
        <Form className="InputForm" onSubmit={handleSubmit}>
          {isPasswordResetSuccessful != null && !isPasswordResetSuccessful ? (
            <Alert variant="danger">Password reset link has expired.</Alert>
          ) : null}
          {showPasswordNotMatch ? (
            <Alert variant="danger">Passwords don't match.</Alert>
          ) : null}
          <Form.Group controlId="formNewPassword">
            <Form.Label>New password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              required
              isInvalid={newPasswordInValid}
            />
            <Form.Control.Feedback type="invalid">
              Passwords must be strong enough, with at least 6 characters,
              digits, lowercase/uppercase letters and special characters(e.g.
              $#%^*?:).
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Confirm new password</Form.Label>
            <Form.Control type="password" name="passwordRepeat" required />
          </Form.Group>
          <br /> <br />
          <Button
            aria-label="create-new-password"
            size="md"
            variant="primary"
            type="submit"
            disabled={isPasswordResetSuccessful}
            block
          >
            Create new password
          </Button>
          <br />
          <hr />
        </Form>
      </div>
    </div>
  );
};

export default PasswordResetCreate;
