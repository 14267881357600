import {
  MAX_ENCODED_FILENAME_SIZE,
  MAX_FILENAME_SIZE,
  AWS_REGION_COUNTRY_MAP,
  COUNTRY_INFO,
} from "./constants";

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const downloadStringAsFile = (fileName, data) => {
  let blob = new Blob([data]);
  let safeFileName = getSafeFileName(fileName);
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, safeFileName);
  } else {
    let elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = safeFileName;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};

export const getSafeFileName = (fileName) => {
  return fileName.replace(/[^a-z0-9.]/gi, "_");
};

export const isFileNameTooLong = (fileName) => {
  return (
    fileName.length > MAX_FILENAME_SIZE ||
    encodeURI(fileName).length > MAX_ENCODED_FILENAME_SIZE
  );
};

export const getNameInitials = (name) => {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  return initials;
};

export const scorePassword = (pass) => {
  var score = 0;
  if (!pass) return score;

  // award every unique letter until 5 repetitions
  var letters = {};
  for (var i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  var variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  var variationCount = 0;
  for (var check in variations) {
    variationCount += variations[check] ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  return parseInt(score);
};

export const getNewPasswordValid = (newPassword) => {
  if (!newPassword || newPassword.length < 6) {
    return false;
  }
  if (scorePassword(newPassword) < 35) {
    return false;
  }

  // Check if it is only digits/lower case letters/upper case letters
  let cases = [
    /^[a-z]+$/.test(newPassword),
    /^[A-Z]+$/.test(newPassword),
    /^[\d]+$/.test(newPassword),
  ];
  if (cases.some((ele) => ele)) {
    return false;
  }
  return true;
};
export const isAUPlatform = () => {
  const { REGION } = window._env_;
  return REGION === "ap-southeast-2";
};

export const getGenericReactSelectOption = (value, label) => ({
  value: value,
  label: label ? label : value,
});

// TODO: test this
// Find ranges in array: [1,2,3,5] -> "1-3, 5"
export const findRanges = (numbers) => {
  return [...numbers]
    .sort((a, b) => a - b)
    .reduce(
      (acc, x, i) => {
        if (i === 0) {
          acc.ranges.push(x);
          acc.rangeStart = x;
        } else {
          if (x === acc.last + 1) {
            acc.ranges[acc.ranges.length - 1] = acc.rangeStart + "-" + x;
          } else {
            acc.ranges.push(x);
            acc.rangeStart = x;
          }
        }
        acc.last = x;
        return acc;
      },
      { ranges: [] }
    )
    .ranges.join(", ");
};

export const getCurrentCountryInfo = () => {
  const region = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];
  return COUNTRY_INFO[region];
};

export const isValidSalesforceId = (str) => {
  // https://stackoverflow.com/a/29299786/1333724
  if (typeof str !== "string" || str.length !== 18) {
    return false;
  }

  let upperCaseToBit = (char) => (char.match(/[A-Z]/) ? "1" : "0");
  let binaryToSymbol = (digit) =>
    digit <= 25
      ? String.fromCharCode(digit + 65)
      : String.fromCharCode(digit - 26 + 48);

  let parts = [
    str
      .slice(0, 5)
      .split("")
      .reverse()
      .map(upperCaseToBit)
      .join(""),
    str
      .slice(5, 10)
      .split("")
      .reverse()
      .map(upperCaseToBit)
      .join(""),
    str
      .slice(10, 15)
      .split("")
      .reverse()
      .map(upperCaseToBit)
      .join(""),
  ];

  let check = parts.map((str) => binaryToSymbol(parseInt(str, 2))).join("");

  return check === str.slice(-3);
};
