import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import {
  loadSchoolDetail,
  deleteSchool,
  loadUserSchoolLinkList,
} from "../../actions";
import { RoleType } from "../../utils/constants";

const SchoolDeleteForm = ({ schoolRef, onSaveSuccess }) => {
  const [schoolDetailData, setSchoolDetailData] = useState(null);
  const [schoolDeleteSuccess, setSchoolDeleteSuccess] = useState(null);
  const [teacherList, setTeacherList] = useState(null);
  const [studentList, setStudentList] = useState(null);

  useEffect(() => {
    loadSchoolDetail(schoolRef, (data) => setSchoolDetailData(data));
    loadUserSchoolLinkList(schoolRef, RoleType.STUDENT, (data) =>
      setStudentList(data)
    );
    loadUserSchoolLinkList(schoolRef, RoleType.TEACHER, (data) =>
      setTeacherList(data)
    );
  }, [schoolRef]);

  const handleDelete = () => {
    const data = {
      schoolRef: schoolRef,
    };
    deleteSchool(
      data,
      () => {
        setSchoolDeleteSuccess(true);
        onSaveSuccess();
      },
      () => setSchoolDeleteSuccess(false)
    );
  };

  return (
    schoolDetailData &&
    teacherList &&
    studentList && (
      <div>
        {teacherList.length + studentList.length > 0 ? (
          <Alert variant="danger text-lg">
            There are still <b>{teacherList.length + studentList.length}</b>{" "}
            active users in this school. Remove all users before you delete the
            school.
          </Alert>
        ) : (
          <>
            <p>
              Are you sure you want to delete: "
              {schoolDetailData === null ? "" : schoolDetailData.schoolName}"?
            </p>
            <br /> <br />
            <Button
              size="md"
              variant="primary"
              type="submit"
              disabled={schoolDeleteSuccess}
              onClick={handleDelete}
              block
            >
              Delete
            </Button>
            {schoolDeleteSuccess ? (
              <Alert variant="success">Deleted.</Alert>
            ) : null}
          </>
        )}
      </div>
    )
  );
};

export default SchoolDeleteForm;
