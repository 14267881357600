import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import {
  deactivateUser,
  getActiveUserCourseTaskLinkCount,
} from "../../actions";
import AvatarWithText from "../util/AvatarWithText";

const UserDeactivateForm = ({ userDetail, onSaveSuccess, handleCloseForm }) => {
  const [linkCount, setLinkCount] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    const data = { user_ref: userDetail.id };
    getActiveUserCourseTaskLinkCount(
      data,
      (data) => {
        setLinkCount(data.count);
      },
      () => {
        addToast(`Something went wrong. Please try again`, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    );
  }, []);

  const handleSaveSuccess = () => {
    addToast(`The user has been successfully deleted`, {
      appearance: "success",
      autoDismiss: true,
    });
    onSaveSuccess();
    if (handleCloseForm) handleCloseForm();
  };

  const handleSaveFailed = () => {
    addToast(`Something went wrong. Please try again`, {
      appearance: "error",
      autoDismiss: true,
    });
    if (handleCloseForm) handleCloseForm();
  };

  const handleDelete = (event) => {
    const data = {
      userRef: userDetail.id,
    };
    deactivateUser(
      data,
      () => handleSaveSuccess(),
      () => handleSaveFailed()
    );
  };

  return (
    linkCount !== null && (
      <div>
        <Alert variant="warning">
          Are you sure you want to delete this user?
        </Alert>
        <div className="mb-3">
          <AvatarWithText userDetail={userDetail} showEmail={true} />
        </div>
        {linkCount !== 0 && (
          <Alert variant="danger">
            This user is enrolled in{" "}
            <b className="text-danger text-lg">
              {linkCount} running classroom(s)
            </b>
            . <br />
            Deleting this user will <b>
              remove their access to the classroom
            </b>{" "}
            and <b>their data</b> within it.
          </Alert>
        )}
        <br />
        <Button
          size="md"
          variant="danger"
          type="submit"
          onClick={handleDelete}
          block
        >
          Delete{linkCount ? " anyway" : ""}
        </Button>
      </div>
    )
  );
};

export default UserDeactivateForm;
