import { jsonToCSV } from "react-papaparse";
import { downloadStringAsFile } from "./GenericUtils";

export const exportUserCSV = (filename, linkListData) => {
  let users = [];
  for (const link of linkListData) {
    users.push({
      firstName: link.user.firstName,
      lastName: link.user.lastName,
      email: link.user.email,
    });
  }
  exportGenericCSV(`${filename}_users.csv`, users);
};

export const exportGenericCSV = (filename, data) =>
  downloadStringAsFile(`${filename}.csv`, jsonToCSV(data));
