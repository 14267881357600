import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { useParams } from "react-router-dom";
import { getClassroomTimeline } from "../../../actions";
import {
  CLASSROOM_TIMELINE_ACTION_TYPES,
  RoleType,
} from "../../../utils/constants";
import { deepCopy } from "../../../utils/GenericUtils";
import { Person } from "react-bootstrap-icons";

const preprocessData = (data) => {
  let timelineData = [];
  // Create time
  timelineData.push({
    date: new Date(data.courseExec.createAt).toISOString().split("T")[0],
    action: CLASSROOM_TIMELINE_ACTION_TYPES.CREATE_CLASSROOM,
    data: data.courseExec,
  });
  const teacherManagementList = data.userManagement[RoleType.TEACHER];
  const studentManagementList = data.userManagement[RoleType.STUDENT];
  const taskViewList = data.taskView;
  teacherManagementList.forEach((element) => {
    element.action = CLASSROOM_TIMELINE_ACTION_TYPES.MANAGE_TEACHER;
  });
  studentManagementList.forEach((element) => {
    element.action = CLASSROOM_TIMELINE_ACTION_TYPES.MANAGE_STUDENT;
  });
  taskViewList.forEach((element) => {
    element.action = CLASSROOM_TIMELINE_ACTION_TYPES.TASK_ACCESS;
    element.courseTaskLinkDetail = data.courseTaskLinkDetail;
  });
  const allActions = teacherManagementList.concat(
    studentManagementList,
    taskViewList
  );
  const sortedActions = _.sortBy(allActions, [(o) => o.date]);
  return timelineData.concat(sortedActions);
};

const dataCell = (data) => {
  if (
    [
      CLASSROOM_TIMELINE_ACTION_TYPES.MANAGE_TEACHER,
      CLASSROOM_TIMELINE_ACTION_TYPES.MANAGE_STUDENT,
    ].includes(data.action)
  ) {
    return (
      <>
        {data.count > 0 ? "Added" : "Deleted"} <Person />
        {data.count}
      </>
    );
  }
  if (data.action === CLASSROOM_TIMELINE_ACTION_TYPES.TASK_ACCESS) {
    return (
      <>
        Teacher: {data.teacher.length}/{data.teacherTotal}
        <br />
        Student: {data.student.length}/{data.studentTotal}
        <br />
      </>
    );
  }
  return <>N/A</>;
};

const taskCell = (data) => {
  if (data.action !== CLASSROOM_TIMELINE_ACTION_TYPES.TASK_ACCESS) return <></>;
  return (
    <>
      {data.courseTaskLinkRefCount.map((ele) => {
        const linkDetail = data.courseTaskLinkDetail[ele.courseTaskLinkRef];
        return (
          <>
            Task {linkDetail.seqNumInCourse + 1}.
            {linkDetail.seqNumInSection + 1}: {linkDetail.taskTitle} <Person />
            {ele.count}
            <br />
          </>
        );
      })}
    </>
  );
};

const ClassroomTimelineTable = () => {
  const { courseExecRef } = useParams();
  const [classroomTimeline, setClassroomTimeline] = useState(null);
  useEffect(() => {
    getClassroomTimeline(
      { courseExecRef, timezone_str: "Australia/Melbourne" },
      (data) => {
        setClassroomTimeline(preprocessData(deepCopy(data)));
      }
    );
  }, [courseExecRef]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "date",
      dataIndex: "date",
      key: "date",
      width: "4rem",
      sorter: (a, b) => a.date > b.date,
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("date"),
    },
    {
      title: "action",
      dataIndex: "action",
      key: "action",
      width: "6rem",
      sorter: (a, b) => a.action > b.action,
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("action"),
    },
    {
      title: "data",
      width: "12rem",
      render: (text, record) => dataCell(record),
    },
    {
      title: "Tasks",
      width: "20rem",
      render: (text, record) => taskCell(record),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={classroomTimeline}
      scroll={{ x: "calc(100% - 18rem)", y: 500 }}
      pagination={{ position: ["bottomRight"], pageSize: 25 }}
      bordered
    />
  );
};
export default ClassroomTimelineTable;
