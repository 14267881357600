import axios from "axios";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      .split(";")
      .shift();
}

const getAxiosRestObject = (shouldUseToken = true) => {
  let config = { baseURL: "/api/v1" };
  if (shouldUseToken) {
    let token = getCookie("escs");
    if (!token) {
      // Create a 401 Error
      throw Object.assign(new Error("No Authentication Token."), {
        response: { status: 401 },
      });
    }
    config = {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return axios.create(config);
};

export const requestJsonObject = (
  url,
  handleSuccess,
  handleFailed = (err) => console.log(err)
) => {
  axios
    .get(url)
    .then((res) => handleSuccess(res.data))
    .catch(handleFailed);
};

export default getAxiosRestObject;
