export const handleGenericSaveSuccess = (
  addToast,
  toastMessage,
  onSaveSuccess,
  handleCloseForm,
  overrideMessage
) => {
  addToast(
    overrideMessage
      ? overrideMessage
      : `The ${toastMessage.object} has been successfully ${
          "operation" in toastMessage ? toastMessage.operation : "saved"
        }`,
    {
      appearance: "success",
      autoDismiss: true,
    }
  );
  onSaveSuccess();
  if (handleCloseForm) handleCloseForm();
};

export const handleGenericSaveFailed = (
  addToast,
  toastMessage,
  handleCloseForm
) => {
  addToast(
    toastMessage ? toastMessage : `Something went wrong. Please try again`,
    {
      appearance: "error",
      autoDismiss: true,
    }
  );
  if (handleCloseForm) handleCloseForm();
};
