import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";
import { loadSchoolList } from "../../actions";

const SchoolListDropDown = ({
  onSchoolChange,
  defaultValue = null,
  schoolsData = null,
  showAll = false,
  itemKey,
  name,
  size = "large",
}) => {
  const [schoolListData, setSchoolListData] = useState(null);
  useEffect(() => {
    const getSchoolList = (data) => {
      let schoolList = [...data];
      if (showAll)
        schoolList.unshift({
          schoolRef: null,
          schoolName: "All Schools",
        });
      return schoolList;
    };
    if (!schoolsData) {
      loadSchoolList((data) => {
        setSchoolListData(getSchoolList(data));
      });
    } else {
      setSchoolListData(getSchoolList(schoolsData));
    }
  }, []);

  return (
    <Form.Item name={name} key={itemKey} className="p-0 m-0">
      {schoolListData && (
        <Select
          key={itemKey}
          size={size}
          showSearch
          optionFilterProp="children"
          onChange={onSchoolChange}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={defaultValue}
          className="border-secondary"
          style={{ width: "16rem" }}
          options={schoolListData.map((school) => {
            return { label: school.schoolName, value: school.schoolRef };
          })}
        />
      )}
    </Form.Item>
  );
};

export default SchoolListDropDown;
