import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CourseBuilder from "../../components/course/courseBuilder/CourseBuilder";
import { loadCourseDetail, loadProjectDetail } from "../../actions";
import { ChevronRight } from "react-bootstrap-icons";

const CourseDetailEditPage = () => {
  const { courseRef } = useParams();
  const [projectDetailData, setProjectDetailData] = useState(null);
  const [courseDetailData, setCourseDetailData] = useState(null);

  // TODO: Optimise these 2 requests. Project name can be get from coursebuilder
  useEffect(() => {
    loadCourseDetail(courseRef, (data) => {
      setCourseDetailData(data);
      loadProjectDetail(data.projectRef, (data) => {
        setProjectDetailData(data);
      });
    });
  }, [courseRef]);
  return (
    <div>
      {projectDetailData && (
        <div>
          <div className="text-light mt-4 mb-3">
            <Link className="text-light" to={`/admin/projects`}>
              <u>All Projects</u>
            </Link>
            <ChevronRight className="mx-2" />
            <Link
              className="text-light"
              to={`/admin/projects/project/${projectDetailData.projectRef}`}
            >
              <u>{projectDetailData.title}</u>
            </Link>
            <ChevronRight className="mx-2" />
            {courseDetailData.title}
          </div>
          <CourseBuilder
            courseRef={courseRef}
            courseTitle={courseDetailData.title}
          />
        </div>
      )}
    </div>
  );
};

export default CourseDetailEditPage;
