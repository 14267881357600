import React from "react";

const TeacherPurchaseCourseModal = () => {
  return (
    <div className="text-center mt-3">
      <h4>
        Purchase this course to access <br />
        all lesson content
      </h4>
      <div className="text-dark my-4">
        Email <u className="text-primary">support@curaeducation.com</u> for a
        quote
      </div>
      <img src="/img/build_rocket.jpg" alt="sign up" className="w-100" />
    </div>
  );
};

export default TeacherPurchaseCourseModal;
