import React from "react";
import { Image } from "antd";
import "../../style/antd-image-preview.css";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "../util/HorizontalGalleryArrows";

const StudentSuccessSection = ({ studentSuccessMetadata }) => {
  const imageSize = "300px";

  const AdjustedLeftArrow = (props) => {
    return (
      <div
        style={{
          position: "absolute",
          left: "8px",
          top: "208px",
          zIndex: 2000,
        }}
      >
        <LeftArrow {...props} />
      </div>
    );
  };

  const AdjustedRightArrow = (props) => {
    return (
      <div style={{ position: "absolute", right: "8px", top: "208px" }}>
        <RightArrow {...props} />
      </div>
    );
  };

  return (
    <>
      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) =>
            console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        <ScrollMenu
          scrollContainerClassName="hidden-scroll-bar"
          LeftArrow={AdjustedLeftArrow}
          RightArrow={AdjustedRightArrow}
          options={{
            ratio: 0.9,
            rootMargin: "5px",
            threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
          }}
        >
          {studentSuccessMetadata.map((item, index) => (
            <div
              itemId={index} // NOTE: itemId is required for track items
              key={index}
              className="mr-3"
              style={{ width: imageSize }}
            >
              <Image
                key={index}
                width={imageSize}
                height={imageSize}
                style={{
                  objectFit: "cover",
                  objectPosition: "top left",
                }}
                src={item.url}
              />
              <br />
              <p
                className="mt-3 mb-0 text-dark text-sm overflow-hidden"
                style={{
                  width: { imageSize },
                  maxHeight: "2.8rem",
                }}
              >
                {item.description}
              </p>
            </div>
          ))}
        </ScrollMenu>
      </Image.PreviewGroup>
    </>
  );
};

export default StudentSuccessSection;
