import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { loadCourseExecDetail, archiveCourseExec } from "../../actions";

const ClassroomArchiveForm = ({
  courseExecRef,
  onSaveSuccess,
  handleCloseForm,
}) => {
  const [linkDetailData, setLinkDetailData] = useState(null);
  const [linkDeleteSuccess, setLinkDeleteSuccess] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    loadCourseExecDetail(courseExecRef, (data) => setLinkDetailData(data));
  }, [courseExecRef]);

  const handleDelete = (event) => {
    archiveCourseExec(
      courseExecRef,
      () => {
        addToast("Classroom archived", {
          appearance: "success",
          autoDismiss: true,
        });
        if (handleCloseForm) handleCloseForm();
        setLinkDeleteSuccess(true);
        onSaveSuccess();
      },
      () => setLinkDeleteSuccess(false)
    );
  };

  return (
    <div>
      <p>Archiving a classroom cannot be reversed.</p>
      <p>
        You can continue to view the content and student work within an archived
        classroom. But you will not be able to edit it or add students to it.
        Students won't be able to add any work to it.
      </p>
      <p>
        Are you sure you want to archive:{" "}
        <b className="text-dark font-weight-bolder">
          "{linkDetailData === null ? "" : linkDetailData.classroomName}"
        </b>
        ?
      </p>
      <br />
      <Button
        size="md"
        variant="warning text-white"
        type="submit"
        disabled={linkDeleteSuccess}
        onClick={handleDelete}
        block
      >
        Archive
      </Button>
      <Button
        size="sm"
        variant="link text-danger"
        type="submit"
        disabled={linkDeleteSuccess}
        onClick={handleCloseForm}
        block
      >
        Cancel
      </Button>
      {linkDeleteSuccess ? <Alert variant="success">Archived.</Alert> : null}
    </div>
  );
};

export default ClassroomArchiveForm;
