import React from "react";
import ErrorPage from "./ErrorPage";

const ClassroomAccessDeniedPage = () => {
  return (
    <ErrorPage
      heading="You're out of bounds!"
      message={
        "You don't have access to this course or classroom. " +
        "If you're a student, ask your teacher to give you access. " +
        "And if you're a teacher, contact Cura for assistance"
      }
      imageUrl="/img/svg/illustrations/server-down.svg"
      bg="warning"
    />
  );
};

export default ClassroomAccessDeniedPage;
