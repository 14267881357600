import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { EXPLORE_PAGE_MODES, GA_ACTIONS } from "../constants";

const getCurrentUTCTimestamp = () => parseInt(new Date() / 1000);

const eventSender = (category, userId, label, action, value = null) => {
  const eventValue = value ? value : getCurrentUTCTimestamp();
  const event = {
    category: `${category}-${userId}`,
    action: action,
    label: label,
    value: eventValue,
  };
  ReactGA.event(event);
};

export const setGAUserId = (userId) => {
  if (!userId) return;
  ReactGA4.set({ userId: userId, user_id: userId });
};

export const sendGAExploreEvent = (
  userId,
  action,
  courseRef = "",
  calledFrom = EXPLORE_PAGE_MODES.EXPLORE
) => {
  const eventData = {
    data: { courseRef: courseRef },
    timestamp: getCurrentUTCTimestamp(),
  };
  eventSender(
    "EXPLORE-" + calledFrom,
    userId,
    JSON.stringify(eventData),
    action
  );
  ReactGA4.event("user_explore", {
    course_ref: courseRef,
    explore_action: action,
    called_from: calledFrom,
  });
};

export const sendGASearchEvent = (userId, query) => {
  const eventData = { data: query, timestamp: getCurrentUTCTimestamp() };
  eventSender(
    EXPLORE_PAGE_MODES.SEARCH,
    userId,
    JSON.stringify(eventData),
    GA_ACTIONS.EXPLORE_SEARCH.SEARCH
  );
  ReactGA4.event("explore_search", {
    explore_search_query: JSON.stringify(query),
  });
};

export const sendGAGenericEvent = (userId, category, eventData) => {
  eventSender(category, userId, JSON.stringify(eventData));
  ReactGA4.event(category, eventData);
};
