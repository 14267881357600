import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useCookieState } from "ahooks";
import { otpSignUp, signUp } from "../../../actions";
import { MAX_COOKIE_AGE } from "../../../utils/constants";
import OTPSubmitForm from "../OTPSubmitForm";

const OTPSignUp = ({
  originalFormData,
  credential,
  handleUserDetailChange,
}) => {
  const setToken = useCookieState("escs")[1];
  const setTokenExp = useCookieState("exp")[1];
  const { addToast } = useToasts();
  const [invalidType, setInvalidType] = useState(null);
  const [currentCredential, setCurrentCredential] = useState(credential);
  const [otpInValid, setOtpInValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOTPSignupSubmit = (event) => {
    event.preventDefault();
    setOtpInValid(false);
    setInvalidType(null);
    const form = event.target;
    if (currentCredential == null)
      addToast("Something went wrong. Please try again later.", {
        appearance: "error",
        autoDismiss: true,
      });
    const data = {
      ...originalFormData,
      token: currentCredential,
      otp_str: form.elements.code.value,
    };
    setIsSubmitting(true);
    otpSignUp(
      data,
      (data) => {
        const exp = new Date(+new Date() + MAX_COOKIE_AGE);
        setToken(data.token, {
          // Expire after 1 day
          expires: (() => exp)(),
        });
        setTokenExp(exp);
        handleUserDetailChange(data.user);
        setIsSubmitting(false);
      },
      (data) => {
        setOtpInValid(true);
        if (Array.isArray(data) && data.length === 1 && data[0] === "EXPIRED")
          setInvalidType(1);
        else setInvalidType(2);
        setIsSubmitting(false);
      }
    );
  };

  const handleResendOTP = () => {
    setOtpInValid(false);
    setInvalidType(null);
    signUp(
      originalFormData,
      (data) => {
        addToast(
          "We've sent a security code to your registered email address." +
            " Please enter the code to sign in.",
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
        setCurrentCredential(data["token"]);
      },
      (data) => {
        window.location.reload();
      }
    );
  };
  return (
    <div className="text-dark">
      <p>
        We've sent a security code to{" "}
        <span className="text-warning">{originalFormData.email}</span>. Please
        enter the code to sign in.
      </p>
      <p>
        Allow a minute for your email to arrive. Check your spam/junk folder if
        you can't find it.
      </p>
      <div className="pl-1">
        <OTPSubmitForm
          handleSubmit={handleOTPSignupSubmit}
          handleResend={handleResendOTP}
          isSubmitting={isSubmitting}
          otpInValid={otpInValid}
          invalidType={invalidType}
        />
      </div>
      <p className="mt-3 text-sm">
        Typo in your email address? Click{" "}
        <a href="/signup">
          <u>here</u>
        </a>{" "}
        to start again
      </p>
    </div>
  );
};

export default OTPSignUp;
