import React from "react";
import { Card } from "react-bootstrap";

const QuestionBlockContainer = (props) => {
  const enhancedBorderColor = "#e6e5e5";
  return (
    <>
      <div className="bg-warning pt-2 rounded-top"></div>
      <Card
        className="w-100 mb-0 bg-gray-100 border-top-0"
        style={{
          borderColor: enhancedBorderColor,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Card.Body className="text-dark">
          <Card.Title>Question {props.questionNumber}</Card.Title>
          {props.children}
        </Card.Body>
      </Card>
    </>
  );
};
export default QuestionBlockContainer;
