import React from "react";
import { ProductFruits } from "react-product-fruits";

const ProductFruitsUtil = ({ userDetailData }) => {
  const userInfo = {
    username: userDetailData.id,
    email: userDetailData.email,
    firstname: userDetailData.firstName,
    lastname: userDetailData.lastName,
    signUpAt: userDetailData.dateJoined,
    role: userDetailData.userInfo.roleTypeName,
    props: {
      region: window._env_.REGION,
      environment: window._env_.ENVIRONMENT,
      plan: userDetailData.userInfo.plan,
      planStart: userDetailData.userInfo.planStart,
      planEnd: userDetailData.userInfo.planEnd,
    },
  };

  return (
    <ProductFruits
      workspaceCode={window._env_.PRODUCT_FRUITS_WORKSPACE_CODE}
      language="en"
      user={userInfo}
    />
  );
};

export default ProductFruitsUtil;
