import React from "react";
import { Card, Badge, Button } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";

const TaskCardBody = ({
  task,
  projectDictData,
  showDelete = false,
  handleDelete = null,
}) => {
  const projectTitle =
    task.projectRef && projectDictData[task.projectRef].title;
  return (
    <Card.Body className="d-flex justify-content-between">
      <div>
        <div
          className="text-dark font-weight-bold text-truncate"
          style={{ maxWidth: "18rem" }}
        >
          {task.title}
        </div>
        <Badge className={`badge-soft-secondary`}>
          {projectTitle ? projectTitle : "N/A"}
        </Badge>
        <Badge className="badge-soft-warning ml-1">{task.versionName}</Badge>
      </div>
      {showDelete && (
        <div className="text-right inline">
          <Button
            size="xs"
            variant="light rounded-pill btn-icon-only"
            onClick={handleDelete}
          >
            <TrashFill className="text-danger" />
          </Button>
        </div>
      )}
    </Card.Body>
  );
};

export default TaskCardBody;
