import React from "react";
import { Accordion, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuestionCircle } from "react-bootstrap-icons";
import { ContentSection } from "../ContentSection";

const ExemplarAnswerAccordion = ({ content }) => {
  return (
    content && (
      <Accordion className="pt-4">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <b className="text-primary mr-2">Exemplar answer</b>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip>Students will not see exemplar answers</Tooltip>
              }
            >
              <QuestionCircle className="text-secondary" />
            </OverlayTrigger>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <ContentSection content={content} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    )
  );
};

export default ExemplarAnswerAccordion;
