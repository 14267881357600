import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { loadCourseExecDetail, unarchiveCourseExec } from "../../actions";

const ClassroomUnarchiveForm = ({ courseExecRef, onSaveSuccess }) => {
  const [linkDetailData, setLinkDetailData] = useState(null);
  const [linkDeleteSuccess, setLinkDeleteSuccess] = useState(null);

  useEffect(() => {
    loadCourseExecDetail(courseExecRef, (data) => setLinkDetailData(data));
  }, [courseExecRef]);

  const handleDelete = (event) => {
    unarchiveCourseExec(
      courseExecRef,
      () => {
        setLinkDeleteSuccess(true);
        onSaveSuccess();
      },
      () => setLinkDeleteSuccess(false)
    );
  };

  return (
    <div>
      <p>
        Are you sure you want to unarchive: "
        {linkDetailData === null ? "" : linkDetailData.classroomName}"?
      </p>
      <br /> <br />
      <Button
        size="md"
        variant="primary"
        type="submit"
        disabled={linkDeleteSuccess}
        onClick={handleDelete}
        block
      >
        Unarchive
      </Button>
      {linkDeleteSuccess ? <Alert variant="success">Unarchived.</Alert> : null}
    </div>
  );
};

export default ClassroomUnarchiveForm;
