import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Card } from "react-bootstrap";
import ModalButton from "../../util/ModalButton";
import TaskVersionManagementPanel from "../../task/TaskVersionManagementPanel";
import TaskCardBody from "../../task/TaskCardBody";

const DraggableTask = (props) => {
  return (
    <Draggable
      draggableId={props.courseTaskLink.courseTaskLinkRef}
      index={props.index}
      type="TASK"
    >
      {(provided) => (
        <ModalButton
          modalTitle={props.task.title}
          modalSize="xl"
          customisedClickableComponent={
            <Card
              {...provided.draggableProps}
              // Must have this for the draggable part to drag the whole.
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <TaskCardBody
                task={props.task}
                projectDictData={props.projectDictData}
                showDelete={true}
                handleDelete={() => {
                  props.onTaskRemove(props.courseTaskLink);
                }}
              />
            </Card>
          }
        >
          <TaskVersionManagementPanel taskRef={props.task.taskRef} />
        </ModalButton>
      )}
    </Draggable>
  );
};

export default DraggableTask;
