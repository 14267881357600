import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import DraggableTask from "./DraggableTask";
import { Button, Row, Col } from "react-bootstrap";
import Editable from "../../util/Editable";
import { TEXT_FIELD_MAX_LENGTH } from "../../../utils/constants";

const DraggableSection = (props) => {
  return (
    <Draggable
      draggableId={props.section.sectionRef}
      index={props.index}
      type="SECTION"
    >
      {(provided) => (
        <div
          className="bg-gray-100 p-3 border-secondary border-bottom"
          {...provided.draggableProps}
          // Must have this for the draggable part to drag the whole.
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Row>
            <Col className="pr-0" sm={2}>
              <div className="text-dark font-weight-bold">
                Level {props.index + 1}:
              </div>
            </Col>
            <Col className="px-0 w-100" sm={8}>
              <Editable
                text={props.section.title}
                onSave={(value) => {
                  props.onSectionEdit(value, props.section.sectionRef);
                }}
                maxLength={TEXT_FIELD_MAX_LENGTH.SECTION_TITLE}
              />
            </Col>
            <Col className="p-0" sm={2}>
              <Button
                size="xs"
                variant="link"
                className="text-danger"
                onClick={() => {
                  props.onSectionRemove(props.section);
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
          <Droppable droppableId={props.section.sectionRef} type="TASK">
            {(provided, snapshot) => (
              <div
                className="section-droppable"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {props.taskLinks.map((courseTaskLink, index) => (
                  <DraggableTask
                    key={courseTaskLink.courseTaskLinkRef}
                    task={props.tasks[courseTaskLink.taskRef]}
                    courseTaskLink={courseTaskLink}
                    index={index}
                    onTaskRemove={props.onTaskRemove}
                    projectDictData={props.projectDictData}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableSection;
