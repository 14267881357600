import React, { useState } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import Can from "../../utils/Can";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { USER_PLAN_TYPES } from "../../utils/constants";
import { PreviewUserEditCourseAlert } from "../classroom/PreviewUserEditCourseAlert";

const UserEditCourseButton = ({
  courseRef,
  courseTaskLinkRef,
  disabled,
  forceIconOnly = false,
}) => {
  const isPreviewUser = useSelector(
    (state) =>
      state.auth.userDetail?.userInfo.plan === USER_PLAN_TYPES.PREVIEW.value
  );
  const [show, setShow] = useState(false);
  const url = `/course-edit/${courseRef}${
    courseTaskLinkRef ? `?task=${courseTaskLinkRef}` : ""
  }`;

  const handleEditCourseClicked = () => {
    if (isPreviewUser) {
      setShow(true);
      return;
    }
    window.location.href = url;
  };

  const IconOnlyButton = ({ className }) => (
    <Tooltip title={"Edit this task"} className={className}>
      <Button
        variant="warning rounded-circle btn-icon-only text-white"
        // size="sm"
        disabled={disabled}
        onClick={handleEditCourseClicked}
      >
        <FontAwesomeIcon icon={faPen} />
      </Button>
    </Tooltip>
  );
  const PreviewAlertModel = ({ show }) => {
    return (
      show &&
      isPreviewUser && (
        <Modal
          size="md"
          show={show}
          backdrop={true}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PreviewUserEditCourseAlert />
          </Modal.Body>
        </Modal>
      )
    );
  };
  return (
    <Can
      perform="task:edit"
      yes={() => {
        let editButton = null;
        if (forceIconOnly) {
          editButton = <IconOnlyButton className="d-inline-block" />;
        } else
          editButton = (
            <>
              <Button
                className="d-none d-md-inline-block m-0"
                variant="warning text-white rounded-pill b-1"
                size="sm"
                disabled={disabled}
                onClick={handleEditCourseClicked}
              >
                <FontAwesomeIcon icon={faPen} /> Edit Course
              </Button>

              <IconOnlyButton className="d-inline-block d-md-none" />
            </>
          );
        return (
          <>
            {<PreviewAlertModel show={show} />}
            {editButton}
          </>
        );
      }}
      no={() => <></>}
    />
  );
};

export default UserEditCourseButton;
