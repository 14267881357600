import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import {
  // loadCourseDetail,
  loadCourseExecDetail,
  deleteCourse,
} from "../../actions";

const UserCourseDeleteForm = ({
  courseRef,
  onSaveSuccess,
  handleCloseForm,
}) => {
  // const [courseDetailData, setCourseDetailData] = useState(null);
  const [courseExecDetailData, setCourseExecDetailData] = useState(null);
  const [courseDeleteSuccess, setCourseDeleteSuccess] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    // loadCourseDetail(courseRef, (data) => setCourseDetailData(data));
    loadCourseExecDetail(courseRef, (data) => {
      setCourseExecDetailData(data);
    });
  }, [courseRef]);

  const handleDelete = (event) => {
    event.preventDefault();
    const data = {
      courseRef: courseRef,
    };
    deleteCourse(
      data,
      () => {
        addToast("Course deleted", {
          appearance: "success",
          autoDismiss: true,
        });
        if (handleCloseForm) handleCloseForm();
        setCourseDeleteSuccess(true);
        onSaveSuccess();
      },
      () => setCourseDeleteSuccess(false)
    );
  };

  return (
    // courseDetailData &&
    courseExecDetailData && (
      <div>
        <p className="text-dark">
          Deleting a course cannot be reversed.
          <br /> <br />
          You will lose any content edits and student work submissions
          associated with this course.
          <br /> <br />
          Are you sure you want to delete: "
          <b className="font-weight-bolder">
            {courseExecDetailData === null
              ? ""
              : courseExecDetailData.course.project.title}
          </b>
          "?
        </p>
        <br />
        <Button
          size="md"
          variant="danger"
          type="submit"
          disabled={courseDeleteSuccess}
          onClick={handleDelete}
          block
        >
          Delete
        </Button>
        {courseDeleteSuccess ? <Alert variant="success">Deleted.</Alert> : null}
      </div>
    )
  );
};

export default UserCourseDeleteForm;
