import React, { useState, useEffect } from "react";
import ModalButton from "../util/ModalButton";
import ReactTable, { RightButtonsTableCell } from "../util/ReactTable";
import SchoolCreateEditForm from "../createEditForms/SchoolCreateEditForm";
import SchoolDeleteForm from "../createEditForms/SchoolDeleteForm";
import { loadSchoolList } from "../../actions";
import { Link, useRouteMatch } from "react-router-dom";
import TablePanelHeader from "../util/TablePanelHeader";
import Can from "../../utils/Can";

const SchoolList = () => {
  const [schoolListData, setSchoolListData] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(true);
  let { url } = useRouteMatch();

  useEffect(() => {
    if (shouldReloadData) {
      loadSchoolList((data) => {
        setSchoolListData(data);
      });
      setShouldReloadData(false);
    }
  }, [shouldReloadData]);

  const tableRowButtons = (ref) => {
    return (
      <RightButtonsTableCell>
        <ModalButton variant="link" buttonText="Edit" modalTitle="Edit School">
          <SchoolCreateEditForm
            mode="edit"
            schoolRef={ref}
            onSaveSuccess={() => setShouldReloadData(true)}
          />
        </ModalButton>
        <Can
          perform="school-delete:admin"
          yes={() => (
            <ModalButton
              variant="link"
              buttonText="Delete"
              modalTitle="Delete School"
              className="text-danger"
            >
              <SchoolDeleteForm
                schoolRef={ref}
                onSaveSuccess={() => setShouldReloadData(true)}
              />
            </ModalButton>
          )}
          no={() => null}
        />
      </RightButtonsTableCell>
    );
  };

  const columns = [
    {
      Header: "School Name",
      accessor: "schoolName",
      Cell: (cellInfo) => (
        <Link
          to={`${url}/school/${cellInfo.row.original.schoolRef}/dashboard`}
          replace={true}
        >
          {cellInfo.row.original.schoolName}
        </Link>
      ),
    },
    {
      Header: "Country",
      accessor: "country",
      Cell: ({ value }) => value,
    },
    {
      Header: "State/Province/Region",
      accessor: "state",
      Cell: ({ value }) => value,
    },
    {
      Header: "",
      accessor: "schoolRef",
      Cell: ({ value }) => tableRowButtons(value),
    },
  ];

  return (
    <>
      <TablePanelHeader title="">
        <ModalButton
          buttonText="+ CREATE NEW SCHOOL"
          modalTitle="Create New School"
          variant="light"
        >
          <SchoolCreateEditForm
            mode="create"
            onSaveSuccess={() => setShouldReloadData(true)}
          />
        </ModalButton>
      </TablePanelHeader>
      {schoolListData && (
        <ReactTable title="Schools" data={schoolListData} columns={columns} />
      )}
    </>
  );
};

export default SchoolList;
