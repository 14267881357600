import React from "react";
import { Alert } from "react-bootstrap";
import ClassroomListTable from "../../features/insight/classroom/ClassroomListTable";
import SiteSummary from "../../features/insight/site/SiteSummary";
import SiteTeacherExploreReport from "../../features/insight/site/SiteTeacherExploreReport";

const AdminDashboard = () => {
  return (
    <>
      <Alert className="bg-warning text-white mt-3">
        <Alert.Heading>Welcome!</Alert.Heading>
        <p>
          Let's bring classrooms to life with hands-on, real-world curriculum
        </p>
      </Alert>
      <SiteSummary />
      <h4 className="text-white mt-3">Classrooms</h4>
      <ClassroomListTable />
      <h4 className="text-primary mt-3">Teacher Explore</h4>
      <SiteTeacherExploreReport />
    </>
  );
};

export default AdminDashboard;
