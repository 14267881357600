import React, { useState } from "react";
import SchoolListDropDown from "../../../components/school/SchoolListDropdown";
import SiteTeacherExploreTable from "./SiteTeacherExploreTable";

const SiteTeacherExploreReport = () => {
  const [schoolRef, setSchoolRef] = useState(null);
  return (
    <>
      <SchoolListDropDown
        onSchoolChange={(schoolRef) => setSchoolRef(schoolRef)}
        showAll={true}
      />
      <SiteTeacherExploreTable schoolRef={schoolRef} />
    </>
  );
};

export default SiteTeacherExploreReport;
