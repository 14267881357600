import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { loadCourseCurriculum } from "../../actions";
import "../../style/ck-content.css";
import { ListTask } from "react-bootstrap-icons";
import ModalButton from "../util/ModalButton";
import CourseEditor from "./courseEditor/CourseEditor";
import TaskCreateEditForm from "../task/SingleTaskEditView";
import { getFirstCourseTaskLinkRefFromCurriculum } from "../../utils/DataLogicUtils";

const CourseEditorView = ({ courseRef }) => {
  const [courseCurriculumData, setCourseCurriculumData] = useState(null);
  const [shouldReloadCurriculumData, setShouldReloadCurriculumData] =
    useState(false);
  const [displayingCourseTaskLinkRef, setDisplayingCourseTaskLinkRef] =
    useState(null);
  const [isEditingTask, setIsEditingTask] = useState(false);
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (courseRef)
      loadCourseCurriculum(courseRef, (data) => {
        setCourseCurriculumData(data);
        // if there is a url parameter, set the task link ref to that
        if (window.location.search) {
          const urlParams = new URLSearchParams(window.location.search);
          const taskLinkRef = urlParams.get("task");
          if (taskLinkRef) {
            setDisplayingCourseTaskLinkRef(taskLinkRef);
          }
        } else {
          // TODO: need to avoid hidden task in Student Course View
          setDisplayingCourseTaskLinkRef(
            getFirstCourseTaskLinkRefFromCurriculum(data)
          );
        }
        isFirstRun.current = false;
      });
  }, []);

  useEffect(() => {
    if (!isFirstRun.current && shouldReloadCurriculumData) {
      loadCourseCurriculum(courseRef, (data) => {
        setCourseCurriculumData(data);
        setShouldReloadCurriculumData(false);
      });
    }
  }, [shouldReloadCurriculumData, courseRef]);

  const CurriculumListModalButton = () => (
    <div
      className="d-block d-lg-none mt-6 rounded-circle text-light"
      style={{
        backgroundColor: "rgba(255, 168, 52, 0.6)",
        position: "fixed",
        height: "4rem",
        width: "4rem",
        zIndex: "3",
      }}
    >
      <ListTask
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        size={32}
      />
    </div>
  );

  const ModalCurriculumMenu = ({ courseCurriculumData, handleCloseForm }) => (
    <EditableCurriculumMenu
      courseCurriculumData={courseCurriculumData}
      handleItemSelected={handleCloseForm}
    />
  );

  const EditableCurriculumMenu = ({ courseCurriculumData }) => {
    return (
      <div>
        {courseCurriculumData && (
          <CourseEditor
            courseRef={courseRef}
            courseTitle={courseCurriculumData.courseDetail.project.title}
            handleSwitchTaskEdit={(courseTaskLinkRef, allowSameTask = true) => {
              if (
                allowSameTask &&
                courseTaskLinkRef === displayingCourseTaskLinkRef
              )
                return true;
              if (isEditingTask) {
                return false;
              }
              setDisplayingCourseTaskLinkRef(courseTaskLinkRef);
              return true;
            }}
            displayingCourseTaskLinkRef={displayingCourseTaskLinkRef}
            parentCourseCurriculumData={courseCurriculumData}
            handleCurriculumUpdate={(data) => {
              setCourseCurriculumData(data);
              // setShouldReloadCurriculumData(true);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <Container fluid className="h-100 w-100">
      <ModalButton
        customisedClickableComponent={<CurriculumListModalButton />}
        customisedModalBodyClassName="p-0"
        variant="link"
        className="text-primary"
        modalTitle="Choose a Task"
      >
        <ModalCurriculumMenu courseCurriculumData={courseCurriculumData} />
      </ModalButton>
      <div
        className="h-100 bg-white mt-7 pb-10 d-none d-lg-block"
        style={{
          overflowY: "scroll",
          width: "24rem",
          position: "fixed",
          zIndex: 1,
        }}
      >
        <EditableCurriculumMenu courseCurriculumData={courseCurriculumData} />
      </div>
      <div className="d-flex h-100 w-100">
        <div
          className="h-100 d-lg-block d-none"
          style={{ paddingLeft: "24rem" }}
        ></div>

        <div className="h-100 pt-6 pt-lg-7 course-task-view-container">
          {!courseCurriculumData ? (
            ""
          ) : displayingCourseTaskLinkRef &&
            displayingCourseTaskLinkRef in
              courseCurriculumData.courseTaskLinks ? (
            <TaskCreateEditForm
              mode="edit"
              taskRef={
                courseCurriculumData.courseTaskLinks[
                  displayingCourseTaskLinkRef
                ].taskRef
              }
              onSaveSuccess={() => {
                console.log("Save success");
                setShouldReloadCurriculumData(true);
              }}
              onStartEdit={() => {
                setIsEditingTask(true);
              }}
              onEndEdit={() => {
                setIsEditingTask(false);
              }}
            />
          ) : (
            <div
              className="h-100 w-100 d-flex text-muted"
              style={{ alignItems: "center" }}
            >
              <div className="m-auto text-center">
                <h3>No task selected</h3>
                <p>Please select a task from the left-hand menu</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CourseEditorView;
