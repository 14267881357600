import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { loadCourseDetail, deleteCourse } from "../../actions";

const CourseDeleteForm = ({ courseRef, onSaveSuccess }) => {
  const [courseDetailData, setCourseDetailData] = useState(null);
  const [courseDeleteSuccess, setCourseDeleteSuccess] = useState(null);

  useEffect(() => {
    loadCourseDetail(courseRef, (data) => setCourseDetailData(data));
  }, [courseRef]);

  const handleDelete = (event) => {
    const data = {
      courseRef: courseRef,
    };
    deleteCourse(
      data,
      () => {
        setCourseDeleteSuccess(true);
        onSaveSuccess();
      },
      () => setCourseDeleteSuccess(false)
    );
  };

  return (
    <div>
      <p>
        Are you sure you want to delete: "
        {courseDetailData === null ? "" : courseDetailData.title}"?
      </p>
      <br /> <br />
      <Button
        size="md"
        variant="primary"
        type="submit"
        disabled={courseDeleteSuccess}
        onClick={handleDelete}
        block
      >
        Delete
      </Button>
      {courseDeleteSuccess ? <Alert variant="success">Deleted.</Alert> : null}
    </div>
  );
};

export default CourseDeleteForm;
