import React from "react";
import { Col, Row } from "react-bootstrap";
import { ExploreCourseCard } from "../course/CourseCard";

const CourseCardsFlat = ({
  courseList,
  handleClick = () => {},
  isRowNoWrap = false,
  isSchoolCoursePurchaseModel,
  purchasedCourseList,
}) => {
  const purchasedCourseRefList = purchasedCourseList?.map(
    (item) => item.courseRef
  );
  const isCoursePurchased = (course) => {
    return (
      isSchoolCoursePurchaseModel &&
      purchasedCourseRefList?.includes(course.courseRef)
    );
  };
  return (
    <Row style={isRowNoWrap ? { flexWrap: "nowrap" } : null}>
      {courseList.courses.map((course) => {
        const project = courseList.projectDict[course.projectRef];
        return (
          <Col
            key={course.courseRef}
            xl={3}
            lg={4}
            sm={6}
            xs={12}
            itemID={course.courseRef}
          >
            <ExploreCourseCard
              course={course}
              project={project}
              isPurchased={isCoursePurchased(course)}
              onClick={() =>
                handleClick({
                  course: course,
                  project: project,
                  shouldPurchaseCourse:
                    isSchoolCoursePurchaseModel && !isCoursePurchased(course),
                })
              }
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default CourseCardsFlat;
