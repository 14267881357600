import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import MyCourses from "../../components/classroom/MyCoursesView";
import Can from "../../utils/Can";
import HeaderPageContainer from "../common/HeaderPageContainer";
import MyTeacherEditableCourses from "../../components/classroom/MyTeacherEditableCoursesView";

const TeacherRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <div className="mt-7">
      <Switch>
        <Route exact path={`${path}`} children={<Redirect to={`/explore`} />} />
        <Route exact path={`${path}/enrolled`} children={<MyCourses />} />
        <Route
          exact
          path={`${path}/my-courses`}
          children={<MyTeacherEditableCourses />}
        />
      </Switch>
    </div>
  );
};

const TeacherPage = () => {
  return (
    <HeaderPageContainer>
      <Can
        perform="enrolled:teacher"
        yes={() => <TeacherRoutes />}
        no={() => <Redirect to={"/404"} />}
      />
    </HeaderPageContainer>
  );
};

export default TeacherPage;
