import React, { useState } from "react";
import { getNewPasswordValid } from "../../../utils/GenericUtils";
import { Form, Button, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { WEBSITE_LINKS } from "../../../utils/constants";

const SignUpForm = ({ handleSubmit, isSubmitting, errorCode }) => {
  const [showPasswordNotMatch, setShowPasswordNotMatch] = useState(false);
  const [newPasswordInValid, setNewPasswordInValid] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setShowPasswordNotMatch(false);
    setNewPasswordInValid(false);
    const form = event.target;
    const newPassword = form.elements.password.value;
    if (newPassword !== form.elements.passwordAgain.value) {
      setShowPasswordNotMatch(true);
      return;
    }

    if (!getNewPasswordValid(newPassword)) {
      setNewPasswordInValid(true);
      return;
    }
    if (!form.agreement) {
      alert(
        "You must agree to the terms of use in order to access this platform. Please review and accept the terms of use before continuing."
      );
      return;
    }

    handleSubmit(event);
  };

  return (
    <Form onSubmit={onSubmit} className="text-dark">
      <Form.Row>
        <Form.Group className="required" as={Col} controlId="formGridFirstName">
          <Form.Label>First name</Form.Label>
          <Form.Control
            className="text-dark"
            name="firstName"
            type="text"
            placeholder="First name"
            required
          />
        </Form.Group>

        <Form.Group className="required" as={Col} controlId="formGridLastName">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            className="text-dark"
            name="lastName"
            type="text"
            placeholder="Last name"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          className="required"
          as={Col}
          controlId="formGridSchoolName"
        >
          <Form.Label>School name</Form.Label>
          <Form.Control
            className="text-dark"
            name="schoolName"
            type="text"
            placeholder="School name"
            required
          />
        </Form.Group>

        <Form.Group className="required" as={Col} controlId="formGridPassword">
          <Form.Label>Country</Form.Label>
          <Form.Control
            className="text-dark"
            name="country"
            type="text"
            placeholder="Country"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group className="required" as={Col} controlId="formGridEmail">
          <Form.Label>School email address</Form.Label>
          <Form.Control
            className="text-dark"
            name="email"
            type="email"
            placeholder="Enter school email"
            required
            isInvalid={errorCode}
          />
          <Form.Control.Feedback type="invalid">
            {// USER_ALREADY_EXISTS = 408
            // NOT_SCHOOL_EMAIL = 409
            // VALIDATION_REQUIRED = 410
            errorCode === 408 && (
              <>
                This account already exists. Please{" "}
                <a className="text-underline" href="/">
                  sign in here
                </a>
              </>
            )}
            {errorCode === 409 && (
              <>
                We don't recognise this as a school email address. Please use
                your school email to sign up. <br />
                Stuck? Contact{" "}
                <a href="mailto:support@curaeducation.com">
                  <u>support@curaeducation.com</u>
                </a>
              </>
            )}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group className="required" as={Col} controlId="formGridPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            className="text-dark"
            name="password"
            type="password"
            placeholder="Password"
            required
            isInvalid={newPasswordInValid}
          />
          <Form.Control.Feedback type="invalid">
            Passwords must be strong enough, with at least 6 characters, digits,
            lowercase/uppercase letters and special characters(e.g. $#%^*?:).
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          className="required"
          controlId="formGridPasswordAgain"
        >
          <Form.Label>Re-enter password</Form.Label>
          <Form.Control
            className="text-dark"
            name="passwordAgain"
            type="password"
            placeholder="Password"
            required
            isInvalid={showPasswordNotMatch}
          />
          <Form.Control.Feedback type="invalid">
            Passwords don't match.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          controlId="formGridAgeement"
          className="text-sm my-auto ml-1"
        >
          <Form.Check
            type="checkbox"
            id="isTeacher-checkbox"
            name="isTeacher"
            label={
              <>
                I confirm that I am a teacher at a school or other educational
                institution
                <span className="text-danger">*</span>
              </>
            }
            required
          />
          <Form.Check
            type="checkbox"
            id="agreement-checkbox"
            name="agreement"
            label={
              <>
                I've read and agreed to the{" "}
                <a
                  className="text-underline"
                  href={WEBSITE_LINKS.TERMS}
                  target="_blank"
                  rel="noreferrer"
                >
                  terms of use
                </a>{" "}
                and{" "}
                <a
                  className="text-underline"
                  href={WEBSITE_LINKS.PRIVACY}
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy
                </a>
                <span className="text-danger">*</span>
              </>
            }
            required
          />
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="formGridSubmit"
          className="text-right mt-3 my-xs-auto ml-5"
        >
          <Button
            aria-label="signup"
            size="md"
            variant="warning text-white rounded-pill"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner animation="border" variant="light" className="mx-4" />
            ) : (
              <>
                Start trial &nbsp;&nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </>
            )}
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export default SignUpForm;
