import React from "react";
import { Alert } from "react-bootstrap";

export const NonSchoolUserCreateClassroomAlert = () => (
  <Alert variant="warning">
    <p>
      Your account is still being authenticated by the Cura team. You can't
      create a classroom until this is complete.
    </p>
    <p>This won't take long. Try again tomorrow!</p>
    <p>
      Questions? Email us at{" "}
      <a href="mailto:support@curaeducation.com">
        <u>support@curaeducation.com</u>
      </a>
    </p>
  </Alert>
);
