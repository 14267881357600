import React from "react";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import {
  Alarm,
  ExclamationTriangleFill,
  PersonCheckFill,
} from "react-bootstrap-icons";
import { useCountDown } from "ahooks";

const OTPSubmitForm = ({
  handleSubmit,
  handleResend,
  isSubmitting,
  invalidType,
  otpInValid,
}) => {
  const onEnd = () => {};
  const [countdown, setTargetDate] = useCountDown({ onEnd: onEnd });
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col xs="auto" className="px-0">
          <Form.Control
            className="mb-2 mr-2"
            name="code"
            placeholder="Enter code"
            style={{ maxWidth: "12rem" }}
            required
            isInvalid={otpInValid}
          />
          <Form.Control.Feedback type="invalid" style={{ maxWidth: "12rem" }}>
            {invalidType && invalidType === 2 ? (
              <>
                <ExclamationTriangleFill /> Incorrect code. Please try again.
              </>
            ) : null}
            {invalidType && invalidType === 1 ? (
              <>
                <Alarm /> This code has expired. Please click link below to sign
                up again.
              </>
            ) : null}
          </Form.Control.Feedback>
          <Button
            variant="link"
            size="sm"
            className="p-0 m-0"
            disabled={invalidType === 1 || countdown !== 0}
            onClick={() => {
              setTargetDate(Date.now() + 60 * 1000);
              handleResend();
            }}
          >
            <u>
              {countdown === 0
                ? "Resend code"
                : `Resend code in ${Math.round(countdown / 1000)}s`}
            </u>
          </Button>
        </Col>
        <Col xs="auto" className="px-0">
          <Button
            type="submit"
            variant="success"
            className="pl-4 mb-2"
            disabled={isSubmitting}
            style={{ minWidth: "7.25rem" }}
          >
            {isSubmitting ? (
              <Spinner
                size="sm"
                className="mb-1"
                animation="border"
                variant="light"
              />
            ) : (
              <>
                <PersonCheckFill className="mb-1 mr-1" />
                Verify
              </>
            )}
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default OTPSubmitForm;
