import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsUpDownLeftRight,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card } from "react-bootstrap";
import { Tooltip } from "antd";

const TaskCardBody = ({
  task,
  courseTaskLink,
  handleDelete = null,
  handleHide = null,
}) => {
  const IconButton = ({ icon, className, onClick, toolTipText }) => {
    return (
      <Tooltip title={toolTipText}>
        <div className={`ml-2 cursor-pointer ${className}`} onClick={onClick}>
          <FontAwesomeIcon icon={icon} className="align-middle" />
        </div>
      </Tooltip>
    );
  };
  const isHidden = courseTaskLink.isHidden;
  return (
    <Card.Body
      className={`p-2 d-flex justify-content-between ${
        isHidden ? "text-secondary" : "text-dark"
      }`}
    >
      <div className="text-truncate text-sm my-auto">{task.title}</div>
      <div className={`text-right d-flex`}>
        <IconButton
          icon={isHidden ? faEyeSlash : faEye}
          className={isHidden ? "text-secondary" : "text-primary"}
          onClick={handleHide}
          toolTipText={
            courseTaskLink.isHidden
              ? "Show this task to students"
              : "Hide this task from students"
          }
        />
        <IconButton
          icon={faTrashCan}
          className="text-danger"
          onClick={handleDelete}
          toolTipText="Delete task"
        />
        <IconButton
          icon={faArrowsUpDownLeftRight}
          className="text-secondary"
          toolTipText="Drag to reorder task"
        />
      </div>
    </Card.Body>
  );
};

export default TaskCardBody;
