import React from "react";
import ErrorPage from "./ErrorPage";

const Page404 = () => {
  return (
    <ErrorPage
      heading="404"
      message="Sorry, the page you are looking for could not be found."
      imageUrl="/img/svg/illustrations/monitor.svg"
    />
  );
};

export default Page404;
