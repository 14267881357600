import React, { useState, useEffect } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { duplicateUserCourse, existsUserCourseLink } from "../../actions";
import { TEXT_FIELD_MAX_LENGTH } from "../../utils/constants";
import { useSelector } from "react-redux";

const UserCourseDuplicateForm = ({
  courseRef,
  fromPage,
  onSaveSuccess,
  handleCloseForm,
}) => {
  const { addToast } = useToasts();
  const [userCourseLinkExists, setUserCourseLinkExists] = useState(null);

  const userRef = useSelector((state) => state.auth.userDetail.id);

  useEffect(() => {
    if (fromPage === "EXPLORE" || fromPage === "COURSE_PREVIEW") {
      const data = {
        courseRef,
        userRef,
      };
      existsUserCourseLink(data, (data) => {
        if (data.exists) setUserCourseLinkExists(true);
      });
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      classroomName: event.target.classroomName.value,
      subject: event.target.subject.value,
      courseRef: courseRef,
    };
    const params = [
      data,
      (data) => {
        addToast("Course created", {
          appearance: "success",
          autoDismiss: true,
        });
        onSaveSuccess(data);
        if (handleCloseForm) handleCloseForm();
      },
      () => {
        addToast("Saving course failed. Please try again later.", {
          appearance: "error",
          autoDismiss: false,
        });
        if (handleCloseForm) handleCloseForm();
      },
    ];
    duplicateUserCourse(...params);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {userCourseLinkExists ? (
        <Alert variant="warning">
          <p>
            You already have this course in your My Courses page. If you want to
            duplicate this version, you can do so from the My Courses page.
          </p>
          <p>
            Create a new course here if you want to create a second, unrelated
            version using this course's default content
          </p>
        </Alert>
      ) : (
        <Alert variant="success">
          <p>Once saved, you can find your course in "My Courses"</p>
        </Alert>
      )}

      <Form.Group controlId="formName">
        <Form.Label>Classroom Name:</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. 7A"
          name="classroomName"
          required
          maxLength={TEXT_FIELD_MAX_LENGTH.CLASSROOM_TITLE}
        />
      </Form.Group>
      <Form.Group controlId="formSubject">
        <Form.Label>Subject:</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Geology"
          name="subject"
          required
          maxLength={TEXT_FIELD_MAX_LENGTH.CLASSROOM_SUBJECT_NAME}
        />
      </Form.Group>
      <Button className="mt-5" type="submit" size="md" variant="primary" block>
        Save
      </Button>
    </Form>
  );
};

export default UserCourseDuplicateForm;
