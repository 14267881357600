import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import React from "react";
import { ListGroup } from "react-bootstrap";
import {
  FileCheck,
  BoundingBoxCircles,
  ColumnsGap,
  Box,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { RoleType } from "../../utils/constants";

const CurriculumList = ({
  courseCurriculumData,
  selectedCourseTaskLinkRef,
  handleTaskClick,
}) => {
  const isStudent = useSelector(
    (state) => state.auth.userDetail?.userInfo.roleTypeName === RoleType.STUDENT
  );
  const SectionItem = ({ index, title, subtitle, icon, isAction }) => {
    return (
      <ListGroup.Item
        className={`${
          index !== 0 ? "border-top" : "border-top-0"
        } border-left-0 border-right-0 border-bottom-0 pt-3 pl-2 pb-2${
          isAction ? " action" : null
        }`}
      >
        <div className="d-flex align-items-center">
          <div className="avatar rounded-circle border-primary border mr-3">
            {icon ? icon : <FileCheck className="text-primary" size={24} />}
          </div>
          <div>
            <p className="d-block text-xs text-dark mb-0">{title}</p>
            <p className="card-text text-md text-primary font-weight-bold mb-0">
              {subtitle}
            </p>
          </div>
        </div>
      </ListGroup.Item>
    );
  };
  const sectionIconProps = {
    className: "text-primary",
    size: 24,
  };
  const getTitleList = (courseCurriculumData) =>
    courseCurriculumData.sectionOrder.map((sectionRef, index) => {
      const section = courseCurriculumData.sections[sectionRef];
      const linkDict = courseCurriculumData.courseTaskLinks;
      const taskDict = courseCurriculumData.tasks;
      let visibleTaskList = section.courseTaskLinkList;
      // Hide task from students
      if (isStudent) {
        visibleTaskList = section.courseTaskLinkList.filter(
          (linkRef) => !linkDict[linkRef].isHidden
        );
      }
      let displayIndex = 0;
      const tasks = visibleTaskList.map((linkRef, index) => {
        const courseTaskLink = linkDict[linkRef];
        const isHidden = courseTaskLink.isHidden;
        if (!isHidden) {
          displayIndex += 1;
        }
        const taskRef = courseTaskLink.taskRef;
        const task = taskDict[taskRef];
        const linkLineStyle = {
          position: "absolute",
          height: "50%",
          borderLeft: "solid",
          borderStyle: "solid",
          borderRight: 0,
          borderWidth: "1px",
        };
        return (
          <ListGroup.Item
            action
            key={linkRef}
            eventKey={linkRef}
            onClick={() => handleTaskClick(linkRef)}
            className={`border-0 rounded py-2`}
          >
            <div
              className={`w-100 ${isHidden ? "text-secondary" : "text-dark"}`}
            >
              <div className="inline-block">
                {index > 0 && (
                  <div
                    className="border-secondary"
                    style={{
                      ...linkLineStyle,
                      left: "calc(2rem - 1px)",
                      top: "-0.5rem",
                    }}
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    width: "1rem",
                    height: "1rem",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    top: "50%",
                    left: "calc(2rem - 1px)",
                    transform: "translate(-50%, -50%)",
                  }}
                  className={`${
                    selectedCourseTaskLinkRef === linkRef
                      ? "bg-primary border-0"
                      : "bg-white"
                  } rounded-circle border-secondary`}
                />
                {index + 1 !== visibleTaskList.length && (
                  <div
                    className="border-secondary"
                    style={{
                      ...linkLineStyle,
                      left: "calc(2rem - 1px)",
                      top: "calc(50% + 0.5rem)",
                    }}
                  />
                )}
              </div>
              <div
                className={`${
                  selectedCourseTaskLinkRef === linkRef
                    ? "font-weight-bold"
                    : ""
                } mt-0 d-flex justify-content-between`}
                style={{
                  position: "relative",
                  width: "calc(100% - 3rem)",
                  left: "3rem",
                  wordBreak: "break-word",
                }}
              >
                <div className="d-inline-block">
                  {!isHidden && <b className="text-primary">{displayIndex}.</b>}{" "}
                  {task.title}{" "}
                </div>
                <div className="d-inline-block my-auto">
                  {isHidden && (
                    <Tooltip title="Hidden from students">
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </ListGroup.Item>
        );
      });

      const icons = [
        <BoundingBoxCircles {...sectionIconProps} />,
        <ColumnsGap {...sectionIconProps} />,
        <Box {...sectionIconProps} />,
      ];
      return (
        <div key={sectionRef}>
          <SectionItem
            index={index}
            title={`LEVEL ${index + 1}`}
            subtitle={section.title}
            icon={icons[index % icons.length]}
          />
          {tasks}
        </div>
      );
    });
  return courseCurriculumData && <>{getTitleList(courseCurriculumData)}</>;
};

export default CurriculumList;
